<template>
  <button
    type="button"
    v-html="placeholder('%label.offer.ctabtn%', $t('entity.buttons.book'))"
    :disabled="btnDisabled"
    :class="{
      'custom-italic': page.design_data.style?.cta?.italic,
      'custom-bold': page.design_data.style?.cta?.bold,
      'd-none': page.config?.disable_checkin === 1
    }"
    class="btn action-book btn-success btn-lg"
    @click.prevent="bookAction(false)"
  />
</template>
<script setup>
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useBookingMethods } from "@/composables/useBookingMethods";

const { placeholder } = useCheckoutData();

const { bookAction } = useBookingMethods();

defineProps({
  btnDisabled: { type: Boolean, required: true },
  page: { type: Object, required: true }
});
</script>
<style scoped></style>