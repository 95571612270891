var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"ynv-container treatments p-0",attrs:{"id":"treatments-component"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-center treatment-title",domProps:{"innerHTML":_vm._s(
          _vm.placeholder(
            '%label.spa.title%',
            _vm.$t('entity.treatment.booked_spa_header')
          )
        )}})])],1),_c('b-row',{staticClass:"mt-0 no-gutters"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"12"}},[_vm._l((_vm.treatmentsData.filter(
          (t) => t.product_type == 'package'
        )),function(treatment){return [_c('div',{key:treatment.id},[(treatment.product_type == 'package')?_c('Package',{attrs:{"styleData":_vm.styleData,"treatment-data":treatment,"page":_vm.page}}):_vm._e()],1)]}),_vm._l((_vm.treatmentsData.filter(
          (t) => t.product_type == 'spa'
        )),function(treatment){return [_c('div',{key:treatment.id},[(treatment.product_type == 'spa')?_c('spa',{attrs:{"page":_vm.page,"styleData":_vm.styleData,"treatment-data":treatment}}):_vm._e()],1)]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }