<template>
  <div id="checkout-main" class="pt-4 pb-0 pb-lg-4">
    <b-container class="checkout-main-container px-2 px-lg-5">
      <b-row class="mx-0">
        <b-col class="px-0 mb-0">
          <h4
            class="pb-2"
            v-html="placeholder('%label.checkout.title%', '')"
          ></h4>
          <p v-html="placeholder('%label.checkout.text%', '')"></p>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col cols="12">
          <CheckoutForm />
        </b-col>
      </b-row>
      <b-row class="mt-4 d-lg-none">
        <b-col cols="12" class="show-details">
          <PriceDetails @hideBtnBook="hideBtnBook()" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xl="8">
          <Promocode
            v-if="selectedVariant.promocode_enabled"
            :priceDetails="selectedVariant.price_details"
            :requestURL="backendURL() + page.hash"
            @render="renderData($event)"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="
          reservation_process === 'twosteps' &&
          selectedVariant.booking_action === 'payment'
        "
        class="mt-5"
      >
        <b-col cols="12">
          <p v-html="placeholder('%label.checkout.customer_payment%', '')"></p>
        </b-col>
        <b-col cols="12" class="mt-3 mt-lg-2">
          <div>
            <pay-methods
              :selectedVariant="selectedVariant"
              :book-label="
                placeholder('%label.offer.ctabtn%', $t('entity.buttons.book'))
              "
              direction="stacked"
              @change="selectedPaymentValue"
              @save="bookAction(true)"
              :page="page"
              :isLoading="isLoading"
            />
          </div>
        </b-col>
      </b-row>
      <b-row class="my-5 pt-1 mb-xl-0">
        <b-col cols="12" class="terms-link mb-4">
          <b-form-checkbox v-model="form.terms" :disabled="isLoading" data-cy="checkoutTerms">
            <a v-if="page.agb_url" :href="page.agb_url" target="_blank">
              <span
                v-html="placeholder('%label.checkout.terms%', $t('form.terms'))"
              />
            </a>
            <span v-else>
              <span
                v-html="placeholder('%label.checkout.terms%', $t('form.terms'))"
              />
            </span>
          </b-form-checkbox>
          <b-form-checkbox
            v-model="request.newsletter"
            class="mt-2"
            :disabled="isLoading"
            data-cy="checkoutNewsletter"
          >
            <span
              v-html="
                placeholder(
                  '%label.checkout.newsletter%',
                  $t('form.newsletter')
                )
              "
            />
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" lg="5" xl="4" class="ml-auto">
          <div id="tooltip-button-disable-terms">
            <button
              v-if="reservation_process == 'twosteps'"
              :disabled="
                hasPayments
                  ? (!selectedPaymentMethod && !btnDisabled) ||
                    !form.terms ||
                    isLoading
                  : btnDisabled || !form.terms || isLoading
              "
              type="button"
              class="btn action-book btn-success btn-lg book-action"
              :class="{
                'custom-italic': page.design_data.style?.cta?.italic,
                'custom-bold': page.design_data.style?.cta?.bold
              }"
              data-cy="twoStepsBookButton"
              @click.prevent="bookAction(true)"
            >
              <template>
                <span
                  v-html="
                    placeholder(
                      '%label.checkout.ctabtn%',
                      $t('entity.buttons.book')
                    )
                  "
                ></span>
                <b-spinner
                  v-if="isLoading"
                  small
                  variant="light"
                  class="ml-2"
                ></b-spinner>
              </template>
            </button>
            <button
              v-else
              v-html="
                placeholder(
                  '%label.checkout.ctabtn%',
                  $t('form.easy_reservation')
                )
              "
              :disabled="
                hasPayments
                  ? (!selectedPaymentMethod && !btnDisabled) ||
                    !form.terms ||
                    isLoading
                  : btnDisabled || !form.terms || isLoading
              "
              type="button"
              class="btn action-book btn-success btn-lg mt-3 finalize-booking"
              :class="{
                'custom-italic': page.design_data.style?.cta?.italic,
                'custom-bold': page.design_data.style?.cta?.bold
              }"
              data-cy="finalizeBookingButton"
              @click="finalizeBooking()"
            >
              <template>
                <span
                  v-html="
                    placeholder(
                      '%label.checkout.ctabtn%',
                      $t('form.easy_reservation')
                    )
                  "
                ></span>
                <b-spinner
                  v-if="isLoading"
                  small
                  variant="light"
                  class="ml-2"
                ></b-spinner>
              </template>
            </button>
          </div>
          <b-tooltip
            :disabled="form.terms"
            ref="tooltip"
            target="tooltip-button-disable-terms"
          >
            {{ $t("form.check_terms") }}
          </b-tooltip>
        </b-col>
      </b-row>
    </b-container>
    <CheckoutModals />
  </div>
</template>

<script setup>
import { reactive, inject, defineEmits, ref, watchEffect } from "vue";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useBookingMethods } from "@/composables/useBookingMethods";
import { useUpselling } from "@/composables/useUpselling";
import CheckoutModals from "./CheckoutModals";
import CheckoutForm from "./CheckoutForm";
import Promocode from "@/components/Promocode";
import PayMethods from "@/components/PayMethods";
import PriceDetails from "./PriceDetails";

const {
  placeholder,
  backendURL,
  page,
  request,
  selectedVariant,
  reservation_process
} = useCheckoutData();
const {
  bookAction,
  finalizeBooking,
  selectedPaymentValue,
  selectedPaymentMethod,
  isLoading
} = useBookingMethods();
const { upselling } = useUpselling();

const renderData = inject("renderData");
const btnDisabled = inject("btnDisabled");

const emit = defineEmits(["hideBtnBook"]);

const form = reactive({
  terms: false,
  newsletter: false
});

const hasPayments = ref(selectedVariant.value.booking_action === "payment");
const isUpselling = ref(false);
const borderRight = ref('2px solid #f3f3f3');

watchEffect(() => {
  isUpselling.value = page?.upselling_enabled && page?.entity_id && upselling?.value?.length;
  borderRight.value = isUpselling.value ? 'none' : '2px solid #f3f3f3';
})

function hideBtnBook() {
  emit("hideBtnBook");
}
</script>

<style scoped>
.show-details >>> .show-table a {
  font-weight: 500;
}

.show-details >>> .summary-footer {
  margin-top: 0px !important;
}

.show-details >>> .price-wrapper {
  margin-top: 25px !important;
  gap: 20px !important;
}

.show-details >>> .price-total-label.deposit:before {
  content: none !important;
}

.show-details >>> .price-total-label.deposit {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.show-details >>> .price-total-label {
  text-align: left !important;
  font-weight: 500;
}

.show-details >>> .price-total-value {
  text-align: left !important;
}

.checkout-main-container >>> .price-promocode .input-group {
  margin-right: 0px !important;
}

.checkout-main-container >>> .price-promocode .btn {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.btn.action-book:active {
  font-size: 14px;
}

.action-book {
  width: 100%;
}

@media (min-width: 999px) {
  #checkout-main {
    border-right: v-bind(borderRight);
  }
}
</style>