<template>
  <div id="header-component" class="header-section">
    <div class="component-header">
      <template v-if="override != 2">
        <template
          v-for="block in Object.keys(headerData).filter(
            (type) => type != 'title'
          )"
        >
          <div :key="block">
            <gallery
              v-if="block == 'gallery' && galleryDataComputed.length"
              :galleryData="galleryDataComputed"
              :title="galleryTitleComputed"
              :styleData="styleDataComputed"
              :account="accountData"
              :config-data="configData"
              :audioColor="galleryAudioBtnColorComputed"
            />
            <custom-element
              v-if="block == 'block'"
              :content="headerData[block][0]"
            ></custom-element>
          </div>
        </template>
      </template>
      <template v-else-if="override == 2">
        <custom-element
          v-if="headerData.block"
          :content="headerData.block[0]"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Gallery from "@/components/blocks/header/Gallery";
import CustomElement from "@/components/CustomElement";

export default {
  name: "Header",
  components: { Gallery, CustomElement },
  props: ["styleData", "headerData", "override", "accountData", "configData"],
  computed: {
    styleDataComputed() {
      return this.styleData?.header || {};
    },
    galleryTitleComputed() {
      const title = this.headerData?.title;
      const array = title?.length ? title[0] : null;
      return array?.content_data?.data?.content || "";
    },
    galleryDataComputed() {
      const gallery = this.headerData?.gallery;
      const arrGallery = gallery?.length ? gallery[0] : null;
      return arrGallery?.content_data?.data?.media || [];
    },
    galleryAudioBtnColorComputed() {
      return this.styleData?.cta?.audio_btn_color || 'white';
    }
  }
};
</script>