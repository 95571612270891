var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-header-gallery",on:{"mouseenter":function($event){_vm.mouseIn = true},"mouseleave":function($event){_vm.mouseIn = false}}},[(_vm.logoDataComputed)?_c('logo',{attrs:{"logoData":_vm.logoDataComputed,"logoPosition":_vm.logoConfigComputed}}):_vm._e(),(_vm.canShowSoundBtn)?_c('div',{staticClass:"audio rounded-circle",on:{"click":function($event){return _vm.audioControl()}}},[_c('div',{staticClass:"audio-icons-wrapper"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.v-secondary",value:(_vm.$t('page.gallery.mute_audio')),expression:"$t('page.gallery.mute_audio')",modifiers:{"hover":true,"bottom":true,"v-secondary":true}}],staticClass:"audio-tooltip"},[_c('b-icon',{staticClass:"muted mb-0",attrs:{"icon":"volume-up"}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom.v-secondary",value:(_vm.$t('page.gallery.unmute_audio')),expression:"$t('page.gallery.unmute_audio')",modifiers:{"hover":true,"bottom":true,"v-secondary":true}}],staticClass:"audio-tooltip"},[_c('b-icon',{staticClass:"unmuted mb-0",attrs:{"icon":"volume-mute"}})],1)])]):_vm._e(),_c('b-carousel',{ref:"headerCarousel",staticClass:"ynv-core-carousel overflow-hidden",attrs:{"fade":"","interval":_vm.headerCarouselInterval,"controls":_vm.galleryData.length > 1},on:{"sliding-start":function($event){return _vm.slideEnter($event)},"sliding-end":function($event){return _vm.slideEnd()}},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.galleryData),function(item){return _c('b-carousel-slide',{key:item.public_id,staticClass:"header-slide",class:{
        'custom-italic': 'italic' in _vm.styleData && _vm.styleData.italic,
        'custom-bold': 'bold' in _vm.styleData && _vm.styleData.bold,
        'type-video': item.type == 'video'
      },attrs:{"caption":_vm.title},scopedSlots:_vm._u([{key:"img",fn:function(){return [(item.type === 'video')?[(item.provider === 'cdn')?_c('videoPlayer',{ref:`cdn${item.public_id}`,refInFor:true,staticClass:"video-wrapper",attrs:{"options":{
              sources: [
                {
                  type: 'video/mp4',
                  src: item.public_url
                }
              ],
              autoplay: true,
              ..._vm.options
            }},on:{"ended":function($event){return _vm.onEnded('cdn')}}}):_vm._e(),_c('div',{staticClass:"video-background"},[(item.provider === 'youtube')?_c('YoutubePlayer',{ref:`youtube${item.public_id}`,refInFor:true,attrs:{"player-id":`youtube${item.public_id}`,"videoId":item.public_id,"loop":Number(_vm.galleryData.length === 1),"autoplay":_vm.canAutoplay() ? 1 : 0},on:{"ended":function($event){return _vm.onEnded('youtube')}}}):(item.provider === 'vimeo')?_c('vueVimeoPlayer',{ref:`vimeo${item.public_id}`,refInFor:true,attrs:{"allow":"autoplay","options":{
                autoplay: _vm.canAutoplay('vimeo'),
                ..._vm.options
              },"loop":false,"video-id":item.public_id},on:{"ended":function($event){return _vm.onEnded('vimeo')}}}):_vm._e()],1)]:[_c('div',{staticClass:"preview-dummy-img"},[_c('div',{staticClass:"img-container",style:('background-image: url(' +
                _vm.buildMediaURL(item.public_url, item.provider, '50x50') +
                ')')})]),_c('div',{staticClass:"image-wrapper",style:({
              'background-image':
                'url(' +
                _vm.buildMediaURL(
                  item.public_url,
                  item.provider,
                  _vm.returnImgWidth()
                ) +
                ')'
            })})]]},proxy:true}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }