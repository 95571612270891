var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.navigationItems && _vm.navigationItems.length > 0)?_c('div',{staticClass:"navi-line",attrs:{"id":"navigation-component"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-navigation",modifiers:{"sidebar-navigation":true}}],staticClass:"mobile-menu",class:{
      'menu-left':
        'header' in _vm.config &&
        _vm.config.header &&
        'logo' in _vm.config.header &&
        _vm.config.header.logo == 'end'
    },attrs:{"id":"mobile-menu"}},[_c('b-icon-list')],1),_c('b-sidebar',{attrs:{"right":"","sidebar-class":"","id":"sidebar-navigation","z-index":"2050"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"mx-4 contact-buttons",attrs:{"cols":"12"}},[_c('ul',{staticClass:"list-inline d-flex"},[(_vm.config.floating && _vm.config.floating.phone)?_c('li',{staticClass:"mr-2"},[_c('b-button',{staticClass:"tel",attrs:{"pill":""},on:{"click":function($event){return _vm.startConversationEmit('phone')}}},[_c('img',{staticStyle:{"filter":"invert(100%) sepia(0%) saturate(4810%)"},attrs:{"src":require("@/assets/icons/phone.svg"),"height":"20"}})])],1):_vm._e(),(_vm.config.floating && _vm.config.floating.email)?_c('li',{staticClass:"mr-2"},[_c('b-button',{staticClass:"email",attrs:{"pill":""},on:{"click":function($event){return _vm.startConversationEmit('email')}}},[_c('img',{staticStyle:{"margin-bottom":"3px","filter":"invert(100%) sepia(0%) saturate(4810%)"},attrs:{"src":require("@/assets/icons/mail.svg"),"height":"15"}})])],1):_vm._e(),(_vm.config.floating && _vm.config.floating.whatsapp)?_c('li',{staticClass:"mr-2"},[_c('b-button',{staticClass:"whatsapp",attrs:{"pill":""},on:{"click":function($event){return _vm.startConversationEmit('whatsapp')}}},[_c('img',{staticStyle:{"margin-bottom":"5px","filter":"invert(100%) sepia(0%) saturate(4810%)"},attrs:{"src":require("@/assets/icons/whatsapp.svg"),"height":"20"}})])],1):_vm._e()])]),(
          _vm.editor &&
          _vm.editor.data &&
          'content' in _vm.config &&
          'editor_position' in _vm.config.content &&
          _vm.config.content.editor_position != 'none'
        )?_c('div',{staticClass:"d-flex mx-4 mb-3"},[_c('editor',{attrs:{"page":_vm.page,"editor-data":_vm.editor.data,"account-data":_vm.account,"isSidebar":true}})],1):_vm._e()]},proxy:true}],null,false,3192231035)},[(_vm.account.logo && _vm.account.logo.data)?_c('div',{staticClass:"sidebar-logo"},[_c('div',{staticClass:"sidebar-logo-wrapper"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.getVal(_vm.account.logo.data.url) + '?d=200x200'}})])]):_vm._e(),_c('b-nav',{attrs:{"vertical":""}},_vm._l((_vm.navigationItems),function(navi,k){return _c('li',{key:k,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":navi.url,"target":navi.target}},[_c('span',{domProps:{"innerHTML":_vm._s(navi.title)}})])])}),0),(_vm.history.state && _vm.history.data.length > 0)?_c('div',{staticClass:"menu-stack"},[_c('b-btn',{staticClass:"history-btn",on:{"click":function($event){return _vm.$bvModal.show('show-history')}}},[_vm._v(" "+_vm._s(_vm.$t("core.history"))+" ")])],1):_vm._e()],1),_c('b-nav',{staticClass:"d-flex container ynv-core-navigation"},_vm._l((_vm.navigationItems),function(navi,k){return _c('li',{key:k,staticClass:"nav-item"},[(navi.url.indexOf('#') == 0)?_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],staticClass:"nav-link",class:{
          'custom-italic': _vm.settings.font.italic,
          'custom-bold': _vm.settings.font.bold
        },attrs:{"href":navi.url,"target":navi.target}},[_c('span',{domProps:{"innerHTML":_vm._s(navi.title)}})]):_c('a',{staticClass:"nav-link",class:{
          'custom-italic': _vm.settings.font.italic,
          'custom-bold': _vm.settings.font.bold
        },attrs:{"href":navi.url,"target":navi.target}},[_c('span',{domProps:{"innerHTML":_vm._s(navi.title)}})])])}),0),(_vm.history.state && _vm.history.data.length > 0)?_c('div',{staticClass:"menu-line"},[_c('b-btn',{staticClass:"history-btn",on:{"click":function($event){return _vm.$bvModal.show('show-history')}}},[_vm._v(" "+_vm._s(_vm.$t("core.history"))+" ")])],1):_vm._e(),_c('b-modal',{attrs:{"size":"lg","centered":"","hide-header":"","id":"show-history"}},[_c('div',{staticClass:"closeModal"},[_c('button',{on:{"click":function($event){return _vm.$bvModal.hide('show-history')}}},[_vm._v(" "+_vm._s(_vm.$t("entity.treatment.close"))+" "),_c('span',{staticClass:"closeIcon"},[_c('b-icon',{attrs:{"icon":"x"}})],1)])]),_c('div',{staticClass:"px-2 py-1 px-lg-5 py-lg-4"},[_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t("core.history")))]),_vm._l((_vm.history.data),function(historyItem,index){return _c('div',{key:historyItem.hash},[_c('b-list-group',[_c('b-list-group-item',{staticClass:"flex-column align-items-start",attrs:{"href":_vm.lpURL + '/v/' + historyItem.hash,"target":"_blank"}},[_c('div',{staticClass:"d-flex w-100"},[(historyItem.editor)?[_c('b-avatar',{staticClass:"mr-3",attrs:{"src":(historyItem.editor &&
                      _vm.getVal(historyItem.editor.avatar_url) &&
                      _vm.getVal(historyItem.editor.avatar_url) + '?d=53x53') ||
                    (_vm.account.logo &&
                      _vm.account.logo.data &&
                      _vm.account.logo.data.url &&
                      _vm.getVal(_vm.account.logo.data.url) + '?d=53x53'),"title":historyItem.editor.displayname
                      ? historyItem.editor.displayname
                      : historyItem.editor.firstname +
                        ' ' +
                        historyItem.editor.lastname,"variant":"light","size":"53px"}}),_c('div',{staticClass:"history-details"},[_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(_vm._s(_vm.$moment(historyItem.created_at).format("L | LT")))]),_c('div',[_vm._v(" "+_vm._s(historyItem.editor.displayname ? historyItem.editor.displayname : historyItem.editor.firstname + " " + historyItem.editor.lastname)+" ")])],1)]:[_c('b-avatar',{staticClass:"mr-3",attrs:{"src":_vm.account.logo &&
                    _vm.account.logo.data &&
                    _vm.account.logo.data.url &&
                    _vm.getVal(_vm.account.logo.data.url) + '?d=53x53',"title":_vm.account.name,"variant":"light","size":"53px"}}),_c('div',{staticClass:"history-details"},[_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(_vm._s(_vm.$moment(historyItem.created_at).format("L | LT")))]),_c('div',[_vm._v(_vm._s(_vm.account.name))])],1)],_c('div',{staticClass:"ml-auto d-flex align-items-center"},[_c('span',{staticClass:"mdi mdi-chevron-right",staticStyle:{"font-size":"30px"}})])],2)])],1),(index < _vm.history.data.length - 1)?_c('div',{staticClass:"history-divider"},[_vm._v(" ."),_c('br'),_vm._v(" ."),_c('br'),_vm._v(" . ")]):_vm._e()],1)})],2)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }