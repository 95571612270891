var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"print-content"},[_c('b-row',{staticClass:"print-header"},[_c('b-col',{staticClass:"text-center",staticStyle:{"margin-bottom":"10%"},attrs:{"cols":"12"}},[(_vm.logoDataComputed)?_c('b-img',{attrs:{"src":_vm.buildMediaURL(
            _vm.logoDataComputed.url,
            _vm.logoDataComputed.provider,
            '200x200'
          )}}):_vm._e()],1),_c('b-col',{staticClass:"account-data",attrs:{"cols":"6"}},[(_vm.page.account.name)?_c('div',[_vm._v(" "+_vm._s(_vm.page.account.name)+" ")]):_vm._e(),(_vm.page.account.address.data.street)?_c('div',[_vm._v(" "+_vm._s(_vm.page.account.address.data.street)+" ")]):_vm._e(),(
          _vm.page.account.address.data.city_zip ||
          _vm.page.account.address.data.city_name
        )?_c('div',[_vm._v(" "+_vm._s(_vm.page.account.address.data.city_zip)+" "+_vm._s(_vm.page.account.address.data.city_name)+" ")]):_vm._e(),(_vm.page.account.address.data.country_alpha3)?_c('div',[_vm._v(" "+_vm._s(_vm.page.account.address.data.country_alpha3 && _vm.countries.find( (c) => c.value == _vm.page.account.address.data.country_alpha3 ) ? _vm.countries.find( (c) => c.value == _vm.page.account.address.data.country_alpha3 ).text : "-")+" ")]):_vm._e(),(_vm.page.account.contact.data.phone)?_c('div',[_vm._v(" "+_vm._s(_vm.page.account.contact.data.phone)+" ")]):_vm._e(),(_vm.page.account.contact.data.email)?_c('div',[_vm._v(" "+_vm._s(_vm.page.account.contact.data.email)+" ")]):_vm._e(),(_vm.page.account.contact.data.website)?_c('div',[_vm._v(" "+_vm._s(_vm.page.account.contact.data.website.replace(/(^\w+:|^)\/\//, ""))+" ")]):_vm._e()]),(_vm.page.entity && _vm.page.entity.order_customer)?_c('b-col',{staticClass:"customer-data",attrs:{"cols":"6"}},[(_vm.page.entity.order_customer.companyname)?_c('div',[_vm._v(" "+_vm._s(_vm.page.entity.order_customer.companyname)+" ")]):_vm._e(),(
          _vm.page.entity.order_customer.firstname ||
          _vm.page.entity.order_customer.lastname
        )?_c('div',[_vm._v(" "+_vm._s(_vm.page.entity.order_customer.firstname)+" "+_vm._s(_vm.page.entity.order_customer.lastname)+" ")]):_vm._e(),(_vm.page.entity.order_customer.street)?_c('div',[_vm._v(" "+_vm._s(_vm.page.entity.order_customer.street)+" ")]):_vm._e(),(
          _vm.page.entity.order_customer.zip || _vm.page.entity.order_customer.city
        )?_c('div',[_vm._v(" "+_vm._s(_vm.page.entity.order_customer.zip)+" "+_vm._s(_vm.page.entity.order_customer.city)+" ")]):_vm._e(),(_vm.page.entity.order_customer.country_alpha3)?_c('div',[_vm._v(" "+_vm._s(_vm.page.entity.order_customer.country_alpha3 && _vm.countries.find( (c) => c.value == _vm.page.entity.order_customer.country_alpha3 ) ? _vm.countries.find( (c) => c.value == _vm.page.entity.order_customer.country_alpha3 ).text : "-")+" ")]):_vm._e(),(_vm.page.entity.order_customer.phone)?_c('div',[_vm._v(" "+_vm._s(_vm.page.entity.order_customer.phone)+" ")]):_vm._e(),(_vm.page.entity.order_customer.email)?_c('div',[_vm._v(" "+_vm._s(_vm.page.entity.order_customer.email)+" ")]):_vm._e()]):_vm._e()],1),_c('b-row',{staticClass:"print-price-table"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{domProps:{"innerHTML":_vm._s(
          _vm.placeholder('%label.offer.title%', _vm.$t('entity.offer')) +
          ': ' +
          _vm.page.entity.slug
        )}}),_c('pricetable',{attrs:{"selected-variant":_vm.selectedVariant,"page":_vm.page}})],1),(_vm.selectedVariant.price_note)?_c('b-col',{staticClass:"mt-3"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectedVariant.price_note)}})]):_vm._e()],1),_c('b-row',{staticClass:"print-payments px-3 mb-4"},[_c('ExecutedPayments',{staticClass:"pb-1",attrs:{"page":_vm.page,"lpLocale":_vm.lpLocale}})],1),_c('b-row',{staticClass:"print-cancel-policy"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("entity.cancel_policy.title")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.selectedVariant.cancelpolicy_data.description)}})]),_c('div',{staticClass:"copyright"},[_c('img',{attrs:{"height":"20","src":require("@/assets/powered_by_yanovis_positive.png"),"alt":"Powered by Yanovis","title":"Visions become software"}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }