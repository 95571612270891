var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{key:_vm.highlight.type,staticClass:"ynv-highlight-component ynv-accordion mx-0 collapse-item",attrs:{"no-body":""}},[_c('b-card-header',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('highlight-' + _vm.highlight.id),expression:"'highlight-' + highlight.id"}],staticClass:"ynv-accordion-header",attrs:{"header-tag":"div","role":"tab"},on:{"click":function($event){return _vm.toggleHighlight(_vm.highlight.id)}}},[_c('b-row',{staticClass:"header-row mx-0 my-0 px-0 py-0"},[_c('b-col',{staticClass:"title-container pl-0 pr-0",attrs:{"cols":"12","md":"11"}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.highlight.content_data.data.components[0].title)+" ")])]),_c('b-col',{staticClass:"d-flex justify-content-end pl-0 pr-md-2 pr-lg-3"},[_c('b-button',{staticClass:"align-self-center d-flex accordion-button",class:{
            square:
              _vm.designData.style &&
              'radius' in _vm.designData.style.general &&
              _vm.designData.style.general.radius != '' &&
              parseInt(_vm.designData.style.general.radius) == 0
          },attrs:{"pill":""}},[_c('div',{staticClass:"chevron when-open"},[_c('b-icon',{staticStyle:{"margin-bottom":"2px"},attrs:{"icon":"chevron-up"}})],1),_c('div',{staticClass:"chevron when-closed"},[_c('b-icon',{attrs:{"icon":"chevron-down"}})],1)])],1)],1)],1),_c('b-collapse',{attrs:{"visible":_vm.highlight.config.expanded,"id":'highlight-' + _vm.highlight.id,"role":"tabpanel"}},[(_vm.highlight.type != 'card')?_c('b-card-body',[(_vm.highlight.config.raw || !_vm.shadowRootEnabled)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight.content_data.data.components[0].content_html)}}):_c('shadow-root',{on:{"error":function($event){return _vm.handleShadowRootError($event)}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight.content_data.data.components[0].content_html)}})])],1):_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"6"}},[(
              _vm.getMedia(_vm.highlight).filter(
                (m) => m.scope == 'gallery' || m.scope == 'picture'
              ).length > 0
            )?_c('mini-slider',{ref:"card_media",staticClass:"mt-4 mt-md-0",attrs:{"media-list":_vm.getMedia(_vm.highlight).filter(
                (m) => m.scope == 'gallery' || m.scope == 'picture'
              ),"current-index":{ index: 0, interval: 4000 },"uniqueIndex":'card-media' + _vm.highlight.id,"video-ratio":'4by3',"video-modal":false}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.highlight.content_data.data.components[0].content_html)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.highlight.content_data.data.components[0].content_html)}}):_vm._e(),(
              _vm.highlight.content_data.data.components[0].config.cta &&
              _vm.highlight.content_data.data.components[0].content_json.cta_label
            )?_c('div',{staticClass:"mt-3 text-right"},[_c('b-btn',{staticClass:"btn btn-success highlight_cta",on:{"click":function($event){return _vm.openURL(_vm.highlight.content_data.data.components[0])}}},[_vm._v(" "+_vm._s(_vm.highlight.content_data.data.components[0].content_json .cta_label)+" ")])],1):_vm._e()])],1)],1)],1),_c('div',{staticClass:"accordion-controller"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('highlight-' + _vm.highlight.id),expression:"'highlight-' + highlight.id"}],staticClass:"d-flex",attrs:{"pill":""},on:{"click":function($event){return _vm.toggleHighlight(_vm.highlight.id)}}},[_c('div',{staticClass:"show-details"},[_c('div',{staticClass:"button when-closed"},[_vm._v(" "+_vm._s(_vm.$t("entity.show_details"))+" ")]),_c('div',{staticClass:"button when-open"},[_vm._v(" "+_vm._s(_vm.$t("entity.hide_details"))+" ")]),_c('div',{staticClass:"chevron when-closed"},[_c('b-icon',{attrs:{"icon":"chevron-down"}})],1),_c('div',{staticClass:"chevron when-open"},[_c('b-icon',{attrs:{"icon":"chevron-up"}})],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }