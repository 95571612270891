<template>
  <div class="px-0 mt-5 mx-0">
    <h4 class="mb-2 mb-lg-4">{{ $t("upselling.service.service_title") }}</h4>
    <b-table v-if="!isMobile" :stacked="isMobile" fixed :items="tableItems" :fields="fields" class="desktop mb-0">
      <template #table-colgroup="scope">
      <col
        v-for="field in scope.fields"
        :key="field.key"
        :style="{ width: field.key === 'description' ? '63% !important' : '' }"
      >
      </template>
      <template #cell(description)="data">
        <p class="table-title mb-0">
          <span v-b-tooltip.hover.bottom.v-secondary ="data.item.description">
            {{data.item.description}}
          </span>
        </p>
      </template>
    </b-table>

    <div
      v-else
      v-for="(service, index) in selectedServices"
      class="service w-100 py-2"
      :key="index"
      style="border-bottom: solid 2px #fafafa"
    >
      <div class="d-flex justify-content-between py-1">
        <p class="mb-0">{{ $t("upselling.service_price_table.service") }}</p>
        <p class="service-title text-right mb-0">
          <span v-b-tooltip.hover.bottom.v-secondary="service.item.title">
            {{ service.item.title }}
          </span>
        </p>
      </div>
      <div class="d-flex justify-content-between py-1">
        <p class="mb-0">{{ $t("upselling.service_price_table.quantity") }}</p>
        <p class="text-right mb-0">{{ formatPrice(service.quantity) }}</p>
      </div>
      <div class="d-flex justify-content-between py-1">
        <p class="mb-0">{{ $t("upselling.service_price_table.price") }}</p>
        <p class="text-right mb-0">
          {{ formatPrice(service.item.pu_amount * service.quantity) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useUpselling } from "@/composables/useUpselling";
import {
  ref,
  onMounted,
  getCurrentInstance,
  nextTick,
  onBeforeUnmount
} from "vue";

const { formatPrice } = useCheckoutData();
const { selectedServices } = useUpselling();
const { proxy } = getCurrentInstance();

const tableItems = ref([]);
const isMobile = ref(false);
const fields = [
  {
    key: "description",
    label: proxy.$t("upselling.service_price_table.description")
  },
  {
    key: "quantity",
    label: proxy.$t("upselling.service_price_table.quantity")
  },
  {
    key: "price",
    label: proxy.$t("upselling.service_price_table.price")
  },
  {
    key: "amount",
    label: proxy.$t("upselling.service_price_table.amount")
  }
]

onMounted(() => {
  onResize();
  nextTick(() => {
    window.addEventListener("resize", onResize);
  });

  selectedServices.value?.forEach((serv) => {
    tableItems.value.push({
      description: serv.item.title,
      quantity: serv.quantity,
      price: formatPrice(serv.item.pu_amount),
      amount: formatPrice(serv.item.pu_amount * serv.quantity)
    });
  });
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
});

function onResize() {
  isMobile.value = window.innerWidth < 769;
}
</script>

<style scoped>
.service-title {
  width: 50%;
}

.service-title span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  height: 100%;
}

.table-title {
  width: 100%;
}

.service {
  padding: 0 15px;
}

.table-title span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  height: 100%;
}
</style>