<template>
  <div id="debug-bar" v-if="debug">
    <transition name="fade">
      <b-container v-if="debug_bar">
        <b-row>
          <b-col cols="4">
            <table>
              <tr>
                <td class="pr-2">CAMPAIGN</td>
                <td>
                  <a
                    :href="
                      (backendEndpoint == 'http://api.yanovis.lo:8000'
                        ? 'http://localhost:8080'
                        : 'https://staging-travelsuite.yanovis.com') +
                      '/communication/campaigns/' +
                      page.entity.id
                    "
                    target="_blank"
                  >
                    {{ page.entity.slug }}
                  </a>
                </td>
              </tr>
              <tr>
                <td class="pr-2">scope</td>
                <td>{{ page.entity.scope }}</td>
              </tr>
              <tr>
                <td class="pr-2">Locale</td>
                <td>
                  LP:{{ lpLocale }} / SET:{{ page.locale }} / Entity:{{
                    page.entity.language
                  }}
                </td>
              </tr>
              <tr>
                <td class="pr-2">status</td>
                <td>{{ page.entity.status }}</td>
              </tr>
              <tr>
                <td class="pr-2">operation_mode</td>
                <td>{{ page.operationMode }}</td>
              </tr>
              <tr>
                <td class="pr-2">booking_action</td>
                <td>{{ selectedVariant.booking_action || "null" }}</td>
              </tr>
            </table>
          </b-col>
          <b-col cols="4">
            <table>
              <tr>
                <td class="pr-2">NODE ENV</td>
                <td>
                  {{ debug_node_env.substring(0, 3).toUpperCase() }} -
                  {{ backendEndpoint }}
                </td>
              </tr>
              <tr>
                <td class="pr-2">status_changed_at</td>
                <td>
                  {{
                    (page.entity.status_changed_at &&
                      $moment(page.entity.status_changed_at).format(
                        "Y-MM-DD HH:MM:SS"
                      )) ||
                    "-"
                  }}
                </td>
              </tr>
              <tr>
                <td class="pr-2">updated_at</td>
                <td>
                  {{
                    (page.entity.updated_at &&
                      $moment(page.entity.updated_at).format(
                        "Y-MM-DD HH:MM:SS"
                      )) ||
                    "-"
                  }}
                </td>
              </tr>
              <tr>
                <td class="pr-2">expires_at</td>
                <td>
                  {{
                    (page.entity.expires_at &&
                      $moment(page.entity.expires_at).format(
                        "Y-MM-DD HH:MM:SS"
                      )) ||
                    "-"
                  }}
                </td>
              </tr>
              <tr>
                <td class="pr-2">created_at</td>
                <td>
                  {{
                    (page.entity.created_at &&
                      $moment(page.entity.created_at).format(
                        "Y-MM-DD HH:MM:SS"
                      )) ||
                    "-"
                  }}
                </td>
              </tr>
              <tr>
                <td class="pr-2">confirmed_at</td>
                <td>
                  {{
                    (page.entity.confirmed_at &&
                      $moment(page.entity.confirmed_at).format(
                        "Y-MM-DD HH:MM:SS"
                      )) ||
                    "-"
                  }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col cols="4">
            <table>
              <tr>
                <td class="pr-2">reservation_process</td>
                <td>{{ reservation_process || "-" }}</td>
              </tr>
              <tr>
                <td class="pr-2">Currency</td>
                <td>
                  API: {{ page.currency }} / SET: {{ currentCurrency.code }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b-btn class="m-1" @click="$router.push({ hash: 'checkout' })"
                    >C_OUT</b-btn
                  >
                  <b-btn
                    class="m-1"
                    @click="$bvModal.show('show-checkout-thank-you')"
                    >C_OUT THX</b-btn
                  >
                  <b-btn
                    class="m-1"
                    @click="$bvModal.show('show-checkin-thank-you')"
                    >C_IN THX</b-btn
                  >
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-container>
    </transition>
    <b-btn @click="debug_bar = !debug_bar" class="debug_btn"></b-btn>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "DebugBar",
  mixins: [lpMixins],
  props: ["page", "reservation_process", "lpLocale", "selectedVariant"],
  computed: {
    backendEndpoint() {
      return process.env.VUE_APP_API_BACKEND;
    }
  }
};
</script>

<style scoped></style>