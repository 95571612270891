var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"package",attrs:{"id":'package-' + _vm.treatmentData.key}},[_c('b-card',{staticClass:"mb-3 p-0 overflow-hidden package-inner"},[_c('div',{class:_vm.isMobile ? 'w-100' : 'd-flex flex-nowrap'},[(_vm.picture)?_c('div',{staticClass:"flex-shrink-0 mx-0 px-0",class:_vm.isMobile ? 'w-100' : 'w-25'},[_c('div',{staticClass:"package-picture w-100 h-100",style:({
            'background-image':
              'url(' +
              _vm.buildMediaURL(_vm.picture.public_url, _vm.picture.provider, '300x400') +
              ')'
          })},[_c('b-aspect',{attrs:{"aspect":_vm.isMobile ? '4:3' : '3:4'}})],1)]):_vm._e(),_c('div',{staticClass:"wrap-when-no-space"},[_c('div',{staticClass:"w-100 flex-shrink-0 flex-grow-0",staticStyle:{"padding":"30px 0 20px 40px !important"}},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.treatmentData.title))])]),_c('div',{staticClass:"package-description-wrapper"},[_c('b-card-body',[_c('b-card-text',{staticClass:"package-description"},[_c('h5',[_vm._v(_vm._s(_vm.$t("entity.treatment.package.description")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.treatmentData.description)}}),_c('div',{staticClass:"show-package"},[_c('b-btn',{staticClass:"show-package-btn",on:{"click":function($event){return _vm.$bvModal.show(
                      'treatment-package-modal-' + _vm.treatmentData.key
                    )}}},[_vm._v(" "+_vm._s(_vm.$t("entity.treatment.show_details"))+" ")])],1)])],1)],1),_c('div',{staticClass:"w-25 package-summary-wrapper mr-3"},[_c('b-card-body',[_c('b-card-text',{staticClass:"package-summary"},[_c('h5',[_vm._v(_vm._s(_vm.$t("entity.treatment.package.applied_spa"))+":")]),_c('div',{staticClass:"package-products"},[_vm._l((_vm.treatmentData.product_groups),function(product){return _c('div',{key:product.id},[_vm._v(" "+_vm._s(product.amount)+"x "+_vm._s(product.title)+" ")])}),_c('div',{staticClass:"package-products-summary"},[_vm._v(" "+_vm._s(_vm.$t("entity.treatment.package.total_amount"))+": "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.totalCostComputed))+" ")])])],2)])],1)],1)])])]),_c('b-modal',{attrs:{"id":'treatment-package-modal-' + _vm.treatmentData.key,"modal-class":"treatment-modal","size":"xl","centered":"","hide-header":""}},[_c('div',{staticClass:"closeModal"},[_c('button',{on:{"click":function($event){return _vm.$bvModal.hide('treatment-package-modal-' + _vm.treatmentData.key)}}},[_vm._v(" "+_vm._s(_vm.$t("core.close_modal"))+" "),_c('span',{staticClass:"closeIcon"},[_c('b-icon',{attrs:{"icon":"x"}})],1)])]),_c('div',{staticClass:"modal-treatment-package"},[_c('div',{staticClass:"modal-wrapper"},[_c('b-row',[_c('b-col',{staticClass:"accordion-inner",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"modal-title"},[_c('h4',[_vm._v(_vm._s(_vm.treatmentData.title))])]),(_vm.treatmentData.description)?_c('div',{staticClass:"modal-description"},[_c('h5',[_vm._v(_vm._s(_vm.$t("entity.treatment.package.description")))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.treatmentData.description)}})]):_vm._e()]),_c('b-col',{staticClass:"d-none d-lg-block",attrs:{"cols":"12","md":"1"}}),_c('b-col',{staticClass:"accordion-inner mb-5",attrs:{"cols":"12","md":"6","lg":"5"}},[_c('mini-slider',{ref:'treatment-slider_' + _vm.treatmentData.key,attrs:{"mediaList":_vm.mediaList,"currentIndex":{
                index: _vm.treatmentGalleryIndex,
                interval: _vm.sendInterval
              },"uniqueIndex":_vm.treatmentData.key,"video-ratio":'4by3'},on:{"sliderControl":function($event){return _vm.sliderChanged($event)}}})],1)],1),_c('div',{staticClass:"modal-contents ynv-container mb-4"},_vm._l((_vm.treatmentData.products_mapped),function(product){return _c('treatment-item',{key:product.id,attrs:{"data":product,"styleData":_vm.styleData,"prekey":'package-'}})}),1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }