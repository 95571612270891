<template>
  <div id="checkout-price-table" class="mt-3 px-3 px-xl-5">
    <h4 class="pb-4 pt-1 mb-0">{{ $t("checkout.price_table") }}</h4>
    <div class="summary mb-5">
      <b-row no-gutters>
        <b-col
          v-for="item in selectedVariant.price_details"
          :key="item.id"
          :cols="12"
          style="border-bottom: solid 1px #1d354410"
          class="py-2"
        >
          <b-row
            v-if="
              item.title ||
              item.amount ||
              item.discount_value ||
              item.pu_price ||
              item.quantity
            "
            no-gutters
          >
            <b-col :cols="9" class="item-name-price pr-3">
              <div v-html="formatPriceTitle(item.title)" />
            </b-col>
            <b-col
              v-if="
                item.amount ||
                item.discount_value ||
                item.pu_price ||
                item.quantity
              "
              :cols="3"
              class="item-name-price d-flex justify-content-end"
            >
              {{ formatPrice(item.amount) }}
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-for="offer in selectedVariant.products.data.filter(
            (r) => r.type === 'spa'
          )"
          :key="offer.id"
          :cols="12"
          style="border-bottom: solid 1px #1d354410"
          class="py-2 d-flex justify-content-between"
        >
          <div class="pr-2">{{ offer.title }}</div>
          <button
            @click="showDetails(offer)"
            class="btn-details btn btn-secondary btn-sm align-self-center"
          >
            {{ $t("entity.buttons.details") }}
          </button>
        </b-col>
      </b-row>
    </div>

    <div v-if="page?.upselling_enabled && page?.entity_id && selectedServices.length" class="summary-services">
      <h4 class="pb-4">{{ placeholder("%label.upselling.service_title%", $t("upselling.service.service_title")) }}</h4>
      <b-row class="mx-0">
        <b-col cols="11" style="border-bottom: solid 1px #1d354410"/>
        <template v-for="(service, index) in selectedServices">
          <b-col
            cols="11"
            class="service-line px-0 py-2 d-flex justify-content-between"
            style="border-bottom: solid 1px #1d354410"
            :key="index"
          >
            <div class="service-title pr-1">
              <span v-b-tooltip.hover.bottom.v-secondary="service.item.title">
                {{service.quantity}}x {{service.item.title}}
              </span>
            </div>
            <div class="pr-1">{{formatPrice(service.item.pu_amount * service.quantity)}}</div>
          </b-col>
          <b-col :key="index + 'svg'" cols="1" class="px-0 d-flex justify-content-end align-items-center">
            <div class="text-center" @click="removeService(service.item.id)">
              <b-iconstack
                class="delete-service mb-0"
                font-scale="1.4"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                  <path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                  <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352"/>
                  <path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                </svg>
              </b-iconstack>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>

    <div class="summary-footer d-none d-sm-block">
      <PriceDetails @hideBtnBook="hideBtnBook()" />
    </div>
  </div>
</template>

<script setup>
import PriceDetails from "./PriceDetails";
import { defineEmits } from "vue";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useUpselling } from "@/composables/useUpselling";
import { getCurrentInstance } from "vue";

const { formatPrice, formatPriceTitle, selectedVariant, showModalOffer, page, placeholder } =
  useCheckoutData();
const { selectedServices, removeService } = useUpselling();

const { proxy } = getCurrentInstance();

const emit = defineEmits(["hideBtnBook"]);

const deleteIconColor = page.design_data.style.general["font-color"] || "#6c757d";

function showDetails(data) {
  new Promise((resolve) => {
    showModalOffer.value = data;
    resolve(showModalOffer.value);
  }).then(() => {
    proxy.$bvModal.show("show-offer");
  });
}

function hideBtnBook() {
  emit("hideBtnBook");
}
</script>

<style scoped>
#checkout-price-table {
  padding: 50px 15px;
}

.service {
  width: 90%;
}

.delete-service {
  font-size: 15px;
  cursor: pointer;
  color: v-bind(deleteIconColor);
}

.summary-footer {
  margin-top: 0 !important;
}

.btn-details {
  max-height: 29px;
}

.service-title {
  width: 100%;
  overflow: hidden;
}

.service-title span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  height: 100%;
}
</style>