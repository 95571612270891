import { ref, getCurrentInstance } from "vue";
import { useCheckoutData } from "@/composables/useCheckoutData";

const selectedServices = ref([]);

export function useUpselling() {
  const { proxy } = getCurrentInstance();

  const { page, selectedVariant, formatPrice } = useCheckoutData();

  const upselling = proxy._provided.upselling;
  const preselectedServices = proxy._provided.preselectedServices;
  const deposit_rounding = proxy._provided.deposit_rounding;

  const calcModes = [
    {
      mode: "MODE_PER_DAY",
      value: 1
    },
    {
      mode: "MODE_PER_PERSON",
      value: 2
    },
    {
      mode: "MODE_PER_DAY_AND_PERSON",
      value: 3
    },
    {
      mode: "MODE_PER_PRODUCT_UNIT",
      value: 4
    },
    {
      mode: "MODE_PER_LIVING_UNIT_AND_DAY",
      value: 5
    },
    {
      mode: "MODE_PER_ITEM",
      value: 6
    }
  ];

  function addServices(service) {
    const exist = selectedServices.value.findIndex(
      ({ item }) => item.id === service.item.id
    );
    if (exist === -1) {
      selectedServices.value.push(service);
    } else {
      selectedServices.value[exist].quantity = service.quantity;
    }
    calcTotalAmountOfServices();
  }

  function removeService(id) {
    const indexOfService = selectedServices.value.findIndex(
      ({ item }) => item.id === id
    );

    selectedServices.value[indexOfService].quantity > 1
      ? selectedServices.value[indexOfService].quantity--
      : selectedServices.value.splice(indexOfService, 1);

    calcTotalAmountOfServices();
  }

  function calcTotalAmountOfServices() {
    let calc = 0;
    selectedServices.value.forEach((item) => {
      calc += item?.item?.pu_amount * item?.quantity;
    });
    return calc || 0;
  }

  // there is the same function in App.vue for option api
  // if you update something do it also in App.vue
  function recalculateDepositIfUpselling() {
    if (
      page?.upselling_enabled &&
      page?.entity_id &&
      selectedServices.value.length &&
      !selectedVariant.value.deposit_type
    ) {
      const depositWithUpselling =
        (Number(selectedVariant.value.total_amount) +
          calcTotalAmountOfServices()) *
        (selectedVariant.value.deposit_value / 100);
      return formatPrice(deposit_rounding.value ? Math.ceil(depositWithUpselling) : depositWithUpselling);
    } else {
      return (
        selectedVariant.value.total_amount &&
        selectedVariant.value.deposit_amount &&
        formatPrice(selectedVariant.value.deposit_amount)
      );
    }
  }

  function getCalcModeTranslation(id) {
    return proxy.$t(
      "upselling.service.calc_modes." +
        calcModes.find((cm) => cm.value === id)?.mode
    );
  }

  return {
    upselling,
    addServices,
    removeService,
    calcTotalAmountOfServices,
    recalculateDepositIfUpselling,
    selectedServices,
    getCalcModeTranslation,
    preselectedServices
  };
}
