export default {
  methods: {
    successMessage(msg = null, opts = {}) {
      this.$toast.success(msg, {
        position: "bottom",
        duration: 4000,
        ...opts
      });
    },
    errorMessage(msg = null, opts = {}) {
      this.$toast.error(msg, {
        position: "bottom",
        duration: 4000,
        ...opts
      });
    }
  }
};