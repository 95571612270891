<template>
  <div class="d-flex">
    <div v-for="i in fullStars" :key="i">
      <b-icon icon="star-fill" class="star-fill" font-scale="1.3"></b-icon>
    </div>
    <div v-if="halfStar">
      <div class="star-half">s</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StarsRating",
  props: ["rating"],
  data() {
    return {
      fullStars: 0,
      halfStar: false
    };
  },
  mounted() {
    if (this.rating % 1 == 0) {
      this.fullStars = this.rating;
    } else {
      this.fullStars = Math.trunc(this.rating);
      this.halfStar = true;
    }
  }
};
</script>