var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"image-box-component px-0"},[_c('b-row',{staticClass:"image-box-row"},_vm._l((_vm.highlights.items),function(highlight,hlIndex){return _c('b-col',{key:highlight.id,staticClass:"image-box-item",class:_vm.highlights.items.length > 1 && !highlight.config.expanded
          ? 'multi-item'
          : 'single-item',attrs:{"md":_vm.colsMatrix[hlIndex]},on:{"click":function($event){return _vm.showCardDetails(highlight)}}},[_c('div',{staticClass:"image-box-wrapper"},[_c('div',{staticClass:"image",style:({
            'background-image': _vm.getMedia(highlight, 'picture')
              ? 'url(' +
                _vm.getMedia(highlight, 'picture').preview_img +
                '?d=500x400)'
              : 'none'
          })}),_c('div',{staticClass:"bottom-line d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(highlight.content_data.data.components[0].title)+" ")]),_c('div',{staticClass:"action-button"},[_c('a',{staticClass:"show-more",class:{
                square:
                  _vm.designData.style &&
                  'radius' in _vm.designData.style.general &&
                  _vm.designData.style.general.radius != '' &&
                  parseInt(_vm.designData.style.general.radius) == 0
              },on:{"click":function($event){return _vm.showCardDetails(highlight)}}},[_c('b-icon',{attrs:{"icon":"plus"}})],1)])])])])}),1),(_vm.modalCardObject)?_c('b-modal',{attrs:{"id":'card-details_' + _vm.modalCardObject.id,"body-class":'py-0 px-0',"size":"lg","hide-header":"","centered":""}},[_c('div',{staticClass:"imagebox-close-btn"},[_c('span',{staticClass:"mdi mdi-close",staticStyle:{"font-size":"20px"},on:{"click":function($event){return _vm.closeCardDetails()}}})]),_c('div',{staticClass:"modal-header-wrapper"},[_c('mini-slider',{ref:"cardmedia",class:{
          'no-content':
            !_vm.modalCardObject.content_data.data.components[0].config.content &&
            !_vm.modalCardObject.content_data.data.components[0].config.cta
        },attrs:{"media-list":_vm.media,"current-index":{ index: 0, interval: 4000 },"uniqueIndex":'card-media_' + 'modalCardObject.id',"video-ratio":'4by3',"noRoundedBorder":true,"video-modal":false}}),_c('div',{staticClass:"modal-header-title",class:{
          'title-border-bottom':
            !_vm.modalCardObject.content_data.data.components[0].config.content &&
            !_vm.modalCardObject.content_data.data.components[0].config.cta
        }},[_vm._v(" "+_vm._s(_vm.modalCardObject.content_data.data.components[0].title)+" ")])],1),_c('div',{class:{
        'modal-description':
          _vm.modalCardObject.content_data.data.components[0].config.content ||
          _vm.modalCardObject.content_data.data.components[0].config.cta
      }},[(_vm.modalCardObject.content_data.data.components[0].config.content)?_c('div',{staticClass:"modal-card-text",domProps:{"innerHTML":_vm._s(_vm.modalCardObject.content_data.data.components[0].content_html)}}):_vm._e(),(_vm.modalCardObject.content_data.data.components[0].config.cta)?_c('div',{staticClass:"text-right",class:_vm.modalCardObject.content_data.data.components[0].content_html
            .length > 0
            ? 'mt-4 mt-sm-5'
            : 'mt-4'},[_c('b-btn',{staticClass:"btn btn-success highlight_cta",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.openURL(_vm.modalCardObject.content_data.data.components[0])}}},[_vm._v(" "+_vm._s(_vm.modalCardObject.content_data.data.components[0].content_json .cta_label)+" ")])],1):_vm._e()])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }