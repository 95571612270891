<template>
  <div
    id="checkout-label"
    class="px-0 px-xl-5 pb-5"
    :style="`background-color: ${bgColor}`"
  >
    <b-container class="px-xl-0">
      <b-row class="mx-0 pb-5">
        <b-col cols="12" class="logo-container px-0">
          <b-img
            v-if="page.account.logo?.data?.url"
            class="user-logo"
            :src="page.account.logo?.data?.url"
            data-cy="accountLogo"
          />
        </b-col>
        <b-col
          cols="12"
          class="px-0 mb-0"
          :style="`color: ${isTooDarkFun(bgColor)}`"
        >
          <h4
            class="mb-3 pb-1"
            v-if="page.account.name"
            :style="`color: ${isTooDarkFun(bgColor)}`"
            data-cy="accountName"
          >
            {{ page.account.name }}
          </h4>
          <div>
            <span
              v-html="placeholder('%label.checkout.email%', $t('form.email'))"
              class="font-weight-bold"
            ></span
            >:
            <p
              class="d-inline"
              data-cy="accountEmail"
            >
              {{ getVal(page.account.contact.data.email) }}
            </p>
          </div>
          <div>
            <span
              v-html="placeholder('%label.checkout.phone%', $t('form.phone'))"
              class="font-weight-bold"
            ></span
            >:
            <p
              class="d-inline"
              data-cy="accountPhone"
            >
              {{ getVal(page.account.contact.data.phone) }}
            </p>
          </div>
          <div
            v-html="address"
            class="mt-4 pt-1"
            data-cy="accountAddress"
            v-if="page.account.address?.data"
          ></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useCheckoutData } from "@/composables/useCheckoutData";

let { placeholder, getVal, page, getCountry } = useCheckoutData();

const address = computed(() => {
  const { data } = page.account.address;
  return `${getVal(data.street)} </br>
            ${getVal(data.city_zip)} ${getVal(data.city_name)} - ${getCountry(
    data.country_alpha3
  )}`;
});

defineProps({
  bgColor: { type: String },
  isTooDarkFun: { type: Function }
});
</script>

<style scoped>
.user-logo {
  width: 70%;
  max-width: 200px;
  max-height: 100%;
}

.logo-container {
  margin-top: 80px;
  margin-bottom: 40px;
  height: auto;
}

@media (min-width: 999px) {
  #checkout-label {
    height: 100%;
  }

  .logo-container {
    margin-top: 140px;
    margin-bottom: 60px;
    height: 200px;
  }
}
</style>