var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"offer-block"},[(_vm.getMediaOffer.length > 0)?_c('b-row',[_c('b-col',{staticClass:"offer-image-wrapper",attrs:{"cols":"12"}},[_c('div',{staticClass:"offer-header"},[_c(_setup.MiniSlider,{ref:'miniOfferSlider' + _vm.index,attrs:{"imageResolution":'1400x400',"mediaList":_vm.getMediaOffer,"aspect":_setup.isMobile ? '4:3' : '3:1',"currentIndex":{
            index: _vm.galleryIndex,
            interval: _vm.sendInterval
          },"uniqueIndex":'offer-' + _vm.index,"video-ratio":_setup.isMobile ? '4by3' : '3by1'},on:{"sliderControl":function($event){return _vm.sliderChanged($event)}}}),_c('div',{staticClass:"offer-title",domProps:{"innerHTML":_vm._s(_vm.getOfferTitle)}})],1)])],1):_vm._e(),_c('b-row',{staticClass:"mt-3",class:{ 'mt-md-5': _vm.getMediaOffer.length > 0 }},[(_vm.getMediaOffer.length > 0)?_c('b-col',{staticClass:"offer-gallery pl-0 order-1 order-md-2",attrs:{"cols":"12","md":"5"}},[_c('b-row',{staticClass:"no-gutters justify-content-end"},_vm._l((_vm.mediaListArr),function(media,index){return _c('b-col',{key:index,staticClass:"offer-gallery-preview-wrapper",class:{
            active:
              media.index === _vm.galleryIndex ||
              (media.index ===
                _vm.mediaListArr.findLast((m) => m.scope === 'gallery')?.index &&
                _vm.gallery.some((img) => img.index === _vm.galleryIndex))
          },attrs:{"cols":"3"},on:{"mouseenter":function($event){return _vm.selectImage(media.index)},"mouseleave":function($event){return _vm.mouseOut(media.index)},"click":function($event){return _vm.showLightbox(media.index)}}},[_c('div',{staticClass:"offer-gallery-preview-image",style:({
              'background-image':
                'url(' + _vm.buildMediaURL(_vm.getMediaUrl(media), '150x110') + ')'
            })},[_c('b-aspect',{attrs:{"aspect":"4:3"}})],1)])}),1)],1):_vm._e(),_c('b-col',{staticClass:"offer-description order-2 order-md-1 mt-4 mt-md-0",attrs:{"cols":"12","md":_vm.getMediaOffer.length > 0 ? '7' : '12'}},[(_vm.getMediaOffer.length === 0)?_c('h4',{staticClass:"offer-title font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.getOfferTitle)}}):_vm._e(),_c('input',{staticClass:"expanded_checkbox",attrs:{"type":"checkbox","id":'expanded' + _vm.index}}),_c(_setup.TruncateText,{attrs:{"max-length":_setup.truncateLength,"content":_vm.offerData[0].description},scopedSlots:_vm._u([{key:"showMore",fn:function(){return [_c('label',{staticClass:"offer-show-more",attrs:{"for":'expanded' + _vm.index,"role":"button"}},[_vm._v(" "+_vm._s(_vm.$t("core.read_more"))+" ")])]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }