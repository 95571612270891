<template>
  <b-container
    id="personal-info-component"
    class="ynv-container personal-info mt-5"
  >
    <b-row class="">
      <b-col cols="12">
        <h2
          v-html="
            placeholder('%label.customer.title%', $t('entity.personal_info'))
          "
          class="text-center"
        ></h2>
      </b-col>
    </b-row>
    <div class="mt-0 d-block d-md-flex justify-content-center">
      <b-card>
        <b-card-text>
          <h4
            v-html="
              placeholder('%label.customer.title%', $t('entity.personal_info'))
            "
          ></h4>
          <div class="personal-info-details custom-table">
            <div>
              <span class="t-key">{{ $t("form.salutation") }}:</span>
              <span class="t-value">
                <strong>
                  {{
                    customerInformation.gender &&
                    customerInformation.gender != "u"
                      ? $t(
                          "form.title_options." +
                            getVal(customerInformation.gender)
                        )
                      : "-"
                  }}
                </strong>
              </span>
            </div>
            <div>
              <span class="t-key">{{ $t("form.name") }}:</span>
              <span class="t-value">
                <strong>
                  <template
                    v-if="
                      customerInformation.gender == 'u' &&
                      customerInformation.companyname != ''
                    "
                  >
                    {{ getVal(customerInformation.companyname) }}
                  </template>
                  <template v-else>
                    {{ getVal(customerInformation.firstname) }}
                    {{ getVal(customerInformation.lastname) }}
                    {{
                      !customerInformation.lastname &&
                      !customerInformation.firstname
                        ? "-"
                        : ""
                    }}
                  </template>
                </strong>
              </span>
            </div>
            <div>
              <span class="t-key">{{ $t("form.email") }}:</span>
              <span class="t-value">
                <strong>
                  {{ customerInformation.email || "-" }}
                </strong>
              </span>
            </div>
            <div>
              <span class="t-key">{{ $t("form.phone") }}:</span>
              <span class="t-value">
                <strong>
                  {{ customerInformation.phone || "-" }}
                </strong>
              </span>
            </div>
          </div>
        </b-card-text>
      </b-card>
      <b-card>
        <b-card-text>
          <h4>{{ $t("form.contact") }}</h4>
          <div class="treatment-details custom-table">
            <div>
              <span class="t-key">{{ $t("form.street") }}:</span>
              <span class="t-value">
                <strong>
                  {{ getVal(customerInformation.street) }}
                </strong>
              </span>
            </div>
            <div>
              <span class="t-key"
                >{{ $t("form.zip") }}/{{ $t("form.city") }}:</span
              >
              <span class="t-value">
                <strong>
                  {{ customerInformation.zip || "-" }} /
                  {{ customerInformation.city || "-" }}
                </strong>
              </span>
            </div>
            <div>
              <span class="t-key">{{ $t("form.country") }}:</span>
              <span class="t-value">
                <strong>
                  {{
                    getVal(customerInformation.country)
                      ? countryText(customerInformation.country)
                      : "-"
                  }}
                </strong>
              </span>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </div>
  </b-container>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "PersonalInformation",
  mixins: [lpMixins],
  props: ["customerInformation", "page"],
  methods: {
    countryText(country) {
      let found = this.countries.filter(
        (c) => c[country.length === 2 ? "code" : "value"] === country
      );
      return found.length ? found[0].text : country;
    }
  }
};
</script>

<style scoped></style>