<template>
  <div
    class="audio rounded-circle"
    v-b-tooltip.hover.bottom.v-secondary="audioFlag ? $t('page.gallery.unmute_audio') : $t('page.gallery.mute_audio')"
    @click="$emit('clicked')"
  >
    <b-icon
      class="mb-0"
      :icon="audioFlag ? 'volume-mute' : 'volume-up'"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();

defineProps({
  audioFlag: { type: Boolean, required: true }
});
defineEmits(['clicked']);

const page = proxy._provided.page;
const isTooDark = proxy._provided.isTooDark;

const audioBtnColor = page?.design_data?.style?.cta?.audio_btn_color || 'white';
const audioIconColor = isTooDark(audioBtnColor);
</script>

<style scoped>
.audio {
  padding: 8px;
  position: absolute;
  background-color: v-bind(audioBtnColor);
  line-height: 0;
  cursor: pointer;
  user-select: none;
  bottom: 15px;
  right: 22px;
  z-index: 121;
}

.audio .b-icon {
  width: 20px;
  height: 20px;
  color: v-bind(audioIconColor);
}

</style>