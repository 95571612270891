<template>
  <b-card
    :key="highlight.type"
    class="ynv-highlight-component ynv-accordion mx-0 collapse-item"
    no-body
  >
    <b-card-header
      class="ynv-accordion-header"
      v-b-toggle="'highlight-' + highlight.id"
      @click="toggleHighlight(highlight.id)"
      header-tag="div"
      role="tab"
    >
      <b-row class="header-row mx-0 my-0 px-0 py-0">
        <b-col cols="12" md="11" class="title-container pl-0 pr-0">
          <span class="title">
            {{ highlight.content_data.data.components[0].title }}
          </span>
        </b-col>
        <b-col class="d-flex justify-content-end pl-0 pr-md-2 pr-lg-3">
          <b-button
            class="align-self-center d-flex accordion-button"
            :class="{
              square:
                designData.style &&
                'radius' in designData.style.general &&
                designData.style.general.radius != '' &&
                parseInt(designData.style.general.radius) == 0
            }"
            pill
          >
            <div class="chevron when-open">
              <b-icon icon="chevron-up" style="margin-bottom: 2px"></b-icon>
            </div>
            <div class="chevron when-closed">
              <b-icon icon="chevron-down"></b-icon>
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-card-header>
    <b-collapse
      :visible="highlight.config.expanded"
      :id="'highlight-' + highlight.id"
      role="tabpanel"
    >
      <b-card-body v-if="highlight.type != 'card'">
        <div
          v-if="highlight.config.raw || !shadowRootEnabled"
          v-html="highlight.content_data.data.components[0].content_html"
        ></div>
        <shadow-root v-else @error="handleShadowRootError($event)">
          <div
            v-html="highlight.content_data.data.components[0].content_html"
          ></div>
        </shadow-root>
      </b-card-body>
      <b-card-body v-else>
        <b-row>
          <b-col cols="12" md="6" class="mb-4">
            <mini-slider
              v-if="
                getMedia(highlight).filter(
                  (m) => m.scope == 'gallery' || m.scope == 'picture'
                ).length > 0
              "
              :media-list="
                getMedia(highlight).filter(
                  (m) => m.scope == 'gallery' || m.scope == 'picture'
                )
              "
              :current-index="{ index: 0, interval: 4000 }"
              ref="card_media"
              :uniqueIndex="'card-media' + highlight.id"
              :video-ratio="'4by3'"
              class="mt-4 mt-md-0"
              :video-modal="false"
            ></mini-slider>
          </b-col>
          <b-col cols="12" md="6">
            <div
              v-if="highlight.content_data.data.components[0].content_html"
              v-html="highlight.content_data.data.components[0].content_html"
            ></div>
            <div
              v-if="
                highlight.content_data.data.components[0].config.cta &&
                highlight.content_data.data.components[0].content_json.cta_label
              "
              class="mt-3 text-right"
            >
              <b-btn
                class="btn btn-success highlight_cta"
                @click="openURL(highlight.content_data.data.components[0])"
              >
                {{
                  highlight.content_data.data.components[0].content_json
                    .cta_label
                }}
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
    <div class="accordion-controller">
      <b-button
        v-b-toggle="'highlight-' + highlight.id"
        class="d-flex"
        pill
        @click="toggleHighlight(highlight.id)"
      >
        <div class="show-details">
          <div class="button when-closed">
            {{ $t("entity.show_details") }}
          </div>
          <div class="button when-open">
            {{ $t("entity.hide_details") }}
          </div>
          <div class="chevron when-closed">
            <b-icon icon="chevron-down"></b-icon>
          </div>
          <div class="chevron when-open">
            <b-icon icon="chevron-up"></b-icon>
          </div>
        </div>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import MiniSlider from "@/components/blocks/MiniSlider";

export default {
  name: "HighlightBox",
  components: { MiniSlider },
  mixins: [lpMixins],
  props: ["highlight", "highlightContent", "designData"],
  data() {
    return {
      highlightContentLoaded: null
    };
  },
  mounted() {
    this.highlightContentLoaded = this.highlightContent;
  },
  methods: {
    toggleHighlight(index) {
      this.highlightContentLoaded.push(index);
      setTimeout(() => {
        this.parseForScript(true, [this.highlight]);
      }, 100);
    }
  },
  handleShadowRootError(err) {
    console.log(err);
  }
};
</script>

<style scoped lang="sass">
@import "../../../styles/components/highlights/HighlightBox.sass"
.title
  line-height: 20px !important
  overflow-y: auto
  height: 100%
  color: var(--highlights_title_color, #414141)
  font-weight: 600
  font-size: 17px
  letter-spacing: 0px
  display: grid
  align-items: center
  padding-right: 5px

.title::-webkit-scrollbar
  width: 10px

.title::-webkit-scrollbar-track
  background-color: rgba(31, 31, 31, 0.24)
  border-radius: 10px

.title::-webkit-scrollbar-thumb
  border-radius: 10px
  background-color: #414141

.ynv-app .highlights .ynv-accordion .card-header
  padding-right: 15px !important
  padding-left: 15px !important

.header-row, .title-container
  width: 100%
  height: 100%

@media (min-width: 1360px)
  .title
    padding-left: 2px

@media (min-width: 1025px)
  .title-container
    padding-left: 24px !important
</style>