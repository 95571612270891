var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.videoPlayer,{ref:"player",staticClass:"d-flex align-items-center embed-responsive-item",attrs:{"options":{
    sources: [
      {
        type: 'video/mp4',
        src: _vm.public_url
      }
    ],
    autoplay: true,
    ..._vm.options
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }