<template>
  <div
    class="sticky-contact-menu"
    :class="{
      'no-fixed-menu': !fixedFooterVisible
    }"
  >
    <div
      v-if="showChat && isMobile && dialogOpend"
      class="chat-background"
    ></div>
    <chat-window
      v-if="showChat"
      v-show="dialogOpend"
      :key="'chat_' + key"
      :height="chatHeight"
      :current-user-id="currentUserId"
      :rooms="rooms"
      :messages="messages"
      :messages-loaded="true"
      :show-footer="mode != 'preview'"
      :show-audio="false"
      :show-files="false"
      :show-reaction-emojis="false"
      :message-actions="[]"
      :single-room="true"
      :user-tags-enabled="false"
      :text-messages="textMessages"
      :auto-scroll="autoScroll"
      :onload="scrollToBottom()"
      class="chat-window shadow"
      @send-message="sendMessage"
    >
      <template #room-options="{}">
        <div class="d-flex just justify-content-center chat-contact-buttons">
          <div v-if="config.floating && config.floating.whatsapp" class="mx-1">
            <b-button
              :disabled="!contact.whatsapp"
              v-b-tooltip.hover.bottom
              :title="contact.whatsapp"
              class="whatsapp"
              pill
              @click="
                startConversation('whatsapp', placeholderWhatsapp, contact);
                whatsappDialogPropagation();
              "
            >
              <img src="@/assets/icons/whatsapp.svg" height="18" class="mb-1" />
            </b-button>
          </div>
          <div v-if="config.floating && config.floating.email" class="mx-1">
            <b-button
              v-b-tooltip.hover.bottom
              :title="contact.email"
              class="email d-flex justify-content-center align-items-center"
              pill
              @click="startConversation('email', placeholderWhatsapp, contact)"
            >
              <img src="@/assets/icons/mail.svg" height="15" />
            </b-button>
          </div>
          <div v-if="config.floating && config.floating.phone" class="mx-1">
            <b-button
              v-b-tooltip.hover.bottom
              :title="contact.phone ? contact.phone : contact.mobil"
              class="tel"
              pill
              @click="startConversation('phone', placeholderWhatsapp, contact)"
            >
              <img src="@/assets/icons/phone.svg" height="18" class="mb-1" />
            </b-button>
          </div>
        </div>
        <div>
          <button
            type="button"
            style="color: #555"
            class="close ml-4"
            aria-label="Close"
            @click="toggleChatWindow()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </template>
    </chat-window>

    <!-- Button -->
    <div class="contact-button-wrapper">
      <div v-if="dialogOpend && !showChat" class="contact-buttons">
        <div
          v-if="config.floating && config.floating.whatsapp"
          class="whatsapp-wrapper"
        >
          <b-button
            :disabled="!contact.whatsapp"
            v-b-tooltip.hover.bottom
            :title="contact.whatsapp"
            class="whatsapp"
            pill
            @click="startConversation('whatsapp', placeholderWhatsapp, contact)"
          >
            <img src="@/assets/icons/whatsapp.svg" height="27" />
          </b-button>
        </div>
        <div
          v-if="config.floating && config.floating.email"
          class="email-wrapper"
        >
          <b-button
            v-b-tooltip.hover.bottom
            :title="contact.email"
            class="email"
            pill
            @click="startConversation('email', placeholderWhatsapp, contact)"
          >
            <img src="@/assets/icons/mail.svg" height="21" class="mb-1" />
          </b-button>
        </div>
        <div
          v-if="config.floating && config.floating.phone"
          class="tel-wrapper"
        >
          <b-button
            v-b-tooltip.hover.bottom
            :title="contact.phone ? contact.phone : contact.mobil"
            class="tel"
            pill
            @click="startConversation('phone', placeholderWhatsapp, contact)"
          >
            <img src="@/assets/icons/phone.svg" height="26" />
          </b-button>
        </div>
      </div>
      <div
        v-if="!dialogOpend && unreadMessages > 0 && showChat"
        class="unread-message-counter d-flex justify-content-center align-items-center"
      >
        {{ unreadMessages }}
      </div>
      <div
        class="contact-button d-flex justify-content-center align-items-center"
        :class="{
          square:
            'radius' in styleData.general &&
            styleData.general.radius != '' &&
            parseInt(styleData.general.radius) == 0
        }"
        @click="toggleChatWindow()"
      >
        <img
          v-if="!dialogOpend && showChat"
          src="@/assets/icons/chat_icon.svg"
          key="chat"
          :height="isMobile ? 26 : 32"
        />
        <img
          v-else-if="!dialogOpend"
          src="@/assets/icons/chat.svg"
          key="chat"
          :height="isMobile ? 22 : 25"
        />
        <img
          v-else
          src="@/assets/icons/close.svg"
          key="chat"
          :height="isMobile ? 20 : 25"
        />
      </div>
    </div>

    <!-- Whatsapp dialog -->
    <b-modal
      v-if="whatsappURL"
      id="whatsapp-box"
      hide-header
      centered
    >
      <div class="closeModal">
        <button @click="$bvModal.hide('whatsapp-box')">
          {{ $t("entity.treatment.close") }}
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <div
        class="modal-editor d-flex justify-content-center"
        v-if="
          'content' in config &&
          'editor_position' in config.content &&
          config.content.editor_position != 'none'
        "
      >
        <b-avatar
          v-if="avatarDataComputed"
          :src="avatarDataComputed.url"
          variant="light"
          size="88px"
        ></b-avatar>
      </div>
      <h4 class="my-3 text-center mt-4">{{ contact.whatsapp }}</h4>
      <div class="whatsapp-qr-code d-flex justify-content-center">
        <vue-qrcode :value="whatsappURL"></vue-qrcode>
      </div>
      <div class="whatsapp-description text-center my-3">
        <h4>{{ $t("page.whatsapp.scan_code") }}</h4>
        <p>{{ $t("page.whatsapp.contact_over") }}</p>
      </div>
      <div class="whatsapp-footer text-center pt-4 pb-3">
        <div class="mb-3">
          <a :href="whatsappURL" target="_blank">
            <img src="@/assets/icons/screen.svg" height="50" />
          </a>
        </div>
        <h4>{{ $t("page.whatsapp.whatsapp_web") }}</h4>
        <p>{{ $t("page.whatsapp.whatsapp_web_contact") }}</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from "vue";
import { lpMixins } from "@/mixins/lpMixins";
import VueQrcode from "vue-qrcode";
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import ReceptionStatusInChat from "./ReceptionStatusInChat";

export default {
  name: "StickyContactsMenu",
  mixins: [lpMixins],
  components: {
    VueQrcode,
    ChatWindow
  },
  props: [
    "config",
    "contact",
    "entity",
    "account",
    "styleData",
    "placeholderWhatsapp",
    "fixedFooterVisible",
    "showChat",
    "campaignId",
    "page"
  ],
  data() {
    return {
      dialogOpend: false,
      floating: {
        items: [],
        done: []
      },
      unreadMessages: 0,
      currentUserId: 0,
      rooms: [
        {
          roomId: 1,
          roomName: this.account.name,
          avatar: this.account.logo?.data?.url,
          users: [{ _id: 0 }, { _id: 1 }]
        }
      ],
      messages: [],
      textMessages: {
        NEW_MESSAGES: this.$t("chat_window.new_messages"),
        MESSAGES_EMPTY: this.$t("chat_window.message_empty"),
        CONVERSATION_STARTED: this.$t("chat_window.conversation_started"),
        TYPE_MESSAGE: this.$t("chat_window.type_message"),
        SEARCH: this.$t("chat_window.search")
      },
      autoScroll: {
        send: {
          new: true,
          newAfterScrollUp: true
        },
        receive: {
          new: true,
          newAfterScrollUp: true
        }
      },
      windowHeight: document.querySelector("body").clientHeight + "px",
      isSafari: false,
      key: 0,
      maxScrollTop: 0,
      receptionStatus: null
    };
  },
  created() {
    try {
      this.isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
    } catch (e) {
      console.log(e);
    }
    if (this.config.floating) {
      Object.keys(this.config.floating).forEach((key) => {
        if (this.config.floating[key]) {
          this.floating.items.push(key);
        }
      });
    }
    if (this.isSafari) {
      this.$nextTick(() => {
        const element = document.getElementsByClassName("vac-svg-button");
        if (element?.length > 1) {
          element[1].style.padding = "3px 0";
        }
      });
    }
  },
  mounted() {
    if (!this.showChat) return;
    if (this.mode != "preview" && this.entity.id) {
      this.getMessages();
      this.needEcho();
      this.listenTyping();
      window.Echo.private(`pub.campaign.${this.campaignId}`).listen(
        ".pub.crm.campaign",
        () => {
          this.getMessages();
        }
      );
    } else {
      this.messages = [
        {
          _id: 1,
          senderId: 0,
          content: this.$t("chat_window.previewMode"),
          system: true,
          date: this.$moment().format("D MMMM")
        }
      ];
      this.messages = [...this.messages];
    }
  },
  computed: {
    avatarDataComputed() {
      let data = { url: null };
      let editorData = (this.entity.editor && this.entity.editor.data) || null;
      let accountLogoData =
        (this.account.logo && this.account.logo.data) || null;
      data.url =
        (editorData &&
          editorData.avatar_url != "" &&
          editorData.avatar_url + "?d=90x90") ||
        (accountLogoData && accountLogoData.url + "?d=90x90") ||
        null;
      return data;
    },
    chatHeight() {
      if (!this.isMobile) return "65vh";
      return this.isSafari ? this.windowHeight : "100%";
    }
  },
  watch: {
    dialogOpend(value) {
      if (this.showChat) {
        if (this.isMobile) {
          this.$nextTick(() => {
            let body = document.querySelector("body");
            let html = document.getElementsByTagName("html")[0];
            let classList = ["preventBackgroundScrolling"];
            if (value) {
              if (this.isSafari) {
                let textarea = document.getElementById("roomTextarea");
                let messageList = document.getElementsByClassName(
                  "vac-container-scroll"
                )[0];
                messageList.addEventListener("scroll", this.scroll1px);
                body.addEventListener("pointermove", this.preventDefault);
                body.addEventListener("touchmove", this.preventDefault);
                textarea.addEventListener("click", this.scrollUp);
                textarea.addEventListener("blur", this.scrollDown);
                classList.push("safariHeight");
              }
              body.classList.add("preventBackgroundScrolling", ...classList);
              html.classList.add("preventBackgroundScrolling", ...classList);
            } else {
              if (this.isSafari) {
                let textarea = document.getElementById("roomTextarea");
                let messageList = document.getElementsByClassName(
                  "vac-container-scroll"
                )[0];
                messageList.removeEventListener("scroll", this.scroll1px);
                body.removeEventListener("pointermove", this.preventDefault);
                body.removeEventListener("touchmove", this.preventDefault);
                textarea.removeEventListener("click", this.scrollUp);
                textarea.removeEventListener("blur", this.scrollDown);
                classList.push("safariHeight");
              }
              body.classList.remove("preventBackgroundScrolling", ...classList);
              html.classList.remove("preventBackgroundScrolling", ...classList);
            }
          });
        } else {
          let chatWindow = document.getElementsByClassName("chat-window")[0];
          let contactButton = document.getElementsByClassName(
            "contact-button-wrapper"
          )[0];
          if (value) {
            chatWindow.addEventListener("mousedown", this.stopPropagation);
            contactButton.addEventListener("mousedown", this.stopPropagation);
            document.addEventListener("mousedown", this.toggleChatWindow);
          } else {
            chatWindow.removeEventListener("mousedown", this.stopPropagation);
            contactButton.removeEventListener(
              "mousedown",
              this.stopPropagation
            );
            document.removeEventListener("mousedown", this.toggleChatWindow);
          }
        }
      }
    }
  },
  destroyed() {
    if (this.showChat && this.entity.id && this.mode != "preview") {
      window.Echo.private(`pub.campaign.${this.campaignId}`).stopListening(
        ".pub.crm.campaign"
      );
    }
  },
  methods: {
    needEcho() {
      const client = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: "eu",
        forceTLS: true,
        authEndpoint: process.env.VUE_APP_PUSHER_AUTH,
        auth: {
          headers: {
            "X-LP-Hash": this.hash
          }
        }
      });
      window.Echo = new Echo({
        broadcaster: "pusher",
        client: client
      });
    },
    listenTyping() {
      let timeoutID;
      window.Echo.private(`pub.campaign.${this.campaignId}`)
        .listen(".pub.crm.campaign", () => {})
        .listenForWhisper("typing", () => {
          const el = document.getElementById("typingContainer");
          el && (el.className = "typing-container d-block");
          timeoutID && clearTimeout(timeoutID);
          timeoutID = setTimeout(
            () => el && (el.className = "typing-container d-none"),
            2000
          );
        });
    },
    scroll1px() {
      let messageList = document.getElementsByClassName(
        "vac-container-scroll"
      )[0];
      if (
        messageList.scrollTop >=
        messageList.scrollHeight - messageList.clientHeight
      ) {
        this.scroll1pxUp();
      } else if (messageList.scrollTop <= 0) {
        messageList.scrollTo(0, 1);
      }
    },
    scroll1pxUp() {
      if (this.isSafari) {
        let messageList = document.getElementsByClassName(
          "vac-container-scroll"
        )[0];
        messageList.scrollTo(0, messageList.scrollTop - 1);
      }
    },
    scrollUp() {
      window.scrollTo(0, 0);
      let chatWindow = document.getElementsByClassName("chat-window")[0];
      let messageList = document.getElementsByClassName(
        "vac-container-scroll"
      )[0];
      let messageContainerHeigth = document.getElementsByClassName(
        "vac-messages-container"
      )[0].clientHeight;
      chatWindow.addEventListener("mousedown", this.triggerSendMessage);
      chatWindow.style.top = 0;
      const repeatCount = 20;
      const delay = 40;
      let vm = this;
      for (let i = 0; i < repeatCount; i++) {
        (function (i) {
          setTimeout(() => {
            window.scrollTo(0, 0);
            vm.windowHeight = window.visualViewport.height + "px";
          }, delay * i);
        })(i);
      }
      setTimeout(() => {
        if (messageList.clientHeight > messageContainerHeigth) {
          messageList.addEventListener("touchmove", this.preventDefault);
        }
      }, delay * repeatCount);
    },
    scrollDown() {
      let chatWindow = document.getElementsByClassName("chat-window")[0];
      let messageList = document.getElementsByClassName(
        "vac-container-scroll"
      )[0];
      messageList.removeEventListener("touchmove", this.preventDefault);
      chatWindow.removeEventListener("mousedown", this.triggerSendMessage);
      chatWindow.style.top = "";
      const repeatCount = 20;
      const delay = 40;
      let vm = this;
      for (let i = 0; i < repeatCount; i++) {
        (function (i) {
          setTimeout(() => {
            vm.windowHeight =
              document.querySelector("html").clientHeight + "px";
          }, delay * i);
        })(i);
      }
    },
    preventDefault(e) {
      e.preventDefault();
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
    scrollToBottom() {
      if (this.messages.length > 0) {
        this.$nextTick(() => {
          let messageList = document.getElementsByClassName(
            "vac-container-scroll"
          )[0];
          messageList.scrollTo(0, messageList.scrollHeight);
          messageList.scrollTo(0, messageList.scrollTop - 1);
        });
      }
    },
    toggleChatWindow() {
      this.dialogOpend = !this.dialogOpend;
      this.dialogOpend
        ? this.openChatUpdater()
        : this.unreadMessages == 0 && this.key++;
    },
    whatsappDialogPropagation() {
      if (!this.isMobile) {
        setTimeout(() => {
          let whatsappDialog = document.getElementById("whatsapp-box");
          whatsappDialog?.addEventListener("mousedown", this.stopPropagation);
        }, 1);
      }
    },
    triggerSendMessage(x) {
      let inputField = document.getElementById("roomTextarea");
      if (
        x.target?.children?.length &&
        x.target?.children[0].id == "vac-icon-send" &&
        inputField.value
      ) {
        this.sendMessage({ content: inputField.value });
        x.target.parentElement.classList.add("vac-send-disabled");
        x.target.children[0].id = "vac-icon-send-disabled";
        inputField.value = "";
        inputField.style.height = "20px";
        inputField.addEventListener("input", this.removeDisabledSendButton, {
          once: true
        });
      }
    },
    removeDisabledSendButton() {
      let sendButton = document.getElementById("vac-icon-send-disabled");
      sendButton.parentElement.parentElement.classList.remove(
        "vac-send-disabled"
      );
      sendButton.id = "vac-icon-send";
    },
    getMessages() {
      this.axios
        .get(this.backendURL + this.hash + "/messages?include=media")
        .then((response) => {
          this.unreadMessages = 0;
          let messages = [];
          response.data?.data?.forEach((message) => {
            let msgObject = {
              _id: message.id,
              senderId: message.inbound ? 0 : 1,
              content: message.message,
              date: this.$moment(message.send_at).format("D MMMM"),
              timestamp: this.$moment(message.send_at).format("HH:mm"),
              distributed: true,
              seen: message.read_at ? true : false
            };
            let msgFiles = [];
            message?.media?.data.forEach((media) => {
              msgFiles.push({
                name: media.name,
                type: media.mime,
                url: media.url
              });
            });
            msgObject.files = msgFiles;

            messages.push(msgObject);
            if (!message.read_at && message.inbound == 0 && !this.dialogOpend) {
              this.unreadMessages++;
            }
          });
          this.messages = messages;
          this.$nextTick(() => {
            this.messages = [...this.messages];
            this.scroll1px();
            if (this.dialogOpend) {
              this.updateUnreadMessage();
            }
          });
        });
    },
    configurationTypingChat() {
      //style chat footer
      const roomFooter = document.getElementById("room-footer");
      roomFooter.classList.add("room-footer");
      //create typing-container
      const typingContainer = document.createElement("div");
      typingContainer.setAttribute("id", "typingContainer");
      typingContainer.className = "typing-container d-none";
      typingContainer.innerHTML += `<div id="typing-avatar" style="background-image: url(${this.account.logo?.data?.url});">
                                        </div>
                                        <p id="avatar-name">${this.account.name}</p>`;
      //create icon typing
      const typingIcon = document.createElement("span");
      typingIcon.className = "loader";
      typingContainer.appendChild(typingIcon);
      roomFooter.appendChild(typingContainer);
    },
    openChatUpdater() {
      if (this.showChat) {
        this.showReceptionStatus();
        this.configurationTypingChat();
      }
      setTimeout(() => {
        if (this.dialogOpend) {
          this.updateUnreadMessage();
        }
      }, 3000);
    },
    async showReceptionStatus() {
      const response = await this.axios.get(
        this.backendURL + this.hash + "/status"
      );
      this.receptionStatus = response?.data?.data;

      if (this.receptionStatus?.reception?.state === "offline") {
        const vacRoomHeader =
          document.getElementsByClassName("vac-room-header")[0];
        vacRoomHeader.classList.add("room-header");

        const roomWrapper =
          document.getElementsByClassName("vac-room-wrapper")[0];
        roomWrapper.classList.add("room-wrapper");

        const vacTextStarted =
          document.getElementsByClassName("vac-text-started")[0];
        vacTextStarted.style.marginTop = "55px";

        const Component = Vue.extend(ReceptionStatusInChat);
        const instance = new Component({
          propsData: { reception: this.receptionStatus.reception }
        });
        instance.$mount();
        vacRoomHeader.appendChild(instance.$el);
      }
    },
    updateUnreadMessage() {
      this.unreadMessages = 0;
      let message = this.messages;
      let indexOfMessage;
      this.messages.forEach((message) => {
        if (message.senderId == 1 && message.distributed && !message.seen) {
          let body = { read_at: "now" };
          this.axios
            .patch(
              this.backendURL + this.hash + "/messages/" + message._id,
              body
            )
            .then((res) => {
              indexOfMessage = this.messages.findIndex(
                (msg) => msg["_id"] == res.data.data.id
              );
              if (indexOfMessage != -1) {
                message[indexOfMessage] = {
                  _id: res.data.data.id,
                  senderId: res.data.data.inbound ? 0 : 1,
                  content: res.data.data.message,
                  date: this.$moment(res.data.data.send_at).format("D MMMM"),
                  timestamp: this.$moment(res.data.data.send_at).format(
                    "HH:mm"
                  ),
                  distributed: true,
                  seen: true
                };
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
      this.messages = message;
      this.$nextTick(() => {
        this.messages = [...this.messages];
      });
    },
    sendMessage(message) {
      let id = "new_" + Math.floor(Math.random() * 1000);
      this.messages = [
        ...this.messages,
        {
          _id: id,
          senderId: 0,
          content: message.content,
          date: this.$moment(new Date()).format("D MMMM"),
          timestamp: this.$moment(new Date()).format("HH:mm"),
          saved: true
        }
      ];
      this.scroll1pxUp();
      if (this.mode != "preview") {
        let body = { message: message.content };
        this.axios
          .post(this.backendURL + this.hash + "/messages", body)
          .then((response) => {
            const index = this.messages.findIndex(
              (message) => message._id == id
            );
            const message = response.data.data;
            this.messages[index] = {
              _id: message.id,
              senderId: message.inbound ? 0 : 1,
              content: message.message,
              date: this.$moment(message.send_at).format("D MMMM"),
              timestamp: this.$moment(message.send_at).format("HH:mm"),
              distributed: true
            };
            this.messages = [...this.messages];
            this.scroll1pxUp();
          })
          .catch(() => {
            const index = this.messages.findIndex(
              (message) => message._id == id
            );
            this.messages[index].failure = true;
            this.messages = [...this.messages];
            this.scroll1pxUp();
          });
      }
    }
  }
};
</script>

<style>
.loader {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: block;
  left: 50px;
  bottom: 15px;
  position: relative;
  color: #6c757d;
  box-sizing: border-box;
  animation: animloader 1s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow: -12px -3px, -4px 0, 4px 0, 12px 0;
  }
  33% {
    box-shadow: -12px 0px, -4px -3px, 4px 0, 12px 0;
  }
  66% {
    box-shadow: -12px 0px, -4px 0, 4px -3px, 12px 0;
  }
  100% {
    box-shadow: -12px 0, -4px 0, 4px 0, 12px -3px;
  }
}

.room-footer {
  padding-top: 45px;
  position: relative;
}

.typing-container {
  position: absolute;
  top: 10px;
  left: 25px;
}

.room-wrapper {
  height: 64px !important;
  padding: 7px 16px !important;
  margin-bottom: 5px;
  border-bottom: 1px solid #e1e4e8;
  background-color: #ffffff;
}

.room-header {
  flex-direction: column;
  border-bottom: none;
  background-color: #f8f9fa;
  height: 105px;
}

#typing-avatar {
  display: inline-block;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: #ddd;
  height: 32px;
  width: 32px;
  min-height: 28px;
  min-width: 28px;
  border-radius: 50%;
}

#avatar-name {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 600;
  position: relative;
  bottom: 17px;
  margin-bottom: 0;
  vertical-align: text-top;
}
</style>