<template>
  <div>
    <b-row class="pb-3 pb-lg-4">
      <b-col cols="12" xl="2" class="input-salutation pr-xl-2">
        <label class="form-label" for="request_salutation">
          <span
            v-html="
              placeholder('%label.checkout.salutation%', $t('form.salutation'))
            "
          ></span>
          <span class="mandatory">{{ isFieldMandatory("salutation") }}</span>
        </label>
        <b-form-select
          class="select-salutation"
          v-model="request.gender"
          :options="salutation"
          :state="getErrorState('salutation')"
          id="request_salutation"
          @input="validateRequestForm('gender')"
          data-cy="salutation"
          :data-cy-value="request.gender"
          :data-cy-required="!!isFieldMandatory('salutation')"
        />
      </b-col>
      <b-col cols="6" xl="5" class="input-firstname pr-2 px-xl-2">
        <label for="request_firstname" class="form-label">
          <span
            v-html="
              placeholder('%label.checkout.firstname%', $t('form.firstname'))
            "
          ></span>
          <span class="mandatory">{{ isFieldMandatory("firstname") }}</span>
        </label>
        <b-form-input
          v-model="request.firstname"
          :state="getErrorState('firstname')"
          id="request_firstname"
          data-cy="firstname"
          :data-cy-required="!!isFieldMandatory('firstname')"
          @input="validateRequestForm('firstname')"
        />
      </b-col>
      <b-col cols="6" xl="5" class="input-lastname pl-2">
        <label for="request_lastname" class="form-label">
          <span
            v-html="
              placeholder('%label.checkout.lastname%', $t('form.lastname'))
            "
          ></span>
          <span class="mandatory">{{ isFieldMandatory("lastname") }}</span>
        </label>
        <b-form-input
          v-model="request.lastname"
          :state="getErrorState('lastname')"
          id="request_lastname"
          data-cy="lastname"
          :data-cy-required="!!isFieldMandatory('lastname')"
          @input="validateRequestForm('lastname')"
        />
      </b-col>
    </b-row>
    <b-row class="pb-3 pb-lg-4">
      <b-col cols="12" xl="6" class="input-email pr-xl-2">
        <label for="request_email" class="form-label">
          <span
            v-html="placeholder('%label.checkout.email%', $t('form.email'))"
          ></span>
          <span class="mandatory">{{ isFieldMandatory("email") }}</span>
        </label>
        <b-form-input
          v-model="request.email"
          :state="getErrorState('email')"
          id="request_email"
          data-cy="email"
          :data-cy-required="!!isFieldMandatory('email')"
          @input="validateRequestForm('email')"
        />
      </b-col>
      <b-col cols="12" xl="6" class="input-phone pt-3 pt-xl-0 pl-xl-2">
        <label for="request_phone" class="form-label">
          <span
            v-html="placeholder('%label.checkout.phone%', $t('form.cellphone'))"
          ></span>
          <span class="mandatory">{{ isFieldMandatory("phone") }}</span>
        </label>
        <div
          :class="{
            'wrapper-phone-number-valid': isPhoneNumberValid && focused,
            'wrapper-phone-number-invalid':
              !isPhoneNumberValid && focused && checkoutErrorPhone
          }"
        >
          <VueTelInput
            id="request_phone"
            v-model="request.phone"
            validCharactersOnly
            @validate="validatePhoneNumber"
            @input="validateRequestForm('phone')"
            :class="{
              isValid: isPhoneNumberValid && focused,
              isInvalid:
                (!firstLoadValidFlag && checkoutErrorPhone) ||
                (!isPhoneNumberValid && checkoutErrorPhone)
            }"
            @focus="setFocused()"
            @blur="setFocused(false)"
            data-cy="phone"
            :data-cy-required="!!isFieldMandatory('phone')"
            style="box-shadow: none"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="pb-4">
      <b-col cols="4" xl="2" class="input-country pr-2">
        <label for="request_country" class="form-label">
          <span
            v-html="placeholder('%label.checkout.country%', $t('form.country'))"
          ></span>
          <span class="mandatory">{{ isFieldMandatory("country") }}</span>
        </label>
        <b-form-select
          v-model="request.country"
          :options="countries"
          :value-field="'value'"
          :label-field="'text'"
          :state="getErrorState('country')"
          id="request_country"
          data-cy="country"
          :data-cy-required="!!isFieldMandatory('country')"
          :data-cy-value="request.country"
          @input="validateRequestForm('country')"
        />
      </b-col>
      <b-col cols="8" xl="3" class="input-city pl-2 px-xl-2">
        <label for="request_city" class="form-label">
          <span
            v-html="placeholder('%label.checkout.city%', $t('form.city'))"
          ></span>
          <span class="mandatory">{{ isFieldMandatory("city") }}</span>
        </label>
        <b-form-input
          v-model="request.city"
          :state="getErrorState('city')"
          id="request_city"
          data-cy="city"
          :data-cy-required="!!isFieldMandatory('city')"
          @input="validateRequestForm('city')"
        />
      </b-col>
      <b-col cols="4" xl="2" class="input-zip pt-3 pt-xl-0 pr-2 px-xl-2">
        <label for="request_zip" class="form-label">
          <span
            v-html="placeholder('%label.checkout.zip%', $t('form.zip'))"
          ></span>
          <span class="mandatory">{{ isFieldMandatory("zip") }}</span>
        </label>
        <b-form-input
          v-model="request.zip"
          :state="getErrorState('zip')"
          id="request_zip"
          data-cy="zip"
          :data-cy-required="!!isFieldMandatory('zip')"
          @input="validateRequestForm('zip')"
        />
      </b-col>
      <b-col cols="8" xl="5" class="input-street pt-3 pt-xl-0 pl-2">
        <label for="request_street" class="form-label">
          <span
            v-html="placeholder('%label.checkout.street%', $t('form.street'))"
          ></span>
          <span class="mandatory">{{ isFieldMandatory("street") }}</span>
        </label>
        <b-form-input
          v-model="request.street"
          :state="getErrorState('street')"
          id="request_street"
          data-cy="street"
          :data-cy-required="!!isFieldMandatory('street')"
          @input="validateRequestForm('street')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="input-note">
        <label for="request_note" class="form-label">
          <span
            v-html="placeholder('%label.checkout.notes%', $t('form.notes'))"
          ></span>
          <span class="mandatory">{{ isFieldMandatory("note") }}</span>
        </label>
        <b-form-textarea
          v-model="request.note"
          :state="getErrorState('note')"
          id="request_note"
          rows="3"
          data-cy="note"
          :data-cy-required="!!isFieldMandatory('note')"
          @input="validateRequestForm('note')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script setup>
import { useBookingMethods } from "@/composables/useBookingMethods";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { getCurrentInstance, ref, computed } from "vue";

/**
 * Component lazy loading
 * Since the library is about 200kb of JavaScript and 100kb of CSS in order to improve initial page loading time
 * you might consider importing it asynchronously only when user navigates to the page where the library is actually needed.
 * @returns {Promise<Awaited<*>[]>}
 * @constructor
 */
const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input"),
    import(
      /* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input/dist/vue-tel-input.css"
    )
  ]).then(([{ VueTelInput }]) => VueTelInput);

const { proxy } = getCurrentInstance();
const { validateRequestForm, checkoutErrors, firstLoadValidFlag } =
  useBookingMethods();
const { placeholder, countries, page, request } = useCheckoutData();

function getErrorState(item) {
  return checkoutErrors.value.find((e) => e === item) ? false : null;
}

const salutation = [
  {
    value: "m",
    text: proxy.$t("form.title_options.m")
  },
  {
    value: "f",
    text: proxy.$t("form.title_options.f")
  }
];

const isPhoneNumberValid = ref(true);
const firstLoad = ref(true);
const focused = ref(false);

const setFocused = (val = true) => (focused.value = val);

const checkoutErrorPhone = computed(() => {
  return !!checkoutErrors.value.find((e) => e === "phone");
});

function isFieldMandatory(field) {
  return page.design_data.config.checkout?.mandatory.indexOf(field) >= 0
    ? "*"
    : "";
}

function validatePhoneNumber(num) {
  if (firstLoad.value) {
    firstLoad.value = false;
    return true;
  }
  isPhoneNumberValid.value = !(num.valid === undefined);
}
</script>

<style scoped>
.mandatory {
  padding-left: 2px;
}

.select-salutation {
  width: 120px;
  display: block;
  margin-bottom: 1rem;
}

.form-label {
  margin-bottom: 2px;
}

.isInvalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  transition: border-color 0.15s ease-in-out;
}

.isValid {
  border: 1px solid rgba(68, 168, 248, 0.75) !important;
  transition: border 0.15s ease-in-out;
}

#request_phone {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

#request_phone >>> input {
  border-radius: 0.3rem;
}

.wrapper-phone-number-invalid {
  box-shadow: rgba(232, 1, 1, 0.2) 0px 0px 0px 3px;
  transition: box-shadow 0.15s ease-in-out;
}

.wrapper-phone-number-valid {
  box-shadow: rgba(107, 181, 241, 0.45) 0px 0px 0px 3px;
  transition: box-shadow 0.15s ease-in-out;
}

#request_phone >>> input {
  color: #495057 !important;
}

@media (min-width: 999px) {
  .select-salutation {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1360px) {
  .select-salutation {
    width: 100%;
    margin-bottom: 0;
  }
}
</style>