import Vue from "vue";
import VueI18n from "vue-i18n";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import messages from "@/i18n";
import { BIcon, BootstrapVue, IconsPlugin, VBToggle } from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Router from "@/router/router";
import VueObserveVisibility from "vue-observe-visibility";
import VueSmoothScroll from "vue2-smooth-scroll";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import VueToast from "vue-toast-notification";
import VueVideoPlayer from "vue-video-player";
import shadow from "vue-shadow-dom";
import smoothscroll from "smoothscroll-polyfill";
import App from "./App.vue";
import "vue-toast-notification/dist/theme-sugar.css";
import "@/plugins/sentry";
import "./styles/styles.sass";
import "@mdi/font/css/materialdesignicons.css";

window.__forceSmoothScrollPolyfill__ = true;

smoothscroll.polyfill();

Vue.use(VueVideoPlayer);
Vue.use(VueToast);
Vue.use(VueSmoothScroll);
Vue.use(VueObserveVisibility);
Vue.use(shadow);
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);

Vue.router = Router;
Vue.directive("b-toggle", VBToggle);
Vue.component("BIcon", BIcon);

Vue.use(VueMoment, {
  moment
});

Vue.use(VueI18n);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
  pluralizationRules: {
    ru: function (choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    }
  }
});

new Vue({
  i18n,
  router: Router,
  render: (h) => h(App)
}).$mount("#app");
