export default {
  core: {
    history: "Historial",
    ok: "Aceptar",
    cancel: "Cancelar",
    read_more: "Mostrar más",
    close_modal: "Cerrar"
  },
  coupon: {
    back: "Volver",
    cancel: "Cancelar",
    redeem: "Continuar",
    input: {
      code: "Código de cupón"
    },
    modal: {
      default: {
        title: "Código de cupón",
        text: "Puede pagar el depósito de {deposit_value} con un vale válido. Si el valor del vale no es suficiente, puede pagar el monto restante con otro método de pago."
      },
      insufficient: {
        title: "Valor del cupón insuficiente",
        text: "El cupón tiene un valor de {coupon_amount}. Puede canjear este vale de todas formas y luego realizar el pago del saldo de {deposit_balance}."
      },
      invalid: {
        title: "Cupón no válido",
        text: "Lamentablemente, el cupón ingresado no es válido. Por favor, utilice un cupón válido o seleccione un método de pago diferente."
      },
      remaining: {
        title: "Pago del saldo",
        item: "Pago de {payment_amount} completado exitosamente el {payment_date}",
        text: "Seleccione un método de pago para realizar el pago del saldo de {deposit_balance}."
      }
    }
  },
  page: {
    gallery: {
      image: "Imagen",
      video: "Video",
      pano: "360°",
      plan: "Planimetría",
      gallery: "Imagen de la galería",
      mute_audio: 'Silenciar',
      unmute_audio: 'Activar sonido'
    },
    whatsapp: {
      scan_code: "Escanear código QR",
      contact_over: "Contactar a través de la aplicación de WhatsApp",
      whatsapp_web: "WhatsApp Web",
      whatsapp_web_contact: "Contactar a través de la aplicación web"
    },
    mode: {
      configuration: {
        message: "La página de destino está en modo de configuración"
      },
      versioned: {
        message_default: "Versión antigua",
        message: "Versión del {date}"
      }
    },
    success: {
      messageSuccessful: "Tu mensaje se envió correctamente"
    },
    errors: {
      messageIsEmpty: "Por favor, añade un mensaje",
      no_hash: {
        title: "Ha ocurrido un error",
        text: "Por favor, inténtalo de nuevo más tarde"
      },
      payment: {
        title: "No se pudo procesar el pago",
        text: "Por favor, inténtalo de nuevo más tarde",
        hint: "Mensaje de error: ",
        btn_retry: "Reintentar"
      },
      checkin: {
        tech_issue: {
          title:
            "Lo sentimos, el servicio de check-in en línea no está disponible",
          text: "Por favor, contáctanos si deseas hacer el check-in en línea"
        }
      },
      book_after_arrival_date: {
        title: "Oferta ya no disponible",
        text: "Lo sentimos, la oferta seleccionada ya no está disponible. Por favor, contáctanos para una nueva oferta."
      }
    },
    nav: {
      offer: "Oferta",
      highlights: "Aspectos destacados",
      info_services: "Información y servicios",
      about: "Acerca de",
      contact: "Contacto",
      terms_of_use: "Términos de uso",
      privacy_policy: "Política de privacidad"
    },
    content: {
      today_reception: "Hoy para ti en la recepción:"
    },
    highlights: {},
    footer: {
      partners: "Socios",
      contact: "Contáctanos",
      menu: "Navegación",
      info: "Información"
    },
    account: {
      vat: "IVA"
    },
    placeholder: {
      whatsapp: "Tengo una pregunta sobre una oferta #{offer}"
    }
  },
  entity: {
    best_offer: "Nuestra mejor oferta para ti",
    details: "Detalles",
    insurance: {
      text: "Con nuestro seguro de protección por cancelación de viaje, estarás protegido financieramente en caso de que no puedas viajar de manera inesperada.",
      ctabtn: "SEGURO DE VIAJE",
      title: "Seguro de viaje"
    },
    selected: "SELECCIONADO",
    select: "Seleccionar",
    highlight: "Destacado",
    highlights_header: "Aspectos destacados",
    personal_info_header: "Información personal",
    personal_info: "Información personal",
    show_details: "Mostrar detalles",
    hide_details: "Ocultar detalles",
    variant: "Variante",
    variant_x: "Variante {x}",
    nights_x: "1 noche|{x} noches",
    room_x: "Habitación {x}",
    arrival: "Llegada",
    arrival_daparture: "Llegada/Salida",
    persons_treatment: "Personas/Tratamiento",
    room_category: "Categoría de habitación",
    departure: "Salida",
    offer: "Oferta",
    entity_of: " {n} de {count}",
    deposit_needed: "Se requiere un depósito de {amount}",
    no_deposit_needed: "No se requiere depósito",
    valid_till: "La oferta es válida hasta:",
    valid_till_expired:
      "Esta oferta ya no es válida, por favor contáctanos si deseas recibir otra oferta.",
    cancel_policy: {
      title: "Política de cancelación",
      show: "Mostrar política de cancelación",
      free_cancellation: "Cancelación gratuita",
      free_cancellation_until: "Cancelación gratuita antes de"
    },
    mealplans: {
      HB: "Media pensión",
      WS: "-",
      BB: "Alojamiento y desayuno",
      FB: "Pensión completa",
      AI: "Todo incluido",
      RO: "Solo alojamiento"
    },
    room: {
      rooms: "Alojamiento | Alojamientos",
      persons: "1 persona | {count} personas",
      child_pl: "1 Niño | {count} Niños",
      adult_pl: "1 Adulto | {count} Adultos",
      traveler: "Personas",
      treatment: "Tratamiento",
      period: "Estancia",
      info: "Información",
      description: "Descripción",
      room_change_on: "Cambio de habitación el"
    },
    treatment: {
      close: "Cerrar",
      booked_spa_header: "Servicios de spa reservados",
      date: "Fecha",
      time: "Hora",
      time_definition: "A las {time}",
      period: "Duración",
      show_details: "Mostrar detalles",
      time_slug: "min",
      package: {
        applied_spa: "Servicios",
        description: "Descripción",
        total_amount: "Monto total"
      },
      item: {
        description: "Descripción",
        title: "Tratamiento",
        quantity: "Cantidad",
        price: "Precio",
        time: "Duración",
        guest_name: "Huésped",
        date: "Fecha",
        date_time: "Hora"
      }
    },
    price: {
      description: "Descripción",
      description_short: "Descr.",
      quantity: "Cantidad",
      discount: "Descuento",
      amount: "Monto",
      taxes_included: "Impuesto turístico incluido",
      show_price_details:
        "Mostrar detalles de precio y política de cancelación",
      show_price_details_short: "Mostrar detalles de precio",
      hide_price_details: "Ocultar detalles de precio",
      deposit_balance: "Depósito pendiente",
      price: "Precio",
      total_price: "Precio total",
      deposit: "Depósito",
      deposit_amount: "Monto del depósito",
      balance: "Saldo pendiente"
    },
    payment: {
      select_method: "Por favor, selecciona un método de pago",
      payment_success:
        "El pago de {amount} se realizó correctamente a través de {gateway_name} el {payment_created_data} a las {payment_created_time}.",
      authorized_bank_transfer_payment:
        "Se seleccionó {gateway_name} como método de pago por el monto de {amount}.",
      success_bank_transfer_payment:
        "La transferencia de {amount} se realizó correctamente a través de {gateway_name} el {payment_changed_data} a las {payment_changed_time}.",
      default_payment_success:
        "El pago de {amount} se realizó correctamente el {payment_created_data}.",
      default_payment_authorized: "Se registró el pago de {amount}.",
      no_payment_methods:
        "Desafortunadamente, esta oferta no se puede reservar porque no se han definido métodos de pago. Por favor, ponte en contacto con el hotel para obtener más información.",
      preauthorized_payment: "El pago de {amount} se autorizó con éxito el {payment_created_data} a las {payment_created_time} a través de la {gateway_name}.",
      methods: {
        credit_card: "Tarjeta de crédito",
        credit_card_hint:
          "Tarjeta de crédito: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        wire_transfere: "Transferencia bancaria",
        wire_transfere_hint:
          "Transferencia bancaria: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        paypal: "PayPal",
        paypal_hint:
          "PayPal: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur."
      },
      secure_checkout: "Pago seguro",
      secure_checkout_hint:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat condimentum."
    },
    request: {
      thank_you: {
        header: "Muchas gracias por tu solicitud",
        text: "Tu reserva se ha transmitido con éxito y la confirmaremos lo antes posible. Por favor, no dudes en contactarnos si tienes alguna pregunta adicional.",
        button: "Cerrar"
      }
    },
    buttons: {
      book: "Reservar",
      details: "Detalles",
      coupon_apply: "Canjear",
      reserve: "Reservar"
    },
    expiration_time: "hasta las {time} CET"
  },
  personal_details: "Detalles personales",
  checkin: {
    room_x_of: "Habitación",
    next: "Siguiente",
    select: {
      country: "Seleccionar país",
      date: "Seleccionar fecha",
      time: "Seleccionar hora"
    },
    allocate_room: "Asignar habitación",
    thank_you: {
      title: "¡Check-in en línea completado con éxito!",
      text: "Tu reserva se ha transmitido con éxito y la confirmaremos lo antes posible. Por favor, no dudes en contactarnos si tienes alguna pregunta adicional."
    },
    title: "Check-In en línea",
    guest_1: "Huésped principal",
    guest_2: "Huésped secundario",
    choose_room: "Elegir una habitación"
  },
  promocode: {
    apply: "Aplicar",
    remove: "Eliminar",
    label: "Código promocional",
    added: "Se ha aplicado el código de descuento",
    not_valid: "El código de descuento no es válido",
    deleted: "Se ha eliminado el código de descuento"
  },
  form: {
    errors: {
      selectPayMethod: "Por favor, selecciona tu método de pago"
    },
    error_occured: "Ha ocurrido un error. Por favor, verifica tus datos",
    payment: {
      error: {
        title: "Ha ocurrido un error",
        text: "Por favor, inténtalo nuevamente más tarde"
      }
    },
    doctypes: {
      ID_CARD: "Documento de identidad",
      DRIVERS_LICENSE: "Licencia de conducir",
      PASSPORT: "Pasaporte"
    },
    labels: {
      additional_info: "Información adicional",
      document_info: "Información del documento",
      birth_info: "Información de nacimiento",
      address_info: "Dirección",
      contact_info: "Nombre e información de contacto",
      assign_room: "Asignación de habitación",
      other_info: "Otro"
    },
    region: "Provincia",
    upload: "Cargar",
    country_iso: "País",
    mobilephone: "Teléfono móvil",
    birth_date: "Fecha de nacimiento",
    birth_place: "Lugar de nacimiento",
    birth_municipality: "Municipio de nacimiento",
    birth_country_iso: "País de nacimiento",
    birth_region: "Provincia de nacimiento",
    fiscal_code: "Número de identificación fiscal",
    nationality_iso: "Nacionalidad",
    document_type: "Tipo de documento",
    document_number: "Número de documento",
    document_valid_from: "Fecha de emisión",
    document_valid_to: "Válido hasta",
    document_issuing_country_iso: "País emisor",
    document_issuing_city: "Autoridad emisora",
    arrival: "Llegada",
    checkin_time: "Hora de llegada",
    license_plate: "Matrícula",
    driver_name: "Nombre del conductor",
    car_brand: "Marca del coche",
    notes: "Solicitudes adicionales",
    terms_short: "Acepto la política de privacidad",
    action_send: "Enviar datos",
    gender_please_select: "Por favor, selecciona el género",
    back: "Volver",
    close: "Cerrar",
    select_paymethod: "Selecciona tu método de pago",
    checkout: "Finalizar compra",
    salutation: "Tratamiento",
    title_options: {
      m: "Sr.",
      f: "Sra."
    },
    name: "Nombre",
    firstname: "Nombre",
    lastname: "Apellido",
    street: "Calle",
    zip: "Código postal",
    city: "Ciudad",
    municipality: "Municipio",
    country: "País",
    email: "Correo electrónico",
    email_hint: "Lorem ipsum dolor sit amet consectetur.",
    phone: "Teléfono",
    cellphone: "Teléfono móvil",
    phone_hint: "Lorem ipsum dolor sit amet consectetur.",
    contact: "Dirección",
    hint_booking: "{link_booking} Loren ipsum sit dolor",
    //your_request: 'Your request',
    easy_reservation: "Reserva sin compromiso",
    terms: "Acepto la política de privacidad y las condiciones generales.",
    checkin_terms: "Acepto la [política de privacidad].",
    newsletter:
      "Deseo recibir notificaciones regulares sobre ofertas y noticias.",
    check_terms: "Por favor, acepta los términos y la política"
  },
  dict: {
    for: "por"
  },
  booking: {
    default: {
      title: "¡Muchas gracias por tu solicitud!",
      text: "Tu solicitud se ha enviado correctamente y la procesaremos lo antes posible. No dudes en contactarnos si tienes alguna pregunta adicional."
    },
    offer: {
      title: "¡Muchas gracias por tu solicitud de reserva!",
      text: "Tu solicitud de reserva se ha enviado correctamente y confirmaremos la reserva lo antes posible. No dudes en contactarnos si tienes alguna pregunta adicional."
    },
    order: {
      title: "¡Muchas gracias por tu reserva!",
      text: "Tu reserva se ha transmitido correctamente y la confirmaremos lo antes posible. No dudes en contactarnos si tienes alguna pregunta adicional."
    }
  },
  chat_window: {
    new_messages: "¡Tienes mensajes no leídos!",
    message_empty: "No hay mensajes",
    conversation_started: "Conversación iniciada el:",
    type_message: "Escribe un mensaje",
    search: "Buscar",
    previewMode:
      "No se pueden enviar mensajes de chat en el modo de vista previa."
  },
  checkin_error_message:
    "Atención, esta campaña no está asignada a un huésped. Primero se debe agregar un huésped para poder completar el formulario de check-in en línea.",
  checkout: {
    price_table: "Tabla de precios"
  },
  chat_email_from: "de",
  upselling: {
    category: {
      category_title: 'Categoría',
      without_category: 'Servicios adicionales sin categoría'
    },
    service: {
      service_title: 'Servicios adicionales',
      add_service: 'A la selección',
      selected_service: 'seleccionado',
      change_service: 'Editar',
      add_to_cart_service: 'Añadir',
      calc_modes: {
        MODE_PER_DAY: "por noche",
        MODE_PER_PERSON: "por persona",
        MODE_PER_DAY_AND_PERSON: "por noche y persona",
        MODE_PER_PRODUCT_UNIT: "por unidad de alojamiento",
        MODE_PER_LIVING_UNIT_AND_DAY: "por unidad de alojamiento y noche",
        MODE_PER_ITEM: "tarifa plana"
      },
      calc_info: 'El precio indicado es el importe total del tipo de cálculo "{calc_mode}" de este servicio.',
      preselected_service_hotel: 'Reservado {n}x por el hotel',
      preselected_service_customer: 'Reservado {n}x por el huésped',
      show_more_services: 'Mostrar todos los servicios',
      hide_services: 'Ocultar todos los servicios'
    },
    service_price_table: {
      service: 'Prestaciones',
      quantity: 'Cantidad',
      price: 'Precio',
      amount: 'Monto',
      description: 'Descripción'
    }
  }
};