<template>
  <div ref="player" :id="playerId"></div>
</template>

<script setup>
import {
  ref,
  onUnmounted,
  onMounted,
  defineProps,
  defineEmits,
  toRefs,
  watch,
  defineExpose
} from "vue";
import YouTubePlayer from "youtube-player";
import { usePlayer } from "@/components/youtubePlayer/usePlayer";

const props = defineProps({
  width: { type: Number, default: 480 },
  height: { type: Number, default: 320 },
  mute: { type: Boolean, default: true },
  autoplay: {
    type: Number,
    default: 1,
    validator: (v) => Number(v) === 0 || Number(v) === 1
  },
  videoId: { type: String, required: true },
  playerId: { type: String, required: true },
  loop: {
    type: Number,
    default: 1,
    validator: (v) => Number(v) === 0 || Number(v) === 1
  },
  controls: {
    type: Number,
    default: 0,
    validator: (v) => Number(v) === 0 || Number(v) === 1
  }
});

const emits = defineEmits(["ended", "paused", "played", "speed-changed"]);

const { videoId, controls, loop, autoplay, width, height, mute } =
  toRefs(props);
const player = ref();
const { players } = usePlayer();
onMounted(() => {
  const playerVars = {
    autoplay: autoplay.value,
    loop: loop.value,
    controls: controls.value,
    playlist: videoId.value,
    mute: mute.value,
    modestbranding: 1
  };
  player.value = YouTubePlayer(props.playerId, {
    host: "https://www.youtube.com",
    width: width.value,
    height: height.value,
    videoId: videoId.value,
    playerVars
  });
  player.value[mute.value ? "mute" : "unMute"]();
  player.value.on("stateChange", (e) => {
    if (e.data === window.YT.PlayerState.ENDED) {
      emits("ended");
    } else if (e.data === window.YT.PlayerState.PAUSED) {
      emits("paused");
    } else if (e.data === window.YT.PlayerState.PLAYING) {
      emits("played");
    }
  });
  players[props.playerId] = player.value;
});

watch(videoId, () => {
  player.value.loadVideoById(videoId.value);
  player.value.playVideo();
});

onUnmounted(() => {
  player.value?.destroy();
  player.value = null;
});

defineExpose({ player });
</script>