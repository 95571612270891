<template>
  <div>
    <b-modal
      id="show-offer"
      size="md"
      hide-header
      class="pl-0"
      v-if="showModalOffer.value"
      centered
    >
      <div class="closeOfferModal">
        <button @click="closeOfferModal()">
          {{ $t("entity.treatment.close") }}
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <b-row class="mx-0">
        <b-col cols="6" style="padding: 0">
          <div
            v-if="
              showModalOffer.value?.media.length > 0 &&
              showModalOffer.value.media[0].public_url
            "
            :style="{
              'background-image':
                'url(' + getVal(showModalOffer.value.media[0].public_url) + ')'
            }"
            class="spa-image"
          />
        </b-col>
        <b-col cols="6" style="padding: 0 10px">
          <div class="treatment-details custom-table">
            <h5 class="mt-0">{{ showModalOffer.value.title }}</h5>
            <div>
              {{ $t("form.name") }}:
              {{ getVal(page.entity.order_customer.firstname) }}
              {{ getVal(page.entity.order_customer.lastname) }}
            </div>
            <div>
              {{ $t("entity.treatment.date") }}:
              {{ formatDate(getVal(showModalOffer.value.from_date)) }}
            </div>
            <div>
              {{ $t("entity.treatment.time") }}:
              {{
                $t("entity.treatment.time_definition", {
                  time: getVal(showModalOffer.value.from_time)
                })
              }}
            </div>
            <div>
              {{ $t("entity.treatment.period") }}:
              {{
                timeDifference(
                  getVal(showModalOffer.value.from_time),
                  getVal(showModalOffer.value.to_time)
                )
              }}
            </div>
            <div>
              {{ $t("entity.price.price") }}:
              {{
                parseInt(getVal(showModalOffer.value.pu_amount))
                  ? formatPrice(getVal(showModalOffer.value.pu_amount))
                  : formatPrice(getVal(showModalOffer.value.pu_price))
              }}
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="payment-html"
      hide-header
      size="xl"
      centered
      body-class="pt-5"
      @hidden="isLoading = false"
    >
      <div class="closeButton">
        <button @click="$bvModal.hide('payment-html')">
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>

      <iframe
        frameborder="0"
        style="height: 80vh; width: 100%"
        :srcdoc="paymentHTML"
      />
    </b-modal>
    <b-modal
      id="booking-after-arrival"
      size="md"
      centered
      hide-header
    >
      <div class="closeModal">
        <button @click="$bvModal.hide('booking-after-arrival')">
          <span class="close-label">{{ $t("entity.treatment.close") }}</span>
          <span class="closeIcon"> <b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <div class="text-center">
        <h2
          v-html="
            placeholder(
              '%label.message.after_arrival_date.title%',
              $t('page.errors.book_after_arrival_date.title')
            )
          "
          class="mt-4 mb-2"
        />
        <p
          v-html="
            placeholder(
              '%label.message.after_arrival_date.text%',
              $t('page.errors.book_after_arrival_date.text')
            )
          "
        />
      </div>
    </b-modal>
  </div>
</template>

<script setup>
import { getCurrentInstance } from "vue";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useBookingMethods } from "@/composables/useBookingMethods";

const {
  placeholder,
  formatPrice,
  formatDate,
  getVal,
  timeDifference,
  page,
  showModalOffer
} = useCheckoutData();
const { isLoading, paymentHTML } = useBookingMethods();

const { proxy } = getCurrentInstance();

function closeOfferModal() {
  showModalOffer.value = null;
  proxy.$bvModal.hide("show-offer");
}
</script>

<style scoped>
.spa-image {
  max-width: 100%;
}
</style>