<template>
  <div id="truncateText">
    <div v-html="isTruncated ? truncatedContent : content"></div>
    <div
      class="text-center"
      @click="isTruncated = false"
      v-if="isTruncated && shouldTruncate"
    >
      <slot name="showMore"></slot>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import clip from "text-clipper";

const props = defineProps({
  content: {
    type: String,
    required: true
  },
  maxLength: {
    type: Number,
    required: false,
    default: 300
  }
});

const parsedHtml = computed(() => {
  const div = document.createElement("div");
  div.innerHTML = props.content;
  return div.innerText;
});
const shouldTruncate = computed(
  () => parsedHtml.value.length > props.maxLength
);
const isTruncated = ref(shouldTruncate.value);
const truncatedContent = computed(() => {
  return isTruncated.value
    ? clip(props.content, props.maxLength, { html: true })
    : props.content;
});
</script>