var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.ready)?_c('div',[_c('div',{staticClass:"picture-main"},[_c('mini-slider',{ref:'miniSlider' + _vm.index,attrs:{"mediaList":_vm.mediaList,"currentIndex":{
        index: _vm.roomGalleryIndex,
        interval: _vm.sendInterval
      },"uniqueIndex":_vm.index,"video-ratio":'4by3'},on:{"sliderControl":function($event){return _vm.sliderChanged($event)}}})],1),(_vm.mediaList.length > 1)?_c('div',{staticClass:"picture-select"},[_c('b-row',{staticClass:"no-gutters"},[_vm._l((_vm.mediaListArray),function(media,mediaIndex){return [_c('b-col',{key:media.public_id + mediaIndex,class:{
            active:
              _vm.hoverClass(media.scope) &&
              (mediaIndex === _vm.freeSlotsC &&
              _vm.room.media[_vm.roomGalleryIndex].scope === media.scope
                ? _vm.roomGalleryIndex > _vm.freeSlotsC
                : false)
          },attrs:{"cols":"3"},on:{"mouseenter":function($event){return _vm.selectScope(media.scope, mediaIndex)},"mouseleave":function($event){return _vm.mouseOut(media.scope, mediaIndex)},"click":function($event){return _vm.showGallery(media.scope, mediaIndex)}}},[_c('div',{staticClass:"spinner-border text-secondary gallery-loading",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"bg-img embed-responsive embed-responsive-4by3",style:({
              'background-image':
                'url(' +
                _vm.buildMediaURL(
                  _vm.roomGalleryIndex > _vm.freeSlotsC &&
                    mediaIndex === _vm.freeSlotsC &&
                    _vm.room.media[_vm.roomGalleryIndex].scope === 'gallery'
                    ? _vm.room.media[_vm.roomGalleryIndex].preview_img ||
                        _vm.room.media[_vm.roomGalleryIndex].public_url
                    : media.preview_img || media.public_url,
                  media.provider,
                  '200x150'
                ) +
                ')'
            })},[_c('transition',{attrs:{"name":"slider-fade"}},[(
                  _vm.freeSlotsC === 0
                    ? !_vm.hoverClass(media.scope) &&
                      _vm.roomGalleryIndex !== mediaIndex
                    : !(
                        _vm.hoverClass(media.scope) &&
                        _vm.roomGalleryIndex === mediaIndex
                      )
                )?_c('div',{staticClass:"icon-overlay"},[(media.scope === 'picture')?_c('div',[_c('img',{staticClass:"icon d-block mx-auto",attrs:{"src":require("@/assets/icons/screen.svg")}}),_vm._v(" "+_vm._s(_vm.$t("page.gallery.image"))+" ")]):_vm._e(),(mediaIndex === _vm.freeSlotsC)?_c('div',{style:('font-size: 20px')},[(
                      _vm.mediaList.filter((i) => i.scope === 'gallery').length -
                        _vm.freeSlotsC >
                      0
                    )?[_vm._v(" ("+_vm._s(_vm.freeSlotsC !== 0 ? _vm.mediaList.filter((i) => i.scope === "gallery") .length - _vm.freeSlotsC : _vm.mediaList.filter((i) => i.scope === "gallery") .length - _vm.freeSlotsC + 1)+") ")]:_vm._e()],2):_vm._e()]):_vm._e()])],1)])]}),(
          _vm.mediaList.filter((i) => i.scope == 'picture_floorplan').length > 0
        )?_c('b-col',{staticClass:"floor_plan",class:{
          active: _vm.hoverClass('picture_floorplan')
        },attrs:{"cols":"3"},on:{"mouseenter":function($event){return _vm.selectScope('picture_floorplan')},"mouseleave":function($event){return _vm.mouseOut('picture_floorplan')},"click":function($event){return _vm.showGallery('picture_floorplan')}}},[_c('div',{staticClass:"spinner-border text-secondary gallery-loading",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"bg-img embed-responsive embed-responsive-4by3",style:({
            'background-image':
              'url(' +
              _vm.buildMediaURL(
                _vm.mediaList.filter((i) => i.scope == 'picture_floorplan')[0]
                  .preview_img,
                _vm.mediaList.filter((i) => i.scope == 'picture_floorplan')[0]
                  .provider,
                '200x150'
              ) +
              ')'
          })},[_c('transition',{attrs:{"name":"slider-fade"}},[(!_vm.hoverClass('picture_floorplan'))?_c('div',{staticClass:"icon-overlay"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/skizze.svg")}}),_c('div',[_vm._v(_vm._s(_vm.$t("page.gallery.plan")))])]):_vm._e()])],1)]):_vm._e(),(_vm.mediaList.filter((i) => i.scope == 'video').length > 0)?_c('b-col',{staticClass:"video",class:{
          active: _vm.hoverClass('video')
        },attrs:{"cols":"3"},on:{"mouseenter":function($event){return _vm.selectScope('video')},"mouseleave":function($event){return _vm.mouseOut('video')},"click":function($event){return _vm.showGallery('video')}}},[_c('div',{staticClass:"spinner-border text-secondary gallery-loading",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"bg-img embed-responsive embed-responsive-4by3",style:({
            'background-image':
              'url(' +
              _vm.buildMediaURL(
                _vm.mediaList.filter((i) => i.scope == 'video')[0].preview_img,
                'cdn',
                '200x150'
              ) +
              ')'
          })},[_c('transition',{attrs:{"name":"slider-fade"}},[(!_vm.hoverClass('video'))?_c('div',{staticClass:"icon-overlay"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/video.svg")}}),_c('div',[_vm._v(_vm._s(_vm.$t("page.gallery.video")))])]):_vm._e()])],1)]):_vm._e(),(_vm.mediaList.filter((i) => i.scope == 'panorama').length > 0)?_c('b-col',{staticClass:"panorama",class:{
          active: _vm.hoverClass('panorama')
        },attrs:{"cols":"3"},on:{"mouseenter":function($event){return _vm.selectScope('panorama')},"mouseleave":function($event){return _vm.mouseOut('panorama')},"click":function($event){return _vm.showGallery('panorama')}}},[_c('div',{staticClass:"spinner-border text-secondary gallery-loading",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]),_c('div',{staticClass:"bg-img embed-responsive embed-responsive-4by3",style:({
            'background-image':
              'url(' +
              _vm.buildMediaURL(
                _vm.mediaList.filter((i) => i.scope == 'panorama')[0].preview_img,
                'cdn',
                '200x150'
              ) +
              ')'
          })},[_c('transition',{attrs:{"name":"slider-fade"}},[(!_vm.hoverClass('panorama'))?_c('div',{staticClass:"icon-overlay"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/360.svg")}}),_c('div',[_vm._v(_vm._s(_vm.$t("page.gallery.pano")))])]):_vm._e()])],1)]):_vm._e()],2)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }