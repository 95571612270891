import { reactive } from "vue";
import { createSharedComposable } from "@vueuse/core";

const usePlayerSingleton = () => {
  const players = reactive({});

  return { players };
};

export const usePlayer = createSharedComposable(usePlayerSingleton);
