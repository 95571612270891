<template>
  <div>
    <b-modal
      v-if="coupon.modal"
      id="coupon-box"
      size="lg"
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div v-if="!coupon.progress" class="closeModal">
        <button @click="couponBoxClose()">
          <span class="close-label">{{ $t("entity.treatment.close") }}</span>
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <div class="p-3">
        <template v-if="coupon.page === 'default'">
          <h2>
            <span
              v-html="
                placeholder(
                  '%label.coupon.default.title%',
                  $t('coupon.modal.default.title')
                )
              "
            />
          </h2>
          <div class="mx-0 my-0 my-lg-4 mx-lg-4">
            <p
              v-html="
                placeholder(
                  '%label.coupon.default.text%',
                  $t('coupon.modal.default.text', {
                    deposit_value: formatPrice(selectedVariant.deposit_balance)
                  }),
                  false,
                  {
                    deposit_value: formatPrice(selectedVariant.deposit_balance)
                  }
                )
              "
            />
          </div>
          <div class="my-5">
            <b-col cols="12" lg="6" class="mx-auto">
              <b-input-group class="mt-3">
                <template #append>
                  <div
                    class="ml-2"
                    style="padding-top: 3px; height: 37px; width: 40px"
                  >
                    <b-spinner
                      v-if="coupon.progress"
                      variant="secondary"
                      label="Spinning"
                    />
                  </div>
                </template>
                <b-form-input
                  :readonly="coupon.progress"
                  :label="$t('coupon.input.code')"
                  :placeholder="$t('coupon.input.code')"
                  v-model.trim="coupon.code"
                />
              </b-input-group>
            </b-col>
          </div>
          <div class="text-right mt-5">
            <button
              v-html="
                placeholder('%label.offer.ctabtn%', $t('entity.buttons.book'))
              "
              :disabled="
                coupon.progress || !coupon.code || coupon.code?.length < 3
              "
              :class="{
                'custom-italic': page.design_data.style?.cta?.italic,
                'custom-bold': page.design_data.style?.cta?.bold
              }"
              type="button"
              class="btn action-book btn-success btn-lg"
              @click="couponAction('redeem')"
            />
          </div>
        </template>
        <template v-if="coupon.page == 'insufficient'">
          <h2>
            {{
              placeholder(
                "%label.coupon.insufficient.title%",
                $t("coupon.modal.insufficient.title")
              )
            }}
          </h2>
          <div class="mx-0 my-0 my-lg-4 mx-lg-4">
            <p>
              {{
                placeholder(
                  "%label.coupon.insufficient.text%",
                  $t("coupon.modal.insufficient.text", {
                    coupon_amount: formatPrice(coupon.amount),
                    deposit_balance: formatPrice(
                      (selectedVariant.deposit_balance > 0
                        ? selectedVariant.deposit_balance
                        : selectedVariant.total_amount) - coupon.amount
                    )
                  }),
                  false,
                  {
                    coupon_amount: formatPrice(coupon.amount),
                    deposit_balance: formatPrice(
                      (selectedVariant.deposit_balance > 0
                        ? selectedVariant.deposit_balance
                        : selectedVariant.total_amount) - coupon.amount
                    )
                  }
                )
              }}
            </p>
          </div>
          <div class="text-right mt-5">
            <button
              :class="{
                'custom-italic': page.design_data.style?.cta?.italic,
                'custom-bold': page.design_data.style?.cta?.bold
              }"
              class="btn action-book btn-secondary btn-lg mr-3"
              type="button"
              @click="coupon.page = 'default'"
            >
              {{ $t("coupon.cancel") }}
            </button>
            <button
              :class="{
                'custom-italic': page.design_data.style?.cta?.italic,
                'custom-bold': page.design_data.style?.cta?.bold
              }"
              class="btn action-book btn-success btn-lg"
              type="button"
              @click="couponAction('continue')"
            >
              {{ $t("coupon.redeem") }}
            </button>
          </div>
        </template>
        <template v-if="coupon.page === 'invalid'">
          <h2>
            <span
              v-html="
                placeholder(
                  '%label.coupon.invalid.title%',
                  $t('coupon.modal.invalid.title')
                )
              "
            />
          </h2>
          <div class="mx-0 my-0 my-lg-4 mx-lg-4">
            <p
              v-html="
                placeholder(
                  '%label.coupon.invalid.text%',
                  $t('coupon.modal.invalid.text')
                )
              "
            />
          </div>
          <div class="text-right mt-5">
            <button
              :class="{
                'custom-italic': page.design_data.style?.cta?.italic,
                'custom-bold': page.design_data.style?.cta?.bold
              }"
              class="btn action-book btn-secondary btn-lg mr-3"
              type="button"
              @click="coupon.page = 'default'"
            >
              {{ $t("coupon.back") }}
            </button>
          </div>
        </template>
        <template v-if="coupon.page === 'remaining'">
          <h2>
            <span
              v-html="
                placeholder(
                  '%label.coupon.remaining.title%',
                  $t('coupon.modal.remaining.title')
                )
              "
            />
          </h2>
          <div class="mx-0 my-0 my-lg-4 mx-lg-4">
            <ExecutedPayments :page="page" :lpLocale="lpLocale" class="pb-3" />
            <div
              class="py-3"
              v-html="
                placeholder(
                  '%label.coupon.remaining.text%',
                  $t('coupon.modal.remaining.text', {
                    deposit_balance: formatPrice(
                      selectedVariant.deposit_balance
                    )
                  }),
                  false,
                  {
                    deposit_balance: formatPrice(
                      selectedVariant.deposit_balance
                    )
                  }
                )
              "
            />
            <PayMethods
              :selectedVariant="selectedVariant"
              :book-label="
                placeholder('%label.offer.ctabtn%', $t('entity.buttons.book'))
              "
              class="my-4"
              direction="inline"
              @save="bookAction(false)"
              @change="selectedPaymentMethod = $event"
              :page="page"
            />
          </div>
          <div class="text-right mt-5">
            <button
              :disabled="!selectedPaymentMethod"
              :class="{
                'custom-italic': page.design_data.style?.cta?.italic,
                'custom-bold': page.design_data.style?.cta?.bold
              }"
              class="btn action-book btn-success btn-lg"
              type="button"
              @click="bookAction(true)"
            >
              <span>{{
                placeholder("%label.offer.ctabtn%", $t("entity.buttons.book"))
              }}</span>
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
                class="ml-2"
              ></b-spinner>
            </button>
          </div>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script setup>
import PayMethods from "@/components/PayMethods";
import ExecutedPayments from "@/components/ExecutedPayments";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useBookingMethods } from "@/composables/useBookingMethods";
import { getCurrentInstance } from "vue";

const { placeholder, formatPrice } = useCheckoutData();
const {
  bookAction,
  couponAction,
  complete_payment,
  selectedPaymentMethod,
  isLoading
} = useBookingMethods();
const { proxy } = getCurrentInstance();

defineProps({
  selectedVariant: { type: Object, required: true },
  page: { type: Object, required: true },
  coupon: { type: Object, required: true },
  lpLocale: { type: String, required: true }
});

function couponBoxClose() {
  complete_payment.value = false;
  proxy.$bvModal.hide("coupon-box");
}
</script>