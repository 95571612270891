<template>
  <div class="h-100">
    <b-card
      :class="{ active: setActiveClass(), 'variant-card-mobile': isMobileView }"
      class="variant-card"
      no-body
      @click="
        $emit('selectVariant', {
          variant: variantData.variant,
          index: variantData.index
        })
      "
    >
      <!-- Highligthsbadge for old LP -->
      <div v-if="showHighlightBadgeComputed" class="variant-highlight-label">
        <b-badge
          v-html="
            placeholder(
              '%label.offer.variant.highlight%',
              $t('entity.highlight')
            )
          "
          :class="{
            'custom-italic':
              'badges' in designStyle &&
              'highlight_italic' in designStyle.badges &&
              designStyle.badges.highlight_italic,
            'custom-bold':
              'badges' in designStyle &&
              'highlight_bold' in designStyle.badges &&
              designStyle.badges.highlight_bold
          }"
          class="ml-2 highlight-badge"
          variant="info"
        ></b-badge>
      </div>
      <!-- Customized badges -->
      <div
        v-else-if="variantData.variant.badges"
        v-for="(marker, idx) in variantData.variant.badges.data"
        :key="idx"
        :class="{'badge-position': isMobileView}"
      >
        <div :style="{ top: 50 * idx + 'px' }" class="position-relative">
          <div class="offermarker-wrapper">
            <div
              :style="[getOfferMarkerStyle(marker.style)]"
              :class="'badge-' + marker.style.type"
              class="d-flex justify-content-center align-items-center offermarker position-absolute"
            >
              {{ marker.title }}
            </div>
            <!-- Triangles for the type ribbon -->
            <div v-if="marker.style.type == 'ribbon'" class="ribbon-triangles">
              <div
                :style="[
                  { borderTop: 'solid 22px' + marker.style['background-color'] }
                ]"
                class="ribbon-triangle-top"
              ></div>
              <div
                :style="[
                  {
                    borderBottom:
                      'solid 22px' + marker.style['background-color']
                  }
                ]"
                class="ribbon-triangle-bottom"
              ></div>
            </div>

            <!-- Triangles for the type banner -->
            <div
              v-else-if="marker.style.type == 'banner'"
              :style="{
                borderTop: '10px solid ' + marker.style['background-color']
              }"
              class="banner-triangle"
            ></div>
          </div>
        </div>
      </div>
      <div v-if="isMobileView" class="variant-preheader-mobile d-flex justify-content-between py-1 px-2 mb-2 mt-1">
        <div>
          <div
            v-if="variantsList.length != 1"
            class="offer-details-entity_of pl-1 font-weight-bold"
          >
          <span
            v-html="placeholder('%label.offer.variant%', $t('entity.variant'))"
          />
            {{
              $t("entity.entity_of", {
                n: variantsSlider + 1,
                count: variantsList.length
              })
            }}
          </div>
        </div>
        <div>{{ formatPrice(variantData.variant.total_amount) }}</div>
      </div>
      <b-card-header text-tag="div" class="variant-image-wrapper" :class="{'variant-image-wrapper-mobile mx-0': isMobileView}">
        <div
          class="variant-image"
          :class="{'variant-image-mobile': isMobileView}"
          :style="{
            'background-image': getVariantImageURL
          }"
        >
          <b-aspect aspect="16:9">
            <div v-if="variantTitleComputed" class="variant-title">
              {{ variantTitleComputed }}
            </div>
          </b-aspect>
        </div>
      </b-card-header>
      <b-card-text text-tag="div" class="variant-card-content">
        <div v-if="showMerchentsData">
          <div v-for="(merchantData, idx) in merchentsData" :key="idx">
            <div class="variant-hotel-name">
              {{ merchantData.name }}
            </div>
            <stars-rating
              :rating="merchantData.properties.stars"
              class="mt-2 mb-4"
            ></stars-rating>
          </div>
        </div>
        <div
          v-html="
            variantData.variant.title
              ? variantData.variant.title
              : placeholder('%label.offer.title%', $t('entity.offer')) +
                ': ' +
                page.entity.slug
          "
          class="variant-number"
          :class="{'mb-4': isMobileView}"
        ></div>
        <div
          v-for="productKey in Object.keys(groupProductsComputed)"
          :key="'key-' + productKey"
        >
          <div
            v-for="(product, index) in groupProductsComputed[productKey]"
            :key="product.id"
            class="variant-summary"
          >
            <div v-if="index == 0" class="variant-summary-group" :class="{'mb-3': isMobileView}">
              <div class="summary-label" :class="{'mb-1': isMobileView}">
                {{ $t("entity.arrival_daparture") }}
              </div>
              <div class="summary-value">
                {{ $moment(product.from_date).format("dd. L") }} -
                {{ $moment(product.to_date).format("dd. L") }}
                <div class="d-inline-block">
                  {{
                    $tc(
                      "entity.nights_x",
                      $moment(product.to_date).diff(
                        $moment(product.from_date),
                        "days"
                      ),
                      {
                        x: $moment(product.to_date).diff(
                          $moment(product.from_date),
                          "days"
                        )
                      }
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="variant-summary-group">
              <div class="summary-label" :class="{'mb-1': isMobileView}">
                {{
                  $tc("entity.room.rooms", 1) +
                  (variantData.variant.products.data.length > 1
                    ? " " + product.customIndex
                    : "")
                }}
              </div>
              <div class="summary-value">
                <span
                  v-if="
                    product.pu_participants_details.filter(
                      (p) => p.age_code == 'adult'
                    ).length > 0
                  "
                >
                  {{
                    $tc(
                      "entity.room.adult_pl",
                      product.pu_participants_details.filter(
                        (p) => p.age_code == "adult"
                      ).length,
                      {
                        count: product.pu_participants_details.filter(
                          (p) => p.age_code == "adult"
                        ).length
                      }
                    )
                  }}
                </span>
                <span
                  v-if="
                    product.pu_participants_details.filter(
                      (p) =>
                        p.age_code == 'child' ||
                        p.age_code == 'infant' ||
                        p.age_code == 'teenager'
                    ).length > 0
                  "
                >
                  -{{
                    $tc(
                      "entity.room.child_pl",
                      product.pu_participants_details.filter(
                        (p) =>
                          p.age_code == "child" ||
                          p.age_code == "infant" ||
                          p.age_code == "teenager"
                      ).length,
                      {
                        count: product.pu_participants_details.filter(
                          (p) =>
                            p.age_code == "child" ||
                            p.age_code == "infant" ||
                            p.age_code == "teenager"
                        ).length
                      }
                    )
                  }}
                </span>
                <span
                  >-
                  <span class="d-inline-block">
                    {{
                      variantData.variant.properties.data.find(
                        (item) => item.product_type == "mealplan"
                      ) &&
                      variantData.variant.properties.data.find(
                        (item) => item.product_type == "mealplan"
                      ).title
                    }}
                  </span></span
                >
              </div>
              <div class="summary-value">
                {{ product.quantity > 1 ? product.quantity + "x" : "" }}
                {{ product.title }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            $moment(new Date()).diff(
              $moment(
                variantData.variant.cancelpolicy_data.refundable_until
              ).format()
            ) < 0
          "
          class="variant-extras extras"
        >
          <span
            v-if="
              $moment(new Date()).diff(
                $moment(
                  variantData.variant.cancelpolicy_data.refundable_until
                ).format()
              ) < 0 &&
              'content' in designConfig &&
              'badge_cancellation' in designConfig.content &&
              designConfig.content.badge_cancellation
            "
            v-html="
              placeholder(
                '%label.cancel_policy.free_cancellation%',
                $t('entity.cancel_policy.free_cancellation')
              )
            "
            v-b-tooltip.hover.html
            :title="
              placeholder(
                '%label.cancel_policy.free_cancellation_until%',
                $t('entity.cancel_policy.free_cancellation_until')
              ) +
              ' ' +
              formatDate(variantData.variant.cancelpolicy_data.refundable_until)
            "
            class="badge badge-success cancel_policy-badge alt"
          ></span>
        </div>
      </b-card-text>
      <b-card-footer class="variant-card-footer mt-auto" :class="{'variant-card-footer-mobile': isMobileView}">
        <div class="variant-price" :class="{'mb-4 pb-1': isMobileView}">
          <span class="variant-price-amount">
            {{ formatPrice(variantData.variant.total_amount) }}
          </span>
          <template v-if="Object.keys(groupProductsComputed).length == 1">
            <span class="variant-price-amount">/</span>
            <span class="variant-price-nights">
              {{
                $tc(
                  "entity.nights_x",
                  $moment(variantData.variant.products.data[0].to_date).diff(
                    $moment(variantData.variant.products.data[0].from_date),
                    "days"
                  ),
                  {
                    x: $moment(
                      variantData.variant.products.data[0].to_date
                    ).diff(
                      $moment(variantData.variant.products.data[0].from_date),
                      "days"
                    )
                  }
                )
              }}
            </span>
          </template>
        </div>
        <template v-if="variantData.confirmed">
          <b-btn class="select-btn">
            <b-icon
              icon="check-circle"
              v-if="selected == variantData.variant.ref"
              class="selected-icon rounded-circle"
            ></b-icon>
            {{
              $t(
                selected == variantData.variant.ref
                  ? "entity.selected"
                  : "entity.select"
              )
            }}
          </b-btn>
        </template>
        <template v-else>
          <b-btn
            v-if="variantData.variant.confirmed"
            class="select-btn selected"
          >
            <b-icon
              icon="check-circle"
              class="selected-icon rounded-circle"
            ></b-icon>
            {{ $t("entity.selected") }}
          </b-btn>
        </template>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import StarsRating from "../../StarsRating";

export default {
  name: "VariantBox",
  components: { StarsRating },
  mixins: [lpMixins],
  data() {
    return {
      consoleLog: false,
      showMerchentsData:
        this.operationMode == 3 &&
        !Array.isArray(this.variantData.variant.merchants_data),
      merchentsData: []
    };
  },
  mounted() {
    Object.keys(this.variantData.variant.merchants_data).forEach(
      (merchantsCode) => {
        this.merchentsData.push(
          this.variantData.variant.merchants_data[merchantsCode]
        );
      }
    );
  },
  props: {
    variantsList: {
      type: Array
    },
    variantsSlider: {
      type: Number
    },
    isMobileView: {
      type: Boolean
    },
    variantData: {
      type: Object
    },
    page: {
      type: Object
    },
    designConfig: {
      type: Object
    },
    designStyle: {
      type: Object
    },
    selected: {
      type: String
    },
    variantIsConfirmed: {
      type: Boolean
    },
    picture: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    },
    operationMode: {
      required: true
    },
    fallbackImage: {
      required: true
    }
  },
  computed: {
    getVariantImageURL() {
      let url;
      if (
        this.showMerchentsData &&
        this.merchentsData[0]?.media?.filter((image) => image.type == "image")
          .length > 0
      ) {
        url = this.merchentsData[0].media.filter(
          (image) => image.type == "image"
        )[0].public_url;
      } else {
        url = this.picture?.public_url;
      }

      return url
        ? `url('${this.buildMediaURL(url, this.picture.provider, "400x227")}')`
        : "";
    },
    showHighlightBadgeComputed() {
      return (
        this.variantData.variant.highlight ||
        this.query_options?.show_variant_highlight_badge ||
        false
      );
    },
    variantTitleComputed() {
      if ("properties" in this.variantData.variant) {
        const offers = this.variantData.variant.properties.data.filter(
          (property) => {
            return property.product_type === "offer" && property.description;
          }
        );
        const titles = offers.map(({ title }) => {
          return title;
        });
        return titles.join(", ") || "";
      } else {
        return "";
      }
    },
    groupProductsComputed() {
      let groupedProducts = {};
      let i = 1;
      this.variantData.variant.products.data
        .filter((p) => p.type == "room")
        .forEach((item) => {
          let key = item.from_date + item.to_date;
          if (Object.keys(groupedProducts)?.indexOf(key) == -1) {
            groupedProducts[key] = [];
          }
          item.customIndex = i++;
          groupedProducts[key].push(item);
        });
      return groupedProducts;
    }
  },
  methods: {
    getOfferMarkerStyle(style) {
      let cssStyle = {
        color: style["font-color"],
        backgroundColor: style["background-color"]
      };
      if (style["font-weight"] == "bold") cssStyle.fontWeight = "bold";
      if (style["font-style"] == "italic") cssStyle.fontStyle = "italic";
      return cssStyle;
    },
    setActiveClass() {
      if (
        (this.selected === this.variantData.variant.ref &&
          !this.variantIsConfirmed) ||
        (this.variantIsConfirmed && this.variantData.variant.confirmed)
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.variant-image-mobile, .variant-image-wrapper-mobile {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.variant-card-footer-mobile {
  padding: 45px 30px 20px !important;
}

.badge-position {
  position: relative;
  top: 40px;
}
</style>