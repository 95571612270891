<template>
  <b-row class="mx-0">
    <div class="show-checkout-back">
      <a
        @click="closeCheckOut()"
        :style="`color: ${isTooDark(backgroundColor)}`"
      >
        <span class="mdi mdi-chevron-left" />
        {{ $t("form.back") }}
      </a>
    </div>
    <b-col
      cols="12"
      lg="3"
      class="col-checkout-label px-0 mb-0 order-1 order-lg-0"
    >
      <CheckoutLabel :bgColor="backgroundColor" :isTooDarkFun="isTooDark" />
    </b-col>
    <b-col cols="12" lg="6" class="px-0 order-0 order-lg-1 mt-5 mt-lg-0 pt-5">
      <Upselling v-if="page?.upselling_enabled && page?.entity_id && upselling?.length" :isTooDarkFun="isTooDark"/>
      <CheckoutMain @hideBtnBook="transferValue()" />
    </b-col>
    <b-col
      cols="12"
      lg="3"
      class="px-0 pr-xl-2 mb-0 mt-5 mt-lg-0 d-none d-lg-inline-block order-lg-2"
    >
      <CheckoutPriceTable @hideBtnBook="transferValue()" />
    </b-col>
  </b-row>
</template>

<script setup>
import { defineEmits, defineAsyncComponent } from "vue";
import CheckoutLabel from "./CheckoutLabel.vue";
import CheckoutMain from "./CheckoutMain.vue";
import CheckoutPriceTable from "./CheckoutPriceTable.vue";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useUpselling } from "@/composables/useUpselling";

const { closeCheckOut, page } = useCheckoutData();
const { upselling } = useUpselling();

const Upselling = defineAsyncComponent(() => import('./upselling/Upselling.vue'));

const emit = defineEmits(["hideCheckoutBtnBook"]);

const backgroundColor =
  page.design_data.style.general.checkout_background_color;

function transferValue() {
  emit("hideCheckoutBtnBook");
}

function isTooDark(hexcolor) {
  if (hexcolor) {
    const r = parseInt(hexcolor.substr(1, 2), 16);
    const g = parseInt(hexcolor.substr(3, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq < 100 ? "#eeeaea" : "#414141";
  } else {
    return "#414141";
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}

.row {
  height: 100%;
}

.show-checkout-back {
  top: 35px !important;
  z-index: 1;
}

.col-checkout-label {
  height: auto !important;
}
</style>