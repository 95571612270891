import en from "./en/messages";
import de from "./de/messages";
import it from "./it/messages";
import fr from "./fr/messages";
import ru from "./ru/messages";
import es from "./es/messages";

const messages = {
  en,
  de,
  it,
  fr,
  ru,
  es
};

export default messages;