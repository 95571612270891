var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"show-price-table"},[(!_vm.isMobile)?_c('b-table',{staticClass:"mb-0",class:_vm.isMobile ? 'mobile' : 'desktop',attrs:{"stacked":_vm.isMobile,"items":_vm.selectedVariant.price_details,"fields":_vm.priceFieldsComputed},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatPriceTitle(data.item.title))}})]}},{key:"cell(quantity)",fn:function(data){return [(!data.item.quantity_hidden)?[_vm._v(" "+_vm._s(_vm.getQuantity(data.item))+" ")]:_vm._e()]}},{key:"cell(pu_price)",fn:function(data){return [(!data.item.pu_price_hidden)?[_vm._v(" "+_vm._s(_vm.showOutput(data) ? _vm.formatPrice(data.item.pu_price) : "")+" ")]:_vm._e()]}},{key:"cell(discount_value)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getDiscount(data.item))+" ")]}},{key:"cell(amount)",fn:function(data){return [(!data.item.amount_hidden)?[_vm._v(" "+_vm._s(_vm.getAmount(data.item))+" ")]:_vm._e()]}}],null,false,29151658)}):_c('b-container',{staticClass:"pt-3"},_vm._l((_vm.selectedVariant.price_details.filter(
        (priceRow) => priceRow.title != ''
      )),function(priceRow,index){return _c('b-row',{key:'price_table' + index,staticClass:"mobile-pricetable"},[_c('b-col',{staticClass:"d-flex mobile-pricetable-item",attrs:{"cols":"12"}},[_c('div',{staticClass:"p-1 w-100"},[_c('div',{staticClass:"price-title"},[_c('strong',[_vm._v(_vm._s(priceRow.title))])]),_vm._l((_vm.priceFieldsComputed),function(field,findex){return [(
                field && field.key + '_hidden' in priceRow
                  ? !priceRow[field.key + '_hidden']
                  : true
              )?[(
                  field &&
                  field.key != 'title' &&
                  ((priceRow[field.key] == 0 &&
                    priceRow['discount_value'] > 0) ||
                    priceRow[field.key])
                )?_c('div',{key:'pinfo-' + findex,staticClass:"price-title d-flex justify-content-between py-1"},[_c('div',[_vm._v(_vm._s(field.label))]),(
                    (field.key === 'amount' && !priceRow.amount_hidden) ||
                    (field.key === 'pu_price' && !priceRow.pu_price_hidden)
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.formatPrice(priceRow[field.key]))+" ")]):(field.key === 'discount_value')?_c('div',[_vm._v(" "+_vm._s(_vm.getDiscount(priceRow))+" ")]):_c('div',[_vm._v(" "+_vm._s(priceRow[field.key])+" ")])]):_vm._e()]:_vm._e()]})],2)])],1)}),1),(_setup.page?.upselling_enabled && _setup.page?.entity_id && _setup.selectedServices.length)?_c(_setup.PriceTableServices):_vm._e(),_c('b-row',{staticClass:"mx-0 pb-2 mb-4 price-table-footer",staticStyle:{"border-top":"2px #e8e8e8 solid"}},[_c('b-col',{staticClass:"font-weight-bold",attrs:{"cols":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.placeholder('%label.amount%', _vm.$t('entity.price.total_price')))}})]),_c('b-col',{staticClass:"text-right font-weight-bold",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.formatPrice(Number(_vm.selectedVariant.total_amount) + _setup.calcTotalAmountOfServices()))+" ")]),((_vm.is('option') || _vm.is('offer')) && _vm.selectedVariant.deposit_amount)?[_c('b-col',{staticClass:"to-print",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t("entity.price.deposit"))+" ")]),_c('b-col',{staticClass:"text-right to-print",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_setup.recalculateDepositIfUpselling())+" ")])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }