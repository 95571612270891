var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"ynv-container personal-info mt-5",attrs:{"id":"personal-info-component"}},[_c('b-row',{},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
          _vm.placeholder('%label.customer.title%', _vm.$t('entity.personal_info'))
        )}})])],1),_c('div',{staticClass:"mt-0 d-block d-md-flex justify-content-center"},[_c('b-card',[_c('b-card-text',[_c('h4',{domProps:{"innerHTML":_vm._s(
            _vm.placeholder('%label.customer.title%', _vm.$t('entity.personal_info'))
          )}}),_c('div',{staticClass:"personal-info-details custom-table"},[_c('div',[_c('span',{staticClass:"t-key"},[_vm._v(_vm._s(_vm.$t("form.salutation"))+":")]),_c('span',{staticClass:"t-value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.customerInformation.gender && _vm.customerInformation.gender != "u" ? _vm.$t( "form.title_options." + _vm.getVal(_vm.customerInformation.gender) ) : "-")+" ")])])]),_c('div',[_c('span',{staticClass:"t-key"},[_vm._v(_vm._s(_vm.$t("form.name"))+":")]),_c('span',{staticClass:"t-value"},[_c('strong',[(
                    _vm.customerInformation.gender == 'u' &&
                    _vm.customerInformation.companyname != ''
                  )?[_vm._v(" "+_vm._s(_vm.getVal(_vm.customerInformation.companyname))+" ")]:[_vm._v(" "+_vm._s(_vm.getVal(_vm.customerInformation.firstname))+" "+_vm._s(_vm.getVal(_vm.customerInformation.lastname))+" "+_vm._s(!_vm.customerInformation.lastname && !_vm.customerInformation.firstname ? "-" : "")+" ")]],2)])]),_c('div',[_c('span',{staticClass:"t-key"},[_vm._v(_vm._s(_vm.$t("form.email"))+":")]),_c('span',{staticClass:"t-value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.customerInformation.email || "-")+" ")])])]),_c('div',[_c('span',{staticClass:"t-key"},[_vm._v(_vm._s(_vm.$t("form.phone"))+":")]),_c('span',{staticClass:"t-value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.customerInformation.phone || "-")+" ")])])])])])],1),_c('b-card',[_c('b-card-text',[_c('h4',[_vm._v(_vm._s(_vm.$t("form.contact")))]),_c('div',{staticClass:"treatment-details custom-table"},[_c('div',[_c('span',{staticClass:"t-key"},[_vm._v(_vm._s(_vm.$t("form.street"))+":")]),_c('span',{staticClass:"t-value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.getVal(_vm.customerInformation.street))+" ")])])]),_c('div',[_c('span',{staticClass:"t-key"},[_vm._v(_vm._s(_vm.$t("form.zip"))+"/"+_vm._s(_vm.$t("form.city"))+":")]),_c('span',{staticClass:"t-value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.customerInformation.zip || "-")+" / "+_vm._s(_vm.customerInformation.city || "-")+" ")])])]),_c('div',[_c('span',{staticClass:"t-key"},[_vm._v(_vm._s(_vm.$t("form.country"))+":")]),_c('span',{staticClass:"t-value"},[_c('strong',[_vm._v(" "+_vm._s(_vm.getVal(_vm.customerInformation.country) ? _vm.countryText(_vm.customerInformation.country) : "-")+" ")])])])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }