<template>
  <div
    v-if="variantsList.length > 1"
    id="variants-list"
    class="variants-list-v2"
  >
    <b-col cols="12" :class="{'mb-4 pb-1': isMobile}">
      <div class="d-flex justify-content-center mt-4 mb-0">
        <h3
          v-html="
            placeholder('%label.offer.best_offer%', $t('entity.best_offer'))
          "
        ></h3>
      </div>
    </b-col>
    <template v-if="!isMobile">
      <b-row
        class="mt-2 px-3"
        :class="{ 'justify-content-center': variantsList.length <= 2 }"
      >
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="4"
          xxl="4"
          class="variant-item"
          v-for="(variant, index) in variantsList"
          :key="index"
          :class="{
            'no-image': !getVariantsCoverImage(
              variant.products.data,
              variant.ref
            ),
            'variant-confirmed':
              variantsList.filter((v) => v.confirmed).length > 0 &&
              variant.confirmed,
            'variant-unconfirmed':
              variantsList.filter((v) => v.confirmed).length > 0 &&
              !variant.confirmed
          }"
        >
          <variant-box
            :page="page"
            :isMobileView="isMobile"
            :variantsSlider="variantsSlider"
            :variantsList="variantsList"
            :selected="selected"
            :designConfig="designConfig"
            :variant-is-confirmed="variantIsConfirmed"
            :designStyle="designStyle"
            :picture="
              getVariantsCoverImage(variant.products.data, variant.ref) || {}
            "
            :operation-mode="operationMode"
            :fallback-image="fallbackImage"
            :variantData="{
              variant: variant,
              index: index,
              confirmed: variantsList.filter((v) => v.confirmed).length == 0
            }"
            @selectVariant="selectVariantEmit($event)"
          ></variant-box>
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <template v-if="page.mobile_variants_side_by_side">
        <b-carousel
          v-model="variantsSlider"
          ref="variantsSlider"
          :interval="0"
          :controls="variantsList.length > 1"
          class="variants-carousel"
        >
          <b-carousel-slide
            v-for="(variant, index) in variantsList"
            :key="index"
          >
            <div
              class="variant-item"
              :class="{
                'no-image': !getVariantsCoverImage(
                  variant.products.data,
                  variant.ref
                ),
                'variant-confirmed':
                  variantsList.filter((v) => v.confirmed).length > 0 &&
                  variant.confirmed,
                'variant-unconfirmed':
                  variantsList.filter((v) => v.confirmed).length > 0 &&
                  !variant.confirmed
              }"
            >
              <variant-box
                :page="page"
                :isMobileView="isMobile"
                :variantsSlider="variantNumber"
                :variantsList="variantsList"
                :selected="selected"
                :variant-is-confirmed="variantIsConfirmed"
                :designConfig="designConfig"
                :designStyle="designStyle"
                :picture="
                  getVariantsCoverImage(variant.products.data, variant.ref)
                "
                :operation-mode="operationMode"
                :fallback-image="fallbackImage"
                :variantData="{
                  variant: variant,
                  index: index,
                  confirmed: variantsList.filter((v) => v.confirmed).length == 0
                }"
                @selectVariant="selectVariantEmit($event)"
              />
            </div>
          </b-carousel-slide>
        </b-carousel>
      </template>
      <template v-else>
        <b-row
        class="mt-2 px-3"
        :class="{ 'justify-content-center': variantsList.length <= 2 }"
      >
        <b-col
          cols="12"
          class="variant-item mb-2"
          v-for="(variant, index) in variantsList"
          :key="index"
          :class="{
            'no-image': !getVariantsCoverImage(
              variant.products.data,
              variant.ref
            ),
            'variant-confirmed':
              variantsList.filter((v) => v.confirmed).length > 0 &&
              variant.confirmed,
            'variant-unconfirmed':
              variantsList.filter((v) => v.confirmed).length > 0 &&
              !variant.confirmed
          }"
        >
          <variant-box
            :page="page"
            :isMobileView="isMobile"
            :variantsSlider="index"
            :variantsList="variantsList"
            :selected="selected"
            :designConfig="designConfig"
            :variant-is-confirmed="variantIsConfirmed"
            :designStyle="designStyle"
            :picture="
              getVariantsCoverImage(variant.products.data, variant.ref) || {}
            "
            :operation-mode="operationMode"
            :fallback-image="fallbackImage"
            :variantData="{
              variant: variant,
              index: index,
              confirmed: variantsList.filter((v) => v.confirmed).length == 0
            }"
            @selectVariant="selectVariantEmit($event)"
          ></variant-box>
        </b-col>
      </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import VariantBox from "@/components/blocks/variants-list/VariantBox";
import { isEmpty } from "lodash";

export default {
  mixins: [lpMixins],
  components: { VariantBox },
  name: "VariantList",
  data() {
    return {
      variantsSlider: 0,
      variantNumber: 0,
      consoleLog: false
    };
  },
  props: [
    "variantsList",
    "designConfig",
    "designStyle",
    "selected",
    "page",
    "operationMode",
    "fallbackImage",
    "variantIsConfirmed"
  ],
  watch: {
    variantsSlider: function(newVal) {
      setTimeout(() => {
        this.variantNumber = newVal;
      }, 300)
    }
  },
  methods: {
    getVariantsCoverImage(products, ref) {
      if (!isEmpty(products)) {
        const defaultImg = this.checkIfMediaIsSorted(products[0]).find(
          (picture) =>
            picture.scope === "picture" ||
            picture.scope === "gallery" ||
            picture.scope === "picture_floorplan"
        );
        if (products.length > 1) {
          return defaultImg;
        } else {
          const variant = this.variantsList.find((v) => v.ref == ref);
          const offerProperty = variant.properties.data.find(
            (offer) => offer.product_type === "offer"
          );
          if (!offerProperty) return defaultImg;
          const productID = offerProperty?.products[0];
          const checkMainOfferImage = offerProperty?.media?.find(
            (m) => m.scope === "picture" || m.scope === "gallery"
          );
          const mainRoomImage = this.checkIfMediaIsSorted(
            variant.products.data.find((room) => room.id === productID)
          ).find(
            (m) =>
              m.scope === "picture" ||
              m.scope === "gallery" ||
              m.scope === "picture_floorplan"
          );

          return checkMainOfferImage || mainRoomImage;
        }
      }
    },
    checkIfMediaIsSorted(room) {
      if (!room.media.some((pic) => pic.sort)) {
        const sortMedia = [
          "picture",
          "gallery",
          "picture_floorplan",
          "video",
          "panorama"
        ];

        return room.media
          .map((media) => {
            media.sort = sortMedia.indexOf(media.scope);
            return media;
          })
          .sort((a, b) => {
            return a.sort - b.sort;
          });
      } else {
        return room.media;
      }
    },
    selectVariantEmit(variantData) {
      if (
        (variantData.variant.confirmed && this.variantIsConfirmed) ||
        !this.variantIsConfirmed
      ) {
        setTimeout(() => {
          document.getElementById("variant-details").scrollIntoView({
            behavior: "smooth",
            block: 'nearest'
          });
        }, 150)
        this.$emit("selectVariant", {
          variant: variantData.variant,
          index: variantData.index
        });
      }
    }
  }
};
</script>

<style scoped>
>>> .carousel-caption {
  padding-top: 0px;
}
</style>