var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[(_vm.avatarDataComputed && _vm.avatarDataComputed.url)?_c('b-avatar',{attrs:{"variant":"light","src":_vm.buildMediaURL(
        _vm.avatarDataComputed.url,
        _vm.avatarDataComputed.provider,
        '90x90'
      ),"size":_vm.isSidebar ? '53px' : '92px'}}):_vm._e(),_c('div',{staticClass:"text-left",class:_vm.isSidebar ? 'pt-0 ml-2' : 'pt-4 ml-4'},[_c('div',{domProps:{"innerHTML":_vm._s(
        _vm.placeholder(
          '%label.receptionist.title%',
          _vm.$t('page.content.today_reception')
        )
      )}}),_c('strong',[('displayname' in _vm.editorData && _vm.editorData.displayname != '')?[_vm._v(" "+_vm._s(_vm.editorData.displayname)+" ")]:[_vm._v(" "+_vm._s(_vm.editorData.firstname || "")+" "+_vm._s(_vm.editorData.lastname || "")+" ")]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }