<template>
  <b-container class="print-content">
    <b-row class="print-header">
      <b-col cols="12" class="text-center" style="margin-bottom: 10%">
        <b-img
          v-if="logoDataComputed"
          :src="
            buildMediaURL(
              logoDataComputed.url,
              logoDataComputed.provider,
              '200x200'
            )
          "
        />
      </b-col>
      <b-col cols="6" class="account-data">
        <div v-if="page.account.name">
          {{ page.account.name }}
        </div>
        <div v-if="page.account.address.data.street">
          {{ page.account.address.data.street }}
        </div>
        <div
          v-if="
            page.account.address.data.city_zip ||
            page.account.address.data.city_name
          "
        >
          {{ page.account.address.data.city_zip }}
          {{ page.account.address.data.city_name }}
        </div>
        <div v-if="page.account.address.data.country_alpha3">
          {{
            page.account.address.data.country_alpha3 &&
            countries.find(
              (c) => c.value == page.account.address.data.country_alpha3
            )
              ? countries.find(
                  (c) => c.value == page.account.address.data.country_alpha3
                ).text
              : "-"
          }}
        </div>
        <div v-if="page.account.contact.data.phone">
          {{ page.account.contact.data.phone }}
        </div>
        <div v-if="page.account.contact.data.email">
          {{ page.account.contact.data.email }}
        </div>
        <div v-if="page.account.contact.data.website">
          {{ page.account.contact.data.website.replace(/(^\w+:|^)\/\//, "") }}
        </div>
      </b-col>
      <b-col
        cols="6"
        class="customer-data"
        v-if="page.entity && page.entity.order_customer"
      >
        <div
          v-if="page.entity.order_customer.companyname"
        >
          {{ page.entity.order_customer.companyname }}
        </div>
        <div
          v-if="
            page.entity.order_customer.firstname ||
            page.entity.order_customer.lastname
          "
        >
          {{ page.entity.order_customer.firstname }}
          {{ page.entity.order_customer.lastname }}
        </div>
        <div v-if="page.entity.order_customer.street">
          {{ page.entity.order_customer.street }}
        </div>
        <div
          v-if="
            page.entity.order_customer.zip || page.entity.order_customer.city
          "
        >
          {{ page.entity.order_customer.zip }}
          {{ page.entity.order_customer.city }}
        </div>
        <div v-if="page.entity.order_customer.country_alpha3">
          {{
            page.entity.order_customer.country_alpha3 &&
            countries.find(
              (c) => c.value == page.entity.order_customer.country_alpha3
            )
              ? countries.find(
                  (c) => c.value == page.entity.order_customer.country_alpha3
                ).text
              : "-"
          }}
        </div>
        <div v-if="page.entity.order_customer.phone">
          {{ page.entity.order_customer.phone }}
        </div>
        <div v-if="page.entity.order_customer.email">
          {{ page.entity.order_customer.email }}
        </div>
      </b-col>
    </b-row>
    <b-row class="print-price-table">
      <b-col cols="12">
        <h2
          v-html="
            placeholder('%label.offer.title%', $t('entity.offer')) +
            ': ' +
            page.entity.slug
          "
        ></h2>
        <pricetable
          :selected-variant="selectedVariant"
          :page="page"
        ></pricetable>
      </b-col>
      <b-col v-if="selectedVariant.price_note" class="mt-3">
        <div v-html="selectedVariant.price_note"></div>
      </b-col>
    </b-row>
    <b-row class="print-payments px-3 mb-4">
      <ExecutedPayments
        class="pb-1"
        :page="page"
        :lpLocale="lpLocale"
      />
    </b-row>
    <b-row class="print-cancel-policy">
      <b-col cols="12">
        <h2 class="mb-3">{{ $t("entity.cancel_policy.title") }}</h2>
        <div v-html="selectedVariant.cancelpolicy_data.description"></div>
      </b-col>
      <div class="copyright">
        <img
          height="20"
          src="@/assets/powered_by_yanovis_positive.png"
          alt="Powered by Yanovis"
          title="Visions become software"
        />
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import Pricetable from "@/components/Pricetable";
import ExecutedPayments from "@/components/ExecutedPayments.vue";

export default {
  name: "PrintVersion",
  mixins: [lpMixins],
  components: { Pricetable, ExecutedPayments },
  props: ["page", "selectedVariant", "lpLocale"],
  computed: {
    logoDataComputed() {
      return this.page?.account?.logo?.data || null;
    }
  }
};
</script>