<template>
  <b-row>
    <b-col cols="12" v-for="(gallery, index) in content.items" :key="index" class="gallery-wrapper">
      <component :is="components[gallery.config.type]" :gallery="gallery.content_data.data.components[0].media" :arrowsColor="arrowsColor"/>
    </b-col>
  </b-row>
</template>

<script setup>

defineProps({
  content: {
    type: Object,
    required: true
  },
  arrowsColor: {
    type: String,
    required: false
  }
})

const components = {
    carousel: () =>import('@/components/blocks/swiperComponents/Carousel.vue'),
    slicer: () =>import('@/components/blocks/swiperComponents/Slicer.vue'),
    panorama: () =>import('@/components/blocks/swiperComponents/Panorama.vue'),
    coverflow: () =>import('@/components/blocks/swiperComponents/Cowerflow.vue'),
    navigation: () =>import('@/components/blocks/swiperComponents/Navigation.vue'),
    freemode: () =>import('@/components/blocks/swiperComponents/Freemode.vue'),
    history: () =>import('@/components/blocks/swiperComponents/History.vue'),
}
</script>

<style scoped>
/*override bootstrap style*/
.row {
  width: 100%;
  margin: 0;
  padding: 0;
}
.col-12 {
  width: 100%;
  margin: 0;
  padding: 0;
}

.gallery-wrapper {
  margin-bottom: 30px;
  display: flex;align-items: center;
}
</style>