<template>
  <b-container class="image-box-component px-0">
    <b-row class="image-box-row">
      <b-col
        v-for="(highlight, hlIndex) in highlights.items"
        :key="highlight.id"
        class="image-box-item"
        :class="
          highlights.items.length > 1 && !highlight.config.expanded
            ? 'multi-item'
            : 'single-item'
        "
        :md="colsMatrix[hlIndex]"
        @click="showCardDetails(highlight)"
      >
        <div class="image-box-wrapper">
          <div
            class="image"
            :style="{
              'background-image': getMedia(highlight, 'picture')
                ? 'url(' +
                  getMedia(highlight, 'picture').preview_img +
                  '?d=500x400)'
                : 'none'
            }"
          ></div>
          <div
            class="bottom-line d-flex justify-content-between align-items-center"
          >
            <div class="title">
              {{ highlight.content_data.data.components[0].title }}
            </div>
            <div class="action-button">
              <a
                class="show-more"
                :class="{
                  square:
                    designData.style &&
                    'radius' in designData.style.general &&
                    designData.style.general.radius != '' &&
                    parseInt(designData.style.general.radius) == 0
                }"
                @click="showCardDetails(highlight)"
              >
                <b-icon icon="plus"></b-icon>
              </a>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-if="modalCardObject"
      :id="'card-details_' + modalCardObject.id"
      :body-class="'py-0 px-0'"
      size="lg"
      hide-header
      centered
    >
      <div class="imagebox-close-btn">
        <span
          class="mdi mdi-close"
          @click="closeCardDetails()"
          style="font-size: 20px"
        ></span>
      </div>
      <div class="modal-header-wrapper">
        <mini-slider
          :media-list="media"
          :current-index="{ index: 0, interval: 4000 }"
          :uniqueIndex="'card-media_' + 'modalCardObject.id'"
          :video-ratio="'4by3'"
          :noRoundedBorder="true"
          :video-modal="false"
          ref="cardmedia"
          :class="{
            'no-content':
              !modalCardObject.content_data.data.components[0].config.content &&
              !modalCardObject.content_data.data.components[0].config.cta
          }"
        ></mini-slider>
        <div
          class="modal-header-title"
          :class="{
            'title-border-bottom':
              !modalCardObject.content_data.data.components[0].config.content &&
              !modalCardObject.content_data.data.components[0].config.cta
          }"
        >
          {{ modalCardObject.content_data.data.components[0].title }}
        </div>
      </div>
      <div
        :class="{
          'modal-description':
            modalCardObject.content_data.data.components[0].config.content ||
            modalCardObject.content_data.data.components[0].config.cta
        }"
      >
        <div
          v-if="modalCardObject.content_data.data.components[0].config.content"
          v-html="modalCardObject.content_data.data.components[0].content_html"
          class="modal-card-text"
        ></div>
        <div
          v-if="modalCardObject.content_data.data.components[0].config.cta"
          class="text-right"
          :class="
            modalCardObject.content_data.data.components[0].content_html
              .length > 0
              ? 'mt-4 mt-sm-5'
              : 'mt-4'
          "
        >
          <b-btn
            target="_blank"
            class="btn btn-success highlight_cta"
            @click="openURL(modalCardObject.content_data.data.components[0])"
          >
            {{
              modalCardObject.content_data.data.components[0].content_json
                .cta_label
            }}
          </b-btn>
        </div>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { lpMixins } from "@/mixins/lpMixins";
import MiniSlider from "@/components/blocks/MiniSlider";

export default {
  name: "ImageBox",
  mixins: [lpMixins],
  components: {
    MiniSlider
  },
  props: ["highlights", "designData"],
  data() {
    return {
      modalCardObject: null,
      colsMatrix: []
    };
  },
  computed: {
    media() {
      return [
        this.getMedia(this.modalCardObject, "picture"),
        ...this.getMedia(this.modalCardObject, "gallery")
      ];
    }
  },
  mounted() {
    let indexOfLastHighligtht = 0;
    this.highlights.items.forEach((highlight, idx) => {
      // When an expanded element is found, the column matrix of the previous highlight is created and column 12 is added
      if (highlight.config.expanded) {
        this.generateColsMatrix(idx - indexOfLastHighligtht);
        this.colsMatrix.push(12);
        indexOfLastHighligtht = idx + 1;
      }
    });
    // Create the column matrix for the missing highlight
    if (indexOfLastHighligtht < this.highlights.items.length)
      this.generateColsMatrix(
        this.highlights.items.length - indexOfLastHighligtht
      );
  },
  methods: {
    generateColsMatrix(highlightsLength) {
      let idx;
      for (idx = 0; idx < highlightsLength - 4; idx += 3)
        this.colsMatrix = [...this.colsMatrix, 4, 4, 4];
      switch (highlightsLength - idx) {
        case 1:
          this.colsMatrix.push(12);
          break;
        case 2:
          this.colsMatrix = [...this.colsMatrix, 6, 6];
          break;
        case 3:
          this.colsMatrix = [...this.colsMatrix, 4, 4, 4];
          break;
        case 4:
          this.colsMatrix = [...this.colsMatrix, 6, 6, 6, 6];
          break;
      }
    },
    closeCardDetails() {
      this.$bvModal.show("card-details_" + this.modalCardObject.id);
      this.modalCardObject = null;
    },
    showCardDetails(card) {
      new Promise((resolve) => {
        this.modalCardObject = card;
        resolve();
      }).then(() => {
        setTimeout(() => {
          this.$bvModal.show("card-details_" + this.modalCardObject.id);
        }, 100);
      });
    }
  }
};
</script>

<style scoped lang="sass">
@import "../../../styles/components/highlights/ImageBox.sass"

::v-deep .carousel-image
  border-top-left-radius: 4px !important
  border-top-right-radius: 4px !important

::v-deep .no-content .carousel-inner
  border-radius: 4px

.imagebox-close-btn
  position: absolute
  right: 20px
  top: 15px
  z-index: 9999
  color: white
  cursor: pointer

.modal-header-wrapper
  position: relative

.modal-header-title
  position: absolute
  bottom: 0
  left: 0
  right: 0
  color: white
  padding: 10px 20px
  background-color: #00000060
  z-index: 100000

.title-border-bottom
  border-bottom-left-radius: 4px !important
  border-bottom-right-radius: 4px !important

.modal-description
  padding: 20px

.arrows-wrapper
  position: absolute
  top: 0px
  width: 100%
  height: 100%
  z-index: 1

.ynv-pointer
  cursor: pointer

::v-deep .modal-content
  border-radius: 5px
</style>