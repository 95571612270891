<template>
  <div class="d-flex">
    <b-avatar
      v-if="avatarDataComputed && avatarDataComputed.url"
      variant="light"
      :src="
        buildMediaURL(
          avatarDataComputed.url,
          avatarDataComputed.provider,
          '90x90'
        )
      "
      :size="isSidebar ? '53px' : '92px'"
    ></b-avatar>
    <div class="text-left" :class="isSidebar ? 'pt-0 ml-2' : 'pt-4 ml-4'">
      <div
        v-html="
          placeholder(
            '%label.receptionist.title%',
            $t('page.content.today_reception')
          )
        "
      ></div>
      <strong>
        <template
          v-if="'displayname' in editorData && editorData.displayname != ''"
        >
          {{ editorData.displayname }}
        </template>
        <template v-else>
          {{ editorData.firstname || "" }}
          {{ editorData.lastname || "" }}
        </template>
      </strong>
    </div>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "Editor",
  mixins: [lpMixins],
  props: ["isSidebar", "page", "editorData", "accountData", "designData"],
  computed: {
    avatarDataComputed() {
      let dataProvided = false;
      let avatarObject = {
        url: null,
        provider: null,
        type: null
      };
      if (this.editorData) {
        avatarObject.url = this.editorData.avatar_url;
        avatarObject.provider = "cdn";
        avatarObject.type = "editor";
        dataProvided = true;
      } else if (this.accountData) {
        avatarObject.url = this.accountData.logo?.data?.url || null;
        avatarObject.provider = this.accountData.logo?.data?.provider || null;
        avatarObject.type = "account";
        dataProvided = true;
      }
      return dataProvided ? avatarObject : null;
    }
  }
};
</script>

<style scoped></style>