import Vue from "vue";
import * as Sentry from "@sentry/vue";

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: process.env.VUE_APP_ENV === "production"
      })
    ],
    tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE,
    ignoreErrors: [
      "Network Error",
      "Request aborted",
      "undefined is not an object (evaluating 'e.addHistory')",
      "undefined is not an object (evaluating 'response.reputation')",
      // internal error of VueTelInput
      "Cannot read properties of undefined (reading 'children')",
      "Request failed with status code 401",
      "Non-Error promise rejection captured with value",
      "Non-Error promise rejection captured with value:",
      "TypeError: cancelled",
      "TypeError: Load failed",
      "TypeError: NetworkError when attempting to fetch resource.",
      /change_ua/,
      /NotSupportedError: Failed to load because no supported source was found./,
      /NotSupportedError: The element has no supported sources./,
      new RegExp("^(.*?(Object Not Found Matching Id\\b)[^$]*)$")
    ]
  });
}
