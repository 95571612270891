<template>
  <b-row class="mt-5" id="insurance">
    <b-col cols="12" md="8">
      <div class="mb-3">
        <img src="@/assets/erv_logo.png" style="max-width: 165px" />
      </div>
      <div class="d-block d-md-flex">
        <div class="insurance-text" v-html="insurance_text"></div>
        <div class="insurance-cta ml-0 ml-md-3 mt-5 mt-md-3">
          <a
            @click="openInsurance"
            href="#"
            target="_blank"
            v-html="insurance_cta"
          ></a>
          <b-modal
            id="insurance-modal"
            size="lg"
            no-close-on-esc
            no-close-on-backdrop
            @close="closeModal"
          >
            <template v-slot:modal-title>
              {{ $t("entity.insurance.title") }}
            </template>
            <template v-slot:modal-header-close>
              <span class="mdi mdi-close" style="font-size: 20px"></span>
            </template>
            <b-overlay :show="modal_insurance.state" rounded="sm">
              <iframe
                frameborder="0"
                style="height: 85vh; width: 100%"
                :srcdoc="modal_insurance.data"
                @load="modal_insurance.state = false"
              ></iframe>
            </b-overlay>
          </b-modal>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Insurance",
  props: ["insurance_text", "insurance_cta", "insurance_data"],
  data() {
    return {
      modal_insurance: {
        state: true,
        data: null,
        type: null
      }
    };
  },
  mounted() {
    this.modal_insurance.data = this.insurance_data.data;
    this.modal_insurance.type = this.insurance_data.type;
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("insurance-modal");
      this.modal_insurance.state = true;
    },
    openInsurance(event) {
      event.preventDefault();
      if (this.modal_insurance.type == "URL") {
        window.open(this.modal_insurance.data, "_blank");
      } else {
        // alert("HTML")
        this.$bvModal.show("insurance-modal");
      }
    }
  }
};
</script>