<template>
  <div class="mt-3">
    <pay-methods
      v-if="selectedVariant.booking_action == 'payment'"
      :selectedVariant="selectedVariant"
      :book-label="
        placeholder('%label.offer.ctabtn%', $t('entity.buttons.book'))
      "
      direction="inline"
      @change="selectedPaymentMethod = $event"
      @save="bookAction(false)"
      :page="page"
    />
    <CheckoutModals />
  </div>
</template>
<script setup>
import PayMethods from "@/components/PayMethods";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useBookingMethods } from "@/composables/useBookingMethods";
import CheckoutModals from "@/components/checkout/CheckoutModals";

const { placeholder } = useCheckoutData();
const { bookAction, selectedPaymentMethod } = useBookingMethods();

defineProps({
  selectedVariant: { type: Object, required: true },
  page: { type: Object, required: true },
  btnDisabled: { type: Boolean, required: true }
});
</script>
<style scoped></style>