<template>
  <div
    v-if="navigationItems && navigationItems.length > 0"
    id="navigation-component"
    class="navi-line"
  >
    <b-button
      v-b-toggle.sidebar-navigation
      class="mobile-menu"
      id="mobile-menu"
      :class="{
        'menu-left':
          'header' in config &&
          config.header &&
          'logo' in config.header &&
          config.header.logo == 'end'
      }"
    >
      <b-icon-list></b-icon-list>
    </b-button>
    <b-sidebar right sidebar-class="" id="sidebar-navigation" z-index="2050">
      <div v-if="account.logo && account.logo.data" class="sidebar-logo">
        <div class="sidebar-logo-wrapper">
          <img
            :src="getVal(account.logo.data.url) + '?d=200x200'"
            class="img-fluid"
          />
        </div>
      </div>
      <template v-slot:footer="">
        <div cols="12" class="mx-4 contact-buttons">
          <ul class="list-inline d-flex">
            <li v-if="config.floating && config.floating.phone" class="mr-2">
              <b-button
                class="tel"
                @click="startConversationEmit('phone')"
                pill
              >
                <img
                  src="@/assets/icons/phone.svg"
                  height="20"
                  style="
                    filter: invert(100%) sepia(0%) saturate(4810%)
                      hue-rotate(187deg) brightness(123%) contrast(110%);
                  "
                />
              </b-button>
            </li>
            <li v-if="config.floating && config.floating.email" class="mr-2">
              <b-button
                class="email"
                pill
                @click="startConversationEmit('email')"
              >
                <img
                  src="@/assets/icons/mail.svg"
                  height="15"
                  style="
                    margin-bottom: 3px;
                    filter: invert(100%) sepia(0%) saturate(4810%)
                      hue-rotate(187deg) brightness(123%) contrast(110%);
                  "
                />
              </b-button>
            </li>
            <li v-if="config.floating && config.floating.whatsapp" class="mr-2">
              <b-button
                class="whatsapp"
                @click="startConversationEmit('whatsapp')"
                pill
              >
                <img
                  src="@/assets/icons/whatsapp.svg"
                  height="20"
                  style="
                    margin-bottom: 5px;
                    filter: invert(100%) sepia(0%) saturate(4810%)
                      hue-rotate(187deg) brightness(123%) contrast(110%);
                  "
                />
              </b-button>
            </li>
          </ul>
        </div>
        <div
          v-if="
            editor &&
            editor.data &&
            'content' in config &&
            'editor_position' in config.content &&
            config.content.editor_position != 'none'
          "
          class="d-flex mx-4 mb-3"
        >
          <editor
            :page="page"
            :editor-data="editor.data"
            :account-data="account"
            :isSidebar="true"
          ></editor>
        </div>
      </template>
      <b-nav vertical>
        <li v-for="(navi, k) in navigationItems" :key="k" class="nav-item">
          <a :href="navi.url" :target="navi.target" class="nav-link">
            <span v-html="navi.title"></span>
          </a>
        </li>
      </b-nav>

      <div v-if="history.state && history.data.length > 0" class="menu-stack">
        <b-btn class="history-btn" @click="$bvModal.show('show-history')">
          {{ $t("core.history") }}
        </b-btn>
      </div>
    </b-sidebar>
    <b-nav class="d-flex container ynv-core-navigation">
      <li v-for="(navi, k) in navigationItems" :key="k" class="nav-item">
        <a
          v-if="navi.url.indexOf('#') == 0"
          v-smooth-scroll
          :href="navi.url"
          :target="navi.target"
          :class="{
            'custom-italic': settings.font.italic,
            'custom-bold': settings.font.bold
          }"
          class="nav-link"
        >
          <span v-html="navi.title"></span>
        </a>
        <a
          v-else
          :href="navi.url"
          :target="navi.target"
          :class="{
            'custom-italic': settings.font.italic,
            'custom-bold': settings.font.bold
          }"
          class="nav-link"
        >
          <span v-html="navi.title"></span>
        </a>
      </li>
    </b-nav>
    <div v-if="history.state && history.data.length > 0" class="menu-line">
      <b-btn class="history-btn" @click="$bvModal.show('show-history')">
        {{ $t("core.history") }}
      </b-btn>
    </div>
    <b-modal size="lg" centered hide-header id="show-history">
      <div class="closeModal">
        <button @click="$bvModal.hide('show-history')">
          {{ $t("entity.treatment.close") }}
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <div class="px-2 py-1 px-lg-5 py-lg-4">
        <h3 class="mb-4">{{ $t("core.history") }}</h3>
        <div
          v-for="(historyItem, index) in history.data"
          :key="historyItem.hash"
        >
          <b-list-group>
            <b-list-group-item
              :href="lpURL + '/v/' + historyItem.hash"
              target="_blank"
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100">
                <template v-if="historyItem.editor">
                  <b-avatar
                    :src="
                      (historyItem.editor &&
                        getVal(historyItem.editor.avatar_url) &&
                        getVal(historyItem.editor.avatar_url) + '?d=53x53') ||
                      (account.logo &&
                        account.logo.data &&
                        account.logo.data.url &&
                        getVal(account.logo.data.url) + '?d=53x53')
                    "
                    :title="
                      historyItem.editor.displayname
                        ? historyItem.editor.displayname
                        : historyItem.editor.firstname +
                          ' ' +
                          historyItem.editor.lastname
                    "
                    variant="light"
                    size="53px"
                    class="mr-3"
                  ></b-avatar>
                  <div class="history-details">
                    <b-badge variant="success">{{
                      $moment(historyItem.created_at).format("L | LT")
                    }}</b-badge>
                    <div>
                      {{
                        historyItem.editor.displayname
                          ? historyItem.editor.displayname
                          : historyItem.editor.firstname +
                            " " +
                            historyItem.editor.lastname
                      }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <b-avatar
                    :src="
                      account.logo &&
                      account.logo.data &&
                      account.logo.data.url &&
                      getVal(account.logo.data.url) + '?d=53x53'
                    "
                    :title="account.name"
                    variant="light"
                    size="53px"
                    class="mr-3"
                  ></b-avatar>
                  <div class="history-details">
                    <b-badge variant="success">{{
                      $moment(historyItem.created_at).format("L | LT")
                    }}</b-badge>
                    <div>{{ account.name }}</div>
                  </div>
                </template>
                <div class="ml-auto d-flex align-items-center">
                  <span
                    class="mdi mdi-chevron-right"
                    style="font-size: 30px"
                  ></span>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div class="history-divider" v-if="index < history.data.length - 1">
            .<br />
            .<br />
            .
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import Editor from "@/components/Editor";

export default {
  name: "Navigation",
  components: { Editor },
  mixins: [lpMixins],
  props: [
    "navigationItems",
    "customStyle",
    "page",
    "editor",
    "history",
    "stringOnReception",
    "config",
    "account",
    "entity"
  ],
  data() {
    return {
      settings: {
        font: {
          bold: !!this.customStyle?.navigation?.bold,
          italic: !!this.customStyle?.navigation?.italic
        }
      }
    };
  },
  methods: {
    startConversationEmit(type) {
      this.$emit("startConversation", type);
    }
  }
};
</script>

<style scoped>
.history-details .badge {
  font-size: 12px;
}

.ynv-core-navigation {
  max-width: none !important;
  padding: 0px 50px;
  gap: 25px 50px;
  justify-content: center !important;
}

.nav-item {
  padding: 0;
}
</style>