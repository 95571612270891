<template>
  <b-container class="block-header-logo" :class="logoPositionComputed">
    <div class="logo-wrapper">
      <b-img
        :src="buildMediaURL(logoData.url, logoData.provider, '200x200')"
        class="logo-image"
      />
    </div>
  </b-container>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";

export default {
  mixins: [lpMixins],
  name: "Logo",
  props: ["logoData", "logoPosition"],
  computed: {
    logoPositionComputed() {
      return this.logoPosition != "none"
        ? "d-flex justify-content-" + (this.logoPosition || "start")
        : "d-none";
    }
  }
};
</script>

<style scoped></style>