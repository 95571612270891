export const documentTypes = [
  {
    slug: "ID_CARD",
    type: 1
  },
  {
    slug: "DRIVERS_LICENSE",
    type: 2
  },
  {
    slug: "PASSPORT",
    type: 3
  }
];