<template>
  <b-container id="treatments-component" class="ynv-container treatments p-0">
    <b-row>
      <b-col cols="12">
        <h2
          class="text-center treatment-title"
          v-html="
            placeholder(
              '%label.spa.title%',
              $t('entity.treatment.booked_spa_header')
            )
          "
        ></h2>
      </b-col>
    </b-row>
    <b-row class="mt-0 no-gutters">
      <b-col cols="12" class="p-0">
        <template
          v-for="treatment in treatmentsData.filter(
            (t) => t.product_type == 'package'
          )"
        >
          <div :key="treatment.id">
            <Package
              v-if="treatment.product_type == 'package'"
              :styleData="styleData"
              :treatment-data="treatment"
              :page="page"
            ></Package>
          </div>
        </template>
        <template
          v-for="treatment in treatmentsData.filter(
            (t) => t.product_type == 'spa'
          )"
        >
          <div :key="treatment.id">
            <spa
              :page="page"
              v-if="treatment.product_type == 'spa'"
              :styleData="styleData"
              :treatment-data="treatment"
            ></spa>
          </div>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Package from "@/components/blocks/treatments/Package";
import Spa from "@/components/blocks/treatments/Spa";
import { lpMixins } from "@/mixins/lpMixins";

export default {
  mixins: [lpMixins],
  name: "Treatments",
  components: { Package, Spa },
  props: ["treatmentsData", "styleData", "page"]
};
</script>