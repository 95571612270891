<template>
  <div v-if="payMethodsData.length > 0" class="payment-modes my-0 mx-0">
    <div class="d-md-flex justify-content-between">
      <div class="payment-methods w-100">
        <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
          <b-form-radio-group
            id="payment-method-group"
            v-model="selectedPaymentMethod"
            :aria-describedby="ariaDescribedby"
            name="payment-method"
            data-cy="payMethods"
          >
            <b-form-radio
              v-for="(payMethod, index) in payMethodsData"
              :key="index"
              :value="payMethod.method_id"
              @change="selectPayment(payMethod.method_id)"
              :disabled="isLoading"
            >
              {{ payMethod.title }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
    </div>
    <div
      v-if="selectedPaymentMethod && payMethodsFiltered"
      v-html="
        placeholderString(payMethodsFiltered.description, {
          deposit_balance: formatPrice(selectedVariant.deposit_balance)
        })
      "
      class="payment-description mt-4"
    ></div>
    <b-modal
      v-if="payMethodsData.length > 0"
      id="show-paymethods"
      size="lg"
      hide-header
      centered
    >
      <template #default="{ hide }">
        <div class="close-btn">
          <a @click="hide()">
            <span class="text">{{ $t("form.close") }}</span>
            <span class="mdi mdi-close" style="font-size: 20px"></span>
          </a>
        </div>
        <div class="payment-modal-wrapper">
          <h1>{{ $t("form.select_paymethod") }}</h1>
          <div
            v-if="selectedPaymentMethod"
            v-html="
              placeholderString(payMethodsFiltered.description, {
                deposit_balance: formatPrice(selectedVariant.deposit_balance)
              })
            "
            class="payment-description"
          ></div>
          <div class="payment-modes">
            <div class="d-md-flex justify-content-between">
              <div class="payment-methods d-md-flex">
                <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                  <b-form-radio-group
                    id="payment-method-group-model"
                    v-model="selectedPaymentMethod"
                    :aria-describedby="ariaDescribedby"
                    name="payment-method-model"
                  >
                    <b-form-radio
                      v-for="(payMethod, index) in payMethodsData"
                      :key="index"
                      :value="payMethod.method_id"
                      @change="selectPayment(payMethod.method_id)"
                      :disabled="isLoading"
                    >
                      {{ payMethod.title }}
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>
          <div id="select-paymethod" class="d-inline-block">
            <button
              v-html="bookLabel"
              :disabled="selectedPaymentMethod === null"
              class="btn btn-success btn-lg mt-4 action-book"
              type="button"
              @click="confirmPayMethod"
            ></button>
          </div>
          <b-tooltip
            placement="bottom"
            :disabled="selectedPaymentMethod !== null"
            ref="tooltip"
            target="select-paymethod"
          >
            {{ $t("form.errors.selectPayMethod") }}
          </b-tooltip>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";

export default {
  mixins: [lpMixins],
  name: "PayMethods",
  props: [
    "selectedVariant",
    "showButton",
    "bookLabel",
    "direction",
    "page",
    "isLoading"
  ],
  data() {
    return {
      selectedPaymentMethod: null,
      payMethodsData: this.selectedVariant.paymethods.data
    };
  },
  computed: {
    payMethodsFiltered() {
      return this.payMethodsData.find(
        (p) => p.method_id === this.selectedPaymentMethod
      );
    }
  },
  mounted() {
    if (this.payMethodsData.length === 1) {
      this.selectedPaymentMethod = this.payMethodsData[0].method_id;
      this.selectPayment(this.payMethodsData[0].method_id);
    }
  },
  methods: {
    selectPayment(id) {
      this.selectedPaymentMethod = id;
      this.$emit("change", id);
    },
    confirmPayMethod() {
      this.$bvModal.hide("show-paymethods");
      this.$emit("save", this.reservation_process);
    }
  }
};
</script>

<style scoped></style>