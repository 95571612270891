var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-4 pb-0 pb-lg-4",attrs:{"id":"checkout-main"}},[_c('b-container',{staticClass:"checkout-main-container px-2 px-lg-5"},[_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"px-0 mb-0"},[_c('h4',{staticClass:"pb-2",domProps:{"innerHTML":_vm._s(_setup.placeholder('%label.checkout.title%', ''))}}),_c('p',{domProps:{"innerHTML":_vm._s(_setup.placeholder('%label.checkout.text%', ''))}})])],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c(_setup.CheckoutForm)],1)],1),_c('b-row',{staticClass:"mt-4 d-lg-none"},[_c('b-col',{staticClass:"show-details",attrs:{"cols":"12"}},[_c(_setup.PriceDetails,{on:{"hideBtnBook":function($event){return _setup.hideBtnBook()}}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"8"}},[(_setup.selectedVariant.promocode_enabled)?_c(_setup.Promocode,{attrs:{"priceDetails":_setup.selectedVariant.price_details,"requestURL":_setup.backendURL() + _setup.page.hash},on:{"render":function($event){return _setup.renderData($event)}}}):_vm._e()],1)],1),(
        _setup.reservation_process === 'twosteps' &&
        _setup.selectedVariant.booking_action === 'payment'
      )?_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{domProps:{"innerHTML":_vm._s(_setup.placeholder('%label.checkout.customer_payment%', ''))}})]),_c('b-col',{staticClass:"mt-3 mt-lg-2",attrs:{"cols":"12"}},[_c('div',[_c(_setup.PayMethods,{attrs:{"selectedVariant":_setup.selectedVariant,"book-label":_setup.placeholder('%label.offer.ctabtn%', _vm.$t('entity.buttons.book')),"direction":"stacked","page":_setup.page,"isLoading":_setup.isLoading},on:{"change":_setup.selectedPaymentValue,"save":function($event){return _setup.bookAction(true)}}})],1)])],1):_vm._e(),_c('b-row',{staticClass:"my-5 pt-1 mb-xl-0"},[_c('b-col',{staticClass:"terms-link mb-4",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"disabled":_setup.isLoading,"data-cy":"checkoutTerms"},model:{value:(_setup.form.terms),callback:function ($$v) {_vm.$set(_setup.form, "terms", $$v)},expression:"form.terms"}},[(_setup.page.agb_url)?_c('a',{attrs:{"href":_setup.page.agb_url,"target":"_blank"}},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.placeholder('%label.checkout.terms%', _vm.$t('form.terms')))}})]):_c('span',[_c('span',{domProps:{"innerHTML":_vm._s(_setup.placeholder('%label.checkout.terms%', _vm.$t('form.terms')))}})])]),_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"disabled":_setup.isLoading,"data-cy":"checkoutNewsletter"},model:{value:(_setup.request.newsletter),callback:function ($$v) {_vm.$set(_setup.request, "newsletter", $$v)},expression:"request.newsletter"}},[_c('span',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.checkout.newsletter%',
                _vm.$t('form.newsletter')
              )
            )}})])],1),_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"12","lg":"5","xl":"4"}},[_c('div',{attrs:{"id":"tooltip-button-disable-terms"}},[(_setup.reservation_process == 'twosteps')?_c('button',{staticClass:"btn action-book btn-success btn-lg book-action",class:{
              'custom-italic': _setup.page.design_data.style?.cta?.italic,
              'custom-bold': _setup.page.design_data.style?.cta?.bold
            },attrs:{"disabled":_setup.hasPayments
                ? (!_setup.selectedPaymentMethod && !_setup.btnDisabled) ||
                  !_setup.form.terms ||
                  _setup.isLoading
                : _setup.btnDisabled || !_setup.form.terms || _setup.isLoading,"type":"button","data-cy":"twoStepsBookButton"},on:{"click":function($event){$event.preventDefault();return _setup.bookAction(true)}}},[[_c('span',{domProps:{"innerHTML":_vm._s(
                  _setup.placeholder(
                    '%label.checkout.ctabtn%',
                    _vm.$t('entity.buttons.book')
                  )
                )}}),(_setup.isLoading)?_c('b-spinner',{staticClass:"ml-2",attrs:{"small":"","variant":"light"}}):_vm._e()]],2):_c('button',{staticClass:"btn action-book btn-success btn-lg mt-3 finalize-booking",class:{
              'custom-italic': _setup.page.design_data.style?.cta?.italic,
              'custom-bold': _setup.page.design_data.style?.cta?.bold
            },attrs:{"disabled":_setup.hasPayments
                ? (!_setup.selectedPaymentMethod && !_setup.btnDisabled) ||
                  !_setup.form.terms ||
                  _setup.isLoading
                : _setup.btnDisabled || !_setup.form.terms || _setup.isLoading,"type":"button","data-cy":"finalizeBookingButton"},domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.checkout.ctabtn%',
                _vm.$t('form.easy_reservation')
              )
            )},on:{"click":function($event){return _setup.finalizeBooking()}}},[[_c('span',{domProps:{"innerHTML":_vm._s(
                  _setup.placeholder(
                    '%label.checkout.ctabtn%',
                    _vm.$t('form.easy_reservation')
                  )
                )}}),(_setup.isLoading)?_c('b-spinner',{staticClass:"ml-2",attrs:{"small":"","variant":"light"}}):_vm._e()]],2)]),_c('b-tooltip',{ref:"tooltip",attrs:{"disabled":_setup.form.terms,"target":"tooltip-button-disable-terms"}},[_vm._v(" "+_vm._s(_vm.$t("form.check_terms"))+" ")])],1)],1)],1),_c(_setup.CheckoutModals)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }