<template>
  <div :id="'package-' + treatmentData.key" class="package">
    <b-card class="mb-3 p-0 overflow-hidden package-inner">
      <div :class="isMobile ? 'w-100' : 'd-flex flex-nowrap'">
        <div
          v-if="picture"
          :class="isMobile ? 'w-100' : 'w-25'"
          class="flex-shrink-0 mx-0 px-0"
        >
          <div
            class="package-picture w-100 h-100"
            :style="{
              'background-image':
                'url(' +
                buildMediaURL(picture.public_url, picture.provider, '300x400') +
                ')'
            }"
          >
            <b-aspect :aspect="isMobile ? '4:3' : '3:4'" />
          </div>
        </div>
        <div class="wrap-when-no-space">
          <div
            class="w-100 flex-shrink-0 flex-grow-0"
            style="padding: 30px 0 20px 40px !important"
          >
            <h4 class="card-title">{{ treatmentData.title }}</h4>
          </div>
          <div class="package-description-wrapper">
            <b-card-body>
              <b-card-text class="package-description">
                <h5>{{ $t("entity.treatment.package.description") }}</h5>
                <div v-html="treatmentData.description"></div>
                <div class="show-package">
                  <b-btn
                    class="show-package-btn"
                    @click="
                      $bvModal.show(
                        'treatment-package-modal-' + treatmentData.key
                      )
                    "
                  >
                    {{ $t("entity.treatment.show_details") }}
                  </b-btn>
                </div>
              </b-card-text>
            </b-card-body>
          </div>
          <div class="w-25 package-summary-wrapper mr-3">
            <b-card-body>
              <b-card-text class="package-summary">
                <h5>{{ $t("entity.treatment.package.applied_spa") }}:</h5>
                <div class="package-products">
                  <div
                    v-for="product in treatmentData.product_groups"
                    :key="product.id"
                  >
                    {{ product.amount }}x {{ product.title }}
                  </div>
                  <div class="package-products-summary">
                    {{ $t("entity.treatment.package.total_amount") }}:
                    <strong>
                      {{ formatPrice(totalCostComputed) }}
                    </strong>
                  </div>
                </div>
              </b-card-text>
            </b-card-body>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      :id="'treatment-package-modal-' + treatmentData.key"
      modal-class="treatment-modal"
      size="xl"
      centered
      hide-header
    >
      <div class="closeModal">
        <button
          @click="$bvModal.hide('treatment-package-modal-' + treatmentData.key)"
        >
          {{ $t("core.close_modal") }}
          <span class="closeIcon"><b-icon icon="x"></b-icon></span>
        </button>
      </div>
      <div class="modal-treatment-package">
        <div class="modal-wrapper">
          <b-row>
            <b-col cols="12" md="6" class="accordion-inner">
              <div class="modal-title">
                <h4>{{ treatmentData.title }}</h4>
              </div>
              <div class="modal-description" v-if="treatmentData.description">
                <h5>{{ $t("entity.treatment.package.description") }}</h5>
                <div v-html="treatmentData.description"></div>
              </div>
            </b-col>
            <b-col class="d-none d-lg-block" cols="12" md="1"></b-col>
            <b-col cols="12" md="6" lg="5" class="accordion-inner mb-5">
              <mini-slider
                :mediaList="mediaList"
                :currentIndex="{
                  index: treatmentGalleryIndex,
                  interval: sendInterval
                }"
                :ref="'treatment-slider_' + treatmentData.key"
                :uniqueIndex="treatmentData.key"
                :video-ratio="'4by3'"
                @sliderControl="sliderChanged($event)"
              ></mini-slider>
            </b-col>
          </b-row>
          <div class="modal-contents ynv-container mb-4">
            <treatment-item
              v-for="product in treatmentData.products_mapped"
              :key="product.id"
              :data="product"
              :styleData="styleData"
              :prekey="'package-'"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MiniSlider from "@/components/blocks/MiniSlider";
import TreatmentItem from "@/components/blocks/treatments/TreatmentItem";
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "Package",
  mixins: [lpMixins],
  components: { TreatmentItem, MiniSlider },
  props: ["treatmentData", "styleData", "page"],
  data() {
    return {
      treatmentGalleryIndex: 0,
      sendInterval: 4000
    };
  },
  methods: {
    sliderChanged(data) {
      this.treatmentGalleryIndex = data.index;
    },
    showGallery(scope, index = 0) {
      this.$refs["treatment-slider_" + this.treatmentData.key].showModalGallery(
        scope,
        this.mediaList,
        index
      );
    }
  },
  computed: {
    mediaList() {
      return this.treatmentData?.media || [];
    },
    picture() {
      return (
        this.treatmentData?.media.find((m) => m.scope == "picture") || null
      );
    },
    totalCostComputed() {
      return this.treatmentData.products_mapped
        .map((prod) => {
          return prod.quantity * prod.pu_price;
        })
        .reduce((accum, a) => {
          return accum + a;
        }, 0);
    }
  }
};
</script>