<template>
  <div class="executed-payments">
    <div v-for="(payment, index) in page.entity.payments?.data" :key="index">
      <div v-if="paymentDetails(payment)" class="payment py-1">
        <b-icon
          v-if="
            (payment.display_status === 'authorized' &&
              payment.provider_slug !== 'BankTransfer') ||
            payment.display_status === 'paid'
          "
          icon="check-circle-fill"
          variant="success"
          class="mr-1"
        ></b-icon>
        <b-icon
          v-if="
            payment.display_status === 'authorized' &&
            payment.provider_slug === 'BankTransfer'
          "
          icon="clock"
          class="authorized-payment-icon mr-1"
        ></b-icon>
        <b-img
          v-if="payment.display_status === 'preauthorized'"
          :src="preauthorizedIcon"
          class="preauthorized-payment-img mr-1"
        ></b-img>
        <p v-html="paymentDetails(payment)" class="d-inline"></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCheckoutData } from "@/composables/useCheckoutData";
import { getCurrentInstance, defineProps } from "vue";
import preauthorizedIcon from "@/assets/icons/preauthorized.svg";
const { proxy } = getCurrentInstance();

const { formatPrice } = useCheckoutData();

const props = defineProps({
  page: { type: Object, required: true },
  lpLocale: { type: String, required: true }
});

function paymentDetails(dataPayment) {
  const {
    display_status,
    amount,
    method_id,
    created_at,
    provider_slug,
    status_changed_at
  } = dataPayment;
  const method = dataPayment?.method?.data;
  const paymentObj = {
    amount: formatPrice(amount),
    gateway_name: getProvider(method_id, method, provider_slug),
    payment_created_data: proxy.$moment(created_at).format("DD.MM.YYYY"),
    payment_created_time: proxy.$moment(created_at).format("HH:mm"),
    payment_changed_data: proxy.$moment(status_changed_at).format("DD.MM.YYYY"),
    payment_changed_time: proxy.$moment(status_changed_at).format("HH:mm")
  };
  if (
    provider_slug &&
    ((display_status === "authorized" && provider_slug !== "BankTransfer") ||
      display_status === "paid")
  ) {
    return provider_slug === "BankTransfer"
      ? proxy.$t("entity.payment.success_bank_transfer_payment", paymentObj)
      : proxy.$t("entity.payment.payment_success", paymentObj);
  } else if (display_status === "authorized" && provider_slug === "BankTransfer") {
    return proxy.$t("entity.payment.authorized_bank_transfer_payment", {
      amount: paymentObj.amount,
      gateway_name: paymentObj.gateway_name
    });
  } else if (display_status === "preauthorized") {
      return proxy.$t("entity.payment.preauthorized_payment", paymentObj);
  } else if (!provider_slug && !method?.title) {
    return getTranslation(
      props.lpLocale,
      display_status,
      paymentObj.payment_created_data,
      paymentObj.amount
    );
  }
}

function getProvider(payment_id, method, provider_slug) {
  let topic;
  if (method) {
    if (payment_id === method.id) {
      topic = method.title;
    } else {
      topic = provider_slug;
    }
  } else {
    topic = provider_slug;
  }
  return topic;
}

function getTranslation(language, display_status, date, amount) {
  switch (language) {
    case "pl":
      if (display_status === "paid") {
        return `Płatność w wysokości ${amount} została pomyślnie zrealizowana w dniu ${date}.`;
      } else if (display_status === "authorized") {
        return `Płatność w wysokości ${amount} została zaksięgowana.`;
      }
      break;
    case "nl":
      if (display_status === "paid") {
        return `De betaling van ${amount} werd met succes uitgevoerd op ${date}.`;
      } else if (display_status === "authorized") {
        return `De betaling van ${amount} werd geregistreerd.`;
      }
      break;
    case "cs":
      if (display_status === "paid") {
        return `Dne ${date} byla úspěšně provedena platba ve výši ${amount}.`;
      } else if (display_status === "authorized") {
        return `Byla zaznamenána platba ve výši ${amount}.`;
      }
      break;
    default:
      if (display_status === "paid") {
        return proxy.$t("entity.payment.default_payment_success", {
          amount: amount,
          payment_created_data: date
        });
      } else if (display_status === "authorized") {
        return proxy.$t("entity.payment.default_payment_authorized", {
          amount: amount
        });
      }
  }
}
</script>

<style scoped>
.authorized-payment-icon {
  color: #e88f3a;
}

.preauthorized-payment-img {
  width: 15px;
  height: 15px;
}
</style>