<template>
  <div>
    <div
      v-for="product in treatmentData.products_mapped"
      :key="product.id"
      class="treatment-item"
    >
      <treatment-item
        :data="product"
        :styleData="styleData"
        :prekey="'spa-'"
        :page="page"
      ></treatment-item>
    </div>
  </div>
</template>

<script>
import TreatmentItem from "@/components/blocks/treatments/TreatmentItem";
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "SpaItem",
  components: { TreatmentItem },
  mixins: [lpMixins],
  props: ["treatmentData", "styleData", "page"]
};
</script>

<style scoped></style>