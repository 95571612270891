<template>
  <b-row
    :id="'custom-element-' + (('id' in content && content.id) || 0)"
    class="block-custom-element no-gutters"
  >
    <b-col cols="12" v-if="contentDataComputed" class="custom-content">
      <template v-if="content.type == 'block.html'">
        <div v-html="contentDataComputed.content_html"></div>
      </template>
      <template v-else>
        <shadow-root
          v-if="shadowRootEnabled"
          @error="handleShadowRootError($event)"
        >
          <div v-html="contentDataComputed.content_html"></div>
        </shadow-root>
        <template v-else>
          <div v-html="contentDataComputed.content_html"></div>
        </template>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "CustomElement",
  mixins: [lpMixins],
  props: {
    /**
     * datesForSkyAlps
     * { from_date: Date, to_date: Date }
     */
    datesForSkyAlps: {
      type: Object
    },
    content: Object,
    parseAssets: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    page: {
      type: Object
    }
  },
  mounted() {
    if (this.parseAssets) {
      this.parseForScript(true, [this.content]);
    }
  },
  computed: {
    contentDataComputed() {
      const component = this.content?.content_data?.data?.components;
      const processed = component?.length ? component[0] : null;
      if (!processed) return null;
      let content_html = processed.content_html;
      if (this.datesForSkyAlps) {
        if (content_html?.includes("noi-mobility-atc")) {
          // Begin processing OLD skyalps TODO will be deleted after we implement 100% our new skyAlpsComponent

          const fromDateArrival = new Date(
            this.datesForSkyAlps.from_date
          ).setHours(-24);
          const toDateArrival = new Date(
            this.datesForSkyAlps.from_date
          ).setHours(72);
          const fromDateDeparture = new Date(
            this.datesForSkyAlps.to_date
          ).setHours(1);
          const toDateDeparture = new Date(
            this.datesForSkyAlps.to_date
          ).setHours(72);

          const changeDomArrival = processed.content_html.replaceAll(
            '"FROM_TS_YANOVIS"',
            fromDateArrival / 1000
          );
          const toDomArrival = changeDomArrival.replaceAll(
            '"TO_TS_YANOVIS"',
            toDateArrival / 1000
          );
          const fromDomDeparture = toDomArrival.replaceAll(
            '"FROM_TS_YANOVIS_DEPARTURE"',
            fromDateDeparture / 1000
          );
          content_html = fromDomDeparture.replaceAll(
            '"TO_TS_YANOVIS_DEPARTURE"',
            toDateDeparture / 1000
          );
          // End processing OLD skyalps
        }
        if (content_html?.includes("yanovis-skyalps")) {
          // // Begin processing NEW skyalps
          const changeDomArrivalNew = processed.content_html.replaceAll(
            '"from_ts_arrival"',
            new Date(this.datesForSkyAlps.from_date).getTime()
          );
          const toDomArrivalNew = changeDomArrivalNew.replaceAll(
            '"to_ts_arrival"',
            new Date(this.datesForSkyAlps.from_date).getTime()
          );
          const fromDomDepartureNew = toDomArrivalNew.replaceAll(
            '"from_ts_departure"',
            new Date(this.datesForSkyAlps.to_date).getTime()
          );
          content_html = fromDomDepartureNew.replaceAll(
            '"to_ts_departure"',
            new Date(this.datesForSkyAlps.to_date).getTime()
          );
          // // End processing NEW skyalps
        }
      }
      return { ...processed, content_html };
    }
  },
  handleShadowRootError(err) {
    console.log(err);
  }
};
</script>