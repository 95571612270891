<template>
  <div class="status-header">
    <div id="circle"></div>
    <p id="status-text" ref="status">
      {{ reception.state_msg }}
    </p>

    <b-tooltip
      target="circle"
      :title="transformFirstLetterUpperCase(reception.state)"
      placement="bottomright"
    ></b-tooltip>
    <b-tooltip
      v-if="isEllipsisActive"
      target="status-text"
      :title="reception.state_msg"
      placement="bottom"
    ></b-tooltip>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

defineProps({
  reception: {
    type: Object
  }
});

const status = ref(null);

function transformFirstLetterUpperCase(str) {
  const newStr = str.charAt(0).toUpperCase() + str.slice(1);
  return newStr;
}

const isEllipsisActive = computed(
  () => status.value?.offsetWidth < status.value?.scrollWidth
);
</script>

<style scoped>
.status-header {
  width: 100%;
  background-color: #acadad;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  height: 35px;
}

#circle {
  background-color: #d01616;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  min-width: 13px;
}

#status-text {
  margin-bottom: 0;
  padding: 0 13px;
  color: white;
  position: relative;
  bottom: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>