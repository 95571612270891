var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"no-gutters"},[_c('b-col',{staticClass:"picture-main-wrapper",attrs:{"cols":"12"}},[(_vm.slider.items.length > 1)?_c('div',[_c('a',{staticClass:"custom-carousel-controls carousel-control-next",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.miniSliderControl('next')}}},[_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/icons/arrow.svg")}})]),_c('a',{staticClass:"custom-carousel-controls carousel-control-prev",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.miniSliderControl('prev')}}},[_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/icons/arrow.svg")}})])]):_vm._e(),(_vm.canShowSoundBtn)?_c('AudioButton',{attrs:{"audioFlag":_vm.sliderMuteVideo},on:{"clicked":function($event){return _vm.sliderAudioControl('slider')}}}):_vm._e(),_c('b-carousel',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(
        (isVisible, entry) => _vm.startGallery(isVisible, entry)
      ),expression:"\n        (isVisible, entry) => startGallery(isVisible, entry)\n      "}],ref:'miniSlider' + _vm.uniqueIndex,staticClass:"ynv-core-carousel",attrs:{"controls":false,"interval":_vm.slider.interval,"fade":"","img-height":"400","img-width":"650"},on:{"input":function($event){return _vm.sliderChangeState()},"sliding-end":function($event){return _vm.slideEnd()}},model:{value:(_vm.slider.current),callback:function ($$v) {_vm.$set(_vm.slider, "current", $$v)},expression:"slider.current"}},_vm._l((_vm.slider.items),function(media,k){return _c('b-carousel-slide',{key:k,on:{"click":function($event){return _vm.showModalGallery(media.scope)}},scopedSlots:_vm._u([{key:"img",fn:function(){return [(media.scope == 'panorama')?[_c('div',{staticClass:"click-trigger",on:{"click":function($event){return _vm.showModalGallery(media.scope)}}}),_c('b-embed',{staticClass:"video-item",attrs:{"type":"iframe","aspect":_vm.videoRatio,"src":_vm.getVal(media.public_url)}})]:(
              media.scope == 'video' ||
              (media.type == 'video' && media.scope == 'gallery')
            )?[(_vm.videoModal)?_c('div',{staticClass:"click-trigger",on:{"click":function($event){return _vm.showModalGallery(media.scope)}}}):_vm._e(),_c('div',{staticClass:"video-background2 embed-responsive embed-responsive-4by3"},[(media.provider === 'youtube')?_c('YoutubePlayer',{ref:`slider_youtube${media.public_id}${media.uniqueId}`,refInFor:true,staticClass:"embed-responsive-item",attrs:{"player-id":`slider_youtube${media.public_id}${media.uniqueId}`,"videoId":media.public_id,"loop":1,"autoplay":1}}):(media.provider === 'cdn')?_c('VideoPlayer',{ref:`slider_cdn${media.public_id}${media.uniqueId}`,refInFor:true,attrs:{"public_url":media.public_url,"options":_vm.options}}):(media.provider === 'vimeo')?_c('VimeoVideo',{ref:`slider_vimeo${media.public_id}${media.uniqueId}`,refInFor:true,attrs:{"public_id":media.public_id,"options":_vm.options}}):_vm._e()],1)]:[_c('div',{staticClass:"carousel-image-container embed-responsive embed-responsive-4by3"},[_c('div',{staticClass:"carousel-image embed-responsive-item",class:[{ 'no-rounded-border': _vm.noRoundedBorder }],style:({
                  'background-image':
                    'url(' +
                    _vm.buildMediaURL(
                      media.public_url,
                      media.provider,
                      _vm.imageResolution
                    ) +
                    ')',
                  cursor: 'pointer'
                }),on:{"click":function($event){return _vm.showModalGallery(media.scope)}}})])]]},proxy:true}],null,true)})}),1),_c('b-modal',{attrs:{"id":'lightbox' + _vm.uniqueIndex,"modal-class":"lightbox","hide-header":"","centered":"","size":"xl"},on:{"hidden":function($event){return _vm.closePreviewModalOutside()}}},[_c('div',{staticClass:"closeModal"},[_c('button',{on:{"click":function($event){return _vm.closePreviewModal()}}},[_vm._v(" "+_vm._s(_vm.$t("core.close_modal"))+" "),_c('b-icon',{staticClass:"closeIcon",attrs:{"icon":"x"}})],1)]),(_vm.canShowPreviewSoundBtn)?_c('AudioButton',{attrs:{"audioFlag":_vm.sliderPreviewMuteVideo},on:{"clicked":function($event){return _vm.sliderAudioControl('preview')}}}):_vm._e(),_c('b-carousel',{staticClass:"ynv-core-carousel",attrs:{"interval":0,"controls":_vm.slider.items.length > 1,"fade":""},on:{"sliding-end":function($event){return _vm.previewSlideEnd()}},model:{value:(_vm.slider.index),callback:function ($$v) {_vm.$set(_vm.slider, "index", $$v)},expression:"slider.index"}},_vm._l((_vm.slider.items),function(media,i){return _c('b-carousel-slide',{key:i,class:{
            'video-item': media.scope == 'video'
          },scopedSlots:_vm._u([{key:"img",fn:function(){return [(media.scope == 'panorama')?[_c('b-embed',{staticClass:"video-item",attrs:{"type":"iframe","aspect":_vm.videoRatio,"src":_vm.getVal(media.public_url)}})]:(media.scope == 'video')?[(media.provider === 'vimeo')?_c('div',{staticClass:"embed-responsive embed-responsive-4by3"},[_c('VimeoVideo',{ref:`slider_preview_vimeo${media.public_id}`,refInFor:true,attrs:{"public_id":media.public_id,"options":_vm.options}})],1):_vm._e(),(media.provider === 'youtube')?_c('div',{staticClass:"embed-responsive embed-responsive-4by3"},[_c('YoutubePlayer',{ref:`slider_preview_youtube${media.public_id}`,refInFor:true,staticClass:"embed-responsive-item",attrs:{"player-id":`slider_preview_youtube${media.public_id}`,"videoId":media.public_id,"loop":1,"autoplay":1}})],1):_vm._e(),(media.provider === 'cdn')?_c('div',{staticClass:"embed-responsive embed-responsive-4by3"},[_c('VideoPlayer',{ref:`slider_preview_cdn${media.public_id}`,refInFor:true,attrs:{"public_url":media.public_url,"options":_vm.options}})],1):_vm._e()]:[_c('div',{staticClass:"preview-dummy-img"},[_c('div',{staticClass:"img-container",style:('background-image: url(' +
                    _vm.getVal(media.public_url) +
                    (media.provider == 'cdn' ? '?d=50x50' : '&w=50x50') +
                    ')')}),_c('div',{staticStyle:{"position":"absolute","left":"49%","top":"49%","padding":"8px 8px 6px","border-radius":"9px","margin-top":"-24px","margin-left":"-24px","background":"white","box-shadow":"rgb(169 169 169) 0px 0px 10px"}},[_c('div',{staticClass:"spinner-border text-secondary gallery-loading",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])])]),_c('div',{staticClass:"text-center bg-image"},[_c('b-img',{staticClass:"img-fluid",attrs:{"src":_vm.buildMediaURL(
                      media.public_url,
                      media.provider,
                      _vm.returnImgWidth()
                    )}})],1)]]},proxy:true}],null,true)})}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }