var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.variantsList.length > 1)?_c('div',{staticClass:"variants-list-v2",attrs:{"id":"variants-list"}},[_c('b-col',{class:{'mb-4 pb-1': _vm.isMobile},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-center mt-4 mb-0"},[_c('h3',{domProps:{"innerHTML":_vm._s(
          _vm.placeholder('%label.offer.best_offer%', _vm.$t('entity.best_offer'))
        )}})])]),(!_vm.isMobile)?[_c('b-row',{staticClass:"mt-2 px-3",class:{ 'justify-content-center': _vm.variantsList.length <= 2 }},_vm._l((_vm.variantsList),function(variant,index){return _c('b-col',{key:index,staticClass:"variant-item",class:{
          'no-image': !_vm.getVariantsCoverImage(
            variant.products.data,
            variant.ref
          ),
          'variant-confirmed':
            _vm.variantsList.filter((v) => v.confirmed).length > 0 &&
            variant.confirmed,
          'variant-unconfirmed':
            _vm.variantsList.filter((v) => v.confirmed).length > 0 &&
            !variant.confirmed
        },attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"4","xxl":"4"}},[_c('variant-box',{attrs:{"page":_vm.page,"isMobileView":_vm.isMobile,"variantsSlider":_vm.variantsSlider,"variantsList":_vm.variantsList,"selected":_vm.selected,"designConfig":_vm.designConfig,"variant-is-confirmed":_vm.variantIsConfirmed,"designStyle":_vm.designStyle,"picture":_vm.getVariantsCoverImage(variant.products.data, variant.ref) || {},"operation-mode":_vm.operationMode,"fallback-image":_vm.fallbackImage,"variantData":{
            variant: variant,
            index: index,
            confirmed: _vm.variantsList.filter((v) => v.confirmed).length == 0
          }},on:{"selectVariant":function($event){return _vm.selectVariantEmit($event)}}})],1)}),1)]:[(_vm.page.mobile_variants_side_by_side)?[_c('b-carousel',{ref:"variantsSlider",staticClass:"variants-carousel",attrs:{"interval":0,"controls":_vm.variantsList.length > 1},model:{value:(_vm.variantsSlider),callback:function ($$v) {_vm.variantsSlider=$$v},expression:"variantsSlider"}},_vm._l((_vm.variantsList),function(variant,index){return _c('b-carousel-slide',{key:index},[_c('div',{staticClass:"variant-item",class:{
              'no-image': !_vm.getVariantsCoverImage(
                variant.products.data,
                variant.ref
              ),
              'variant-confirmed':
                _vm.variantsList.filter((v) => v.confirmed).length > 0 &&
                variant.confirmed,
              'variant-unconfirmed':
                _vm.variantsList.filter((v) => v.confirmed).length > 0 &&
                !variant.confirmed
            }},[_c('variant-box',{attrs:{"page":_vm.page,"isMobileView":_vm.isMobile,"variantsSlider":_vm.variantNumber,"variantsList":_vm.variantsList,"selected":_vm.selected,"variant-is-confirmed":_vm.variantIsConfirmed,"designConfig":_vm.designConfig,"designStyle":_vm.designStyle,"picture":_vm.getVariantsCoverImage(variant.products.data, variant.ref),"operation-mode":_vm.operationMode,"fallback-image":_vm.fallbackImage,"variantData":{
                variant: variant,
                index: index,
                confirmed: _vm.variantsList.filter((v) => v.confirmed).length == 0
              }},on:{"selectVariant":function($event){return _vm.selectVariantEmit($event)}}})],1)])}),1)]:[_c('b-row',{staticClass:"mt-2 px-3",class:{ 'justify-content-center': _vm.variantsList.length <= 2 }},_vm._l((_vm.variantsList),function(variant,index){return _c('b-col',{key:index,staticClass:"variant-item mb-2",class:{
          'no-image': !_vm.getVariantsCoverImage(
            variant.products.data,
            variant.ref
          ),
          'variant-confirmed':
            _vm.variantsList.filter((v) => v.confirmed).length > 0 &&
            variant.confirmed,
          'variant-unconfirmed':
            _vm.variantsList.filter((v) => v.confirmed).length > 0 &&
            !variant.confirmed
        },attrs:{"cols":"12"}},[_c('variant-box',{attrs:{"page":_vm.page,"isMobileView":_vm.isMobile,"variantsSlider":index,"variantsList":_vm.variantsList,"selected":_vm.selected,"designConfig":_vm.designConfig,"variant-is-confirmed":_vm.variantIsConfirmed,"designStyle":_vm.designStyle,"picture":_vm.getVariantsCoverImage(variant.products.data, variant.ref) || {},"operation-mode":_vm.operationMode,"fallback-image":_vm.fallbackImage,"variantData":{
            variant: variant,
            index: index,
            confirmed: _vm.variantsList.filter((v) => v.confirmed).length == 0
          }},on:{"selectVariant":function($event){return _vm.selectVariantEmit($event)}}})],1)}),1)]]],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }