<template>
  <div v-if="ready">
    <div class="picture-main">
      <mini-slider
        :mediaList="mediaList"
        :currentIndex="{
          index: roomGalleryIndex,
          interval: sendInterval
        }"
        :ref="'miniSlider' + index"
        :uniqueIndex="index"
        :video-ratio="'4by3'"
        @sliderControl="sliderChanged($event)"
      ></mini-slider>
    </div>
    <div v-if="mediaList.length > 1" class="picture-select">
      <b-row class="no-gutters">
        <template v-for="(media, mediaIndex) in mediaListArray">
          <b-col
            cols="3"
            :key="media.public_id + mediaIndex"
            @mouseenter="selectScope(media.scope, mediaIndex)"
            @mouseleave="mouseOut(media.scope, mediaIndex)"
            @click="showGallery(media.scope, mediaIndex)"
            :class="{
              active:
                hoverClass(media.scope) &&
                (mediaIndex === freeSlotsC &&
                room.media[roomGalleryIndex].scope === media.scope
                  ? roomGalleryIndex > freeSlotsC
                  : false)
            }"
          >
            <div
              class="spinner-border text-secondary gallery-loading"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            <div
              class="bg-img embed-responsive embed-responsive-4by3"
              :style="{
                'background-image':
                  'url(' +
                  buildMediaURL(
                    roomGalleryIndex > freeSlotsC &&
                      mediaIndex === freeSlotsC &&
                      room.media[roomGalleryIndex].scope === 'gallery'
                      ? room.media[roomGalleryIndex].preview_img ||
                          room.media[roomGalleryIndex].public_url
                      : media.preview_img || media.public_url,
                    media.provider,
                    '200x150'
                  ) +
                  ')'
              }"
            >
              <transition name="slider-fade">
                <div
                  v-if="
                    freeSlotsC === 0
                      ? !hoverClass(media.scope) &&
                        roomGalleryIndex !== mediaIndex
                      : !(
                          hoverClass(media.scope) &&
                          roomGalleryIndex === mediaIndex
                        )
                  "
                  class="icon-overlay"
                >
                  <div v-if="media.scope === 'picture'">
                    <img
                      src="@/assets/icons/screen.svg"
                      class="icon d-block mx-auto"
                    />
                    {{ $t("page.gallery.image") }}
                  </div>
                  <div
                    v-if="mediaIndex === freeSlotsC"
                    :style="'font-size: 20px'"
                  >
                    <template
                      v-if="
                        mediaList.filter((i) => i.scope === 'gallery').length -
                          freeSlotsC >
                        0
                      "
                    >
                      ({{
                        freeSlotsC !== 0
                          ? mediaList.filter((i) => i.scope === "gallery")
                              .length - freeSlotsC
                          : mediaList.filter((i) => i.scope === "gallery")
                              .length -
                            freeSlotsC +
                            1
                      }})
                    </template>
                  </div>
                </div>
              </transition>
            </div>
          </b-col>
        </template>
        <b-col
          v-if="
            mediaList.filter((i) => i.scope == 'picture_floorplan').length > 0
          "
          :class="{
            active: hoverClass('picture_floorplan')
          }"
          cols="3"
          class="floor_plan"
          @mouseenter="selectScope('picture_floorplan')"
          @mouseleave="mouseOut('picture_floorplan')"
          @click="showGallery('picture_floorplan')"
        >
          <div
            class="spinner-border text-secondary gallery-loading"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div
            class="bg-img embed-responsive embed-responsive-4by3"
            :style="{
              'background-image':
                'url(' +
                buildMediaURL(
                  mediaList.filter((i) => i.scope == 'picture_floorplan')[0]
                    .preview_img,
                  mediaList.filter((i) => i.scope == 'picture_floorplan')[0]
                    .provider,
                  '200x150'
                ) +
                ')'
            }"
          >
            <transition name="slider-fade">
              <div class="icon-overlay" v-if="!hoverClass('picture_floorplan')">
                <img src="@/assets/icons/skizze.svg" class="icon" />
                <div>{{ $t("page.gallery.plan") }}</div>
              </div>
            </transition>
          </div>
        </b-col>
        <b-col
          v-if="mediaList.filter((i) => i.scope == 'video').length > 0"
          cols="3"
          class="video"
          @mouseenter="selectScope('video')"
          @mouseleave="mouseOut('video')"
          @click="showGallery('video')"
          :class="{
            active: hoverClass('video')
          }"
        >
          <div
            class="spinner-border text-secondary gallery-loading"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div
            class="bg-img embed-responsive embed-responsive-4by3"
            :style="{
              'background-image':
                'url(' +
                buildMediaURL(
                  mediaList.filter((i) => i.scope == 'video')[0].preview_img,
                  'cdn',
                  '200x150'
                ) +
                ')'
            }"
          >
            <transition name="slider-fade">
              <div v-if="!hoverClass('video')" class="icon-overlay">
                <img src="@/assets/icons/video.svg" class="icon" />
                <div>{{ $t("page.gallery.video") }}</div>
              </div>
            </transition>
          </div>
        </b-col>
        <b-col
          v-if="mediaList.filter((i) => i.scope == 'panorama').length > 0"
          :class="{
            active: hoverClass('panorama')
          }"
          @mouseenter="selectScope('panorama')"
          @mouseleave="mouseOut('panorama')"
          @click="showGallery('panorama')"
          cols="3"
          class="panorama"
        >
          <div
            class="spinner-border text-secondary gallery-loading"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
          <div
            class="bg-img embed-responsive embed-responsive-4by3"
            :style="{
              'background-image':
                'url(' +
                buildMediaURL(
                  mediaList.filter((i) => i.scope == 'panorama')[0].preview_img,
                  'cdn',
                  '200x150'
                ) +
                ')'
            }"
          >
            <transition name="slider-fade">
              <div class="icon-overlay" v-if="!hoverClass('panorama')">
                <img src="@/assets/icons/360.svg" class="icon" />
                <div>{{ $t("page.gallery.pano") }}</div>
              </div>
            </transition>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import MiniSlider from "@/components/blocks/MiniSlider";

export default {
  name: "RoomsGallery",
  components: { MiniSlider },
  mixins: [lpMixins],
  props: ["mediaList", "roomGalleryStart", "index", "room"],
  data() {
    return {
      ready: false,
      sendInterval: 4000,
      hover: {
        picture: false,
        video: false,
        panorama: false,
        picture_floorplan: false
      },
      galleryIndex: null,
      galleryItems: [],
      timeoutStarted: {
        state: false,
        theTimeout: null
      },
      roomGalleryIndex: 0
    };
  },
  computed: {
    freeSlotsC() {
      let scopes = ["picture", "picture_floorplan", "video", "panorama"];

      let scopesAvailable = [];
      this.mediaList.forEach((media) => {
        if (
          scopesAvailable.indexOf(media.scope) == -1 &&
          media.scope != "gallery"
        ) {
          scopesAvailable.push(media.scope);
        }
      });
      return scopes.length - scopesAvailable.length;
    },
    mediaListArray() {
      const arrMedia = [];
      const picture = this.mediaList.find((media) => media.scope === "picture");
      if (picture) {
        arrMedia.push(picture);
      }
      const galleryArr = this.mediaList
        .filter((i) => i.scope === "gallery")
        .slice(0, this.freeSlotsC);
      galleryArr.forEach((i) => {
        arrMedia.push(i);
      });
      return arrMedia;
    },
    startGalleryC() {
      return this.roomGalleryStart;
    }
  },
  mounted() {
    this.ready = true;
  },
  methods: {
    hoverClass(scope) {
      return (
        this.room &&
        (this.room.media[this.roomGalleryIndex].scope === scope ||
          (this.room.media[this.roomGalleryIndex].scope === "gallery" &&
            this.freeSlotsC === 0 &&
            scope === "picture"))
      );
    },
    selectScope(scope = false, mediaIndex = null) {
      this.roomGalleryIndex =
        mediaIndex || this.mediaList.findIndex((m) => m.scope === scope);
      this.sendInterval = 0;
    },
    mouseOut(scope, mediaIndex = null) {
      this.roomGalleryIndex =
        mediaIndex || this.mediaList.findIndex((m) => m.scope === scope);
      this.sendInterval = 4000;
      this.$refs["miniSlider" + this.index].setData({
        index: mediaIndex || this.mediaList.findIndex((m) => m.scope === scope),
        interval: 4000
      });
    },
    sliderChanged(data) {
      this.roomGalleryIndex = data.index;
    },
    showGallery(scope, index = null) {
      this.$refs["miniSlider" + this.index].showModalGallery(
        scope,
        this.mediaList,
        index
      );
    }
  }
};
</script>

<style scoped></style>