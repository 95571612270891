export default {
  core: {
    history: "Verlauf",
    ok: "OK",
    cancel: "Abbrechen",
    read_more: "Mehr anzeigen",
    close_modal: "Schließen"
  },
  coupon: {
    back: "Zurück",
    cancel: "Abbrechen",
    redeem: "Fortfahren",
    input: {
      code: "Gutscheincode"
    },
    modal: {
      default: {
        title: "Gutscheincode",
        text: "Sie können das Angeld von {deposit_value} mit einem gültigen Gutschein begleichen. Falls der Gutscheinwert nicht ausreichend ist, können Sie den Restbetrag mit einer weiteren Zahlung begleichen."
      },
      insufficient: {
        title: "Gutscheinwert nicht ausreichend",
        text: "Der Gutschein hat einen Wert von {coupon_amount}. Sie können den Gutschein trotzdem einlösen und anschließend eine Restzahlung von {deposit_balance} durchführen."
      },
      invalid: {
        title: "Gutschein nicht gültig",
        text: "Leider ist der eingegebene Gutschein nicht gültig. Bitte benutzen Sie einen gültigen Gutschein oder eine andere Zahlungsmethode."
      },
      remaining: {
        title: "Zahlung des Restbetrages",
        item: "{payment_date} Zahlung von {payment_amount} erfolgreich durchgeführt",
        text: "Bitte wählen Sie eine Zahlungsmethode aus, mit der Sie die Restzahlung von {deposit_balance} durchführen möchten."
      }
    }
  },
  page: {
    gallery: {
      image: "Bild",
      video: "Video",
      pano: "360°",
      plan: "Skizze",
      gallery: "Galleriebild",
      mute_audio: 'Stummschalten',
      unmute_audio: 'Stummschaltung aufheben'
    },
    whatsapp: {
      scan_code: "QR-Code scannen",
      contact_over: "Über WhatsApp App kontaktieren",
      whatsapp_web: "WhatsApp Web",
      whatsapp_web_contact: "Über Web-App kontaktieren"
    },
    mode: {
      configuration: {
        message: "Landingpage befindet sich in Konfigurationsmodus."
      },
      versioned: {
        message_default: "Alte Version",
        message: "Version vom {date}"
      }
    },
    success: {
      messageSuccessful: "Ihre Nachricht wurde erfolgreich gesendet"
    },
    errors: {
      messageIsEmpty: "Bitte fügen Sie eine Nachricht hinzu",
      no_hash: {
        title: "Es ist ein Fehler aufgetreten",
        text: "Bitte versuche es später noch einmal"
      },
      payment: {
        title: "Die Zahlung konnte nicht durchgeführt werden",
        text: "Bitte versuchen Sie es später noch einmal",
        hint: "Fehlermeldung: ",
        btn_retry: "Erneut versuchen"
      },
      checkin: {
        tech_issue: {
          title: "Der Online-Check-in-Service ist leider nicht verfügbar",
          text: "Bitte kontaktieren Sie uns falls Sie einen Online Check-In durchführen möchten"
        }
      },
      book_after_arrival_date: {
        title: "Angebot nicht mehr verfügbar",
        text: "Das gewünschte Angebot ist leider nicht mehr verfügbar. Bitte kontaktieren Sie uns für ein neues Angebot."
      }
    },
    nav: {
      offer: "Angebot",
      highlights: "Highlights",
      info_services: "Info & Services",
      about: "About",
      contact: "Contact",
      terms_of_use: "Terms of use",
      privacy_policy: "Privacy Policy"
    },
    content: {
      today_reception: "Heute für Sie am Empfang:"
    },
    highlights: {},
    footer: {
      partners: "Partnerbetriebe",
      contact: "Kontaktieren Sie uns",
      menu: "Navigation",
      info: "Informationen"
    },
    account: {
      vat: "MwSt"
    },
    placeholder: {
      whatsapp: "Ich habe eine Frage zum Angebot #{offer}"
    }
  },
  entity: {
    best_offer: "Unser bestes Angebot für Sie",
    details: "Übersicht",
    insurance: {
      text: "Mit unserer Reise-Storno-Schutz-Versicherung sind Sie finanziell abgesichert, sollte Sie unerwartet verhindert sein.",
      ctabtn: "ZUR REISEVERSICHERUNG",
      title: "Reiseversicherung"
    },
    selected: "AUSGEWÄHLT",
    select: "Auswählen",
    highlight: "Highlight",
    highlights_header: "Highlights",
    personal_info_header: "Persönliche angaben",
    personal_info: "Persönliche angaben",
    show_details: "Details anzeigen",
    hide_details: "Details ausblenden",
    variant: "Variante",
    variant_x: "Variante {x}",
    nights_x: "1 Nacht|{x} Nächte",
    room_x: "Zimmer {x}",
    arrival: "Anreise",
    arrival_daparture: "An- Abreise",
    persons_treatment: "Personen/Verpflegung",
    room_category: "Zimmerkategorie",
    departure: "Abreise",
    offer: "Angebot",
    entity_of: " {n} von {count}",
    deposit_needed: "Vorauszahlung im Höhe von {amount} notwendig",
    no_deposit_needed: "Kein Angeld notwendig",
    valid_till: "Angebot gültig bis:",
    valid_till_expired:
      "Dieses Angebot ist leider nicht mehr gültig, bitte kontaktieren Sie uns falls Sie ein weiteres Angebot wünschen.",
    cancel_policy: {
      title: "Stornobedingungen",
      show: "Stornierungsrichtlinien einblenden",
      free_cancellation: "Kostenlos stornierbar",
      free_cancellation_until: "Kostenlos stornierbar innerhalb "
    },
    mealplans: {
      HB: "Halbpension",
      WS: "-",
      BB: "Bed&Breakfast",
      FB: "Vollpension",
      AI: "All Inclusive",
      RO: "Nur Übernachtung"
    },
    room: {
      rooms: "Unterbringung | Unterbringungen",
      persons: "1 Person|{count} Personen",
      child_pl: "1 Kind|{count} Kinder",
      adult_pl: "1 Erwachsener|{count} Erwachsene",
      traveler: "Reisende",
      treatment: "Verpflegung",
      period: "Reisezeitraum",
      info: "Informationen",
      description: "Beschreibung",
      room_change_on: "Zimmerwechsel am"
    },
    treatment: {
      close: "Schließen",
      booked_spa_header: "Gebuchte SPA Leistungen",
      date: "Datum",
      time: "Uhrzeit",
      time_definition: "{time} Uhr",
      period: "Dauer",
      show_details: "Details anzeigen",
      time_slug: "min",
      package: {
        applied_spa: "Leistungen",
        description: "Beschreibung",
        total_amount: "Gesamtpreis"
      },
      item: {
        description: "Beschreibung",
        title: "Anwendung",
        quantity: "Anzahl",
        price: "Preis",
        time: "Dauer",
        guest_name: "Gast",
        date: "Datum",
        date_time: "Uhrzeit"
      }
    },
    price: {
      description: "Beschreibung",
      description_short: "Beschr.",
      quantity: "Anzahl",
      discount: "Rabatt",
      amount: "Betrag",
      taxes_included: "Kurtaxe inklusiv",
      show_price_details: "Preis Details und Stornobedingungen einblenden",
      show_price_details_short: "Preis Details einblenden",
      hide_price_details: "Preis Details ausblenden",
      deposit_balance: "Ausstehendes Angeld",
      price: "Preis",
      total_price: "Gesamtpreis",
      deposit: "Angeld",
      deposit_amount: "Zu bezahlendes Angeld",
      balance: "Ausstehender Restbetrag"
    },
    payment: {
      select_method: "Bitte eine Zahlungsmethode auswählen",
      payment_success:
        "Die Zahlung von {amount} wurde am {payment_created_data} um {payment_created_time} Uhr erfolgreich mittels {gateway_name} durchgeführt.",
      authorized_bank_transfer_payment:
        "{gateway_name} wurde als Zahlungsmethode für den Betrag von {amount} ausgewählt.",
      success_bank_transfer_payment:
        "Die Überweisung von {amount} wurde am {payment_changed_data} um {payment_changed_time} Uhr erfolgreich mittels {gateway_name} durchgeführt.",
      default_payment_success:
        "Die Zahlung von {amount} wurde am {payment_created_data} erfolgreich durchgeführt.",
      default_payment_authorized: "Die Zahlung von {amount} wurde erfasst.",
      no_payment_methods:
        "Leider kann dieses Angebot nicht gebucht da keine Zahlungsmethoden definiert wurden. Bitte kontaktieren Sie das Hotel für weitere Informationen.",
      preauthorized_payment: "Die Zahlung von {amount} wurde am {payment_created_data} um {payment_created_time} Uhr erfolgreich mittels {gateway_name} autorisiert.",
      methods: {
        credit_card: "Kreditkarte",
        credit_card_hint:
          "Kreditkarte: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        wire_transfere: "Überweisung",
        wire_transfere_hint:
          "Überweisung: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur.",
        paypal: "PayPal",
        paypal_hint:
          "PayPal: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eleifend dapibus ex a eleifend. Donec blandit posuere risus quis consectetur."
      },
      secure_checkout: "Secure Checkout",
      secure_checkout_hint:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat condimentum."
    },
    request: {
      thank_you: {
        header: "Vielen Dank für Ihre Buchung",
        text: "Ihre Daten wurden erfolgreich übermittelt und wir werden sie so schnell wie möglich bearbeiten. Für weitere Fragen stehen wir Ihnen gerne zur Verfügung.",
        button: "Schließen"
      }
    },
    buttons: {
      book: "Buchen",
      details: "Details",
      coupon_apply: "Einlösen",
      reserve: "Unverbindlich reservieren"
    },
    expiration_time: "bis {time} MEZ"
  },
  personal_details: "Persönliche Angaben",
  checkin: {
    room_x_of: "Zimmer",
    next: "Weiter",
    select: {
      country: "Land auswählen",
      date: "Datum wählen",
      time: "Zeit wählen"
    },
    allocate_room: "Zimmer zuweisen",
    thank_you: {
      title: "Online Check-in erfolgreich durchgeführt!",
      text: "Ihre Daten wurden erfolgreich übermittelt und wir werden sie so schnell wie möglich bearbeiten. Für weitere Fragen stehen wir Ihnen gerne zur Verfügung."
    },
    title: "Online Check-In",
    guest_1: "Hauptgast",
    guest_2: "Zusatzgast",
    choose_room: "Bitte Zimmer wählen"
  },
  promocode: {
    apply: "Einlösen",
    remove: "Entfernen",
    label: "Rabattcode",
    added: "Rabattcode wurde angewendet",
    not_valid: "Der eingegebenen Rabattcode ist nicht gültig",
    deleted: "Rabattcode wurde entfernt"
  },
  form: {
    errors: {
      selectPayMethod: "Bitte Zahlungsmethode auswählen"
    },
    error_occured:
      "Es ist ein Fehler aufgetretten, bitte überprüfen Sie Ihre Angabe und versuchen Sie erneut",
    payment: {
      error: {
        title: "Es ist ein Fehler aufgetreten",
        text: "Bitte versuche es später noch einmal"
      }
    },
    doctypes: {
      ID_CARD: "Identitätskarte",
      DRIVERS_LICENSE: "Führerschein",
      PASSPORT: "Reisepass"
    },
    labels: {
      additional_info: "Zusätzliche Angaben",
      document_info: "Ausweisdaten",
      birth_info: "Geburtsdaten",
      address_info: "Anschrift",
      contact_info: "Name und Kontakt",
      assign_room: "Zimmer zuweisen",
      other_info: "Weitere"
    },
    region: "Provinz",
    upload: "Upload",
    country_iso: "Land",
    mobilephone: "Handy",
    birth_date: "Geburtsdatum",
    birth_place: "Geburtsort",
    birth_municipality: "Geburtsort/Gemeinde",
    birth_country_iso: "Geburtsland",
    birth_region: "Provinz",
    fiscal_code: "Steuernummer",
    nationality_iso: "Nationalität",
    document_type: "Dokumentart",
    document_number: "Dokumentnr.",
    document_valid_from: "Ausstellungsdatum",
    document_valid_to: "Gültig bis",
    document_issuing_country_iso: "Ausstellungsland",
    document_issuing_city: "Ausstellungsbehörde",
    arrival: "Ankunft",
    checkin_time: "Ankunftszeit",
    license_plate: "KFZ-Kennzeichen",
    driver_name: "Name des Fahrers/der Fahrerin",
    car_brand: "Automarke",
    notes: "Weitere Wünsche",
    terms_short: "Datenschutzerklärung akzeptieren",
    action_send: "Absenden",
    gender_please_select: "Bitte Geschlecht wählen",
    back: "Zurück",
    close: "Schließen",
    select_paymethod: "Zahlungsmethode wählen",
    checkout: "Checkout",
    salutation: "Anrede",
    title_options: {
      m: "Herr",
      f: "Frau"
    },
    name: "Name",
    firstname: "Vorname",
    lastname: "Nachname",
    street: "Straße",
    zip: "PLZ",
    city: "Ort",
    municipality: "Gemeinde",
    country: "Land",
    email: "E-Mail",
    email_hint: "Lorem ipsum dolor sit amet consectetur.",
    phone: "Telefon",
    cellphone: "Mobilnummer",
    phone_hint: "Lorem ipsum dolor sit amet consectetur.",
    contact: "Adresse",
    hint_booking: "{link_booking} Loren ipsum sit dolor",
    //your_request: 'Ihre Anfrage',
    easy_reservation: "Unverbindlich reservieren",
    terms:
      "Hiermit akzeptiere ich die Datenschutzbestimmungen und allgemeinen Geschäftsbedingungen.",
    checkin_terms: "Hiermit akzeptiere ich die [Datenschutzbestimmungen].",
    newsletter:
      "Ich möchte regelmäßig Mitteilungen über Angebote und Neuigkeiten bekommen.",
    check_terms: "Bitte akzeptieren Sie die AGB"
  },
  dict: {
    for: "für"
  },
  booking: {
    default: {
      title: "Vielen Dank für Ihre Anfrage!",
      text: "Ihre Anfrage wurde erfolgreich übermittelt und wir werden sie so schnell wie möglich bearbeiten. Für weitere Fragen stehen wir Ihnen gerne zur Verfügung."
    },
    offer: {
      title: "Vielen Dank für Ihre Buchungsanfrage!",
      text: "Ihre Buchungsanfrage wurde erfolgreich übermittelt und wir werden die Buchung so schnell wie möglich bestätigen. Für weitere Fragen stehen wir Ihnen gerne zur Verfügung."
    },
    order: {
      title: "Vielen Dank für Ihre Buchung!",
      text: "Ihre Buchung wurde erfolgreich übermittelt und wir werden sie so schnell wie möglich bestätigen. Für weitere Fragen stehen wir Ihnen gerne zur Verfügung."
    }
  },
  chat_window: {
    new_messages: "Sie haben ungelesene Nachrichten!",
    message_empty: "Keine Nachrichten",
    conversation_started: "Gespräch begonnen am:",
    type_message: "Nachricht eingeben",
    search: "Suche",
    previewMode:
      "Im Vorschaumodus können keine Chatnachrichten versendet werden."
  },
  checkin_error_message:
    "Achtung, diese Kampagne ist keinem Gast zugeordnet. Es muss zuerst ein Gast hinzugefügt werden, damit das Online Check-In Formular ausgefüllt werden kann.",
  checkout: {
    price_table: "Preistabelle"
  },
  chat_email_from: "von",
  upselling: {
    category: {
      category_title: 'Kategorie',
      without_category: 'Zusatzleistungen ohne Kategorie'
    },
    service: {
      service_title: 'Zusatzleistungen',
      add_service: 'Zur Auswahl',
      selected_service: 'ausgewählt',
      change_service: 'Bearbeiten',
      add_to_cart_service: 'Hinzufügen',
      calc_modes: {
        MODE_PER_DAY: "pro Nacht",
        MODE_PER_PERSON: "pro Person",
        MODE_PER_DAY_AND_PERSON: "pro Nacht und Person",
        MODE_PER_PRODUCT_UNIT: "pro Wohneinheit",
        MODE_PER_LIVING_UNIT_AND_DAY: "pro Wohneinheit und Nacht",
        MODE_PER_ITEM: "Pauschal"
      },
      calc_info: 'Der angezeigte Preis ist der Gesamtbetrag der Berechnungsart "{calc_mode}" dieser Leistung.',
      preselected_service_hotel: '{n}x vom Hotel aufgebucht',
      preselected_service_customer: '{n}x vom Gast aufgebucht',
      show_more_services: 'Alle Leistungen anzeigen',
      hide_services: 'Alle Leistungen ausblenden'
    },
    service_price_table: {
      service: 'Leistung',
      quantity: 'Anzahl',
      price: 'Preis',
      amount: 'Betrag',
      description: 'Beschreibung'
    }
  }
};