var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":'merchants-code' + _vm.merchantsData.code}},[_c('div',{staticClass:"hotel-area d-flex align-items-center position-relative py-5",style:({
      'background-image': `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${
        _vm.media.filter((image) => image.type == 'image').length > 0 &&
        _vm.media.filter((image) => image.type == 'image')[0].public_url
      })`
    })},[_c('div',{staticClass:"hotel-block"},[_c('h1',{staticClass:"hotel-name",class:_vm.stars >= 0 ? 'mb-0' : 'mb-2'},[_vm._v(" "+_vm._s(_vm.hotelName)+" ")]),(_vm.stars >= 0)?_c('stars-rating',{staticClass:"mb-4",attrs:{"rating":_vm.stars}}):_vm._e(),_c('p',{staticClass:"hotel-description mb-4"},[_vm._v(" "+_vm._s(_vm.hotelDescription)+" ")]),_c('b-btn',{staticClass:"select-btn",on:{"click":function($event){_vm.$bvModal.show('show-hotel-dialog' + _vm.merchantsData.code);
          _vm.setImageSize();}}},[_vm._v(" "+_vm._s(_vm.$t("entity.show_details"))+" ")])],1),(
        _vm.trustYouProperties.provider === 'trustyou' && _vm.trustYouProperties.id
      )?_c('iframe',{staticClass:"trustYouWidged",attrs:{"src":`https://api.trustyou.com/hotels/${_vm.trustYouProperties.id}/seal.html?key=ceb60caa-a2b8-4339-9477-8bbc5aab2d61&size=xl&lang=de`,"align":"rigth","scrolling":"no","frameborder":"0","height":"144px","width":"170px"}}):_vm._e()]),_c('b-modal',{attrs:{"id":'show-hotel-dialog' + _vm.merchantsData.code,"body-class":"p-0","modal-class":"border-rounded-modal","size":"xl","hide-header":"","centered":""},on:{"loaded":function($event){return _vm.setImageSize()}}},[_c('div',{staticClass:"closeModal"},[_c('button',{on:{"click":function($event){return _vm.$bvModal.hide('show-hotel-dialog' + _vm.merchantsData.code)}}},[_vm._v(" "+_vm._s(_vm.$t("entity.treatment.close"))+" "),_c('span',{staticClass:"closeIcon"},[_c('b-icon',{attrs:{"icon":"x"}})],1)])]),(_vm.media.length > 0)?_c('div',{staticClass:"p-0 w-100 border-top-rounded-body get-image-size",attrs:{"cols":"12"}},[_c('mini-slider',{attrs:{"aspect":'3:1',"mediaList":_vm.media,"currentIndex":{ index: 0, interval: 4000 },"uniqueIndex":'hotel-',"imageResolution":_vm.dialog.imageSize,"noRoundedBorder":true}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column m-5 pt-2 position-relative"},[_c('div',{staticClass:"d-flex"},[_c('h1',{class:_vm.stars >= 0 ? 'mb-0' : 'mb-2'},[_vm._v(_vm._s(_vm.hotelName))]),(
            _vm.trustYouProperties.provider === 'trustyou' &&
            _vm.trustYouProperties.id
          )?_c('iframe',{staticClass:"trustYouWidgedModal",attrs:{"src":`https://api.trustyou.com/hotels/${_vm.trustYouProperties.id}/seal.html?key=ceb60caa-a2b8-4339-9477-8bbc5aab2d61&size=xl&lang=de`,"align":"rigth","scrolling":"no","frameborder":"0","height":"144px","width":"170px"}}):_vm._e()]),(_vm.stars >= 0)?_c('stars-rating',{staticClass:"mb-4",attrs:{"rating":_vm.stars}}):_vm._e(),_c('div',{staticClass:"mb-4",staticStyle:{"color":"black","size":"18px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.hotelDescription)+" ")]),(_vm.geolocation)?_c('div',{staticClass:"google-maps"},[_c('iframe',{attrs:{"src":`https://maps.google.com/maps?q=${_vm.geolocation.latitude},${_vm.geolocation.longitude}&hl=es;z=14&output=embed`,"id":"gmap_canvas","width":"100%","height":"300","frameborder":"0","scrolling":"no","marginheight":"0","marginwidth":"0"}})]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }