<template>
  <vueVimeoPlayer
    allow="autoplay"
    :options="{
      ...options,
      autoplay: true,
      loop: true
    }"
    ref="player"
    :video-id="public_id"
    player-width="100%"
    player-height="100%"
    class="embed-responsive-item"
  />
</template>
<script setup>
import { shallowRef } from "vue";
import { vueVimeoPlayer } from "vue-vimeo-player";

defineProps({
  options: { required: true },
  public_id: { required: true }
});
const player = shallowRef();
defineExpose({ player });
</script>
