var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"summary-footer"},[_c('div',{staticClass:"price-wrapper d-flex justify-content-start justify-content-lg-end mt-5 flex-row flex-lg-column"},[_c('div',[_c('div',{staticClass:"price-total-label"},[_c('span',{domProps:{"innerHTML":_vm._s(
            _setup.placeholder('%label.amount%', _vm.$t('entity.price.total_price'))
          )}})]),_c('div',{staticClass:"price-total-value"},[_vm._v(" "+_vm._s(_setup.formatPrice(Number(_setup.selectedVariant.total_amount) + _setup.calcTotalAmountOfServices()))+" ")])]),(_setup.showDepositAmountComputed)?[_c('div',[_c('div',{staticClass:"price-total-label deposit"},[_c('span',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.deposit%',
                _vm.$t('entity.price.deposit_amount')
              )
            )}})]),_c('div',{staticClass:"price-total-value",class:{
            deposit:
              _setup.selectedVariant.deposit_balance ===
                _setup.selectedVariant.deposit_amount ||
              _setup.selectedVariant.deposit_balance === 0
          }},[_vm._v(" "+_vm._s(_setup.recalculateDepositIfUpselling())+" ")])])]:_vm._e(),(
        _setup.selectedVariant.deposit_balance !== _setup.selectedVariant.deposit_amount &&
        _setup.selectedVariant.deposit_balance !== 0
      )?[_c('div',[_c('div',{staticClass:"price-total-label deposit"},[_c('span',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.deposit_balance%',
                _vm.$t('entity.price.deposit_balance')
              )
            )}})]),_c('div',{staticClass:"price-total-value deposit"},[_vm._v(" "+_vm._s(_setup.selectedVariant.deposit_balance && _setup.formatPrice(_setup.selectedVariant.deposit_balance))+" ")])])]:_vm._e()],2),_c('div',{staticClass:"mt-4"},[(
        _vm.$moment(new Date()).diff(
          _vm.$moment(_setup.selectedVariant.cancelpolicy_data.refundable_until).format()
        ) < 0 || _setup.selectedVariant.deposit_amount === 0
      )?_c('div',{staticClass:"price-extras d-none d-lg-inline-block"},[(_setup.selectedVariant.deposit_amount === 0)?_c('div',{staticClass:"d-inline-block"},[(_setup.page.design_data.config?.content?.badge_deposit)?_c('span',{staticClass:"badge badge-secondary no_deposit-badge ml-0",domProps:{"innerHTML":_vm._s(
            _setup.placeholder(
              '%label.deposit_policy.no_deposit_needed%',
              _vm.$t('entity.no_deposit_needed')
            )
          )}}):_vm._e()]):_vm._e(),(
          _vm.$moment(new Date()).diff(
            _vm.$moment(
              _setup.selectedVariant.cancelpolicy_data.refundable_until
            ).format()
          ) < 0
        )?_c('div',{staticClass:"d-inline-block"},[(_setup.page.design_data.config?.content?.badge_cancellation)?_c('span',{staticClass:"badge badge-success cancel_policy-badge ml-0"},[_c('span',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.cancel_policy.free_cancellation%',
                _vm.$t('entity.cancel_policy.free_cancellation_until')
              )
            )}})]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"show-table mt-0"},[_c('a',{class:{
          'custom-italic': _setup.page.design_data.style?.rooms?.storno_italic,
          'custom-bold': _setup.page.design_data.style?.rooms?.storno_bold
        },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _setup.seeDetails()}}},[_vm._v(" "+_vm._s(_vm.$t("entity.price.show_price_details"))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }