<template>
  <b-card no-body class="ynv-accordion spa-accordion my-3">
    <b-card-header
      v-b-toggle="
        data.description != ''
          ? prekey + 'treatment-item-' + data.id
          : 'disabled'
      "
      header-tag="div"
      class="p-1"
      role="tab"
      :style="{ cursor: data.description != '' ? 'pointer' : 'default' }"
    >
      <div class="ynv-accordion-header w-100 justify-content-between d-flex">
        <div v-if="avatarComputed" class="order-sm-2 order-md-1 spa-avatar">
          <b-avatar
            variant="info"
            :src="
              buildMediaURL(
                avatarComputed.public_url,
                avatarComputed.provider,
                '150x150'
              )
            "
            size="63"
          ></b-avatar>
        </div>
        <div class="order-sm-1 order-md-2 acc-name ml-sm-0 acc-name">
          <div class="acc-label">
            {{ $t("entity.treatment.item.title") }}
          </div>
          <div class="acc-content">
            {{ data.title }}
          </div>
        </div>
        <div class="order-sm-3 d-none d-md-block">
          <div class="acc-label">
            {{ $t("entity.treatment.item.quantity") }}
          </div>
          <div class="acc-content">
            {{ data.quantity }}
          </div>
        </div>
        <div
          v-if="showDateAndName"
          class="order-sm-7 d-none d-md-block price-field"
        >
          <div class="acc-label">
            {{ $t("entity.treatment.item.price") }}
          </div>
          <div class="acc-content">
            {{ formatPrice(data.pu_price) }}
          </div>
        </div>
        <div class="order-sm-6 d-none d-md-block">
          <div class="acc-label">
            {{ $t("entity.treatment.item.time") }}
          </div>
          <div class="acc-content">
            {{ timeDifference(data.from_time, data.to_time)
            }}{{ $t("entity.treatment.time_slug") }}
          </div>
        </div>
        <div v-if="showDateAndName" class="order-sm-4 d-none d-md-block">
          <div class="acc-label">
            {{ $t("entity.treatment.item.guest_name") }}
          </div>
          <div class="acc-content">
            <template v-for="(guest, index) in data.pu_participants_details">
              <div :key="'pu_participants_details' + index">
                {{ guest.firstname }}
                {{ guest.lastname }}
              </div>
            </template>
          </div>
        </div>
        <div v-if="showDateAndName" class="order-sm-5 d-none d-md-block">
          <div class="acc-label">
            {{ $t("entity.treatment.item.date") }} -
            {{ $t("entity.treatment.item.date_time") }}
          </div>
          <div class="acc-content">
            <template v-if="data.from_date == data.to_date">
              {{
                $moment(data.from_date).format(
                  $i18n.locale === "it" || $i18n.locale === "en"
                    ? "DD/MM/YYYY"
                    : "DD.MM.YYYY"
                )
              }}
              - {{ $moment(data.from_time, "H:mm").format("H:mm") }}
            </template>
            <template v-else>
              {{
                $moment(data.from_date).format(
                  $i18n.locale === "it" || $i18n.locale === "en"
                    ? "DD/MM/YYYY"
                    : "DD.MM.YYYY"
                )
              }}
              -
              {{
                $moment(data.to_date).format(
                  $i18n.locale === "it" || $i18n.locale === "en"
                    ? "DD/MM/YYYY"
                    : "DD.MM.YYYY"
                )
              }}
              - {{ $moment(data.from_time, "H:mm").format("H:mm") }}
            </template>
          </div>
        </div>
        <div v-if="data.description != ''" class="order-sm-8 d-none d-md-block">
          <div class="acc-content">
            <b-button
              class="d-flex accordion-button"
              :class="{
                square: isButtonSquare
              }"
              pill
            >
              <div class="chevron when-open">
                <b-icon icon="chevron-up"></b-icon>
              </div>
              <div class="chevron when-closed">
                <b-icon icon="chevron-down"></b-icon>
              </div>
            </b-button>
          </div>
        </div>
      </div>
      <div class="treatment-mobile-details d-block d-md-none mb-2 mt-2 ml-2">
        <div class="details-item">
          {{ $t("entity.treatment.item.quantity") }}: {{ data.quantity }}
        </div>
        <div v-if="showDateAndName" class="details-item">
          {{ $t("entity.treatment.item.guest_name") }}:
          <template v-for="(guest, index) in data.pu_participants_details">
            <span :key="'pu_participants_details' + index">
              {{ guest.firstname }}
              {{ guest.lastname }}
            </span>
          </template>
        </div>
        <div v-if="showDateAndName" class="details-item">
          {{ $t("entity.treatment.item.date") }} -
          {{ $t("entity.treatment.item.date_time") }}:
          <template v-if="data.from_date == data.to_date">
            {{
              $moment(data.from_date).format(
                $i18n.locale === "it" || $i18n.locale === "en"
                  ? "DD/MM/YYYY"
                  : "DD.MM.YYYY"
              )
            }}
            - {{ $moment(data.from_time, "H:mm").format("H:mm") }}
          </template>
          <template v-else>
            {{
              $moment(data.from_date).format(
                $i18n.locale === "it" || $i18n.locale === "en"
                  ? "DD/MM/YYYY"
                  : "DD.MM.YYYY"
              )
            }}
            -
            {{
              $moment(data.to_date).format(
                $i18n.locale === "it" || $i18n.locale === "en"
                  ? "DD/MM/YYYY"
                  : "DD.MM.YYYY"
              )
            }}
            - {{ $moment(data.from_time, "H:mm").format("H:mm") }}
          </template>
        </div>
        <div class="details-item">
          {{ $t("entity.treatment.item.time") }}:
          {{ timeDifference(data.from_time, data.to_time) }}
        </div>
        <div v-if="showDateAndName" class="details-item price-field">
          {{ $t("entity.treatment.item.price") }}:
          {{ formatPrice(data.pu_price) }}
        </div>
      </div>
    </b-card-header>
    <b-collapse
      :id="prekey + 'treatment-item-' + data.id"
      accordion="my-accordion"
      role="tabpanel"
    >
      <b-card-body>
        <b-row
          v-if="data.description || data.media.length > 0"
          class="mt-2 mt-md-4 treatment-item-inner"
        >
          <b-col cols="12" md="6" class="accordion-inner">
            <h5>{{ $t("entity.treatment.item.description") }}</h5>
            <div v-html="data.description"></div>
          </b-col>
          <b-col class="d-none d-lg-block" cols="12" md="1"></b-col>
          <b-col
            v-if="data.media.length > 0"
            cols="12"
            md="6"
            lg="5"
            class="accordion-inner"
          >
            <mini-slider
              :media-list="data.media"
              :current-index="{ index: galleryIndex, interval: 4000 }"
              ref="treatmenstSlider"
              :uniqueIndex="data.id"
              :video-ratio="'4by3'"
              class="mt-4 mt-md-0"
            ></mini-slider>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
    <div v-if="data.description != ''" class="accordion-controller">
      <b-button
        v-b-toggle="prekey + 'treatment-item-' + data.id"
        class="d-flex"
        pill
      >
        <div class="show-details">
          <div class="button when-closed">
            {{ $t("entity.show_details") }}
          </div>
          <div class="button when-open">
            {{ $t("entity.hide_details") }}
          </div>
          <div class="chevron when-closed">
            <b-icon icon="chevron-down"></b-icon>
          </div>
          <div class="chevron when-open">
            <b-icon icon="chevron-up"></b-icon>
          </div>
        </div>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import MiniSlider from "@/components/blocks/MiniSlider";
import { lpMixins } from "@/mixins/lpMixins";

export default {
  name: "TreatmentItem",
  mixins: [lpMixins],
  components: { MiniSlider },
  props: ["data", "prekey", "styleData", "page"],
  data() {
    return {
      galleryIndex: 0
    };
  },
  computed: {
    isButtonSquare() {
      return (
        "radius" in this.styleData.style.general &&
        this.styleData.style.general.radius != "" &&
        parseInt(this.styleData.style.general.radius) == 0
      );
    },
    avatarComputed() {
      return this.data?.media.find((m) => m.scope == "picture") || null;
    },
    showDateAndName() {
      return ["order", "cancellation"].includes(this.data.sale_scope);
    }
  }
};
</script>