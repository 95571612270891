import Vue from "vue";
import Router from "vue-router";
import App from "@/App.vue";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: App
    },
    {
      path: "/:hash",
      name: "hashed",
      component: App
    },
    {
      path: "/:hash/v/:version_hash",
      name: "version_hashed",
      component: App
    },
    {
      path: "/:hash/checkout",
      name: "checkout",
      component: App
    },
    {
      path: "/:hash/checkin",
      name: "checkin",
      component: App
    },
    {
      path: "/:hash/checkin-done",
      name: "checkin-done",
      component: App
    },
    {
      path: "/:hash/print",
      name: "print",
      component: App
    }
  ]
});