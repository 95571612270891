var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer-wrapper"},[_c('b-container',[_c('div',{staticClass:"footer",attrs:{"id":"footer"}},[(_vm.configData.sections.overwrite != 2)?[_c('b-row',{staticClass:"pt-3"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[(_vm.configData.map.image && _vm.configData.map.url)?[_c('a',{attrs:{"href":_vm.configData.map.url,"target":"_blank"}},[(_vm.configData.map.image)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":_vm.configData.map.image + '?d=300x200'}}):_vm._e()],1)]:[(_vm.configData.account.logo && _vm.configData.account.logo.data)?_c('b-img',{staticClass:"hotel-logo img-fluid",attrs:{"src":_vm.configData.account.logo.data.url + '?d=150x150'}}):_vm._e()],(_vm.configData.account.name)?_c('div',{staticClass:"mt-3"},[_vm._v(" © "+_vm._s(_vm.configData.account.name)+" ")]):_vm._e()],2),_c('b-col',{attrs:{"cols":"12","md":"3"}},[(_vm.configData.account.name)?_c('h5',{staticClass:"footer-heading"},[_vm._v(" "+_vm._s(_vm.configData.account.name)+" ")]):_vm._e(),(
                _vm.configData.account.address && _vm.configData.account.address.data
              )?_c('div',{staticClass:"footer-content"},[_c('div',[_vm._v(" "+_vm._s(_vm.getVal(_vm.configData.account.address.data.street))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getVal(_vm.configData.account.address.data.city_zip))+" "+_vm._s(_vm.getVal(_vm.configData.account.address.data.city_name))+" ")]),(
                  _vm.configData.account.fiscal && _vm.configData.account.fiscal.data
                )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("page.account.vat"))+": "+_vm._s(_vm.getVal(_vm.configData.account.fiscal.data.vat_number))+" ")]):_vm._e()]):_vm._e()]),(
              _vm.configData.design_config.navigation &&
              _vm.configData.design_config.navigation.filter(
                (m) => m.type == 'secondary'
              ).length > 0
            )?_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('h5',{staticClass:"footer-heading",domProps:{"innerHTML":_vm._s(
                _vm.placeholder(
                  '%label.footer.custom_menu%',
                  _vm.$t('page.footer.menu')
                )
              )}}),_c('div',{staticClass:"footer-content"},_vm._l((_vm.configData.design_config.navigation
                  .filter((m) => m.type == 'secondary')
                  .sort((a, b) => a.sequence - b.sequence)),function(page,k){return _c('div',{key:k},[_c('a',{attrs:{"href":page.translations[_vm.$i18n.locale].url,"target":"_blank"},domProps:{"innerHTML":_vm._s(_vm.placeholder(page.placeholder, page.id))}})])}),0)]):_vm._e(),(
              _vm.configData.account.contact && _vm.configData.account.contact.data
            )?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('h5',{staticClass:"footer-heading",domProps:{"innerHTML":_vm._s(
                _vm.placeholder(
                  '%label.footer.contact%',
                  _vm.$t('page.footer.contact')
                )
              )}}),_c('div',{staticClass:"flex-column social-media footer-content d-flex"},[(
                  _vm.configData.account.contact.data.email ||
                  _vm.configData.account.contact.data.phone
                )?_c('div',{staticClass:"email"},[_c('div',[_c('a',{attrs:{"href":'tel:' + _vm.configData.account.contact.data.phone}},[_vm._v(" "+_vm._s(_vm.configData.account.contact.data.phone)+" ")])]),_c('div',[_c('a',{attrs:{"href":'mailto:' +
                      _vm.configData.account.contact.data.email +
                      '?Subject=' +
                      _vm.placeholder('%label.offer.title%', _vm.$t('entity.offer')) +
                      ' ' +
                      _vm.configData.entity.slug +
                      ' ' +
                      _vm.$t('chat_email_from') +
                      ' ' +
                      _vm.configData.account.name}},[_vm._v(" "+_vm._s(_vm.configData.account.contact.data.email)+" ")])])]):_vm._e(),(_vm.configData.design_config.footer)?_c('div',{staticClass:"social-media"},[(_vm.configData.design_config.footer.facebook)?_c('a',{attrs:{"target":"_blank","href":_vm.configData.account.contact.data.facebook}},[_c('span',{staticClass:"mdi mdi-facebook"})]):_vm._e(),(_vm.configData.design_config.footer.twitter)?_c('a',{attrs:{"target":"_blank","href":_vm.configData.account.contact.data.twitter}},[_c('span',{staticClass:"mdi mdi-twitter"})]):_vm._e(),(_vm.configData.design_config.footer.instagram)?_c('a',{attrs:{"target":"_blank","href":_vm.configData.account.contact.data.instagram}},[_c('span',{staticClass:"mdi mdi-instagram"})]):_vm._e(),(_vm.configData.design_config.footer.pinterest)?_c('a',{attrs:{"target":"_blank","href":_vm.configData.account.contact.data.pinterest}},[_c('span',{staticClass:"mdi mdi-pinterest"})]):_vm._e()]):_vm._e()])]):_vm._e()],1)]:_vm._e()],2)]),(
      _vm.configData.sections.overwrite == 2 ||
      ('block' in _vm.configData.elements && _vm.configData.elements.block.length > 0)
    )?_vm._l((_vm.configData.elements.block),function(customFooter){return _c('custom-element',{key:customFooter.id,staticClass:"py-3",attrs:{"content":customFooter}})}):_vm._e(),_c('b-container',{staticClass:"pb-2"},[_c('b-row',[_c('b-col',{staticClass:"text-right powered-by"},[_c('a',{attrs:{"href":"https://www.yanovis.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/powered_by_yanovis_positive.png"),"alt":"Powered by Yanovis","title":"Visions become software"}})])])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }