<template>
  <div class="show-price-table">
    <b-table
      :stacked="isMobile"
      v-if="!isMobile"
      :class="isMobile ? 'mobile' : 'desktop'"
      class="mb-0"
      :items="selectedVariant.price_details"
      :fields="priceFieldsComputed"
    >
      <template v-slot:cell(title)="data">
        <div v-html="formatPriceTitle(data.item.title)"></div>
      </template>
      <template v-slot:cell(quantity)="data">
        <template v-if="!data.item.quantity_hidden">
          {{ getQuantity(data.item) }}
        </template>
      </template>
      <template v-slot:cell(pu_price)="data">
        <template v-if="!data.item.pu_price_hidden">
          {{ showOutput(data) ? formatPrice(data.item.pu_price) : "" }}
        </template>
      </template>
      <template v-slot:cell(discount_value)="data">
        {{ getDiscount(data.item) }}
      </template>
      <template v-slot:cell(amount)="data">
        <template v-if="!data.item.amount_hidden">
          {{ getAmount(data.item) }}
        </template>
      </template>
    </b-table>
    <b-container v-else class="pt-3">
      <b-row
        v-for="(priceRow, index) in selectedVariant.price_details.filter(
          (priceRow) => priceRow.title != ''
        )"
        :key="'price_table' + index"
        class="mobile-pricetable"
      >
        <b-col cols="12" class="d-flex mobile-pricetable-item">
          <div class="p-1 w-100">
            <div class="price-title">
              <strong>{{ priceRow.title }}</strong>
            </div>
            <template v-for="(field, findex) in priceFieldsComputed">
              <template
                v-if="
                  field && field.key + '_hidden' in priceRow
                    ? !priceRow[field.key + '_hidden']
                    : true
                "
              >
                <div
                  v-if="
                    field &&
                    field.key != 'title' &&
                    ((priceRow[field.key] == 0 &&
                      priceRow['discount_value'] > 0) ||
                      priceRow[field.key])
                  "
                  class="price-title d-flex justify-content-between py-1"
                  :key="'pinfo-' + findex"
                >
                  <div>{{ field.label }}</div>
                  <div
                    v-if="
                      (field.key === 'amount' && !priceRow.amount_hidden) ||
                      (field.key === 'pu_price' && !priceRow.pu_price_hidden)
                    "
                  >
                    {{ formatPrice(priceRow[field.key]) }}
                  </div>
                  <div v-else-if="field.key === 'discount_value'">
                    {{ getDiscount(priceRow) }}
                  </div>
                  <div v-else>
                    {{ priceRow[field.key] }}
                  </div>
                </div>
              </template>
            </template>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <PriceTableServices v-if="page?.upselling_enabled && page?.entity_id && selectedServices.length"/>
    <b-row
      class="mx-0 pb-2 mb-4 price-table-footer"
      style="border-top: 2px #e8e8e8 solid"
    >
      <b-col cols="6" class="font-weight-bold">
        <span
          v-html="placeholder('%label.amount%', $t('entity.price.total_price'))"
        ></span>
      </b-col>
      <b-col cols="6" class="text-right font-weight-bold">
        {{ formatPrice(Number(selectedVariant.total_amount) + calcTotalAmountOfServices()) }}
      </b-col>
      <template
        v-if="(is('option') || is('offer')) && selectedVariant.deposit_amount"
      >
      <b-col cols="6" class="to-print">
        {{ $t("entity.price.deposit") }}
      </b-col>
      <b-col cols="6" class="text-right to-print">
        {{ recalculateDepositIfUpselling() }}
      </b-col>
      </template>
    </b-row>
  </div>
</template>
<script setup>
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useUpselling } from "@/composables/useUpselling";

const {  page } = useCheckoutData();
const { calcTotalAmountOfServices, recalculateDepositIfUpselling, selectedServices } = useUpselling();
</script>
<script>
import { lpMixins } from "@/mixins/lpMixins";
import PriceTableServices from "@/components/checkout/upselling/PriceTableServices.vue";

export default {
  name: "Pricetable",
  components: {
    PriceTableServices
  },
  mixins: [lpMixins],
  props: ["selectedVariant", "page"],
  data() {
    return {
      fieldsList: [
        { key: "title", label: this.$t("entity.price.description") },
        { key: "quantity", label: this.$t("entity.price.quantity") },
        { key: "pu_price", label: this.$t("entity.price.price") },
        { key: "discount_value", label: this.$t("entity.price.discount") },
        {
          key: "amount",
          label: this.$t("entity.price.amount"),
          class: "text-right"
        }
      ]
    };
  },
  methods: {
    getQuantity(item) {
      const { quantity, amount, discount_value, pu_price } = item;
      if (quantity && (amount || discount_value || pu_price || quantity)) {
        return quantity;
      }
      return "";
    },
    getAmount(item) {
      const { amount, discount_value, pu_price, quantity } = item;
      if (amount && (amount || discount_value || pu_price || quantity)) {
        return this.formatPrice(amount);
      }
      return "";
    },
    getDiscount(item) {
      const { quantity, amount, discount_value, pu_price, discount_type } =
        item;
      if (
        discount_value &&
        (amount || discount_value || pu_price || quantity)
      ) {
        if (discount_type === 1) {
          return this.formatPrice(discount_value);
        }
        return discount_value + " " + (discount_type === 0 ? "%" : "");
      }
      return "";
    }
  },
  computed: {
    priceFieldsComputed() {
      return this.fieldsList.map((field) => {
        if (field.key === "discount_value") {
          if (this.showDiscount) {
            return field;
          }
        } else {
          return field;
        }
      });
    }
  }
};
</script>

<style scoped>
.to-print {
  display: none;
}

@media print {
  .to-print {
    display: block;
  }
}
</style>