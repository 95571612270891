<template>
  <div class="ynv-app">
    <div id="show-checkin-back">
      <a href="#" @click="closeCheckIn()">
        <span class="mdi mdi-chevron-left"></span>
        {{ $t("form.back") }}
      </a>
    </div>
    <b-container class="ynv-container mt-0 mb-5" id="check-in">
      <b-row v-if="!show_error_message" class="d-flex align-items-center">
        <b-col cols="12" md="10" class="order-1 order-md-0">
          <h2
            v-html="
              placeholder('%label.order.checkin.title%', $t('checkin.title'))
            "
          ></h2>
          <p
            v-html="placeholder('%label.order.checkin.text%', '')"
            class="pt-1"
          ></p>
          <p
            v-html="placeholder('%label.order.checkin.guest_fields%', '')"
            class="label-table"
          ></p>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="text-center text-md-right mt-n5 mt-md-0 order-0 order-md-1"
        >
          <b-img
            v-if="page.account.logo && page.account.logo.data"
            :src="page.account.logo.data.url + '?d=150x150'"
            :style="fixImageInHeight ? 'height: 150px' : 'width: 150px'"
            class="hotel-logo img-fluid"
            @load="adjustHotelLogo()"
          />
        </b-col>
      </b-row>
      <div v-if="ready" class="mt-5">
        <b-row>
          <b-col v-if="requestData.participants" cols="12">
            <b-card
              v-for="(guest, i) in requestData.participants"
              :key="'guest-' + i"
              :id="'guest-' + i"
              no-body
              class="ynv-accordion mb-3"
            >
              <b-card-header
                v-b-toggle="'guest-' + i"
                header-tag="div"
                class="p-1"
                role="tab"
              >
                <div
                  class="ynv-accordion-header justify-content-md-between align-items-center d-flex"
                >
                  <div>
                    <b-icon
                      v-if="percentage[i] == 100"
                      icon="check2"
                      class="rounded-circle p-3"
                      style="
                        background-color: #4aac1e10;
                        color: #4aac1e;
                        width: 60px;
                        height: 60px;
                      "
                    />
                    <b-icon
                      v-else
                      icon="person"
                      class="rounded-circle p-3"
                      style="
                        background-color: #1e5aac10;
                        color: #1d3544;
                        width: 60px;
                        height: 60px;
                      "
                    />
                  </div>
                  <div
                    class="d-md-flex justify-content-md-between w-100 mx-3 ml-md-5"
                  >
                    <div>
                      <div class="room-label">
                        <strong>
                          {{ guest.firstname }}
                          {{ guest.lastname }}
                        </strong>
                      </div>
                      <div class="room-content">
                        {{ $t("checkin.guest_" + guest.type) }}
                      </div>
                    </div>
                    <div class="ml-auto mr-3 d-md-flex align-items-md-center">
                      <div
                        class="content d-flex align-content-center progress-bar-accordion"
                      >
                        <div style="width: 100%" class="mr-0 mr-sm-3 pt-2">
                          <b-progress
                            :value="percentage[i]"
                            variant="warning"
                            :style="{ opacity: percentage[i] == 100 ? 1 : 0.5 }"
                            height="5px"
                          ></b-progress>
                        </div>
                        <div>
                          <strong>{{ percentage[i] }}%</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="content">
                    <b-button
                      :class="{
                        square:
                          'radius' in page.design_data.style.general &&
                          parseInt(page.design_data.style.general.radius) == 0
                      }"
                      class="d-flex accordion-button"
                      pill
                    >
                      <div class="chevron when-open">
                        <b-icon icon="chevron-up"></b-icon>
                      </div>
                      <div class="chevron when-closed">
                        <b-icon icon="chevron-down"></b-icon>
                      </div>
                    </b-button>
                  </div>
                </div>
              </b-card-header>
              <b-collapse
                :id="'guest-' + i"
                :visible="true"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-container class="pt-3">
                    <b-row class="mt-0">
                      <b-col cols="12">
                        <h5 class="py-2">
                          <span
                            v-html="
                              placeholder(
                                '%label.order.checkin.group.contact_info%',
                                $t('form.labels.contact_info')
                              )
                            "
                          ></span>
                        </h5>
                      </b-col>
                      <b-col
                        cols="12"
                        v-if="'gender' in requestData.participants[i]"
                      >
                        <div class="form-label"></div>
                        <b-form-group>
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.salutation%', 'form.salutation', 'gender', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.salutation%',
                                  $t('form.salutation')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('gender', guest.type)"
                                class="mandatory"
                              >*</span
                              >
                            </div>
                          </template>
                          <b-form-radio-group
                            :id="guest.type + '-' + i + '_' + 'gender'"
                            v-model="requestData.participants[i].gender"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'gender'
                              ).length > 0
                                ? false
                                : null
                            "
                            :name="guest.type + '-' + i + '_' + 'gender'"
                            @input="inputValue(i)"
                            class="input-gender"
                          >
                            <b-form-invalid-feedback
                              :state="
                                errors.filter(
                                  (e) =>
                                    e.field ==
                                    guest.type + '-' + i + '_' + 'gender'
                                ).length > 0
                                  ? false
                                  : null
                              "
                            >
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.salutation.please_select%',
                                    $t('form.gender_please_select')
                                  )
                                "
                              ></span>
                            </b-form-invalid-feedback>
                            <b-form-radio value="m" class="input-gender-m">
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.salutation.m%',
                                    $t('form.title_options.m')
                                  )
                                "
                              ></span>
                            </b-form-radio>
                            <b-form-radio value="f" class="input-gender-m">
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.salutation.f%',
                                    $t('form.title_options.f')
                                  )
                                "
                              ></span>
                            </b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="
                        isFieldGroupMensiond(i == 0, [
                          'firstname',
                          'lastname',
                          'phone',
                          'email',
                          'mobilephone'
                        ])
                      "
                      class="mt-0"
                    >
                      <b-col
                        v-if="'firstname' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-firstname">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.firstname%', 'form.firstname', 'firstname', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.firstname%',
                                    $t('form.firstname')
                                  )
                                "
                              ></span>
                              <span
                                v-if="isMandatory('firstname', guest.type)"
                                class="mandatory"
                              >*</span
                              >
                            </div>
                          </template>
                          <b-form-input
                            v-model="requestData.participants[i].firstname"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'firstname'
                              ).length > 0
                                ? false
                                : null
                            "
                            @input="inputValue(i)"
                            :id="guest.type + '-' + i + '_' + 'firstname'"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                        v-if="'lastname' in requestData.participants[i]"
                      >
                        <b-form-group class="input-lastname">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.lastname%', 'form.lastname', 'lastname', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.lastname%',
                                  $t('form.lastname')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('lastname', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-input
                            v-model="requestData.participants[i].lastname"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'lastname'
                              ).length > 0
                                ? false
                                : null
                            "
                            :id="guest.type + '-' + i + '_' + 'lastname'"
                            @input="inputValue(i)"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="'phone' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-phone">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.phone%', 'form.phone', 'phone', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.phone%',
                                    $t('form.phone')
                                  )
                                "
                              ></span>
                              <span
                                v-if="isMandatory('phone', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-input
                            v-model="requestData.participants[i].phone"
                            @input="inputValue(i)"
                            :id="guest.type + '-' + i + '_' + 'phone'"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'phone'
                              ).length > 0
                                ? false
                                : null
                            "
                            autocomplete="off"
                            @keydown="inputHandler($event)"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="'mobilephone' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-mobilephone">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.mobilephone%', 'form.mobilephone', 'mobilephone', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.mobilephone%',
                                    $t('form.mobilephone')
                                  )
                                "
                              ></span>
                              <span
                                v-if="isMandatory('mobilephone', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <div
                            :class="{
                              'wrapper-phone-number-valid':
                                isPhoneNumberValid && focused,
                              'wrapper-phone-number-invalid':
                                !isPhoneNumberValid && focused
                            }"
                          >
                            <VueTelInput
                              :id="guest.type + '-' + i + '_' + 'mobilephone'"
                              :value="
                                requestData.participants[i].mobilephone || ''
                              "
                              @keydown="inputHandler($event)"
                              @validate="validatePhoneNumber"
                              @input="inputValue(i, $event)"
                              @focus="focused = true"
                              @blur="focused = false"
                              style="box-shadow: none"
                              :class="{
                                isValid: isPhoneNumberValid && focused,
                                isInvalid: !isPhoneNumberValid,
                                'is-invalid':
                                  errors.filter(
                                    (e) =>
                                      e.field ==
                                      guest.type + '-' + i + '_' + 'mobilephone'
                                  ).length > 0
                              }"
                              validCharactersOnly
                              :inputOptions="{ placeholder: '' }"
                              mode="international"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        v-if="'email' in requestData.participants[i]"
                      >
                        <b-form-group class="input-email">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.email%', 'form.email', 'email', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.email%',
                                    $t('form.email')
                                  )
                                "
                              ></span>
                              <span
                                v-if="isMandatory('email', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-input
                            @input="inputValue(i)"
                            v-model="requestData.participants[i].email"
                            :id="guest.type + '-' + i + '_' + 'email'"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'email'
                              ).length > 0
                                ? false
                                : null
                            "
                            autocomplete="off"
                            @keydown="inputHandler($event, 'email')"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="
                        isFieldGroupMensiond(i == 0, [
                          'street',
                          'zip',
                          'country',
                          'city',
                          'municipality_code'
                        ])
                      "
                      class="mt-4 mt-md-0 pt-3"
                    >
                      <b-col cols="12">
                        <h5 class="py-2">
                          <span
                            v-html="
                              placeholder(
                                '%label.order.checkin.group.address_info%',
                                $t('form.labels.address_info')
                              )
                            "
                          ></span>
                        </h5>
                      </b-col>
                      <b-col
                        v-if="'country_iso' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-country">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.country%', 'form.country_iso', 'country_iso', guest.type)"
                              @click.stop
                            >
                               <span
                                 v-html="
                                   placeholder(
                                    '%label.order.checkin.country%',
                                    $t('form.country_iso')
                                   )
                                "
                               ></span>
                              <span
                                v-if="isMandatory('country_iso', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <v-select
                            v-model="countryValues[i].country_iso"
                            :options="countryList.map((c) => c.text)"
                            :id="guest.type + '-' + i + '_' + 'country_iso'"
                            :placeholder="$t('checkin.select.country')"
                            :class="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'country_iso'
                              ).length > 0
                                ? 'is-invalid'
                                : ''
                            "
                            :value="requestData.participants[i].country_iso"
                            autocomplete="off"
                            @input="
                              changeCountry($event, 'country_iso', i);
                              changeValueOfAdditionalGuest(
                                $event,
                                'country_iso',
                                i
                              );
                              inputValue(i);
                            "
                          />

                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="'city' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group
                          class="input-municipality"
                          v-if="
                            requestData.participants[i].country_iso == 'ITA'
                          "
                        >
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.municipality%', 'form.municipality', 'city', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.municipality%',
                                  $t('form.municipality')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('city', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <v-select
                            autocomplete="off"
                            :options="
                              paginatedMunicipalities.map((m) => ({
                                label: m.n + ' (' + m.p + ')',
                                value: m.i
                              }))
                            "
                            :id="guest.type + '-' + i + '_' + 'city'"
                            :class="
                              errors.filter(
                                (e) =>
                                  e.field == guest.type + '-' + i + '_' + 'city'
                              ).length > 0
                                ? 'is-invalid'
                                : ''
                            "
                            :value="getValue(i, 'municipality_code')"
                            class="selected-ellipsis"
                            @input="
                              changeMunicipality($event, 'city', i);
                              inputValue(i);
                            "
                            @search="
                              (query) => (municipalitySearchField = query)
                            "
                          >
                            <li slot="list-footer" class="pagination">
                              <div
                                v-if="hasNextPage"
                                @click="municipalityLimit += 10"
                                class="v-select-show-more"
                              >
                                <b-icon
                                  icon="plus"
                                  style="width: 20px; height: 20px"
                                ></b-icon>
                                <span>Mehr anzeigen</span>
                              </div>
                            </li>
                          </v-select>
                        </b-form-group>
                        <b-form-group class="input-city" v-else>
                          <template slot="label">
                            <span
                              v-html="
                                placeholder(
                                  '%label.order.checkin.city%',
                                  $t('form.city')
                                )
                              "
                            ></span>
                            <span
                              v-if="isMandatory('city', guest.type)"
                              class="mandatory"
                              >*</span
                            >
                          </template>
                          <b-form-input
                            @input="inputValue(i)"
                            autocomplete="off"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field == guest.type + '-' + i + '_' + 'city'
                              ).length > 0
                                ? false
                                : null
                            "
                            v-model="requestData.participants[i].city"
                            :id="guest.type + '-' + i + '_' + 'city'"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                        v-if="'zip' in requestData.participants[i]"
                      >
                        <b-form-group class="input-zip">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.zip%', 'form.zip', 'zip', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.zip%',
                                  $t('form.zip')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('zip', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-input
                            @input="inputValue(i)"
                            autocomplete="off"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field == guest.type + '-' + i + '_' + 'zip'
                              ).length > 0
                                ? false
                                : null
                            "
                            @keydown="inputHandler($event)"
                            v-model="requestData.participants[i].zip"
                            :id="guest.type + '-' + i + '_' + 'zip'"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        v-if="'street' in requestData.participants[i]"
                      >
                        <b-form-group class="input-street">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.street%', 'form.street', 'street', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.street%',
                                  $t('form.street')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('street', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-input
                            @input="inputValue(i)"
                            autocomplete="off"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'street'
                              ).length > 0
                                ? false
                                : null
                            "
                            v-model="requestData.participants[i].street"
                            :id="guest.type + '-' + i + '_' + 'street'"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="
                        isFieldGroupMensiond(i == 0, [
                          'birth_date',
                          'birth_country',
                          'birth_place',
                          'nationality'
                        ])
                      "
                      class="mt-4 mt-md-0 pt-3"
                    >
                      <b-col cols="12">
                        <h5 class="py-2">
                          <span
                            v-html="
                              placeholder(
                                '%label.order.checkin.group.birth_info%',
                                $t('form.labels.birth_info')
                              )
                            "
                          ></span>
                        </h5>
                      </b-col>
                      <b-col
                        v-if="'birth_date' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-birthdate">
                          <template slot="label">
                          <div
                            class="lp-label"
                            v-b-tooltip.hover
                            :title="labelTooltipTitle('%label.order.checkin.birth_date%', 'form.birth_date', 'birth_date', guest.type)"
                            @click.stop
                          >
                            <span
                              v-html="
                                placeholder(
                                  '%label.order.checkin.birth_date%',
                                  $t('form.birth_date')
                                )
                              "
                            ></span>
                            <span
                              v-if="isMandatory('birth_date', guest.type)"
                              class="mandatory"
                            >*</span>
                          </div>
                          </template>
                          <div :id="guest.type + '-' + i + '_' + 'birth_date'">
                            <DatetimeComponent
                              v-model="requestData.participants[i].birth_date"
                              :input-class="[
                                'vdatetime-input form-control ' +
                                  (hasValue ? 'inputBlack' : 'inputWhite'),
                                errors.filter(
                                  (e) =>
                                    e.field ==
                                    guest.type + '-' + i + '_' + 'birth_date'
                                ).length > 0
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              ]"
                              :flow="['year', 'month', 'date']"
                              :placeholder="$t('checkin.select.date')"
                              :max-datetime="
                                $moment(new Date()).format('YYYY-MM-DD')
                              "
                              min-datetime="1901"
                              :value-zone="timeZone"
                              :zone="timeZone"
                              autocomplete="off"
                              type="date"
                              format="dd.LL.yyyy"
                              :auto="true"
                              ref="dateTimeRef"
                              @close="clearDate"
                              @mousedown="
                                handleDate(
                                  requestData.participants[i].birth_date
                                )
                              "
                              @input="
                                checkPercAndConfirmForInput(
                                  requestData.participants[i].birth_date
                                );
                                inputValue(i);
                              "
                            >
                              <template slot="button-cancel">
                                <p @click="clearDate()">
                                  {{ $t("core.cancel") }}
                                </p>
                              </template>
                              <template slot="button-confirm">
                                <p
                                  @click="
                                    confirm(
                                      requestData.participants[i].birth_date
                                    )
                                  "
                                >
                                  {{ $t("core.ok") }}
                                </p>
                              </template>
                            </DatetimeComponent>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          'birth_country_iso' in requestData.participants[i]
                        "
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-birthcountry">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.birth_country%', 'form.birth_country_iso', 'birth_country_iso', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.birth_country%',
                                  $t('form.birth_country_iso')
                                )
                              "
                              ></span>
                              <span
                                v-if="
                                isMandatory('birth_country_iso', guest.type)
                              "
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <v-select
                            v-model="countryValues[i].birth_country_iso"
                            :value="
                              requestData.participants[i].birth_country_iso
                            "
                            :options="countryList.map((c) => c.text)"
                            :id="
                              guest.type + '-' + i + '_' + 'birth_country_iso'
                            "
                            :placeholder="$t('checkin.select.country')"
                            :class="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type +
                                    '-' +
                                    i +
                                    '_' +
                                    'birth_country_iso'
                              ).length > 0
                                ? 'is-invalid'
                                : ''
                            "
                            autocomplete="off"
                            @input="
                              changeCountry($event, 'birth_country_iso', i);
                              i == 0 &&
                                changeValueOfAdditionalGuest(
                                  $event,
                                  'birth_country_iso',
                                  i
                                );
                              inputValue(i);
                            "
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          requestData.participants[i].birth_country_iso ==
                            'ITA' && false
                        "
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-birthregion">
                          <template slot="label">
                            <span
                              v-html="
                                placeholder(
                                  '%label.order.checkin.birth_region%',
                                  $t('form.birth_region')
                                )
                              "
                            ></span>
                            <span
                              v-if="isMandatory('birth_region', guest.type)"
                              class="mandatory"
                              >*</span
                            >
                          </template>
                          <vue-typeahead-bootstrap
                            :data="provinces.map((p) => p.text)"
                            :id="guest.type + '-' + i + '_' + 'birth_region'"
                            :inputClass="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'birth_region'
                              ).length > 0
                                ? 'is-invalid'
                                : ''
                            "
                            autocomplete="off"
                            @hit="changeRegion($event, 'birth_region', i)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          'birth_place' in requestData.participants[i] &&
                          requestData.participants[i].birth_country_iso == 'ITA'
                        "
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-birthplace">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.birth_municipality%', 'form.birth_municipality', 'birth_place', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.birth_municipality%',
                                  $t('form.birth_municipality')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('birth_place', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <v-select
                            :value="getValue(i, 'birth_municipality_code')"
                            :options="
                              paginatedMunicipalities.map((m) => ({
                                label: m.n + ' (' + m.p + ')',
                                value: m.i
                              }))
                            "
                            :id="guest.type + '-' + i + '_' + 'birth_place'"
                            :class="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'birth_place'
                              ).length > 0
                                ? 'is-invalid'
                                : ''
                            "
                            class="selected-ellipsis"
                            autocomplete="off"
                            @search="
                              (query) => (municipalitySearchField = query)
                            "
                            @input="
                              changeMunicipality($event, 'birth_place', i);
                              i == 0 &&
                                changeValueOfAdditionalGuest(
                                  $event,
                                  'birth_municipality_code'
                                );
                              inputValue(i);
                            "
                          >
                            <li slot="list-footer" class="pagination">
                              <button
                                :disabled="!hasNextPage"
                                @click="municipalityLimit += 10"
                              >
                                Mehr anzeigen
                              </button>
                            </li>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="'nationality_iso' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-nationality">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.nationality%', 'form.nationality_iso', 'nationality_iso', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.nationality%',
                                  $t('form.nationality_iso')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('nationality_iso', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <v-select
                            v-model="countryValues[i].nationality_iso"
                            :value="requestData.participants[i].nationality_iso"
                            autocomplete="off"
                            :options="countryList.map((c) => c.text)"
                            :id="guest.type + '-' + i + '_' + 'nationality_iso'"
                            :placeholder="$t('checkin.select.country')"
                            :class="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'nationality_iso'
                              ).length > 0
                                ? 'is-invalid'
                                : ''
                            "
                            @input="
                              changeCountry($event, 'nationality_iso', i);
                              i == 0 &&
                                changeValueOfAdditionalGuest(
                                  $event,
                                  'nationality_iso',
                                  i
                                );
                              inputValue(i);
                            "
                          ></v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="
                        isFieldGroupMensiond(i == 0, [
                          'document_type',
                          'document_number',
                          'document_valid_from',
                          'document_valid_to',
                          'document_issuing_country',
                          'document_issuing_city'
                        ])
                      "
                      class="mt-4 mt-md-0 pt-3"
                    >
                      <b-col cols="12">
                        <h5 class="py-2">
                          <span
                            v-html="
                              placeholder(
                                '%label.order.checkin.group.document_info%',
                                $t('form.labels.document_info')
                              )
                            "
                          ></span>
                        </h5>
                      </b-col>
                      <b-col
                        v-if="'document_type' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-doctype">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.document_type%', 'form.document_type', 'document_type', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.document_type%',
                                  $t('form.document_type')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('document_type', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-select
                            v-model="requestData.participants[i].document_type"
                            :options="getDocumentTypes"
                            :id="guest.type + '-' + i + '_' + 'document_type'"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'document_type'
                              ).length > 0
                                ? false
                                : null
                            "
                            autocomplete="off"
                            @input="inputValue(i)"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="'document_number' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-docnr">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.document_number%', 'form.document_number', 'document_number', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.document_number%',
                                  $t('form.document_number')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('document_number', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-input
                            v-model="
                              requestData.participants[i].document_number
                            "
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'document_number'
                              ).length > 0
                                ? false
                                : null
                            "
                            :id="guest.type + '-' + i + '_' + 'document_number'"
                            autocomplete="off"
                            @keydown="inputHandler($event)"
                            @input="inputValue(i)"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          'document_valid_from' in requestData.participants[i]
                        "
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-docvalidfrom">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.document_valid_from%', 'form.document_valid_from', 'document_valid_from', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.document_valid_from%',
                                  $t('form.document_valid_from')
                                )
                              "
                              ></span>
                              <span
                                v-if="
                                isMandatory('document_valid_from', guest.type)
                              "
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <div
                            :id="
                              guest.type + '-' + i + '_' + 'document_valid_from'
                            "
                          >
                            <DatetimeComponent
                              v-model="
                                requestData.participants[i].document_valid_from
                              "
                              :input-class="
                                errors.filter(
                                  (e) =>
                                    e.field ==
                                    guest.type +
                                      '-' +
                                      i +
                                      '_' +
                                      'document_valid_from'
                                ).length > 0
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              "
                              :placeholder="$t('checkin.select.date')"
                              :max-datetime="
                                $moment(new Date()).format('YYYY-MM-DD')
                              "
                              :flow="['year', 'month', 'date']"
                              :id="
                                guest.type +
                                '-' +
                                i +
                                '_' +
                                'document_valid_from'
                              "
                              :phrases="{
                                ok: $t('core.ok'),
                                cancel: $t('core.cancel')
                              }"
                              :auto="true"
                              :value-zone="timeZone"
                              :zone="timeZone"
                              autocomplete="off"
                              type="date"
                              format="dd.LL.yyyy"
                              @input="inputValue(i)"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          'document_valid_to' in requestData.participants[i]
                        "
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-docvalidto">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.document_valid_to%', 'form.document_valid_to', 'document_valid_to', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.document_valid_to%',
                                  $t('form.document_valid_to')
                                )
                              "
                              ></span>
                              <span
                                v-if="
                                isMandatory('document_valid_to', guest.type)
                              "
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <div
                            :id="
                              guest.type + '-' + i + '_' + 'document_valid_to'
                            "
                          >
                            <DatetimeComponent
                              v-model="
                                requestData.participants[i].document_valid_to
                              "
                              :input-class="
                                errors.filter(
                                  (e) =>
                                    e.field ==
                                    guest.type +
                                      '-' +
                                      i +
                                      '_' +
                                      'document_valid_to'
                                ).length > 0
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              "
                              :placeholder="$t('checkin.select.date')"
                              :min-datetime="
                                $moment(new Date())
                                  .add(1, 'days')
                                  .format('YYYY-MM-DD')
                              "
                              :flow="['year', 'month', 'date']"
                              :id="
                                guest.type + '-' + i + '_' + 'document_valid_to'
                              "
                              :phrases="{
                                ok: $t('core.ok'),
                                cancel: $t('core.cancel')
                              }"
                              :auto="true"
                              :value-zone="timeZone"
                              :zone="timeZone"
                              autocomplete="off"
                              type="date"
                              format="dd.LL.yyyy"
                              @input="inputValue(i)"
                            />
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          'document_issuing_country_iso' in
                          requestData.participants[i]
                        "
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-doccountry">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.document_issuing_country%', 'form.document_issuing_country_iso', 'document_issuing_country_iso', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.document_issuing_country%',
                                  $t('form.document_issuing_country_iso')
                                )
                              "
                              ></span>
                              <span
                                v-if="
                                isMandatory(
                                  'document_issuing_country_iso',
                                  guest.type
                                )
                              "
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <v-select
                            v-model="
                              countryValues[i].document_issuing_country_iso
                            "
                            :options="countryList.map((c) => c.text)"
                            :id="
                              guest.type +
                              '-' +
                              i +
                              '_' +
                              'document_issuing_country_iso'
                            "
                            :placeholder="$t('checkin.select.country')"
                            :class="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type +
                                    '-' +
                                    i +
                                    '_' +
                                    'document_issuing_country_iso'
                              ).length > 0
                                ? 'is-invalid'
                                : ''
                            "
                            :value="
                              requestData.participants[i]
                                .document_issuing_country_iso
                            "
                            autocomplete="off"
                            @input="
                              changeCountry(
                                $event,
                                'document_issuing_country_iso',
                                i
                              );
                              inputValue(i);
                            "
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          'document_issuing_city' in requestData.participants[i]
                        "
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-docmunicipality">
                          <template
                            v-if="
                              requestData.participants[i]
                                .document_issuing_country_iso == 'ITA'
                            "
                          >
                            <template slot="label">
                              <div
                                class="lp-label"
                                v-b-tooltip.hover
                                :title="labelTooltipTitle('%label.order.checkin.document_issuing_city%', 'form.document_issuing_city', 'document_issuing_city', guest.type)"
                                @click.stop
                              >
                                <span
                                  v-html="
                                  placeholder(
                                    '%label.order.checkin.document_issuing_city%',
                                    $t('form.document_issuing_city')
                                  )
                                "
                                ></span>
                                <span
                                  v-if="
                                  isMandatory(
                                    'document_issuing_city',
                                    guest.type
                                  )
                                "
                                  class="mandatory"
                                >*</span>
                              </div>
                            </template>
                            <v-select
                              :value="
                                getValue(
                                  i,
                                  'document_issuing_municipality_code'
                                )
                              "
                              :options="
                                paginatedMunicipalities.map((m) => ({
                                  label: m.n + ' (' + m.p + ')',
                                  value: m.i
                                }))
                              "
                              :id="
                                guest.type +
                                '-' +
                                i +
                                '_' +
                                'document_issuing_city'
                              "
                              :class="
                                errors.filter(
                                  (e) =>
                                    e.field ==
                                    guest.type +
                                      '-' +
                                      i +
                                      '_' +
                                      'document_issuing_city'
                                ).length > 0
                                  ? 'is-invalid'
                                  : ''
                              "
                              class="v-select-overflow-hidden"
                              @input="
                                changeMunicipality(
                                  $event,
                                  'document_issuing_city',
                                  i
                                );
                                inputValue(i);
                              "
                              @search="
                                (query) => (municipalitySearchField = query)
                              "
                            >
                              <li slot="list-footer" class="pagination">
                                <button
                                  :disabled="!hasNextPage"
                                  @click="municipalityLimit += 10"
                                >
                                  Mehr anzeigen
                                </button>
                              </li>
                            </v-select>
                          </template>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="isFieldGroupMensiond(i == 0, ['fiscal_code'])"
                      class="mt-4 mt-md-0 pt-3"
                    >
                      <b-col cols="12">
                        <h5 class="py-2">{{ $t("form.labels.other_info") }}</h5>
                      </b-col>
                      <b-col
                        v-if="'fiscal_code' in requestData.participants[i]"
                        cols="12"
                        md="2"
                      >
                        <b-form-group class="input-fiscalcode">
                          <template slot="label">
                            <div
                              class="lp-label"
                              v-b-tooltip.hover
                              :title="labelTooltipTitle('%label.order.checkin.fiscal_code%', 'form.fiscal_code', 'fiscal_code', guest.type)"
                              @click.stop
                            >
                              <span
                                v-html="
                                placeholder(
                                  '%label.order.checkin.fiscal_code%',
                                  $t('form.fiscal_code')
                                )
                              "
                              ></span>
                              <span
                                v-if="isMandatory('fiscal_code', guest.type)"
                                class="mandatory"
                              >*</span>
                            </div>
                          </template>
                          <b-form-input
                            autocomplete="off"
                            @keydown="inputHandler($event)"
                            :state="
                              errors.filter(
                                (e) =>
                                  e.field ==
                                  guest.type + '-' + i + '_' + 'fiscal_code'
                              ).length > 0
                                ? false
                                : null
                            "
                            v-model="requestData.participants[i].fiscal_code"
                            :id="guest.type + '-' + i + '_' + 'fiscal_code'"
                            @input="inputValue(i)"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="mt-0" v-if="false">
                      <b-col cols="12">
                        <b-form-group :label="$t('form.upload')">
                          <b-row class="mt-0">
                            <b-col
                              cols="12"
                              md="4"
                              v-for="(file, k) in files[i]"
                              :key="k"
                              class="mb-4"
                            >
                              <div
                                class="box-wrapper selected d-flex align-items-center"
                              >
                                <div class="icon mr-3">
                                  <b-icon
                                    icon="file-earmark-check"
                                    style="width: 30px; height: 30px"
                                  ></b-icon>
                                </div>
                                <div class="label" :title="file.name">
                                  {{ file.name | shortName }}
                                </div>
                                <div class="close ml-auto">
                                  <b-icon
                                    @click="removeFile(k)"
                                    icon="x"
                                    style="
                                      width: 20px;
                                      height: 20px;
                                      background-color: #e3e3e3;
                                      border-radius: 10px;
                                    "
                                  ></b-icon>
                                </div>
                              </div>
                            </b-col>
                            <b-col cols="12" md="4">
                              <div
                                class="box-wrapper d-flex align-items-center"
                                @click="openFileUploader(i)"
                              >
                                <div class="icon mr-3">
                                  <b-icon
                                    icon="file-earmark-arrow-up"
                                    style="width: 30px; height: 30px"
                                  ></b-icon>
                                </div>
                                <div class="label">{{ $t("form.upload") }}</div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-form-file
                          multiple
                          ref="imageProfile"
                          v-show="false"
                          @input="selectFile($event, i)"
                        ></b-form-file>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="
                        'room_allocation' in requestData.participants[i] &&
                        roomsData.length > 1
                      "
                      class="mt-4 mt-md-0"
                    >
                      <b-col cols="12">
                        <h5>
                          <span
                            v-html="
                              placeholder(
                                '%label.order.checkin.assign_room%',
                                $t('form.labels.assign_room')
                              )
                            "
                          ></span>
                        </h5>
                      </b-col>
                      <b-col cols="12" md="4">
                        <template v-if="guest.product_id">
                          <b-list-group>
                            <b-list-group-item
                              style="
                                border: 1px solid #eaeaea !important;
                                border-radius: 5px !important;
                              "
                              class="d-flex align-items-center"
                              button
                              @click="allocateRoom(guest, i)"
                            >
                              <div
                                class="remove-room"
                                @click="removeRoom(guest, i)"
                              >
                                <span class="mdi mdi-close"></span>
                              </div>
                              <b-avatar
                                v-if="
                                  roomsData?.find(
                                    (r) => r.id == guest.product_id
                                  )?.media?.length
                                "
                                variant="info"
                                :src="
                                  roomsData.find(
                                    (r) => r.id == guest.product_id
                                  ).media[0].public_url +
                                  (roomsData.find(
                                    (r) => r.id == guest.product_id
                                  ).media[0].provider == 'mss'
                                    ? '&w=60'
                                    : '?d=60x60')
                                "
                                size="63"
                              ></b-avatar>
                              <div class="ml-3">
                                <strong class="sub-title">
                                  {{
                                    (roomsData.find(
                                      (r) => r.id == guest.product_id
                                    ) &&
                                      roomsData.find(
                                        (r) => r.id == guest.product_id
                                      ).title) ||
                                    ""
                                  }}
                                </strong>
                                <div class="sub-desc">
                                  {{ $t("checkin.room_x_of") }}
                                  {{
                                    roomsData
                                      .map((e) => e.id)
                                      .indexOf(guest.product_id) + 1
                                  }}
                                </div>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </template>
                        <template v-else>
                          <div
                            class="box-wrapper d-flex align-items-center"
                            :class="{
                              'border-danger':
                                errors.filter(
                                  (e) =>
                                    e.field ==
                                    guest.type +
                                      '-' +
                                      i +
                                      '_' +
                                      'room_allocation'
                                ).length > 0
                            }"
                            :id="guest.type + '-' + i + '_' + 'room_allocation'"
                            @click="allocateRoom(guest, i)"
                          >
                            <div class="icon mr-3">
                              <b-icon
                                icon="plus-circle"
                                style="width: 30px; height: 30px"
                              ></b-icon>
                            </div>
                            <div class="label">
                              <span
                                v-html="
                                  placeholder(
                                    '%label.order.checkin.assign_room%',
                                    $t('form.labels.assign_room')
                                  )
                                "
                              ></span>
                            </div>
                          </div>
                        </template>
                        <div v-if="showRoomAllocation">
                          <h3 class="mt-4">
                            <span
                              v-html="
                                placeholder(
                                  '%label.order.checkin.choose_room%',
                                  $t('checkin.choose_room')
                                )
                              "
                            ></span>
                          </h3>
                          <b-list-group>
                            <b-list-group-item
                              v-for="(room, i) in roomsData"
                              :class="{ 'room-inactive': false }"
                              :key="i"
                              style="
                                border: 1px solid #eaeaea !important;
                                border-radius: 5px !important;
                              "
                              class="d-flex align-items-center mt-2"
                              button
                              @click="selectRoom(room)"
                            >
                              <b-avatar
                                v-if="room.media && room.media.length > 0"
                                variant="info"
                                :src="
                                  room.media[0].public_url +
                                  (room.media[0].provider == 'mss'
                                    ? '&w=60'
                                    : '?d=60x60')
                                "
                                size="63"
                              ></b-avatar>
                              <div class="ml-3">
                                <strong class="sub-title">
                                  {{ room.title || "" }}
                                </strong>
                                <div class="sub-desc">
                                  {{ $t("checkin.room_x_of") }} {{ i + 1 }}
                                </div>
                              </div>
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row
                      class="mt-5"
                      v-if="i + 1 < requestData.participants.length"
                    >
                      <b-col cols="12" sm="4">
                        <b-btn
                          style="background-color: #1d3544"
                          @click="openNext(i)"
                          class="w-100"
                        >
                          {{ $t("checkin.next") }}
                        </b-btn>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row
          v-if="
            ['arrival', 'checkin_time', 'license_plate', 'note'].some(
              (additional_info) => additional_info in requestData
            )
          "
          class="mt-5"
        >
          <b-col cols="12" md="12">
            <h3>
              <span
                v-html="
                  placeholder(
                    '%label.order.checkin.group.additional_info%',
                    $t('form.labels.additional_info')
                  )
                "
              ></span>
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="2" v-if="'arrival' in requestData">
            <b-form-group class="input-arrival">
              <template slot="label">
                <div
                  class="lp-label"
                  v-b-tooltip.hover
                  :title="labelTooltipTitle('%label.order.checkin.arrival%', 'form.arrival', 'arrival', 3)"
                  @click.stop
                >
                  <span
                    v-html="
                    placeholder(
                      '%label.order.checkin.arrival%',
                      $t('form.arrival')
                    )
                  "
                  ></span>
                  <span v-if="isMandatory('arrival', 3)" class="mandatory"
                  >*</span>
                </div>
              </template>
              <b-form-datepicker
                :placeholder="$t('checkin.select.date')"
                :id="'arrival'"
                :state="
                  errors.filter((e) => e.field == 'arrival').length > 0
                    ? false
                    : null
                "
                :date-format-options="{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                }"
                v-model="requestData.arrival"
                :locale="page.locale"
                :min="new Date()"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2" v-if="'checkin_time' in requestData">
            <b-form-group class="input-checkintime">
              <template slot="label">
                <div
                  class="lp-label"
                  v-b-tooltip.hover
                  :title="labelTooltipTitle('%label.order.checkin.checkin_time%', 'form.checkin_time', 'checkin_time', 3)"
                  @click.stop
                >
                  <span
                    v-html="
                    placeholder(
                      '%label.order.checkin.checkin_time%',
                      $t('form.checkin_time')
                    )
                  "
                  ></span>
                  <span v-if="isMandatory('checkin_time', 3)" class="mandatory"
                  >*</span>
                </div>
              </template>
              <DatetimeComponent
                v-model="requestData.checkin_time"
                :id="'checkin_time'"
                :input-class="
                  errors.filter((e) => e.field == 'checkin_time').length > 0
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                :flow="['time']"
                :minute-step="5"
                :placeholder="$t('checkin.select.time')"
                :phrases="{ ok: $t('core.ok'), cancel: $t('core.cancel') }"
                :auto="true"
                :value-zone="timeZone"
                :zone="timeZone"
                type="time"
                @input="selectTime($event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2" v-if="'license_plate' in requestData">
            <b-form-group class="input-licenseplate">
              <template slot="label">
                <div
                  class="lp-label"
                  v-b-tooltip.hover
                  :title="labelTooltipTitle('%label.order.checkin.license_plate%', 'form.license_plate', 'license_plate', 3)"
                  @click.stop
                >
                  <span
                    v-html="
                    placeholder(
                      '%label.order.checkin.license_plate%',
                      $t('form.license_plate')
                    )
                  "
                  ></span>
                  <span v-if="isMandatory('license_plate', 3)" class="mandatory"
                  >*</span>
                </div>
              </template>
              <b-form-input
                :id="'license_plate'"
                :state="
                  errors.filter((e) => e.field == 'license_plate').length > 0
                    ? false
                    : null
                "
                v-model="requestData.license_plate"
                @input="inputValue()"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2" v-if="'car_brand' in requestData">
            <b-form-group class="input-carbrand">
              <template slot="label">
                <div
                  class="lp-label"
                  v-b-tooltip.hover
                  :title="labelTooltipTitle('%label.order.checkin.car_brand%', 'form.car_brand', 'car_brand', 3)"
                  @click.stop
                >
                  <span
                    v-html="
                    placeholder(
                      '%label.order.checkin.car_brand%',
                      $t('form.car_brand')
                    )
                  "
                  ></span>
                  <span v-if="isMandatory('car_brand', 3)" class="mandatory"
                  >*</span>
                </div>
              </template>
              <b-form-input
                v-model="requestData.car_brand"
                :id="'car_brand'"
                :state="
                  errors.filter((e) => e.field == 'car_brand').length > 0
                    ? false
                    : null
                "
                @input="inputValue()"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="2" v-if="'driver_name' in requestData">
            <b-form-group class="input-drivername">
              <template slot="label">
                <div
                  class="lp-label"
                  v-b-tooltip.hover
                  :title="labelTooltipTitle('%label.order.checkin.driver_name%', 'form.driver_name', 'driver_name', 3)"
                  @click.stop
                >
                  <span
                    v-html="
                    placeholder(
                      '%label.order.checkin.driver_name%',
                      $t('form.driver_name')
                    )
                  "
                  ></span>
                  <span v-if="isMandatory('driver_name', 3)" class="mandatory"
                  >*</span>
                </div>
              </template>
              <b-form-input
                v-model="requestData.driver_name"
                :id="'driver_name'"
                :state="
                  errors.filter((e) => e.field == 'driver_name').length > 0
                    ? false
                    : null
                "
                @input="inputValue()"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="5" v-if="'note' in requestData">
            <b-form-group class="input-notes">
              <template slot="label">
                <div
                  class="lp-label"
                  v-b-tooltip.hover
                  :title="labelTooltipTitle('%label.order.checkin.notes%', 'form.notes', 'note', 3)"
                  @click.stop
                >
                  <span
                    v-html="
                    placeholder('%label.order.checkin.notes%', $t('form.notes'))
                  "
                  ></span>
                  <span v-if="isMandatory('note', 3)" class="mandatory">*</span>
                </div>
              </template>
              <b-form-textarea
                v-model="requestData.note"
                :id="'note'"
                rows="5"
                :state="
                  errors.filter((e) => e.field == 'note').length > 0
                    ? false
                    : null
                "
                @input="inputValue()"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12" md="5">
            <b-form-checkbox
              v-model="acceptTerms"
              name="acceptTerms"
              :state="
                errors.filter((e) => e.field == 'acceptTerms').length > 0
                  ? false
                  : null
              "
              @input="inputValue()"
            >
              <span
                v-html="
                  showPrivacyLink(
                    placeholder(
                      '%label.order.checkin.privacy%',
                      $t('form.checkin_terms')
                    ),
                    page.privacy_url
                  )
                "
              >
              </span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-0">
          <b-col cols="12" md="3">
            <b-btn
              :disabled="submitting"
              class="bg-warning submit-checkin mt-3 w-100"
              id="submitCheckInForm"
              @click="sendCheckinInfo"
            >
              <template v-if="submitting">
                <b-spinner variant="light" small></b-spinner>
              </template>
              <template v-else>
                <span
                  v-html="
                    placeholder(
                      '%label.offer.checkin.ctabtn%',
                      $t('form.action_send')
                    )
                  "
                ></span>
              </template>
            </b-btn>
          </b-col>
        </b-row>
        <b-modal id="allocate-room" hide-header class="p-0">
          <a
            style="position: absolute; right: 10px; top: 10px; cursor: pointer"
            @click="$bvModal.hide('allocate-room')"
          >
            <span class="mdi mdi-close"></span>
          </a>
          <div class="p-3">
            <h2>{{ $t("checkin.allocate_room") }}</h2>
            <b-list-group v-if="allocateRoomDetails.guest">
              <b-list-group-item
                class="d-flex align-items-center px-0"
                style="border: none"
              >
                <b-icon
                  icon="person"
                  class="rounded-circle p-3"
                  style="
                    background-color: #1e5aac10;
                    color: #1d3544;
                    width: 60px;
                    height: 60px;
                  "
                ></b-icon>
                <div class="ml-4">
                  <strong class="sub-title">
                    {{ allocateRoomDetails.guest.firstname }}
                    {{ allocateRoomDetails.guest.lastname }}
                  </strong>
                  <div class="sub-desc">
                    {{ $t("checkin.guest_" + allocateRoomDetails.guest.type) }}
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
            <div class="room-list mt-2">
              <b-list-group>
                <b-list-group-item
                  v-for="(room, i) in roomsData"
                  :key="i"
                  style="
                    border: 1px solid #eaeaea !important;
                    border-radius: 5px !important;
                  "
                  class="d-flex align-items-center mt-2"
                  button
                  @click="selectRoom(room)"
                >
                  <b-avatar
                    v-if="room.media && room.media.length > 0"
                    :src="
                      room.media[0].public_url +
                      (room.media[0].provider == 'mss' ? '&w=60' : '?d=60x60')
                    "
                    variant="info"
                    size="63"
                  ></b-avatar>
                  <div class="ml-3">
                    <strong class="sub-title">
                      {{ room.title || "" }}
                    </strong>
                    <div class="sub-desc">Zimmer {{ i + 1 }}</div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </b-modal>
      </div>
      <div v-else class="mt-5">
        <template v-if="!show_error_message">
          <b-row>
            <b-col cols="12" class="mb-2">
              <b-skeleton height="20px" width="150px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="50px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="60px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="40px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="4">
              <b-skeleton height="7px" width="60px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12" class="mb-2">
              <b-skeleton height="20px" width="150px"></b-skeleton>
            </b-col>
            <b-col cols="4">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="60px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="45px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="40px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="80px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12" class="mb-2">
              <b-skeleton height="20px" width="150px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="45px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="60px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="80px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="40px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12" class="mb-2">
              <b-skeleton height="20px" width="150px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="80px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="60px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="40px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="45px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12" class="mb-2">
              <b-skeleton height="20px" width="150px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="80px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="60px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="40px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
            <b-col cols="2">
              <b-skeleton height="7px" width="45px"></b-skeleton>
              <b-skeleton height="35px"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="4">
              <b-skeleton height="7px" width="70px"></b-skeleton>
              <b-skeleton height="64px"></b-skeleton>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="12" class="mb-2">
              <b-skeleton height="20px" width="150px"></b-skeleton>
            </b-col>
            <b-col cols="4">
              <b-skeleton height="64px"></b-skeleton>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <h2>
            <span
              v-html="
                placeholder(
                  '%label.order.checkin.error.title%',
                  $t('page.errors.checkin.tech_issue.title')
                )
              "
            ></span>
          </h2>
          <p>
            <span
              v-html="
                placeholder(
                  '%label.order.checkin.error.text%',
                  $t('page.errors.checkin.tech_issue.text')
                )
              "
            ></span>
          </p>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import { provincesList } from "@/models/provincesList";
import { countryList } from "@/models/countryList";
import { Datetime as DatetimeComponent } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { DateTime, Settings } from "luxon";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { documentTypes } from "@/models/documentTypes";

/**
 * Component lazy loading
 * Since the library is about 200kb of JavaScript and 100kb of CSS in order to improve initial page loading time
 * you might consider importing it asynchronously only when user navigates to the page where the library is actually needed.
 * @returns {Promise<Awaited<*>[]>}
 * @constructor
 */
const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input"),
    import(
      /* webpackChunkName: "chunk-vue-tel-input" */ "vue-tel-input/dist/vue-tel-input.css"
    )
  ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
  name: "Checkin",
  mixins: [lpMixins],
  components: { DatetimeComponent, vSelect, VueTelInput },
  props: ["page", "locale", "firstLoadForSelect"],
  emits: ['checkinSelect'],
  data() {
    return {
      inputTimeout: {
        country: null,
        data: null
      },
      settings: {
        localstorage: {
          escapeWatcherBeforeCount: 2, // needs to escape first two runs of watcher at data init
          currentRun: 0, // the run counter
          ttl: 3 * 24 * 60 * 60, // 3 Days
          keys: {
            integrityData: "checkin.form_data_integrity",
            formData: "checkin.form_data",
            ttl: "checkin.form_data.ttl",
            countryFormData: "checkin.country_data"
          }
        }
      },
      /**
       * This "hasValue" is to put the input of datetime in case of birthday of color white.
       * The reason behind is because of the native behavior of DateTimeComponent, it's recommended
       * for future versions of the app use another library with better use, since also this one
       * is already deprecated. Methods created for DateTimeComponent also are: handleDate and clearDate
       */
      hasValue: false,
      thereIsADate: false,
      provinces: provincesList,
      roomAccordionIndex: 0,
      show_error_message: false,
      document_types: documentTypes,
      acceptTerms: false,
      submitting: false,
      countryList: [],
      municipalityList: [],
      ready: false,
      files: [],
      countryValues: [],
      validationState: false,
      scrollIntoView: true,
      errors: [],
      allocateRoomDetails: {},
      mandatoryFields: [],
      countryKeys: [
        "country",
        "nationality",
        "birth_country",
        "document_issuing_country"
      ],
      cityConditioned: {
        birth_place: "birth_country_iso",
        document_issuing_city: "document_issuing_country_iso",
        city: "country_iso"
      },
      city2Municipality: {
        birth_place: "birth_municipality_code",
        document_issuing_city: "document_issuing_municipality_code",
        city: "municipality_code"
      },
      cityKeys: {
        municipality_code: "city",
        birth_municipality_code: "birth_place",
        document_issuing_municipality_code: "document_issuing_city"
      },
      roomsData: this.getRoomsData(),
      requestData: {
        participants: [],
        vehicle_data: {}
      },
      checkinData: {},
      checkinSettings: {},
      municipalitySearchField: "",
      municipalityLimit: 20,
      municipalityOffset: 0,
      showRoomAllocation: false,
      fixImageInHeight: false,
      timeZone: "Europe/Rome",
      firstLoad: true,
      isPhoneNumberValid: true,
      focused: false,
      mobileNumberValidationFlag: false,
      firstLoadSelect: true,
      datekeys: [
        "birth_date",
        "document_valid_from",
        "document_valid_to"
      ]
    };
  },
  computed: {
    percentage() {
      const percentages = [];
      // let errors = [];
      this.requestData?.participants?.forEach((r) => {
        let filled = 0;
        const type = r.type === 1 ? "mainguest" : "additionalguest";
        const checkFields = [];
        this.mandatoryFields[type].forEach((field) => {
          if (field in this.cityConditioned) {
            if (
              field === "city" ||
              r[this.cityConditioned[field]] === "ITA" ||
              r[this.cityConditioned[field]] === "IT"
            ) {
              checkFields.push(field);
            }
          } else {
            checkFields.push(
              this.countryKeys.indexOf(field) >= 0 ? field + "_iso" : field
            );
          }
        });
        checkFields.forEach((field) => !!r[field] && filled++);
        percentages.push(Math.floor((100 / checkFields.length) * filled));
      });
      return percentages;
    },
    getDocumentTypes() {
      return this.document_types.map((item) => ({
        ...item,
        value: item.type,
        text: this.$t("form.doctypes." + item.slug)
      }));
    },
    getMainguestFields() {
      return Object.entries(this.page.checkin_settings?.fields?.mainguest || [])
        .filter((field) => field[1].active)
        .map((field) => field[0]);
    },
    getAdditionalGuestFields() {
      return Object.entries(
        this.page.checkin_settings?.fields?.additionalguest || []
      )
        .filter((field) => field[1].active)
        .map((field) => field[0]);
    },
    filtered() {
      // Create a regular expression with word boundary and case-insensitive flag
      const regex = new RegExp(`\\b${this.municipalitySearchField}`, "i");

      // Filter the array based on the regex match
      // Sort the results by the most exact match (shortest distance to the beginning)
      return this.municipalityList
        .filter(({ n }) => regex.test(n))
        .sort((a, b) => {
          const municipality = this.municipalitySearchField.toLowerCase();
          // Find the index of the query in each string (case-insensitive)
          const indexA = a.n.toLowerCase().indexOf(municipality);
          const indexB = b.n.toLowerCase().indexOf(municipality);
          if (a.n.toLowerCase() === municipality) {
            return -1;
          }
          return indexA - indexB;
        });
    },
    paginatedMunicipalities() {
      return this.filtered.slice(
        this.municipalityOffset,
        this.municipalityLimit + this.municipalityOffset
      );
    },
    hasNextPage() {
      const nextOffset = this.municipalityOffset + 10;
      return Boolean(
        this.filtered.slice(nextOffset, this.municipalityLimit + nextOffset)
          .length
      );
    }
  },
  filters: {
    shortName(str) {
      return str.length > 30
        ? "..." + str.slice(parseInt(str.length / 2), str.length)
        : str;
    }
  },
  created() {
    try {
      this.timeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone || "Europe/Rome";
      this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
        if (isJustShown) {
          this.roomAccordionIndex = parseInt(collapseId.split("-")[1]);
        }
        this.showRoomAllocation = false;
      });
      Settings.defaultLocale = this.page.locale;
      this.checkinData = this.page.entity.checkin.data || null;
      if (this.checkinData.status !== 1) {
        this.$router.push({ name: "checkin-done" });
      }
      this.checkinSettings = this.page.checkin_settings || null;
      this.countryList = countryList
        .map((country) => ({
          value: country.alpha3,
          code: country.code,
          pinned: country.pinned,
          text:
            typeof country.translations.data[this.$i18n.locale] != "undefined"
              ? country.translations.data[this.$i18n.locale].name
              : country.translations.data.en.name
        }))
        .sort(function (a, b) {
          if (a.pinned > b.pinned) return -1;
          if (a.pinned < b.pinned) return +1;
          if (a.text > b.text) return +1;
          if (a.text < b.text) return -1;
          return 0;
        })
        .map((country) => ({
          value: country.value,
          code: country.code,
          text: country.text,
          pinned: country.pinned
        }));
      let data = this.checkinData.participants?.data.sort((a, b) =>
        a.type > b.type ? 1 : b.type > a.type ? -1 : 0
      );
      for (let i = 0; i < this.checkinData.total_participants; i++) {
        this.files[i] = [];
        let fields = { type: 2 };
        let settingsPool = "additionalguest";
        if (i == 0) {
          settingsPool = "mainguest";
          fields.type = 1;
        }
        this.countryValues[i] = {
          birth_country_iso: "",
          country_iso: "",
          nationality_iso: ""
        };
        Object.keys(this.checkinSettings.fields[settingsPool]).forEach(
          (key) => {
            if (this.checkinSettings.fields[settingsPool][key].active) {
              let theKey =
                this.countryKeys.indexOf(key) >= 0 ? key + "_iso" : key;
              if (key == "room_allocation") {
                let singleRoomData = {
                  product_code: "",
                  product_sku: "",
                  merchant_code: "",
                  room_allocation: ""
                };
                if (this.roomsData.length == 1) {
                  singleRoomData = {
                    product_code: this.roomsData[0].product_code,
                    product_sku: this.roomsData[0].product_sku,
                    merchant_code: this.roomsData[0].merchant_code,
                    room_allocation: this.roomsData[0].room_allocation
                  };
                }
                fields["product_code"] =
                  data[i] && data[i]["product_code"]
                    ? data[i]["product_code"]
                    : singleRoomData.product_code;
                fields["product_sku"] =
                  data[i] && data[i]["product_sku"]
                    ? data[i]["product_sku"]
                    : singleRoomData.product_sku;
                fields["merchant_code"] =
                  data[i] && data[i]["merchant_code"]
                    ? data[i]["merchant_code"]
                    : singleRoomData.merchant_code;
                fields["room_allocation"] =
                  fields["product_code"] != ""
                    ? fields["product_code"]
                    : singleRoomData.product_code;
              } else {
                fields[theKey] =
                  data[i] && data[i][theKey] ? data[i][theKey] : "";
              }
              if (theKey.indexOf("_iso") >= 0 && data[i]) {
                let mainCountry =
                  "country_iso" in data[i] && data[i]["country_iso"] != ""
                    ? data[i]["country_iso"]
                    : null;
                if (mainCountry && mainCountry.length == 2) {
                  mainCountry = this.countryList.find(
                    (c) => c.code == mainCountry
                  ).value;
                  data[i][theKey] = mainCountry;
                }
                if (
                  data[i][theKey] == "ITA" &&
                  this.municipalityList.length == 0
                ) {
                  let locale = this.page.locale == "it" ? "ital" : "intl";
                  this.municipalityList = require("@/models/municipalities/" +
                    locale).municipalities;
                }
                fields[theKey] = data[i][theKey]
                  ? data[i][theKey]
                  : mainCountry;
                this.countryValues[i][theKey] = data[i][theKey]
                  ? this.countryList.find((c) => c.value == data[i][theKey]) &&
                    this.countryList.find((c) => c.value == data[i][theKey])
                      .text
                  : mainCountry
                  ? this.countryList.find((c) => c.value == mainCountry) &&
                    this.countryList.find((c) => c.value == mainCountry).text
                  : "";
              }
            }
          }
        );
        this.requestData.participants[i] = fields;
        if (i != 0) {
          [
            "birth_country_iso",
            "birth_municipality_code",
            "document_issuing_country_iso",
            "nationality_iso",
            "country_iso"
          ].forEach((value) => {
            if (this.requestData.participants[i][value] == "") {
              this.requestData.participants[i][value] =
                this.requestData.participants[0][value];
              this.countryValues[i][value] = this.countryList.find(
                (c) => c.value == this.requestData.participants[0][value]
              )?.text;
            }
          });
        }
      }
      Object.keys(this.checkinSettings.fields["additionalinfo"]).forEach(
        (key) => {
          if (this.checkinSettings.fields["additionalinfo"][key].active) {
            this.requestData[key] = this.checkinData[key]
              ? this.checkinData[key]
              : "";
            if (key == "checkin_time")
              this.requestData[key] = this.checkinData[key]
                ? this.checkinData[key]
                : "15:00";
          }
        }
      );
      Object.keys(this.checkinSettings.fields).forEach((k) => {
        let fieldsList = [];
        if (typeof this.checkinSettings.fields[k] == "object") {
          Object.keys(this.checkinSettings.fields[k]).forEach((kk) => {
            if (this.checkinSettings.fields[k][kk].mandatory) {
              fieldsList.push(kk);
            }
          });
        }
        this.mandatoryFields[k] = [];
        this.mandatoryFields[k].push(...fieldsList);
      });
      this.firstLoadSelect = this.firstLoadForSelect;
      this.ready = true;
      this.processStorageActions();
    } catch (e) {
      console.error(e);
      this.show_error_message = true;
    }
  },
  watch: {
    countryValues: {
      handler() {
        clearTimeout(this.inputTimeout.country);
        let vm = this;
        this.inputTimeout.country = setTimeout(function () {
          vm.handleStorage();
        }, 1000);
      },
      deep: true
    },
    requestData: {
      handler() {
        clearTimeout(this.inputTimeout.data);
        let vm = this;
        this.inputTimeout.data = setTimeout(function () {
          vm.handleStorage();
        }, 1000);
      },
      deep: true
    }
  },
  methods: {
    labelTooltipTitle(label, baseTranslation, mandatory, guestType) {
      const isMandatory = this.isMandatory(mandatory, guestType) || '';
      const placeholder = this.placeholder(label, this.$t(baseTranslation));

      return placeholder + (isMandatory && '*');
    },
    inputValue(index = false, event = null) {
      // event can be an empty string which is accepted
      if (event !== null) {
        this.requestData.participants[index].mobilephone = event;
      }
      if (this.validationState) {
        this.errors = this.validate(index);
      }
    },
    /**
     * TODO
     * Optimize Checkin file, there is a lot of code that can be split and separated by logic
     */
    validatePhoneNumber(num) {
      if (this.firstLoad) {
        this.firstLoad = false;
        this.isPhoneNumberValid = true;
        return true;
      }
      if (num.valid !== undefined) {
        this.isPhoneNumberValid = num.valid;
      }
      if (
        !num.valid &&
        num.formatted === "" &&
        !this.mobileNumberValidationFlag
      ) {
        this.isPhoneNumberValid = true;
      }
    },
    processStorageActions() {
      const { keys, ttl } = this.settings.localstorage;
      const timeObsolet = parseInt(Date.now() / 1000);
      if (parseInt(window.localStorage.getItem(keys.ttl)) + ttl < timeObsolet) {
        window.localStorage.removeItem(keys.countryFormData);
        window.localStorage.removeItem(keys.formData);
      }

      const data = JSON.stringify(this.requestData.participants);
      const dataStorage = window.localStorage.getItem(keys.integrityData);
      if (!dataStorage || dataStorage !== data) {
        window.localStorage.setItem(keys.integrityData, data);
      }

      if (!window.localStorage.getItem(keys.formData) || dataStorage !== data) {
        window.localStorage.setItem(keys.ttl, timeObsolet);
        window.localStorage.setItem(
          keys.formData,
          JSON.stringify(this.requestData)
        );
      }

      if (
        !window.localStorage.getItem(keys.countryFormData) ||
        dataStorage !== data
      ) {
        window.localStorage.setItem(
          keys.countryFormData,
          JSON.stringify(this.countryValues)
        );
      }

      this.requestData = JSON.parse(window.localStorage.getItem(keys.formData));
      this.countryValues = JSON.parse(
        window.localStorage.getItem(keys.countryFormData)
      );

      if (this.requestData?.participants) {
        this.hasValue = true;
      }

      /**
       * TODO Repeated code from percent computed property. Optimize in another ticket
       * When user refresh the page and choose Italy in some field, the cities of Italy must be loaded also
       */
      this.requestData.participants.forEach((r) => {
        this.mandatoryFields[
          r.type === 1 ? "mainguest" : "additionalguest"
        ].forEach((field) => {
          if (field in this.cityConditioned) {
            const value = r[this.cityConditioned[field]];
            if (
              (value === "ITA" || value === "IT") &&
              !this.municipalityList.length
            ) {
              let locale = this.page.locale === "it" ? "ital" : "intl";
              this.municipalityList = require("@/models/municipalities/" +
                locale).municipalities;
            }
          }
        });
      });
    },
    getValue(item, field) {
      const values = this.requestData.participants[item];
      const municipality = values[field]
        ? this.municipalityList.find((m) => m.i === values[field])
        : null;
      return municipality ? `${municipality.n} ${municipality.p} ` : "";
    },
    handleStorage() {
      if (
        this.settings.localstorage.escapeWatcherBeforeCount <=
        this.settings.localstorage.currentRun
      ) {
        if (window.localStorage) {
          window.localStorage.setItem(
            this.settings.localstorage.keys.ttl,
            parseInt(Date.now() / 1000)
          );
          window.localStorage.setItem(
            this.settings.localstorage.keys.formData,
            JSON.stringify(this.requestData)
          );
          window.localStorage.setItem(
            this.settings.localstorage.keys.countryFormData,
            JSON.stringify(this.countryValues)
          );
        }
      }
      this.settings.localstorage.currentRun++;
    },
    checkPercAndConfirmForInput(data) {
      this.confirm(data);
    },
    confirm(data) {
      if (data !== "") {
        this.thereIsADate = true;
      }
    },
    clearDate() {
      this.hasValue = !!this.$refs.dateTimeRef[0].datetime;
      if (this.thereIsADate) return;
      this.$refs.dateTimeRef[0].datetime = null;
    },
    handleDate() {
      this.hasValue = !!this.$refs.dateTimeRef[0].datetime;
      this.$refs.dateTimeRef[0].datetime =
        this.$refs.dateTimeRef[0].datetime ??
        DateTime.now().minus({ year: 30 });
    },
    isFieldGroupMensiond(main = true, fields) {
      let availableFields = main
        ? this.getMainguestFields
        : this.getAdditionalGuestFields;
      return fields.some((field) => availableFields.includes(field));
    },
    inputHandler(event, type = null) {
      if (event && "key" in event) {
        let pattern = null;
        if (type == null) {
          pattern = /[^[a-zA-Z0-9+-]/g;
          if (event.key.replace(pattern, "") == "") {
            event.preventDefault();
          }
        } else {
          pattern = /[^[a-zA-Z0-9+-_\s@.\s*]/g;
          if (event.key == " " || event.key.replace(pattern, "") == "") {
            event.preventDefault();
          }
        }
      }
    },
    getRoomsData() {
      return this.page.entity.variants.data.find((v) => v.confirmed) &&
        this.page.entity.variants.data
          .find((v) => v.confirmed)
          .products.data.filter((r) => r.type == "room")
        ? this.page.entity.variants.data
            .find((v) => v.confirmed)
            .products.data.filter((r) => r.type == "room")
        : this.page.entity.variants.data[0].products.data.filter(
            (r) => r.type == "room"
          );
    },
    selectTime(input) {
      let time = this.$moment(input).format("HH:mm");
      if (time == "Invalid date") {
        if (this.errors.includes("checkin_time"))
          this.errors.push({ field: "checkin_time", guest: -1 });
        return;
      }
      this.requestData.checkin_time = time;
    },
    changeCountry(value, field, index) {
      this.requestData.participants[index][field] =
        (value && this.countryList.find((c) => c.text == value).value) || "";

      if (
        value &&
        this.countryList.find((c) => c.text == value).value == "ITA"
      ) {
        if (this.municipalityList.length == 0) {
          let locale = this.page.locale == "it" ? "ital" : "intl";
          this.municipalityList = require("@/models/municipalities/" +
            locale).municipalities;
        }
      }
      let newCountry =
        (value && this.countryList.find((c) => c.text == value).value) || "";
      if (field == "country_iso") {
        this.requestData.participants[index]["city"] = null;
        this.requestData.participants[index]["municipality_code"] = null;
        // delete this.requestData.participants[index]["municipality_code"]

        this.countryKeys.forEach((field) => {
          if (field + "_iso" in this.requestData.participants[index]) {
            this.requestData.participants[index][field + "_iso"] = newCountry;
            this.countryValues[index][field + "_iso"] = value;
          }
        });
      }
      if (field == "birth_country_iso") {
        this.requestData.participants[index]["birth_municipality_code"] = null;
        this.requestData.participants[index]["birth_place"] = null;
        // delete this.requestData.participants[index]["birth_municipality_code"]
      }
      if (field == "document_issuing_country_iso") {
        this.requestData.participants[index][
          "document_issuing_municipality_code"
        ] = null;
        this.requestData.participants[index]["document_issuing_city"] = null;
        // delete this.requestData.participants[index]["birth_municipality_code"]
      }
    },
    changeRegion(value, field, index) {
      this.requestData.participants[index][field] = this.provinces.find(
        (c) => c.text == value
      ).value;
    },
    changeMunicipality(value, field, index) {
      this.requestData.participants[index][field] =
        (value && value.value) || null;
      if (field == "city") {
        if (this.requestData.participants[index]["country_iso"] == "ITA") {
          this.requestData.participants[index]["municipality_code"] =
            (value && value.value) || null;
          this.requestData.participants[index]["city"] = null;
        } else {
          this.requestData.participants[index]["municipality_code"] = null;
          this.requestData.participants[index]["city"] =
            (value && value.label) || null;
        }

        Object.keys(this.cityKeys).forEach((field) => {
          this.requestData.participants[index][field] = value && value.value;
          this.requestData.participants[index][this.cityKeys[field]] =
            value && value.value;
          // this.changeMunicipality(value.value, field, index)
        });
      }
      if (field == "birth_place") {
        this.requestData.participants[index]["birth_municipality_code"] =
          (value && value.value) || null;
        this.requestData.participants[index]["birth_place"] =
          (value && value.value) || null;
      }
      if (field == "document_issuing_city") {
        this.requestData.participants[index][
          "document_issuing_municipality_code"
        ] = (value && value.value) || null;
        this.requestData.participants[index]["document_issuing_city"] =
          (value && value.value) || null;
      }
    },
    closeCheckIn() {
      this.$bvModal.hide("show-checkin");
      this.$router.push({ name: "hashed", params: { hash: this.page.hash } });
    },
    openNext(index) {
      this.validationState = true;
      this.errors = this.validate(index);
      if (this.errors.length == 0) {
        this.validationState = false;
        this.$root.$emit("bv::toggle::collapse", "guest-" + index);
        let nextIndex = ++index;
        this.$root.$emit("bv::toggle::collapse", "guest-" + nextIndex);
        if (document.getElementById("guest-" + nextIndex)) {
          document
            .getElementById("guest-" + nextIndex)
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }
        this.validationState = false;
        this.scrollIntoView = true;
      }
    },
    isValid(field, index, mandatory = false) {
      if (mandatory) {
        return this.requestData.participants[index][field] != "" ? null : false;
      }
      return null;
    },
    showPrivacyLink(string, url) {
      return url
        ? string
            .toString()
            .replace("[", '<a target="_blank" href="' + url + '">')
            .replace("]", "</a>")
        : string.toString().replace("[", "").replace("]", "");
    },
    sendCheckinInfo() {
      this.validationState = true;
      let accordionOpened = false;
      this.errors = this.validate();
      // let Interval = setInterval(() => {
      if (this.errors.length == 0) {
        // clearInterval(Interval)
      } else {
        if (!accordionOpened) {
          accordionOpened = true;
          if (this.checkinData.total_participants > 1) {
            new Promise((resolve) => {
              if (this.roomAccordionIndex != this.errors[0].guest) {
                this.$root.$emit(
                  "bv::toggle::collapse",
                  "guest-" + this.errors[0].guest
                );
              }
              resolve();
            }).then(() => {
              if (this.errors.length > 0) {
                const el = document.getElementById(
                  "guest-" + this.errors[0].guest
                );
                if (el) {
                  el.scrollIntoView({ behavior: "smooth", block: "start" });
                }
              }
            });
          }
        }
      }
      // }, 300)

      if (this.errors.length == 0) {
        this.submitting = true;
        Object.keys(this.requestData).forEach((key) => {
          if (this.requestData[key] === "") {
            // delete this.requestData[key]
            this.requestData[key] = null;
          }
        });
        this.requestData.participants?.forEach((index, i) => {
          delete this.requestData.participants[i].product_id;
          Object.keys(index).forEach((key) => {
            if (
              this.datekeys.indexOf(key) >= 0 &&
              this.requestData.participants[i][key] != "" &&
              this.requestData.participants[i][key] != null
            ) {
              this.requestData.participants[i][key] = this.$moment(
                this.requestData.participants[i][key]
              ).format("YYYY-MM-DD");
            }

            if (key == "email") {
              this.requestData.participants[i][key] =
                this.requestData.participants[i][key].trim();
            }
            if (this.requestData.participants[i][key] == "") {
              // delete this.requestData.participants[i][key]
              this.requestData.participants[i][key] = null;
            }
          });
        });
        this.requestData.vehicle_data = {
          license_plate: this.requestData.license_plate || null,
          car_brand: this.requestData.car_brand || null,
          driver_name: this.requestData.driver_name || null
        };
        let submitData = Object.assign({}, this.requestData);
        delete submitData.license_plate;
        delete submitData.car_brand;
        delete submitData.driver_name;

        if (!submitData.participants?.length) {
          this.$toast.error(this.$t("checkin_error_message"), {
            position: "bottom",
            duration: 5000
          });
          this.submitting = false;
          return;
        }
        // eslint-disable-next-line no-unreachable
        this.axios
          .post(
            process.env.VUE_APP_API_BACKEND +
              "/pub/lp/" +
              this.page.hash +
              "/checkin",
            submitData
          )
          .then(() => {
            this.$router.push({ name: "checkin-done" });
            this.submitting = false;
          })
          .catch(() => {
            this.$toast.error(this.$t("form.error_occured"), {
              position: "bottom",
              duration: 5000
            });
            this.submitting = false;
          });
      }
    },
    isMandatory(scope, type) {
      let theScope =
        scope.indexOf("_iso") >= 0 ? scope.replace("_iso", "") : scope;
      let theType =
        type === 1
          ? "mainguest"
          : type === 2
          ? "additionalguest"
          : "additionalinfo";
      return this.mandatoryFields[theType].includes(theScope);
    },
    selectRoom(room) {
      this.requestData.participants[
        this.allocateRoomDetails.index
      ].product_code = room.product_code;
      this.requestData.participants[
        this.allocateRoomDetails.index
      ].product_sku = room.product_sku;
      this.requestData.participants[this.allocateRoomDetails.index].product_id =
        room.id;
      this.requestData.participants[
        this.allocateRoomDetails.index
      ].merchant_code = room.merchant_code;
      this.requestData.participants[
        this.allocateRoomDetails.index
      ].room_allocation = room.product_code;

      // this.$bvModal.hide('allocate-room')

      this.showRoomAllocation = false;
      this.allocateRoomDetails = {};
    },
    allocateRoom(guest, index) {
      this.allocateRoomDetails = {
        guest: guest,
        index: index
      };
      this.showRoomAllocation = true;
    },
    removeRoom(guest) {
      this.showRoomAllocation = false;
      guest.product_code = "";
      guest.product_sku = "";
      guest.merchant_code = "";
      guest.room_allocation = "";
      guest.product_id = "";
    },
    selectFile(files, guestIndex) {
      let vm = this;
      files.forEach((f) => {
        vm.files[guestIndex].push(f);
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
    },
    openFileUploader(i) {
      this.$refs.imageProfile[i].$el.childNodes[0].click();
    },
    isValidDate(dateString) {
      const date = new Date(dateString);
      /**
       * getTime() returns NaN if the date is invalid
       */
      return !isNaN(date.getTime());
    },
    validate(index = false) {
      let retData = [];
      let except = {
        city: {
          field: "municipality_code",
          depending: "country_iso"
        },
        birth_place: {
          field: "birth_municipality_code",
          depending: "birth_country_iso"
        },
        document_issuing_city: {
          field: "document_issuing_municipality_code",
          depending: "document_issuing_country_iso"
        }
      };
      if (index !== false) {
        let guest = this.requestData.participants[index];
        let i = index;

        let type = guest.type === 1 ? "mainguest" : "additionalguest";
        let mandatoryData = this.mandatoryFields[type];
        let theFirstId = null;
        Object.keys(guest).forEach((key) => {
          let theKey = key.indexOf("_iso") >= 0 ? key.replace("_iso", "") : key;

          if (key == "email") {
            if (guest[key] != "") {
              if (!this.validateEmail(guest[key])) {
                if (!theFirstId) {
                  theFirstId = guest.type + "-" + i + "_" + key;
                }
                retData.push({
                  guest: i,
                  field: guest.type + "-" + i + "_" + key
                });
              }
            }
          }
          if (mandatoryData.includes(theKey)) {
            if (theKey in except) {
              let keyToCheck =
                guest[except[theKey].depending] == "ITA"
                  ? except[theKey].field
                  : theKey == "city"
                  ? "city"
                  : false;
              if (keyToCheck) {
                if (!guest[keyToCheck]) {
                  if (!theFirstId) {
                    theFirstId = guest.type + "-" + i + "_" + key;
                  }
                  retData.push({
                    guest: i,
                    field: guest.type + "-" + i + "_" + key
                  });
                }
              }
            } else {
              if(this.datekeys.includes(key)) {
                if(guest[key] && !this.isValidDate(guest[key])) {
                  retData.push({
                    guest: i,
                    field: guest.type + "-" + i + "_" + key
                  });
                }
              }

              if (!guest[key] || guest[key] == "") {
                if (!theFirstId) {
                  theFirstId = guest.type + "-" + i + "_" + key;
                }
                retData.push({
                  guest: i,
                  field: guest.type + "-" + i + "_" + key
                });
              }
            }
          }
        });
        if (theFirstId) {
          if (document.getElementById(theFirstId) && this.scrollIntoView) {
            document
              .getElementById(theFirstId)
              .scrollIntoView({ behavior: "smooth", block: "start" });
            this.scrollIntoView = false;
          }
        }
      } else {
        let pData = this.requestData.participants;
        pData?.forEach((guest, i) => {
          let type = guest.type === 1 ? "mainguest" : "additionalguest";
          let mandatoryData = this.mandatoryFields[type];

          Object.keys(guest).forEach((key) => {
            let theKey =
              key.indexOf("_iso") >= 0 ? key.replace("_iso", "") : key;
            if (key == "email") {
              if (guest[key] != "") {
                if (!this.validateEmail(guest[key])) {
                  retData.push({
                    guest: i,
                    field: guest.type + "-" + i + "_" + key
                  });
                }
              }
            }
            if (mandatoryData.includes(theKey)) {
              if (theKey in except) {
                let keyToCheck =
                  guest[except[theKey].depending] === "ITA"
                    ? except[theKey].field
                    : theKey === "city"
                    ? "city"
                    : false;
                if (keyToCheck) {
                  if (!guest[keyToCheck]) {
                    retData.push({
                      guest: i,
                      field: guest.type + "-" + i + "_" + key
                    });
                  }
                }
              } else {
                if(this.datekeys.includes(key)) {
                  if(guest[key] && !this.isValidDate(guest[key])) {
                    retData.push({
                      guest: i,
                      field: guest.type + "-" + i + "_" + key
                    });
                  }
                }
                if (!guest[key]) {
                  retData.push({
                    guest: i,
                    field: guest.type + "-" + i + "_" + key
                  });
                }
              }
            }
          });
        });
      }
      if (index === false) {
        this.mandatoryFields.additionalinfo.forEach((f) => {
          if (this.requestData[f] == "") {
            retData.push({ guest: -1, field: f });
          }
        });
        if (!this.acceptTerms) {
          retData.push({ guest: -1, field: "acceptTerms" });
        }
      }
      return retData;
    },
    changeValueOfAdditionalGuest(value, field, index) {
      if (!value) return;

      if(index === 0 && this.firstLoadSelect) {
        for (let i = 1; i < this.requestData.participants.length; i++) {
          switch (field) {
            case "birth_country_iso": {
              this.changeCountry(value, field, i);
              this.countryValues[i].birth_country_iso = value;
              break;
            }
            case "nationality_iso": {
              this.changeCountry(value, field, i);
              this.countryValues[i].nationality_iso = value;
              break;
            }
            case "birth_municipality_code": {
              this.requestData.participants[i][field] = value.value;
              this.requestData.participants[i].birth_place = null;
              break;
            }
            case "country_iso": {
              this.changeCountry(value, field, i);
              break;
            }
            default: {
              this.requestData.participants[i][field] = value;
            }
          }
        }
        this.firstLoadSelect = false;
        this.$emit('checkinSelect', this.firstLoadSelect);
      }
    },
    adjustHotelLogo() {
      let hotelLogo = document.getElementsByClassName("hotel-logo")[0];
      this.fixImageInHeight = hotelLogo.clientHeight > hotelLogo.clientWidth;
    }
  }
};
</script>

<style>
.inputWhite {
  color: white !important;
}

.inputBlack {
  color: black !important;
}
</style>
<style scoped>
.lp-label {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

div >>> h2 {
  font-size: 28px;
  font-weight: 600;
  color: var(--general_font-color, #414141);
  margin-bottom: 10px;
}

div >>> h3 {
  font-size: 21px;
  font-weight: 600;
  color: var(--general_font-color, #414141);
}

div >>> h5 {
  color: #1c1c1c;
  font-size: 15px;
  font-weight: bold;
}

div >>> legend {
  color: var(--general_font-color, #414141) !important;
  font-size: 13px !important;
}

div >>> .custom-control-label {
  font-size: 15px !important;
  cursor: pointer;
}

.box-wrapper {
  width: 100%;
  height: 64px;
  border: 1px dashed #e3e3e3;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.box-wrapper.selected {
  border: 2px solid #1d3544;
}

.sub-title {
  font-size: 15px;
  color: #414141;
}

.sub-desc {
  font-size: 13px;
  color: #414141;
}

.mandatory {
  /*color: red;*/
}

#show-checkin-back {
  position: absolute;
  top: 20px;
  left: 40px;
}

.vue-tel-input >>> .vti__dropdown {
  padding: 7px 2px;
}

.vue-tel-input >>> .vti__input {
  padding-left: 5px;
}

.vue-tel-input {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
}

.vue-tel-input >>> input {
  border-radius: 0.3rem;
  color: #495057 !important;
}

.isInvalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  transition: border-color 0.15s ease-in-out;
}

.isValid {
  border: 1px solid rgba(68, 168, 248, 0.75) !important;
  transition: border 0.15s ease-in-out;
}

.wrapper-phone-number-invalid {
  border-radius: 0.36rem;
  box-shadow: rgba(232, 1, 1, 0.2) 0px 0px 0px 3px;
  transition: box-shadow 0.15s ease-in-out;
}

.wrapper-phone-number-valid {
  border-radius: 0.36rem;
  box-shadow: rgba(107, 181, 241, 0.45) 0px 0px 0px 3px;
  transition: box-shadow 0.15s ease-in-out;
}

.label-table {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0;
  position: relative;
}

#show-checkin-back a {
  color: #414141;
}

>>> .vs__selected {
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

>>> .vs__actions {
  padding: 4px 6px 0 6px;
}

@media (max-width: 768px) {
  #show-checkin-back {
    left: 15px;
  }
}

@media only screen and (max-width: 998px) {
  .label-table {
    top: 17px;
  }
}

@media (min-width: 999px) {
  .label-table {
    top: 35px;
  }
}
</style>