export const currenciesList = [
  {
    name: "Euro",
    symbol: "€",
    symbolNative: "€",
    decimalDigits: 2,
    rounding: 0,
    code: "EUR",
    namePlural: "euros",
    locale: "de-DE"
  },
  {
    name: "Swiss Franc",
    symbol: "CHF",
    symbolNative: "CHF",
    decimalDigits: 2,
    rounding: 0.05,
    code: "CHF",
    namePlural: "Swiss francs",
    locale: "de-CH"
  },
  {
    name: "British Pound Sterling",
    symbol: "£",
    symbolNative: "£",
    decimalDigits: 2,
    rounding: 0,
    code: "GBP",
    namePlural: "British pounds sterling",
    locale: "en-GB"
  },
  {
    name: "US Dollar",
    symbol: "$",
    symbolNative: "$",
    decimalDigits: 2,
    rounding: 0,
    code: "USD",
    namePlural: "US dollars",
    locale: "en-US"
  }
];