<template>
  <div>
    <loading
      :active.sync="isRedirected"
      :can-cancel="true"
      :is-full-page="true"
    />
  </div>
</template>

<script setup>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { useBookingMethods } from "@/composables/useBookingMethods";

const { isRedirected } = useBookingMethods();
</script>