<template>
  <div class="summary-footer">
    <div
      class="price-wrapper d-flex justify-content-start justify-content-lg-end mt-5 flex-row flex-lg-column"
    >
      <div>
        <div class="price-total-label">
          <span
            v-html="
              placeholder('%label.amount%', $t('entity.price.total_price'))
            "
          />
        </div>
        <div class="price-total-value">
          {{ formatPrice(Number(selectedVariant.total_amount) + calcTotalAmountOfServices()) }}
        </div>
      </div>
      <template v-if="showDepositAmountComputed">
        <div>
          <div class="price-total-label deposit">
            <span
              v-html="
                placeholder(
                  '%label.deposit%',
                  $t('entity.price.deposit_amount')
                )
              "
            />
          </div>
          <div
            :class="{
              deposit:
                selectedVariant.deposit_balance ===
                  selectedVariant.deposit_amount ||
                selectedVariant.deposit_balance === 0
            }"
            class="price-total-value"
          >
            {{ recalculateDepositIfUpselling() }}
          </div>
        </div>
      </template>
      <template
        v-if="
          selectedVariant.deposit_balance !== selectedVariant.deposit_amount &&
          selectedVariant.deposit_balance !== 0
        "
      >
        <div>
          <div class="price-total-label deposit">
            <span
              v-html="
                placeholder(
                  '%label.deposit_balance%',
                  $t('entity.price.deposit_balance')
                )
              "
            />
          </div>
          <div class="price-total-value deposit">
            {{
              selectedVariant.deposit_balance &&
              formatPrice(selectedVariant.deposit_balance)
            }}
          </div>
        </div>
      </template>
    </div>
    <div class="mt-4">
      <div
        v-if="
          $moment(new Date()).diff(
            $moment(selectedVariant.cancelpolicy_data.refundable_until).format()
          ) < 0 || selectedVariant.deposit_amount === 0
        "
        class="price-extras d-none d-lg-inline-block"
      >
        <div v-if="selectedVariant.deposit_amount === 0" class="d-inline-block">
          <span
            v-if="page.design_data.config?.content?.badge_deposit"
            v-html="
              placeholder(
                '%label.deposit_policy.no_deposit_needed%',
                $t('entity.no_deposit_needed')
              )
            "
            class="badge badge-secondary no_deposit-badge ml-0"
          />
        </div>
        <div
          v-if="
            $moment(new Date()).diff(
              $moment(
                selectedVariant.cancelpolicy_data.refundable_until
              ).format()
            ) < 0
          "
          class="d-inline-block"
        >
          <span
            v-if="page.design_data.config?.content?.badge_cancellation"
            class="badge badge-success cancel_policy-badge ml-0"
          >
            <span
              v-html="
                placeholder(
                  '%label.cancel_policy.free_cancellation%',
                  $t('entity.cancel_policy.free_cancellation_until')
                )
              "
          /></span>
        </div>
      </div>
      <div class="show-table mt-0">
        <a
          :class="{
            'custom-italic': page.design_data.style?.rooms?.storno_italic,
            'custom-bold': page.design_data.style?.rooms?.storno_bold
          }"
          href="#"
          @click.prevent="seeDetails()"
        >
          {{ $t("entity.price.show_price_details") }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, defineEmits } from "vue";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useUpselling } from "@/composables/useUpselling";

const {
  placeholder,
  formatPrice,
  page,
  selectedVariant,
  showDepositAmountComputed
} = useCheckoutData();
const {
  calcTotalAmountOfServices,
  recalculateDepositIfUpselling
} = useUpselling();

const { proxy } = getCurrentInstance();

const emit = defineEmits(["hideBtnBook"]);

function seeDetails() {
  proxy.$bvModal.show("show-price-table");
  emit("hideBtnBook");
}
</script>

<style scoped></style>