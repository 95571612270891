var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100"},[_c('b-card',{staticClass:"variant-card",class:{ active: _vm.setActiveClass(), 'variant-card-mobile': _vm.isMobileView },attrs:{"no-body":""},on:{"click":function($event){return _vm.$emit('selectVariant', {
        variant: _vm.variantData.variant,
        index: _vm.variantData.index
      })}}},[(_vm.showHighlightBadgeComputed)?_c('div',{staticClass:"variant-highlight-label"},[_c('b-badge',{staticClass:"ml-2 highlight-badge",class:{
          'custom-italic':
            'badges' in _vm.designStyle &&
            'highlight_italic' in _vm.designStyle.badges &&
            _vm.designStyle.badges.highlight_italic,
          'custom-bold':
            'badges' in _vm.designStyle &&
            'highlight_bold' in _vm.designStyle.badges &&
            _vm.designStyle.badges.highlight_bold
        },attrs:{"variant":"info"},domProps:{"innerHTML":_vm._s(
          _vm.placeholder(
            '%label.offer.variant.highlight%',
            _vm.$t('entity.highlight')
          )
        )}})],1):(_vm.variantData.variant.badges)?_vm._l((_vm.variantData.variant.badges.data),function(marker,idx){return _c('div',{key:idx,class:{'badge-position': _vm.isMobileView}},[_c('div',{staticClass:"position-relative",style:({ top: 50 * idx + 'px' })},[_c('div',{staticClass:"offermarker-wrapper"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center offermarker position-absolute",class:'badge-' + marker.style.type,style:([_vm.getOfferMarkerStyle(marker.style)])},[_vm._v(" "+_vm._s(marker.title)+" ")]),(marker.style.type == 'ribbon')?_c('div',{staticClass:"ribbon-triangles"},[_c('div',{staticClass:"ribbon-triangle-top",style:([
                { borderTop: 'solid 22px' + marker.style['background-color'] }
              ])}),_c('div',{staticClass:"ribbon-triangle-bottom",style:([
                {
                  borderBottom:
                    'solid 22px' + marker.style['background-color']
                }
              ])})]):(marker.style.type == 'banner')?_c('div',{staticClass:"banner-triangle",style:({
              borderTop: '10px solid ' + marker.style['background-color']
            })}):_vm._e()])])])}):_vm._e(),(_vm.isMobileView)?_c('div',{staticClass:"variant-preheader-mobile d-flex justify-content-between py-1 px-2 mb-2 mt-1"},[_c('div',[(_vm.variantsList.length != 1)?_c('div',{staticClass:"offer-details-entity_of pl-1 font-weight-bold"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.placeholder('%label.offer.variant%', _vm.$t('entity.variant')))}}),_vm._v(" "+_vm._s(_vm.$t("entity.entity_of", { n: _vm.variantsSlider + 1, count: _vm.variantsList.length }))+" ")]):_vm._e()]),_c('div',[_vm._v(_vm._s(_vm.formatPrice(_vm.variantData.variant.total_amount)))])]):_vm._e(),_c('b-card-header',{staticClass:"variant-image-wrapper",class:{'variant-image-wrapper-mobile mx-0': _vm.isMobileView},attrs:{"text-tag":"div"}},[_c('div',{staticClass:"variant-image",class:{'variant-image-mobile': _vm.isMobileView},style:({
          'background-image': _vm.getVariantImageURL
        })},[_c('b-aspect',{attrs:{"aspect":"16:9"}},[(_vm.variantTitleComputed)?_c('div',{staticClass:"variant-title"},[_vm._v(" "+_vm._s(_vm.variantTitleComputed)+" ")]):_vm._e()])],1)]),_c('b-card-text',{staticClass:"variant-card-content",attrs:{"text-tag":"div"}},[(_vm.showMerchentsData)?_c('div',_vm._l((_vm.merchentsData),function(merchantData,idx){return _c('div',{key:idx},[_c('div',{staticClass:"variant-hotel-name"},[_vm._v(" "+_vm._s(merchantData.name)+" ")]),_c('stars-rating',{staticClass:"mt-2 mb-4",attrs:{"rating":merchantData.properties.stars}})],1)}),0):_vm._e(),_c('div',{staticClass:"variant-number",class:{'mb-4': _vm.isMobileView},domProps:{"innerHTML":_vm._s(
          _vm.variantData.variant.title
            ? _vm.variantData.variant.title
            : _vm.placeholder('%label.offer.title%', _vm.$t('entity.offer')) +
              ': ' +
              _vm.page.entity.slug
        )}}),_vm._l((Object.keys(_vm.groupProductsComputed)),function(productKey){return _c('div',{key:'key-' + productKey},_vm._l((_vm.groupProductsComputed[productKey]),function(product,index){return _c('div',{key:product.id,staticClass:"variant-summary"},[(index == 0)?_c('div',{staticClass:"variant-summary-group",class:{'mb-3': _vm.isMobileView}},[_c('div',{staticClass:"summary-label",class:{'mb-1': _vm.isMobileView}},[_vm._v(" "+_vm._s(_vm.$t("entity.arrival_daparture"))+" ")]),_c('div',{staticClass:"summary-value"},[_vm._v(" "+_vm._s(_vm.$moment(product.from_date).format("dd. L"))+" - "+_vm._s(_vm.$moment(product.to_date).format("dd. L"))+" "),_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.$tc( "entity.nights_x", _vm.$moment(product.to_date).diff( _vm.$moment(product.from_date), "days" ), { x: _vm.$moment(product.to_date).diff( _vm.$moment(product.from_date), "days" ) } ))+" ")])])]):_vm._e(),_c('div',{staticClass:"variant-summary-group"},[_c('div',{staticClass:"summary-label",class:{'mb-1': _vm.isMobileView}},[_vm._v(" "+_vm._s(_vm.$tc("entity.room.rooms", 1) + (_vm.variantData.variant.products.data.length > 1 ? " " + product.customIndex : ""))+" ")]),_c('div',{staticClass:"summary-value"},[(
                  product.pu_participants_details.filter(
                    (p) => p.age_code == 'adult'
                  ).length > 0
                )?_c('span',[_vm._v(" "+_vm._s(_vm.$tc( "entity.room.adult_pl", product.pu_participants_details.filter( (p) => p.age_code == "adult" ).length, { count: product.pu_participants_details.filter( (p) => p.age_code == "adult" ).length } ))+" ")]):_vm._e(),(
                  product.pu_participants_details.filter(
                    (p) =>
                      p.age_code == 'child' ||
                      p.age_code == 'infant' ||
                      p.age_code == 'teenager'
                  ).length > 0
                )?_c('span',[_vm._v(" -"+_vm._s(_vm.$tc( "entity.room.child_pl", product.pu_participants_details.filter( (p) => p.age_code == "child" || p.age_code == "infant" || p.age_code == "teenager" ).length, { count: product.pu_participants_details.filter( (p) => p.age_code == "child" || p.age_code == "infant" || p.age_code == "teenager" ).length } ))+" ")]):_vm._e(),_c('span',[_vm._v("- "),_c('span',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.variantData.variant.properties.data.find( (item) => item.product_type == "mealplan" ) && _vm.variantData.variant.properties.data.find( (item) => item.product_type == "mealplan" ).title)+" ")])])]),_c('div',{staticClass:"summary-value"},[_vm._v(" "+_vm._s(product.quantity > 1 ? product.quantity + "x" : "")+" "+_vm._s(product.title)+" ")])])])}),0)}),(
          _vm.$moment(new Date()).diff(
            _vm.$moment(
              _vm.variantData.variant.cancelpolicy_data.refundable_until
            ).format()
          ) < 0
        )?_c('div',{staticClass:"variant-extras extras"},[(
            _vm.$moment(new Date()).diff(
              _vm.$moment(
                _vm.variantData.variant.cancelpolicy_data.refundable_until
              ).format()
            ) < 0 &&
            'content' in _vm.designConfig &&
            'badge_cancellation' in _vm.designConfig.content &&
            _vm.designConfig.content.badge_cancellation
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"badge badge-success cancel_policy-badge alt",attrs:{"title":_vm.placeholder(
              '%label.cancel_policy.free_cancellation_until%',
              _vm.$t('entity.cancel_policy.free_cancellation_until')
            ) +
            ' ' +
            _vm.formatDate(_vm.variantData.variant.cancelpolicy_data.refundable_until)},domProps:{"innerHTML":_vm._s(
            _vm.placeholder(
              '%label.cancel_policy.free_cancellation%',
              _vm.$t('entity.cancel_policy.free_cancellation')
            )
          )}}):_vm._e()]):_vm._e()],2),_c('b-card-footer',{staticClass:"variant-card-footer mt-auto",class:{'variant-card-footer-mobile': _vm.isMobileView}},[_c('div',{staticClass:"variant-price",class:{'mb-4 pb-1': _vm.isMobileView}},[_c('span',{staticClass:"variant-price-amount"},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.variantData.variant.total_amount))+" ")]),(Object.keys(_vm.groupProductsComputed).length == 1)?[_c('span',{staticClass:"variant-price-amount"},[_vm._v("/")]),_c('span',{staticClass:"variant-price-nights"},[_vm._v(" "+_vm._s(_vm.$tc( "entity.nights_x", _vm.$moment(_vm.variantData.variant.products.data[0].to_date).diff( _vm.$moment(_vm.variantData.variant.products.data[0].from_date), "days" ), { x: _vm.$moment( _vm.variantData.variant.products.data[0].to_date ).diff( _vm.$moment(_vm.variantData.variant.products.data[0].from_date), "days" ) } ))+" ")])]:_vm._e()],2),(_vm.variantData.confirmed)?[_c('b-btn',{staticClass:"select-btn"},[(_vm.selected == _vm.variantData.variant.ref)?_c('b-icon',{staticClass:"selected-icon rounded-circle",attrs:{"icon":"check-circle"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t( _vm.selected == _vm.variantData.variant.ref ? "entity.selected" : "entity.select" ))+" ")],1)]:[(_vm.variantData.variant.confirmed)?_c('b-btn',{staticClass:"select-btn selected"},[_c('b-icon',{staticClass:"selected-icon rounded-circle",attrs:{"icon":"check-circle"}}),_vm._v(" "+_vm._s(_vm.$t("entity.selected"))+" ")],1):_vm._e()]],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }