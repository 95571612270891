import { useBreakpoints as composable } from "@vueuse/core";

export function useBreakpoints() {
  const breakpoints = composable({
    mobile: 769,
    tabletP: 1025,
    tabletL: 1367
  });
  const isMobile = breakpoints.smaller("tabletP");
  const isDesktop = breakpoints.greaterOrEqual("tabletL");

  return { isMobile, isDesktop };
}