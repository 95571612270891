var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('b-container',{staticClass:"image-box-component px-0"},[_c('b-row',{staticClass:"image-box-row"},[_c('b-col',{staticClass:"image-box-item",attrs:{"cols":"12"}},[_c('ul',{staticClass:"highlight-accordion",class:'items-count-' + _vm.items.length},_vm._l((_vm.items),function(tab,index){return _c('li',{key:'acc_' + tab.id,class:[
            'highlight-accordion__tile',
            _vm.activeTab == index
              ? 'highlight-accordionT--active'
              : 'highlight-accordionT--inactive'
          ],style:(`background:  url('${
            _vm.getMedia(tab, 'picture').public_url + '?d=800x600'
          }') no-repeat center center; background-size: cover;`),on:{"click":function($event){index !== _vm.activeTab && _vm.openTab(index)}}},[_c('div',{class:[
              !_vm.desktopSize ? 'scroll-wrapper' : 'highlight-accordion__content__wrapper',
              {
                'scroll-wrapper-inactive': !_vm.desktopSize && _vm.activeTab !== index
              }
            ]},[_c('div',{staticClass:"button-wrapper",class:{
              square:
                _vm.designData.style &&
                'radius' in _vm.designData.style.general &&
                _vm.designData.style.general.radius != '' &&
                parseInt(_vm.designData.style.general.radius) == 0
            }},[(_vm.activeTab < index)?_c('b-icon',{attrs:{"icon":"chevron-left"}}):_c('b-icon',{attrs:{"icon":"chevron-right"}})],1),_c('div',{staticClass:"highlight-accordion__title_column pr-1"},[_c('div',{staticClass:"highlight-accordion__tile__size"},[_c('div',{staticClass:"highlight-accordion__tile__wrapper"},[(_vm.activeTab === index)?_c('h4',{staticClass:"highlight-accordion__tile__title"},[_vm._v(" "+_vm._s(tab.content_data.data.components[0].title)+" ")]):_c('h4',{staticClass:"highlight-accordion__tile__title"},[_c('TruncateText',{attrs:{"content":tab.content_data.data.components[0].title,"maxLength":_vm.truncateLength}})],1)])])]),_c('div',{staticClass:"highlight-accordion__tile__content"},[_c('div',{staticClass:"highlight-accordion__tile__excerpt",class:{
                'highlight-accordion-no-button':
                  !tab.content_data.data.components[0].config.cta ||
                  !tab.content_data.data.components[0].content_json.cta_label
              }},[_c('p',{staticClass:"mb-0 pr-1",domProps:{"innerHTML":_vm._s(tab.content_data.data.components[0].content_html)}})]),(
                _vm.desktopSize &&
                tab.content_data.data.components[0].config.cta &&
                tab.content_data.data.components[0].content_json.cta_label
              )?_c('div',{staticClass:"mt-3 text-right"},[_c('b-btn',{staticClass:"btn btn-success highlight_cta",on:{"click":function($event){return _vm.openURL(tab.content_data.data.components[0])}}},[_vm._v(" "+_vm._s(tab.content_data.data.components[0].content_json.cta_label)+" ")])],1):_vm._e()])]),(
                _vm.activeTab == index && !_vm.desktopSize &&
                tab.content_data.data.components[0].config.cta &&
                tab.content_data.data.components[0].content_json.cta_label
              )?_c('div',{staticClass:"highlight-accordion__button text-right"},[_c('b-btn',{staticClass:"btn btn-success highlight_cta",on:{"click":function($event){return _vm.openURL(tab.content_data.data.components[0])}}},[_vm._v(" "+_vm._s(tab.content_data.data.components[0].content_json.cta_label)+" ")])],1):_vm._e()])}),0)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }