import axios from "axios";
import { getCurrentInstance, ref } from "vue";
import { useCheckoutData } from "@/composables/useCheckoutData";
import { useUpselling } from "@/composables/useUpselling";

const paymentHTML = ref("");
const checkoutErrors = ref([]);
const firstLoadValidFlag = ref(true);
const selectedPaymentMethod = ref(false);
const isRedirected = ref(false);

export function useBookingMethods() {
  const { proxy } = getCurrentInstance();

  const {
    validateEmail,
    backendURL,
    is,
    page,
    request,
    selectedVariant,
    reservation_process
  } = useCheckoutData();

  const { selectedServices } = useUpselling();

  let coupon = proxy._provided.coupon;
  const lpLocale = proxy._provided.lpLocale;
  const complete_payment = proxy._provided.complete_payment;
  const renderData = (val) => proxy._provided.renderData(val);

  const isLoading = ref(false);

  function validateRequestForm(rField = null) {
    const mandatory = page.design_data.config.checkout?.mandatory;
    const indexExist = checkoutErrors.value.indexOf(rField);
    if (!rField) {
      mandatory?.forEach((field) => {
        if (field === "email") {
          request.email = request.email.trim();
          if (!validateEmail(request.email)) {
            checkoutErrors.value.push("email");
          }
        } else if (!request[field]) {
          checkoutErrors.value.push(field);
        }
      });
    } else {
      if (rField === "email") {
        request.email = request.email.trim();
        if (!validateEmail(request[rField])) {
          checkoutErrors.value.push(rField);
        } else {
          indexExist >= 0 && checkoutErrors.value.splice(indexExist, 1);
        }
      }
      if (!request[rField]) {
        if (mandatory?.find((item) => item === rField)) {
          checkoutErrors.value.push(rField);
        }
      } else {
        indexExist >= 0 && checkoutErrors.value.splice(indexExist, 1);
      }
    }
    checkoutErrors.value = [...new Set(checkoutErrors.value)];
  }

  function bookAction(submit = false) {
    proxy.$bvModal.hide("show-price-table");
    if (
      reservation_process.value === "twosteps" &&
      selectedVariant.value.booking_action !== "payment_balance" &&
      !is("checkin")
    ) {
      if (!submit) {
        proxy.$router.push({ name: "checkout" });
        return;
      }
    }
    switch (selectedVariant.value.booking_action) {
      case "payment_balance":
        if (complete_payment.value) {
          process_payment(submit);
          return;
        }
        new Promise((resolve) => {
          complete_payment.value = true;
          coupon.value.modal = true;
          coupon.value.page = "remaining";
          resolve();
        }).then(() => {
          proxy.$bvModal.show("coupon-box");
        });
        break;
      case "payment":
        process_payment(submit);
        break;
      case "confirmation":
        finalizeBooking(!submit);
        break;
      case "expired":
        proxy.$bvModal.show("booking-after-arrival");
        break;
      default:
        if (is("checkin")) {
          const mode = page.checkin_settings?.mode || "Native";
          const link = page.checkin_settings?.deeplink || null;

          if (mode === "Deeplink" && link) {
            window.open(link, "_blank").focus();
          } else {
            proxy.$router.push({
              name: "checkin",
              params: { hash: page.hash }
            });
          }
        } else {
          proxy.$router.push({ hash: "checkout" });
        }
    }
  }

  function finalizeBooking(skipValidation = false) {
    validateRequestForm();
    firstLoadValidFlag.value = false;
    if (!skipValidation && checkoutErrors.value.length > 0) {
      return;
    }
    isLoading.value = true;
    request.email = request.email.trim(); //removes spaces before and after string
    const { note, ...rest } = request;
    const payload = {
      note,
      variant_ref: selectedVariant.value.ref,
      customer: rest
    };
    if (!payload.customer.lang) payload.customer.lang = lpLocale;
    if (
      page?.upselling_enabled &&
      page?.entity_id &&
      selectedServices?.value?.length
    ) {
      const services = selectedServices.value.map((serv) => {
        return { id: serv.item.id, quantity: serv.quantity };
      });
      payload.additional_services = services;
    }
    axios
      .post(backendURL() + page.hash + "/confirm", payload)
      .then((response) => {
        renderData(response.data.data);
        proxy.$bvModal.hide("show-checkout");
        proxy.$router.push({ hash: "" });
        proxy.$bvModal.show("show-checkout-thank-you");
      })
      .catch(() => {
        proxy.$toast.error(
          "<strong>" +
            proxy.$t("form.payment.error.title") +
            "</strong><br />" +
            proxy.$t("form.payment.error.text"),
          {
            position: "bottom",
            duration: 10000
          }
        );
      })
      .then(() => {
        isLoading.value = false;
      });
  }

  function process_payment(submit) {
    validateRequestForm();
    firstLoadValidFlag.value = false;
    if (!complete_payment.value && submit && checkoutErrors.value.length > 0) {
      return;
    }
    if (
      selectedPaymentMethod.value &&
      selectedVariant.value.paymethods.data.find(
        (pm) => pm.method_id === selectedPaymentMethod.value
      ).provider_type === "coupon"
    ) {
      new Promise((resolve) => {
        coupon.value.modal = true;
        resolve();
      }).then(() => {
        coupon.value.page = "default";
        proxy.$bvModal.show("coupon-box");
      });
      return;
    }
    if (!selectedPaymentMethod.value) {
      proxy.$bvModal.show("show-paymethods");
      return;
    }
    isLoading.value = true;
    const { note, ...rest } = request;
    let payload = {
      note,
      payment: {
        method_id: selectedPaymentMethod.value,
        return_url:
          process.env.VUE_APP_FRONTEND +
          "/" +
          page.hash +
          "?action=payment_confirmed",
        cancel_url:
          process.env.VUE_APP_FRONTEND + "/" + page.hash + "?action=cancelled"
      },
      variant_ref: selectedVariant.value.ref
    };
    if (
      page?.upselling_enabled &&
      page?.entity_id &&
      selectedServices?.value?.length
    ) {
      const services = selectedServices.value.map((serv) => {
        return { id: serv.item.id, quantity: serv.quantity };
      });
      payload.additional_services = services;
    }
    if (reservation_process.value === "twosteps") {
      payload.customer = rest;
      if (!payload.customer.lang) payload.customer.lang = lpLocale;
    }
    axios
      .post(backendURL() + page.hash + "/payment", payload)
      .then((response) => {
        switch (response.data.meta.redirect_type) {
          case "GET":
            window.location.href = response.data.meta.redirect_url;
            break;
          case "POST":
            var f = document.createElement("form");
            f.action = response.data.meta.redirect_url;
            f.method = "POST";
            Object.keys(response.data.meta.redirect_data).forEach((data) => {
              let i = document.createElement("input");
              i.type = "hidden";
              i.name = data;
              i.value = response.data.meta.redirect_data[data];
              f.appendChild(i);
            });
            document.body.appendChild(f);
            f.submit();
            break;
          case "HTML":
            if (response.data.meta?.redirect_mode === "REPLACE") {
              document.open();
              document.write(
                decodeHTMLEntities(response.data.meta.redirect_html)
              );
              document.close();
            } else {
              paymentHTML.value = decodeHTMLEntities(
                response.data.meta.redirect_html
              );
              proxy.$bvModal.show("payment-html");
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          isRedirected.value = true;
          setTimeout(() => {
            proxy.$bvModal.hide("show-checkout");
            proxy.$router.push({ name: "hashed", params: { hash: page.hash } });
            window.location.reload();
          }, 500);
        } else {
          proxy.$toast.error(
            "<strong>" +
              proxy.$t("form.payment.error.title") +
              "</strong><br />" +
              proxy.$t("form.payment.error.text"),
            {
              position: "bottom",
              duration: 10000
            }
          );
        }
      })
      .finally(() => {
        isLoading.value = false;
      });
  }

  function couponAction(action = null) {
    coupon.value.progress = true;
    if (action == "redeem") {
      let payload = {
        payment: {
          method_id: selectedPaymentMethod.value,
          return_url:
            process.env.VUE_APP_FRONTEND +
            "/" +
            page.hash +
            "?action=payment_confirmed",
          cancel_url:
            process.env.VUE_APP_FRONTEND +
            "/" +
            page.hash +
            "?action=coupon_cancelled",
          payload: { couponCode: coupon.value.code }
        },
        variant_ref: selectedVariant.value.ref
      };
      if (
        page?.upselling_enabled &&
        page?.entity_id &&
        selectedServices?.value?.length
      ) {
        const services = selectedServices.value.map((serv) => {
          return { id: serv.item.id, quantity: serv.quantity };
        });
        payload.additional_services = services;
      }
      axios
        .post(backendURL() + page.hash + "/payment", payload)
        .then((response) => {
          if (response.data.data.status === "initialized") {
            coupon.value.amount = response.data.data.amount;
            coupon.value.confirmURL = response.data.meta.redirect_url;
            if (
              response.data.data.amount < selectedVariant.value.deposit_balance
            ) {
              coupon.value.page = "insufficient";
            } else {
              window.location.href = coupon.value.confirmURL;
            }
          } else if (response.data.data.status === "failed") {
            coupon.value.page = "invalid";
          } else {
            proxy.$toast.error(response.data.data.gateway_message, {
              position: "bottom",
              duration: 10000
            });
          }
          coupon.value.progress = false;
        })
        .catch(() => {
          coupon.value.progress = false;
        });
    }
    if (action == "continue") {
      window.location.href = coupon.value.confirmURL;
    }
  }

  function decodeHTMLEntities(text) {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  }

  function selectedPaymentValue(data) {
    selectedPaymentMethod.value = data;
  }

  return {
    validateRequestForm,
    bookAction,
    finalizeBooking,
    selectedPaymentValue,
    checkoutErrors,
    selectedPaymentMethod,
    paymentHTML,
    complete_payment,
    isLoading,
    couponAction,
    coupon,
    firstLoadValidFlag,
    isRedirected
  };
}
