import { countryList } from "@/models/countryList";
import { currenciesList } from "@/models/currenciesList";

export const lpMixins = {
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });

    //Check availability of ShadowRoot
    if (document.head.createShadowRoot || document.head.attachShadow) {
      this.shadowRootEnabled = true;
    }

    this.countries = countryList
      .map((country) => ({
        value: country.alpha3,
        code: country.code,
        pinned: country.pinned,
        text:
          typeof country.translations.data[this.$i18n.locale] != "undefined"
            ? country.translations.data[this.$i18n.locale].name
            : country.translations.data.en.name
      }))
      .sort(function (a, b) {
        if (a.pinned < b.pinned) return +1;
        if (a.pinned > b.pinned) return -1;
        if (a.text > b.text) return +1;
        if (a.text < b.text) return -1;
        return 0;
      })
      .map((country) => ({
        value: country.value,
        code: country.code,
        pinned: country.pinned,
        text: country.text
      }));
    this.mode = this.query_options.mode || "normal";
  },
  data() {
    return {
      shadowRootEnabled: false,
      pageReady: false,
      query_options: this.queryOptions(),
      countries: [],
      mode: null,
      debug: process.env.NODE_ENV == "development" || this.$route.query.debug, //process.env.NODE_ENV=='development'
      debug_node_env: process.env.NODE_ENV,
      env: this.$route.query.env || false, //process.env.NODE_ENV=='development'
      debug_bar: false,
      hash:
        this.$route.name == "version_hashed"
          ? this.$route.params.version_hash
          : this.$route.params.hash || null,
      hash_type: this.$route.name == "version_hashed" ? "versioned" : "normal",
      windowWidth: window.innerWidth,
      currency: {
        defaultCurrency: "EUR",
        availableCurrencies: currenciesList
      },
      whatsappURL: null,
      datetimeFormats: [
        {
          lang: "de",
          longForm: "dddd, Do MMMM YYYY"
        },
        {
          lang: "en",
          longForm: "dddd, Do MMMM YYYY"
        },
        {
          lang: "fr",
          longForm: "dddd, Do MMMM YYYY"
        },
        {
          lang: "it",
          longForm: "dddd, DD MMMM YYYY"
        },
        {
          lang: "ru",
          longForm: "dddd, Do MMMM YYYY"
        },
        {
          lang: "es",
          longForm: "dddd, Do MMMM YYYY"
        }
      ],
      scopes: {
        expired: [
          "crm.campaign.order.unconfirmed",
          "crm.campaign.offer.unconfirmed"
        ],
        declined: ["crm.campaign.request.declined"],
        payment_available: [
          "crm.campaign.order.unconfirmed",
          "crm.campaign.offer.unconfirmed"
        ],
        option: [
          "crm.campaign.order.unconfirmed",
          "crm.campaign.order.confirmed_byguest",
          "crm.campaign.order.paid_byguest"
        ],
        offer: [
          "crm.campaign.request",
          "crm.campaign.request.declined",
          "crm.campaign.request.waitinglist",
          "crm.campaign.offer.unconfirmed",
          "crm.campaign.offer.confirmed_byguest",
          "crm.campaign.offer.reminder",
          "crm.campaign.offer.followup",
          "crm.campaign.offer.expiring",
          "crm.campaign.offer.paid_byguest"
        ],
        order: [
          "crm.campaign.order.confirmed",
          "crm.campaign.order.pre_experience",
          "crm.campaign.order.post_experience"
        ],
        confirmed: [
          "crm.campaign.order.confirmed"
          // 'crm.campaign.order.confirmed_byguest',
        ],
        spa_confirmed: [
          "crm.campaign.order.confirmed",
          "crm.campaign.order.cancellation"
        ],
        confirmed_byguest: [
          "crm.campaign.order.confirmed_byguest",
          "crm.campaign.offer.confirmed_byguest",
          "crm.campaign.offer.paid_byguest",
          "crm.campaign.order.paid_byguest"
        ],
        checkin: ["crm.campaign.order.confirmed"],
        disabled_button: [
          "crm.campaign.offer.confirmed_byguest",
          "crm.campaign.order.confirmed_byguest",
          "crm.campaign.order.confirmed",
          "crm.campaign.request",
          "crm.campaign.request.declined",
          "crm.campaign.request.waitinglist",
          "crm.campaign.order.cancellation",
          "crm.campaign.order.pre_experience",
          "crm.campaign.order.post_experience",
          "crm.campaign.offer.paid_byguest",
          "crm.campaign.order.paid_byguest"
        ],
        disabled_cta_bar: [
          "crm.campaign.request",
          "crm.campaign.request.declined",
          "crm.campaign.request.waitinglist",
          "crm.campaign.offer.confirmed_byguest",
          "crm.campaign.order.confirmed_byguest",
          "crm.campaign.order.confirmed",
          "crm.campaign.order.cancellation",
          "crm.campaign.order.pre_experience",
          "crm.campaign.order.post_experience"
        ]
      }
    };
  },
  computed: {
    availableLocales: function () {
      return ["de", "it", "en", "fr", "nl", "pl", "cs", "ru", "es"];
    },
    isCheckout() {
      return this.$route.name === "checkout";
    },
    currentCurrency() {
      let currencyDef = this?.page?.currency || "EUR";
      return this.currency.availableCurrencies.find(
        (currency) => currency.code == currencyDef
      );
    },
    treatmentsDataComputed() {
      return (
        (this.selectedVariant?.properties?.data.filter(
          (o) => o.product_type == "spa" || o.product_type == "package"
        ).length > 0 &&
          this.mapProductsOnProperties(
            this.selectedVariant.properties.data.filter(
              (o) => o.product_type == "spa" || o.product_type == "package"
            )
          )) ||
        false
      );
    },
    cancelPolicyStateComputed() {
      return (
        this.page.design_data.config?.offer?.cancel_policy_show || "closed"
      );
    },
    priceTableStateComputed() {
      return this.page.design_data.config?.offer?.pricetable_show || "closed";
    },
    onCancel() {
      this.debug && console.log("User cancelled the loader.");
    },
    showPrice(price, value, type) {
      return type == 0 ? (price / 100) * value : value;
    },
    log(val) {
      console.log(val);
    },
    backendURL() {
      return (
        (this.env && this.env == "prod"
          ? process.env.VUE_APP_API_BACKEND_PROD
          : process.env.VUE_APP_API_BACKEND) + "/pub/lp/"
      );
    },
    btnDisabled() {
      return this.hash_type == "versioned";
      // return (this.is('option') && !this.selectedPaymentMethod) ? true : false
    },
    isMobile: function () {
      return this.windowWidth < 769;
    },
    lpURL() {
      return process.env.VUE_APP_FRONTEND + "/" + this.hash;
    },
    showDiscount() {
      if (this.page.offer_show_discount == 0) {
        return false;
      } else if (this.page.offer_show_discount == 1) {
        return true;
      } else {
        let returnVal = false;
        this.selectedVariant.price_details.forEach((pd) => {
          if (pd.discount_value != 0) {
            returnVal = true;
          }
        });
        return returnVal;
      }
    }
  },
  methods: {
    openURL(card) {
      const url = card.content_json?.cta_url;
      if(!url) return;
      if(url.startsWith('mailto:')) {
        window.location.href = url;
      } else {
        window.open(url, "_blank");
      }
    },
    getMedia(card, scope = false) {
      return scope
        ? scope == "picture"
          ? ("media" in card.content_data?.data?.components[0] &&
              card.content_data?.data?.components[0].media.find(
                (m) => m.type == "image" && m.scope == scope
              )) ||
            false
          : card.content_data?.data?.components[0].media.filter(
              (m) => m.type == "image" && m.scope == scope
            ) || false
        : card.content_data?.data?.components[0].media;
    },
    isTooDark(hexcolor) {
      if (hexcolor) {
        const r = parseInt(hexcolor.substr(1, 2), 16);
        const g = parseInt(hexcolor.substr(3, 2), 16);
        const b = parseInt(hexcolor.substr(4, 2), 16);
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        return yiq < 100 ? "#eeeaea" : "#414141";
      } else {
        return "#414141";
      }
    },
    parseForScript(forceInjectScript = false, items) {
      let regex = /<script.*?src="(.*?)"/gim;
      let regexCSS = /<link.*?href="(.*?)"/gim;
      items.forEach((highlight) => {
        if (
          highlight &&
          highlight.content_data.data &&
          "components" in highlight.content_data.data &&
          highlight.content_data.data.components.length > 0
        ) {
          if (forceInjectScript || highlight.config.expanded) {
            let urlJS = regex.exec(
              highlight.content_data.data.components[0].content_html
            );
            let urlCSS = regexCSS.exec(
              highlight.content_data.data.components[0].content_html
            );
            if (urlJS) {
              let s = document.createElement("script");
              s.type = "text/javascript";
              s.src = urlJS[1];
              document.head.appendChild(s);
            }

            if (urlCSS) {
              let cssFile = document.createElement("link");
              cssFile.rel = "stylesheet";
              cssFile.href = urlCSS[1];
              document.head.appendChild(cssFile);
            }
          }
        }
      });
    },
    printAction() {
      try {
        if (!this.page.ready) {
          while (!this.page.ready) {
            window.print();
          }
        } else {
          window.print();
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeCheckOut() {
      this.$bvModal.hide("show-checkout");
      this.$router.push({
        name: "hashed",
        params: {
          hash: this.page.hash
        }
      });
    },
    designDataSettingsComputed() {
      return this.page.design_data?.config?.settings || {};
    },
    designDataStyleComputed() {
      return this.page.design_data?.style || {};
    },
    mapProductsOnProperties(properties = null) {
      try {
        if (properties) {
          // throw "XY"
          properties.map((property) => {
            let mapped = [];
            this.selectedVariant.products.data.forEach((product) => {
              if (property.products.includes(product.id)) {
                mapped.push(product);
              }
            });
            property.products_mapped = mapped;
            return property;
          });
          return properties;
        } else {
          throw "mapProductsOnProperties::ERROR empty properties passed";
        }
      } catch (error) {
        if (properties) {
          properties = properties.map((property) => {
            return {
              ...property,
              products_mapped: []
            };
          });
        }
        return properties;
      }
    },
    queryOptions() {
      let queryOptions = {};
      Object.keys(this.$route.query).forEach((key) => {
        if (key.indexOf("options.") >= 0) {
          queryOptions[key.split(".")[1]] = this.$route.query[key];
        }
      });
      return queryOptions;
    },
    buildMediaURL(url, provider = "cdn", dimension = null) {
      try {
        let debugInfo = this.debug ? "#buildMediaURL" : "";
        let computedURL =
          url +
          (url.indexOf("?") >= 0 ? "&" : "?") +
          (provider == "mss" ? "w=" + dimension : "d=" + dimension);
        return dimension ? computedURL + debugInfo : url;
      } catch (error) {
        console.log(
          "exception->buildMediaURL:args(url,provider,dimension)",
          url,
          provider,
          dimension
        );
        console.log("exception->buildMediaURL:error", error);
        return url;
      }
    },
    validateEmail(email) {
      let pattern = /^\w+(?:[.-]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,63})+$/g;
      return pattern.test(email);
    },
    is(type, scope = false) {
      return this.scopes[type]
        ? this.scopes[type].indexOf(scope ? scope : this.page?.entity?.scope) >=
          0
          ? true
          : false
        : false;
    },
    startConversation(type, placeholder = null, contact = {}) {
      if (type == "whatsapp") {
        new Promise((resolve) => {
          this.whatsappURL = contact.whatsapp
            ? "https://wa.me/" +
              contact.whatsapp.replace("+", "").split(" ").join("") +
              "?text=" +
              encodeURIComponent(placeholder)
            : null;
          resolve(this.whatsappURL);
        }).then((url) => {
          if (this.isMobile) {
            window.open(url, "_blank");
          } else {
            this.$bvModal.show("whatsapp-box");
          }
        });
      } else if (type == "email") {
        window.location.href =
          "mailto:" +
          contact.email +
          "?Subject=" +
          this.placeholder("%label.offer.title%", this.$t("entity.offer")) +
          " " +
          this.entity.slug +
          " " +
          this.$t("chat_email_from") +
          " " +
          this.account.name;
      } else {
        let phone = "tel:" + (contact.phone ? contact.phone : contact.mobil);
        window.location.href = phone;
      }
    },
    returnImgWidth(width = false, ratio = 2.36) {
      let theWidth = width || this.windowWidth;
      return theWidth == 0
        ? "1024x433"
        : theWidth + "x" + parseInt(theWidth / ratio);
    },
    getVal(value, fallback = false) {
      return typeof value !== "undefined" || value !== null ? value : fallback;
    },
    showOutput(item) {
      return (
        item.item.amount ||
        item.item.discount_value ||
        item.item.pu_price ||
        item.item.quantity
      );
    },
    formatPriceTitle(text) {
      text = text || "";
      let text_replaced = text.replace(/^\s+/, "");
      return (
        '<div style="padding-left:' +
        ((text.length - text_replaced.length) * 4).toString() +
        'px">' +
        text_replaced +
        "</div>"
      );
    },
    placeholder(
      placeholder,
      fallback = false,
      forceScope = false,
      params = {}
    ) {
      var returnVal = null;
      var defaultVal = null;

      let custom_labels = this.page.design_data.config.custom_labels || [];
      let pageData = {
        scope: this.page.entity.scope,
        locale: this.page.locale,
        hash: this.page.hash
      };

      let scope = custom_labels.filter((p) => p.placeholder === placeholder);

      if (scope.find((s) => s.scope == pageData.scope)) {
        returnVal = scope.find((s) => s.scope == pageData.scope);
      } else {
        scope.forEach((s) => {
          returnVal =
            s.scope != "" &&
            pageData.scope.indexOf(s.scope.replace("*", "")) === 0
              ? s
              : returnVal;
          defaultVal = s.scope == "" ? s : defaultVal;
        });
      }
      let returnObj = returnVal
        ? returnVal
        : forceScope
        ? fallback
        : defaultVal;

      let l_label =
        this.query_options?.show_labels || this.mode == "configuration"
          ? '<span title="' +
            placeholder +
            (returnObj && returnObj.scope
              ? " / Scope: " + returnObj.scope
              : "") +
            '" onclick="alert(\'' +
            placeholder +
            '\')" class="badge badge-success debug_badge">LABEL</span> '
          : "";
      let f_label =
        this.query_options?.show_labels || this.mode == "configuration"
          ? '<span title="' +
            placeholder +
            '" onclick="alert(\'' +
            placeholder +
            '\')" class="badge badge-info debug_badge">FALLBACK</span> '
          : "";
      let string =
        returnObj &&
        returnObj.translations &&
        returnObj.translations[this.page.locale]
          ? l_label + returnObj.translations[this.page.locale].label
          : f_label + fallback;

      Object.keys(params).forEach((k) => {
        string = string.replace(new RegExp("\\{" + k + "\\}", "g"), params[k]);
      });
      return string;
    },
    placeholderString(text, parameter = {}) {
      Object.keys(parameter).forEach((k) => {
        text = text.replace(new RegExp("\\{" + k + "\\}", "g"), parameter[k]);
      });
      return text;
    },
    formatPrice(price, currencyCode = null) {
      if (!currencyCode) {
        currencyCode = this.currentCurrency.code;
      }
      return Intl
        ? Intl.NumberFormat(this.currentCurrency.locale, {
            style: "currency",
            currency: currencyCode
          }).format(price)
        : parseFloat(price).toFixed(2) +
            " x" +
            (this.currency.availableCurrencies.find(
              (c) => c.code === currencyCode
            )?.symbol || "");
    },
    formatDate(date, timezone = "Europe/Rome") {
      let datetimeFormat = this.datetimeFormats.find(
        (ft) => ft.lang == (this.$i18n.locale || "en")
      ).longForm;
      if (date)
        return this.$moment(new Date(date)).tz(timezone).format(datetimeFormat);
    },
    timeDifference(start, end) {
      if (!start || !end) {
        return;
      }
      start = start.split(":");
      end = end.split(":");
      let startDate = new Date(0, 0, 0, start[0], start[1], 0);
      let endDate = new Date(0, 0, 0, end[0], end[1], 0);
      let diff = endDate.getTime() - startDate.getTime();
      let hours = Math.floor(diff / 1000 / 60 / 60);
      diff -= hours * 1000 * 60 * 60;
      let minutes = Math.floor(diff / 1000 / 60);

      // return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
      return hours * 60 + minutes;
    }
  }
};