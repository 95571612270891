var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.coupon.modal)?_c('b-modal',{attrs:{"id":"coupon-box","size":"lg","hide-header":"","no-close-on-backdrop":"","no-close-on-esc":"","centered":""}},[(!_vm.coupon.progress)?_c('div',{staticClass:"closeModal"},[_c('button',{on:{"click":function($event){return _setup.couponBoxClose()}}},[_c('span',{staticClass:"close-label"},[_vm._v(_vm._s(_vm.$t("entity.treatment.close")))]),_c('span',{staticClass:"closeIcon"},[_c('b-icon',{attrs:{"icon":"x"}})],1)])]):_vm._e(),_c('div',{staticClass:"p-3"},[(_vm.coupon.page === 'default')?[_c('h2',[_c('span',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.coupon.default.title%',
                _vm.$t('coupon.modal.default.title')
              )
            )}})]),_c('div',{staticClass:"mx-0 my-0 my-lg-4 mx-lg-4"},[_c('p',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.coupon.default.text%',
                _vm.$t('coupon.modal.default.text', {
                  deposit_value: _setup.formatPrice(_vm.selectedVariant.deposit_balance)
                }),
                false,
                {
                  deposit_value: _setup.formatPrice(_vm.selectedVariant.deposit_balance)
                }
              )
            )}})]),_c('div',{staticClass:"my-5"},[_c('b-col',{staticClass:"mx-auto",attrs:{"cols":"12","lg":"6"}},[_c('b-input-group',{staticClass:"mt-3",scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"ml-2",staticStyle:{"padding-top":"3px","height":"37px","width":"40px"}},[(_vm.coupon.progress)?_c('b-spinner',{attrs:{"variant":"secondary","label":"Spinning"}}):_vm._e()],1)]},proxy:true}],null,false,2480494674)},[_c('b-form-input',{attrs:{"readonly":_vm.coupon.progress,"label":_vm.$t('coupon.input.code'),"placeholder":_vm.$t('coupon.input.code')},model:{value:(_vm.coupon.code),callback:function ($$v) {_vm.$set(_vm.coupon, "code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"coupon.code"}})],1)],1)],1),_c('div',{staticClass:"text-right mt-5"},[_c('button',{staticClass:"btn action-book btn-success btn-lg",class:{
              'custom-italic': _vm.page.design_data.style?.cta?.italic,
              'custom-bold': _vm.page.design_data.style?.cta?.bold
            },attrs:{"disabled":_vm.coupon.progress || !_vm.coupon.code || _vm.coupon.code?.length < 3,"type":"button"},domProps:{"innerHTML":_vm._s(
              _setup.placeholder('%label.offer.ctabtn%', _vm.$t('entity.buttons.book'))
            )},on:{"click":function($event){return _setup.couponAction('redeem')}}})])]:_vm._e(),(_vm.coupon.page == 'insufficient')?[_c('h2',[_vm._v(" "+_vm._s(_setup.placeholder( "%label.coupon.insufficient.title%", _vm.$t("coupon.modal.insufficient.title") ))+" ")]),_c('div',{staticClass:"mx-0 my-0 my-lg-4 mx-lg-4"},[_c('p',[_vm._v(" "+_vm._s(_setup.placeholder( "%label.coupon.insufficient.text%", _vm.$t("coupon.modal.insufficient.text", { coupon_amount: _setup.formatPrice(_vm.coupon.amount), deposit_balance: _setup.formatPrice( (_vm.selectedVariant.deposit_balance > 0 ? _vm.selectedVariant.deposit_balance : _vm.selectedVariant.total_amount) - _vm.coupon.amount ) }), false, { coupon_amount: _setup.formatPrice(_vm.coupon.amount), deposit_balance: _setup.formatPrice( (_vm.selectedVariant.deposit_balance > 0 ? _vm.selectedVariant.deposit_balance : _vm.selectedVariant.total_amount) - _vm.coupon.amount ) } ))+" ")])]),_c('div',{staticClass:"text-right mt-5"},[_c('button',{staticClass:"btn action-book btn-secondary btn-lg mr-3",class:{
              'custom-italic': _vm.page.design_data.style?.cta?.italic,
              'custom-bold': _vm.page.design_data.style?.cta?.bold
            },attrs:{"type":"button"},on:{"click":function($event){_vm.coupon.page = 'default'}}},[_vm._v(" "+_vm._s(_vm.$t("coupon.cancel"))+" ")]),_c('button',{staticClass:"btn action-book btn-success btn-lg",class:{
              'custom-italic': _vm.page.design_data.style?.cta?.italic,
              'custom-bold': _vm.page.design_data.style?.cta?.bold
            },attrs:{"type":"button"},on:{"click":function($event){return _setup.couponAction('continue')}}},[_vm._v(" "+_vm._s(_vm.$t("coupon.redeem"))+" ")])])]:_vm._e(),(_vm.coupon.page === 'invalid')?[_c('h2',[_c('span',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.coupon.invalid.title%',
                _vm.$t('coupon.modal.invalid.title')
              )
            )}})]),_c('div',{staticClass:"mx-0 my-0 my-lg-4 mx-lg-4"},[_c('p',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.coupon.invalid.text%',
                _vm.$t('coupon.modal.invalid.text')
              )
            )}})]),_c('div',{staticClass:"text-right mt-5"},[_c('button',{staticClass:"btn action-book btn-secondary btn-lg mr-3",class:{
              'custom-italic': _vm.page.design_data.style?.cta?.italic,
              'custom-bold': _vm.page.design_data.style?.cta?.bold
            },attrs:{"type":"button"},on:{"click":function($event){_vm.coupon.page = 'default'}}},[_vm._v(" "+_vm._s(_vm.$t("coupon.back"))+" ")])])]:_vm._e(),(_vm.coupon.page === 'remaining')?[_c('h2',[_c('span',{domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.coupon.remaining.title%',
                _vm.$t('coupon.modal.remaining.title')
              )
            )}})]),_c('div',{staticClass:"mx-0 my-0 my-lg-4 mx-lg-4"},[_c(_setup.ExecutedPayments,{staticClass:"pb-3",attrs:{"page":_vm.page,"lpLocale":_vm.lpLocale}}),_c('div',{staticClass:"py-3",domProps:{"innerHTML":_vm._s(
              _setup.placeholder(
                '%label.coupon.remaining.text%',
                _vm.$t('coupon.modal.remaining.text', {
                  deposit_balance: _setup.formatPrice(
                    _vm.selectedVariant.deposit_balance
                  )
                }),
                false,
                {
                  deposit_balance: _setup.formatPrice(
                    _vm.selectedVariant.deposit_balance
                  )
                }
              )
            )}}),_c(_setup.PayMethods,{staticClass:"my-4",attrs:{"selectedVariant":_vm.selectedVariant,"book-label":_setup.placeholder('%label.offer.ctabtn%', _vm.$t('entity.buttons.book')),"direction":"inline","page":_vm.page},on:{"save":function($event){return _setup.bookAction(false)},"change":function($event){_setup.selectedPaymentMethod = $event}}})],1),_c('div',{staticClass:"text-right mt-5"},[_c('button',{staticClass:"btn action-book btn-success btn-lg",class:{
              'custom-italic': _vm.page.design_data.style?.cta?.italic,
              'custom-bold': _vm.page.design_data.style?.cta?.bold
            },attrs:{"disabled":!_setup.selectedPaymentMethod,"type":"button"},on:{"click":function($event){return _setup.bookAction(true)}}},[_c('span',[_vm._v(_vm._s(_setup.placeholder("%label.offer.ctabtn%", _vm.$t("entity.buttons.book"))))]),(_setup.isLoading)?_c('b-spinner',{staticClass:"ml-2",attrs:{"small":"","variant":"light"}}):_vm._e()],1)])]:_vm._e()],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }