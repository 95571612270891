<template>
  <div class="fixed-footer" v-if="fixedFooterVisible">
    <b-container>
      <b-row>
        <b-col cols="12" class="px-2 px-md-1 d-flex justify-content-between">
          <div class="d-flex justify-content-start">
            <div class="variant-name">
              <span
                v-html="
                  selectedVariant.title
                    ? selectedVariant.title
                    : placeholder('%label.offer.title%', $t('entity.offer')) +
                      ': ' +
                      page.entity.slug
                "
              ></span>
            </div>
            <div class="variant-extras d-flex flex-column align-items-start">
              <template
                v-if="
                  $moment(new Date()).diff(
                    $moment(
                      selectedVariant.cancelpolicy_data.refundable_until
                    ).format()
                  ) < 0
                "
              >
                <div>
                  <span
                    class="badge badge-success cancel_policy-badge mb-1"
                    v-b-tooltip.hover.html
                    v-if="
                      'content' in page.design_data.config &&
                      'badge_cancellation' in page.design_data.config.content &&
                      page.design_data.config.content.badge_cancellation
                    "
                    :title="
                      placeholder(
                        '%label.cancel_policy.free_cancellation_until%',
                        $t('entity.cancel_policy.free_cancellation_until')
                      ) +
                      ' ' +
                      formatDate(
                        selectedVariant.cancelpolicy_data.refundable_until
                      )
                    "
                  >
                    <span
                      v-html="
                        placeholder(
                          '%label.cancel_policy.free_cancellation%',
                          $t('entity.cancel_policy.free_cancellation')
                        )
                      "
                    ></span>
                  </span>
                </div>
              </template>
              <template v-if="selectedVariant.deposit_amount == 0">
                <div>
                  <span
                    class="badge badge-secondary no_deposit-badge"
                    v-if="
                      'content' in page.design_data.config &&
                      'badge_deposit' in page.design_data.config.content &&
                      page.design_data.config.content.badge_deposit
                    "
                    v-html="
                      placeholder(
                        '%label.deposit_policy.no_deposit_needed%',
                        $t('entity.no_deposit_needed')
                      )
                    "
                  ></span>
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex footer-right-col">
            <div
              class="variant-price-show-table text-center text-lg-left d-flex align-items-end d-none d-lg-flex ml-2 mr-2 ml-lg-0 mr-lg-4"
            >
              <a
                :class="{
                  'custom-italic':
                    'rooms' in page.design_data.style &&
                    'storno_italic' in page.design_data.style.rooms &&
                    page.design_data.style.rooms.storno_italic,
                  'custom-bold':
                    'rooms' in page.design_data.style &&
                    'storno_bold' in page.design_data.style.rooms &&
                    page.design_data.style.rooms.storno_bold
                }"
                @click.prevent="$bvModal.show('show-price-table')"
              >
                {{ $t("entity.price.show_price_details_short") }}
              </a>
            </div>
            <div class="d-flex flex-column justify-content-between">
              <div class="variant-price-label">
                <span
                  v-html="
                    placeholder(
                      '%label.amount%',
                      $t('entity.price.total_price')
                    )
                  "
                ></span
                >:
              </div>
              <div class="variant-price-value">
                {{ formatPrice(Number(selectedVariant.total_amount) + calcTotalAmountOfServices()) }}
              </div>
            </div>
            <div
              v-if="showButton"
              class="d-flex flex-column align-items-center justify-content-center variant-price-action"
            >
              <BookButton :page="page" :btn-disabled="btnDisabled" />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script setup>
import { useUpselling } from "@/composables/useUpselling";

const { calcTotalAmountOfServices } = useUpselling();
</script>
<script>
import { lpMixins } from "@/mixins/lpMixins";
import BookButton from "./BookButton";

export default {
  mixins: [lpMixins],
  components: {
    BookButton
  },
  name: "CTABar",
  props: [
    "fixedFooterVisible",
    "selectedVariant",
    "page",
    "showButton",
    "reservation_process"
  ],
  methods: {}
};
</script>

<style scoped></style>