var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"executed-payments"},_vm._l((_vm.page.entity.payments?.data),function(payment,index){return _c('div',{key:index},[(_setup.paymentDetails(payment))?_c('div',{staticClass:"payment py-1"},[(
          (payment.display_status === 'authorized' &&
            payment.provider_slug !== 'BankTransfer') ||
          payment.display_status === 'paid'
        )?_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"check-circle-fill","variant":"success"}}):_vm._e(),(
          payment.display_status === 'authorized' &&
          payment.provider_slug === 'BankTransfer'
        )?_c('b-icon',{staticClass:"authorized-payment-icon mr-1",attrs:{"icon":"clock"}}):_vm._e(),(payment.display_status === 'preauthorized')?_c('b-img',{staticClass:"preauthorized-payment-img mr-1",attrs:{"src":_setup.preauthorizedIcon}}):_vm._e(),_c('p',{staticClass:"d-inline",domProps:{"innerHTML":_vm._s(_setup.paymentDetails(payment))}})],1):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }