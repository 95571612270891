<template>
  <videoPlayer
    ref="player"
    class="d-flex align-items-center embed-responsive-item"
    :options="{
      sources: [
        {
          type: 'video/mp4',
          src: public_url
        }
      ],
      autoplay: true,
      ...options
    }"
  />
</template>
<script setup>
import { videoPlayer } from "vue-video-player";

import { shallowRef } from "vue";

defineProps({
  options: { required: true },
  public_url: { required: true }
});
const player = shallowRef();
defineExpose({ player });
</script>
