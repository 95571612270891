<template>
  <div>
    <div class="price-promocode d-flex mt-4 pt-2">
      <b-input-group class="mr-3">
        <b-form-input
          :state="promocode.state"
          v-model="promocode.code"
          :disabled="promocode.loading"
          class="mr-3"
          @input="promocode.apply = true"
          @keydown.enter="applyPromocode"
          :placeholder="$t('promocode.label')"
        >
        </b-form-input>
        <b-spinner
          v-if="promocode.loading"
          variant="primary"
          style="
            width: 25px;
            height: 25px;
            top: 6px;
            position: absolute;
            right: 24px;
          "
        ></b-spinner>
      </b-input-group>
      <button
        v-if="promocode.object && !promocode.apply"
        class="btn btn-secondary"
        @click="deletePromocode(promocode.object.reference_id)"
      >
        {{ $t("promocode.remove") }}
      </button>
      <button
        v-else
        :disabled="!promocode.code||promocode.loading"
        class="btn btn-secondary apply-promocode"
        @click="applyPromocode"
      >
        {{ $t("promocode.apply") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Promocode",
  props: ["requestURL", "priceDetails"],
  data() {
    return {
      promocode: {
        code: null,
        object:
          (this.priceDetails &&
            this.priceDetails.find(
              (pd) => pd.reference_type == "crm_campaigns_promocodes"
            )) ||
          null,
        state: null,
        apply: false,
        loading: false
      }
    };
  },
  mounted() {
    this.resetCode();
    this.promocode.state = this.promocode.code ? true : null;
    this.promocode.apply = false;
  },
  methods: {
    resetCode() {
      this.promocode.code =
        (this.promocode.object && this.promocode.object.title) ||
        this.promocode.code;
    },
    async applyPromocode() {
      this.promocode.loading = true;
      let payload = {
        promocode: this.promocode.code
      };

      (await this.promocode.code) &&
        this.axios
          .post(this.requestURL + "/promocode", payload)
          .then((response) => {
            this.$emit("render", response.data.data);
            this.promocode.object =
              response.data.data.entity.data.variants.data[0].price_details.find(
                (pd) => pd.reference_type == "crm_campaigns_promocodes"
              );
            this.promocode.apply = false;

            this.$toast.success(this.$t("promocode.added"), {
              position: "bottom",
              duration: 10000
            });
            this.promocode.loading = false;
            this.promocode.state = true;
          })
          .catch(() => {
            this.promocode.state = false;
            this.$toast.error(this.$t("promocode.not_valid"), {
              position: "bottom",
              duration: 10000
            });
            this.promocode.loading = false;
          });
    },
    async deletePromocode(id) {
      this.promocode.loading = true;
      this.promocode.state = null;

      await this.axios
        .delete(this.requestURL + "/promocode/" + id)
        .then((response) => {
          this.$emit("render", response.data.data);
          this.$toast.success(this.$t("promocode.deleted"), {
            position: "bottom",
            duration: 10000
          });
          this.promocode.code = null;
          this.promocode.object = null;
          this.promocode.loading = false;
        })
        .catch((error) => {
          console.log("Promocode delete error", error.response.data.message);
          this.$toast.error(error.response.data.message, {
            position: "bottom",
            duration: 10000
          });
        });
      this.promocode.loading = false;
    }
  }
};
</script>

<style scoped></style>