<template>
  <b-container class="image-box-component px-0" v-if="items">
    <b-row class="image-box-row">
      <b-col class="image-box-item" cols="12">
        <ul class="highlight-accordion" :class="'items-count-' + items.length">
          <li
            v-for="(tab, index) in items"
            :key="'acc_' + tab.id"
            :class="[
              'highlight-accordion__tile',
              activeTab == index
                ? 'highlight-accordionT--active'
                : 'highlight-accordionT--inactive'
            ]"
            :style="`background:  url('${
              getMedia(tab, 'picture').public_url + '?d=800x600'
            }') no-repeat center center; background-size: cover;`"
            @click="index !== activeTab && openTab(index)"
          >
            <div
              :class="[
                !desktopSize ? 'scroll-wrapper' : 'highlight-accordion__content__wrapper',
                {
                  'scroll-wrapper-inactive': !desktopSize && activeTab !== index
                }
              ]"
            >
              <div
                class="button-wrapper"
                :class="{
                square:
                  designData.style &&
                  'radius' in designData.style.general &&
                  designData.style.general.radius != '' &&
                  parseInt(designData.style.general.radius) == 0
              }"
              >
                <b-icon v-if="activeTab < index" icon="chevron-left"></b-icon>
                <b-icon v-else icon="chevron-right"></b-icon>
              </div>
              <div
                class="highlight-accordion__title_column pr-1"
              >
                <div class="highlight-accordion__tile__size">
                  <div class="highlight-accordion__tile__wrapper">
                    <h4 v-if="activeTab === index" class="highlight-accordion__tile__title">
                      {{ tab.content_data.data.components[0].title }}
                    </h4>
                    <h4 v-else class="highlight-accordion__tile__title">
                      <TruncateText
                        :content="tab.content_data.data.components[0].title"
                        :maxLength="truncateLength"
                      />
                    </h4>
                  </div>
                </div>
              </div>
              <div class="highlight-accordion__tile__content">
                <div
                  :class="{
                  'highlight-accordion-no-button':
                    !tab.content_data.data.components[0].config.cta ||
                    !tab.content_data.data.components[0].content_json.cta_label
                }"
                  class="highlight-accordion__tile__excerpt"
                >
                  <p
                    class="mb-0 pr-1"
                    v-html="tab.content_data.data.components[0].content_html"
                  ></p>
                </div>
                <div
                  v-if="
                  desktopSize &&
                  tab.content_data.data.components[0].config.cta &&
                  tab.content_data.data.components[0].content_json.cta_label
                "
                  class="mt-3 text-right"
                >
                  <b-btn
                    class="btn btn-success highlight_cta"
                    @click="openURL(tab.content_data.data.components[0])"
                  >
                    {{
                      tab.content_data.data.components[0].content_json.cta_label
                    }}
                  </b-btn>
                </div>
              </div>
            </div>
            <div
              v-if="
                  activeTab == index && !desktopSize &&
                  tab.content_data.data.components[0].config.cta &&
                  tab.content_data.data.components[0].content_json.cta_label
                "
              class="highlight-accordion__button text-right"
            >
              <b-btn
                class="btn btn-success highlight_cta"
                @click="openURL(tab.content_data.data.components[0])"
              >
                {{
                  tab.content_data.data.components[0].content_json.cta_label
                }}
              </b-btn>
            </div>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { lpMixins } from "@/mixins/lpMixins";
import TruncateText from "@/components/TruncateText.vue";

export default {
  name: "Accordion",
  mixins: [lpMixins],
  props: ["highlights", "designData"],
  components: { TruncateText },
  data() {
    return {
      items: null,
      activeTab: 0
    };
  },
  computed: {
    desktopSize() {
      return this.windowWidth > 1024;
    },
    truncateLength() {
      const truncatesMap = [
        { max: 450, value: 10 },
        { max: 550, value: 17 },
        { max: 650, value: 27 },
        { max: 750, value: 37 },
        { max: 850, value: 47 },
        { max: 998, value: 53 },
        { max: 1024, value: 67 }
      ]

      for (const range of truncatesMap) {
        if (this.windowWidth <= range.max) {
          return range.value;
        }
      }

      return 30;
    }

  },
  methods: {
    useScroll() {
      const elements = document.querySelectorAll(this.desktopSize ? '.highlight-accordion__tile__excerpt' : '.scroll-wrapper');
        elements.forEach(element => {
          element.style.overflow = 'hidden';

          setTimeout(() => {
            element.style.overflow = 'hidden auto'
          }, 400);
        })
    },
    openTab(i) {
      this.activeTab = i;
      this.useScroll();
    }
  },
  mounted() {
    if (this.highlights.length > 5) {
      this.items = this.highlights.splice(0, 5);
    } else {
      this.items = this.highlights;
    }
    this.$nextTick(() => {
      this.useScroll();
    })
  }
};
</script>

<style scoped lang="sass">
@import "../../../styles/components/highlights/Accordion.sass"
</style>